/* eslint-disable no-shadow */
/* eslint-disable import/order */
import React, { useEffect } from 'react';

import { connect } from 'react-redux';
import { Switch } from 'react-router';
import { bindActionCreators } from 'redux';

// **Catálogo
import Skus from 'pages/catalog/skus/index';
import Fichas from 'pages/catalog/cards';
import Produtos from 'pages/catalog/products';
import AdminProdutos from 'pages/catalog/products/AdminProducts';
import OperatingExpenses from 'pages/operating-expenses';

// **Catálogo Cadastro
import FabricantesCadastro from 'pages/catalog/manufacturers/ProducerForm';
import MarcasCadastro from 'pages/catalog/brands/BrandForm';
import ProdutosCadastro from 'pages/catalog/products/Register';
import FichasCadastro from 'pages/catalog/cards/Register';

// **Geral
import Cardapio from 'pages/sales/menu/menu';
import Cmv from 'pages/sales/Cmv';
import Schedule from 'pages/sales/schedule';
import SalesNfe from 'pages/sales/SalesNfe';
import SefazLogs from 'pages/logs/Sefaz';
import Fornecedores from 'pages/suppliers/index';
import Recebimento from 'pages/receipt';
import MonthlyEvolution from 'pages/shopping/monthlyEvolution';
import Pedidos from 'pages/ordered';
import SugestoesDeCompra from 'pages/ordered/purchasing-suggestions';
import Estoque from 'pages/stock/index';
import Producao from 'pages/production';
import Squares from 'pages/production/Squares'
import ProductionStructure from 'pages/production/Structure';

// **Geral - Cadastro
import PedidosCadastro from 'pages/ordered/Register';

// **Admin
import TipoDeDescarte from 'pages/admin/types-discard/index';
import CancelationReasons from 'pages/admin/cancellationReasons/cancellationReasons';
import ClosureAndSuggestions from 'pages/admin/ClosureAndSuggestions';
import ClosureByRestaurants from 'pages/admin/ClosureByRestaurants';

import ProjecaoDeVendas from 'pages/admin/sales-projection/index';
import Usuarios from 'pages/admin/users/index';
import Parametros from 'pages/admin/parameters/index';
import Lojas from 'pages/admin/stores';
import UnidadesDeMedidas from 'pages/admin/units-of-measurement';
import AdminCatalogacao from 'pages/admin/cataloguing/index';
import QuoteSolicitations from 'pages/admin/quoteSolicitations';
import CreateTag from 'pages/admin/createTag';
import BackOffice from 'pages/admin/BackOffice';

// Admin - Cadastro
import TipoDeDescarteCadastro from 'pages/admin/types-discard/Register';
import ProjecaoDeVendasCadastro from 'pages/admin/sales-projection/Register';
import UnidadesDeMedidasCadastro from 'pages/admin/units-of-measurement/Register';

// **Inventário
import Setup from 'pages/inventory/setup';
import Contagens from 'pages/inventory/Counts/index';
import ExtratoContagem from 'pages/inventory/count-extract/index';
import Periodicidade from 'pages/inventory/periodicity/index';
import LocaisDeArmazenamento from 'pages/inventory/storage-Locations/index';
import DiaryCount from 'pages/inventory/diary-count';
import ContagemAvulsa from 'pages/inventory/single-count';
import CountAdditionalItens from 'pages/inventory/mobile/CountAdditionalItens';
import CountPage from 'pages/inventory/mobile/CountPage';
import TransferReports from 'pages/inventory/transferReport';

// **Notificacoes
import Notifications from 'pages/notifications';

// **Ranking
import Ranking from 'pages/ranking/Ranking';

// **Quotes upload
import QuotesUpload from 'pages/quotesUpload';

// **Quotation manager
import QuotationManager from 'pages/quotationManager';
import QuotationManagerProfile from 'pages/quotationManagerProfile/index';

import SugestaoDeCompraPorFornecedor from 'pages/ordered/purchasing-suggestions/by-supplier';

// Config
import AuthRoute from './AuthRoute';
import PrivateRoute from './PrivateRoutes';
import i18n from 'i18n/i18n';
import Catalogacao from 'pages/cataloguing/index';
import Fabricantes from 'pages/catalog/manufacturers/index';
import Marcas from 'pages/catalog/brands/index';
import RegisterCategories from 'pages/categories/Register';
import RegisterModelCategories from 'pages/admin/modelCategory/cadastro'
import Error from 'pages/error/index';
import Blank from 'pages/blank';
import Indicator from 'pages/Indicators/index';

// Save count assortment
import Login from 'pages/login';
import NewHome from 'pages/newHome/NewHomeInfos';
import Home from 'pages/home';
import Cockpit from 'pages/cockpit';

// Fornecedores
import FornecedoresCatalogo from 'pages/providers/catalog/index';
import FornecedoresClientes from 'pages/providers/clients/index';
import ReceiverDetails from 'pages/receipt/MobileReceipt/ReceiverDetails';
import * as MenuActions from 'store/actions/menu';
import PreparationView from 'pages/production/preparationView';
import { reSendQueue } from 'utils/misc/queue';
import Copilot from 'pages/production/Copilot';
import DailyShedule from 'pages/production/DailySchedule';
import Processos from 'pages/production/Process';

function Routes({ userInfo }) {
  if (userInfo) {
    const { companiesActive: { id: companieActiveId = '' } = {} } = userInfo;
    const { companies: { groups = [] } = {} } = userInfo;

    const subGroups = groups.map(({ subGroups }) => subGroups).flat(Infinity);
    const stores = subGroups.map(({ stores }) => stores).flat(Infinity);
    const activeStore = stores.find((store) => store.id === companieActiveId);

    if (activeStore) {
      const { language } = activeStore;

      if (typeof language === 'string') {
        i18n.changeLanguage(language);
      }
    }
  }

  useEffect(() => {
    window.addEventListener('beforeunload', () => {
      sessionStorage.removeItem('checkQueueOn');
    });

    if (!localStorage.getItem('queue')) return;
    let handleSuccess = () => { };
    const urlCountId = location.pathname
      .split('contagem-diaria/')[1]
      ?.split('/')[1];
    const urlQueue = JSON.parse(localStorage.getItem('queue'))
      ?.url?.split('counts/')[1]
      .split('/')[0];
    if (urlCountId === urlQueue) {
      handleSuccess = () => {
        location.reload();
      };
    }
    reSendQueue(handleSuccess, () => { });
  }, []);

  return (
    <Switch>
      <AuthRoute component={Login} exact path="/login" />

      <PrivateRoute component={Indicator} exact path="/indicadores/:id" />
      <PrivateRoute component={Cmv} exact path="/:id" />
      <PrivateRoute component={Indicator} exact path="/:id" />
      <PrivateRoute component={NewHome} exact path="/home/infos/:id" />
      <PrivateRoute component={Home} exact path="/home/:id" />
      <PrivateRoute component={Cockpit} exact path="/cockpit/:id" />

      {/* Catálogo */}
      <PrivateRoute
        component={Fabricantes}
        exact
        path="/catalogo/fabricantes/:id"
      />
      <PrivateRoute component={Marcas} exact path="/catalogo/marcas/:id" />
      {/* <Route component={Categorias} exact path="/catalogo/categorias" /> */}
      <PrivateRoute component={Skus} exact path="/catalogo/skus/:id" />
      <PrivateRoute component={Skus} exact path="/catalogo/skus/:id/:skuId" />
      <PrivateRoute
        component={RegisterCategories}
        exact
        path="/catalogo/categorias/:id"
      />

      <PrivateRoute
        component={RegisterModelCategories}
        exact
        path="/catalogo/categorias/modelo/:id"
      />

      <PrivateRoute
        component={Fichas}
        exact
        path="/catalogo/fichas/:id/menu/:cardId"
      />
      <PrivateRoute component={Fichas} exact path="/catalogo/fichas/:id" />

      <PrivateRoute component={AdminProdutos} exact path="/admin/catalogo/produtos/:id" />
      <PrivateRoute component={Produtos} exact path="/catalogo/produtos/:id" />

      {/* Ranking */}
      <PrivateRoute component={Ranking} exact path="/ranking/:id" />

      {/* Catálogo - Cadastro */}
      <PrivateRoute
        component={FabricantesCadastro}
        exact
        path="/catalogo/fabricantes/cadastro"
      />
      <PrivateRoute
        component={MarcasCadastro}
        exact
        path="/catalogo/marcas/cadastro"
      />
      <PrivateRoute
        component={ProdutosCadastro}
        exact
        path="/catalogo/produtos/cadastro/:id"
      />
      <PrivateRoute
        component={ProdutosCadastro}
        exact
        path="/catalogo/produto/:id/:productId"
      />
      <PrivateRoute
        component={FichasCadastro}
        exact
        path="/catalogo/fichas/cadastro/:id"
      />
      <PrivateRoute
        component={FichasCadastro}
        exact
        path="/catalogo/fichas/:id/:cardId"
      />

      {/* Geral */}
      {/* <Route component={Cardapio} exact path="/Cardapio" /> */}
      <PrivateRoute component={Fornecedores} exact path="/fornecedores/:id" />
      {/* <Route component={Pedidos} exact path="/pedidos" />
      <PrivateRoute component={EditarCardapio} exact path="/cardapio/Editar" /> */}
      <PrivateRoute component={Recebimento} exact path="/recebimento/:id" />
      <PrivateRoute component={MonthlyEvolution} exact path="/compras/evolucao-mensal/:id" />
      <PrivateRoute
        component={ReceiverDetails}
        exact
        path="/recebimento/:id/:receiverId"
      />
      <PrivateRoute component={Cardapio} exact path="/cardapio/:id" />
      <PrivateRoute component={Cmv} exact path="/cmv/:id" />
      <PrivateRoute component={Schedule} exact path="/agenda/:id" />
      <PrivateRoute component={SalesNfe} exact path="/nfes-vendas/:id" />
      <PrivateRoute component={SefazLogs} exact path="/logs/sefaz/:id" />
      <PrivateRoute component={Pedidos} exact path="/pedidos/:id" />
      <PrivateRoute
        component={Pedidos}
        exact
        path="/pedidos/cotacao/profile/:id"
      />
      <PrivateRoute
        component={SugestoesDeCompra}
        exact
        path="/pedidos/sugestao/:id"
      />
      <PrivateRoute
        component={SugestaoDeCompraPorFornecedor}
        exact
        path="/pedidos/sugestao/fornecedor/:id"
      />
      {/* <Route component={Recebimento} exact path="/recebimento" /> */}
      <PrivateRoute component={Estoque} exact path="/estoque/:id" />
      <PrivateRoute component={Producao} exact path="/producao/:id" />
      <PrivateRoute component={Squares} exact path="/pracas/:id" />
      <PrivateRoute component={Processos} exact path="/processos/:id" />
      <PrivateRoute component={Copilot} exact path="/copiloto/:id" />
      <PrivateRoute component={DailyShedule} exact path="/programacao-diaria/:id" />

      <PrivateRoute
        component={ProductionStructure}
        exact
        path="/estrutura/:id"
      />
      <PrivateRoute
        component={PreparationView}
        exact
        path="/preparation/:pdf"
      />
      <PrivateRoute component={Catalogacao} exact path="/catalogacao/:id" />

      {/* Geral - Cadastro */}
      <PrivateRoute
        component={PedidosCadastro}
        exact
        path="/pedidos/cadastro/:id"
      />
      {/* <Route component={RecebimentoCadastro} exact path="/recebimento/cadastro" /> */}
      {/* <Route component={EstoqueCadastro} exact path="/estoque/cadastro" /> }
      <PrivateRoute component={ProducaoCadastro} exact path="/producao/cadastro" />
      {<Route component={InventarioCadastro} exact path="/inventario/cadastro" /> */}
      {/* Admin */}
      <PrivateRoute
        component={TipoDeDescarte}
        exact
        path="/admin/tipo-descarte/:id"
      />
      <PrivateRoute
        component={CancelationReasons}
        exact
        path="/admin/motivos-cancelamento/:id"
      />
      <PrivateRoute
        component={ClosureAndSuggestions}
        exact
        path="/admin/fechamento-e-sugestoes/:id"
      />
      <PrivateRoute
        component={ClosureByRestaurants}
        exact
        path="/admin/fechamento-por-restaurante/:id"
      />
      <PrivateRoute
        component={ProjecaoDeVendas}
        exact
        path="/admin/projecao-de-vendas/:id"
      />
      <PrivateRoute component={Usuarios} exact path="/admin/usuarios/:id" />
      <PrivateRoute component={Parametros} exact path="/admin/parametros/:id" />
      <PrivateRoute component={Lojas} exact path="/admin/lojas/:id" />
      <PrivateRoute
        component={UnidadesDeMedidas}
        exact
        path="/admin/unidades-de-medidas/:id"
      />

      {/* Admin - Cadastro */}
      <PrivateRoute
        component={TipoDeDescarteCadastro}
        exact
        path="/admin/tipo-de-descarte/cadastro/:id"
      />
      <PrivateRoute
        component={ProjecaoDeVendasCadastro}
        exact
        path="/admin/projecao-de-vendas/cadastro/:id"
      />
      <PrivateRoute
        component={UnidadesDeMedidasCadastro}
        exact
        path="/admin/unidades-de-medidas/cadastro/:id"
      />
      <PrivateRoute
        component={AdminCatalogacao}
        exact
        path="/admin/Catalogacao/:id"
      />
      <PrivateRoute
        component={QuoteSolicitations}
        exact
        path="/admin/solicitacoes-cotacao/:id"
      />
      <PrivateRoute
        component={OperatingExpenses}
        exact
        path="/admin/gastos-operacionais/:id"
      />
      <PrivateRoute component={Skus} exact path="/admin/skus/:id" />
      <PrivateRoute component={Skus} exact path="/admin/skus/:id/:skuId" />

      {/* Inventário */}
      <PrivateRoute component={Setup} exact path="/inventario/setup/:id" />
      <PrivateRoute
        component={Contagens}
        exact
        path="/inventario/contagens/:id"
      />
      <PrivateRoute
        component={ExtratoContagem}
        exact
        path="/inventario/extrato/:id"
      />
      <PrivateRoute
        component={Periodicidade}
        exact
        path="/inventario/periodicidade/:id"
      />
      <PrivateRoute
        component={TransferReports}
        exact
        path="/inventario/relatorio-transferencias/:id"
      />
      <PrivateRoute
        component={LocaisDeArmazenamento}
        exact
        path="/inventario/locais-de-armazenamento/:id"
      />
      <PrivateRoute
        component={DiaryCount}
        exact
        path="/inventario/contagem-diaria/:id/:countId"
        key={Math.random()}
      />
      <PrivateRoute
        component={ContagemAvulsa}
        exact
        path="/inventario/contagem-avulsa/:id"
      />
      <PrivateRoute
        component={CountPage}
        exact
        path="/inventario/contagem-diaria-mobile/:id/:selectItem"
        key={Math.random()}
      />
      <PrivateRoute
        component={CountAdditionalItens}
        exact
        path="/inventario/contagem-adicional-de-item/:id"
      />

      <PrivateRoute component={QuotesUpload} exact path="/cotacao/upload/:id" />
      <PrivateRoute
        component={QuotationManager}
        exact
        path="/cotacao/gestor/:id"
      />
      <PrivateRoute
        component={QuotationManagerProfile}
        exact
        path="/cotacao/gestor/compras/:id"
      />

      {/* Notificacoes */}
      <PrivateRoute component={Notifications} exact path="/notifications" />

      {/** Fornecedores */}
      <PrivateRoute
        component={FornecedoresCatalogo}
        exact
        path="/fornecedores/catalogo/:id"
      />
      <PrivateRoute
        component={FornecedoresClientes}
        exact
        path="/fornecedores/clientes/:id"
      />

      <PrivateRoute component={CreateTag} exact path="/admin/tags/:id" />
      <PrivateRoute
        component={BackOffice}
        exact
        path="/admin/bloom/backoffice/:id"
      />
      <PrivateRoute component={Blank} exact path="/" />

      <PrivateRoute component={Error} />
    </Switch>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.user
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(MenuActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Routes);
