/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';

import { LinearProgress, Tooltip } from '@material-ui/core';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import * as UserActions from 'store/actions/user';

function SkusInfosArea({ 
  skusInfos,
  actualDateLessOne,
  actualDateLessFourteen,
  paramsID 
}) {
  const mainContent = skusInfos?.content

  const progressBarItem = (description, total, percent, color, url) => (
    <Tooltip
      TransitionProps={{ timeout: 600 }}
      className={`barItem ${!url && 'disabledLink'}`}
      disableHoverListener={!url}
      title={
        <span
          className="dBlock"
          style={{ padding: '2px 5px' }}
        >
          Visualizar todos os skus
        </span>
      }
    >
      <Link
        target="_blank"
        to={{
          pathname: url
        }}
      >
        <div className="descriptionBox">
          <span className="fontSizeDefault">
            {description}
          </span>

          <div className="verticalDivider"/>
        </div>

        <div className="w100 dFlex alignCenter">
          <div className="progressBarBorder" style={{ width: `${percent}%` }}>
            <div className="progressBar" style={{ background: color, width: "100%" }} />
          </div>

          <span className="fontSizeDefault bold ml5 mt2">
            {total?.toLocaleString('pt-br', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}
          </span>
        </div>
      </Link>
    </Tooltip>
  )

  return (
    <section className="cockpitSkuInfosContainer cockpitDefaultContainer">
      <div className="graphicContainer">
        <div className="graphicBox">
          <div className="graphicItem">
            <span className="graphicTitle">
              skus
            </span>

            {skusInfos?.loading ? (
              <div className="dFlex">
                <LinearProgress className="w100 mb10" id="loading" variant="query" />
              </div>
            ) : 
              <section className="barInfosContainer">
                {progressBarItem(
                  "total de skus",
                  mainContent?.totalSkus,
                  "100",
                  "#5062F0",
                  `/catalogo/skus/${paramsID}`
                )}

                {progressBarItem(
                  "novos",
                  mainContent?.newSkus,
                  (mainContent?.newSkus || 0) * 100 / 1718,
                  "#AA8208",
                  `/catalogo/skus/${paramsID}?skusNews=true`
                )}

                {progressBarItem(
                  "skus sem produto",
                  mainContent?.skusWithoutProduct,
                  (mainContent?.skusWithoutProduct || 0) * 100 / 1718,
                  "#F2B90D",
                  `/catalogo/skus/${paramsID}?withOutProduct=true`
                )}

                {progressBarItem(
                  "recebidos s/ catalogar",
                  mainContent?.receivedWithoutCataloging,
                  (mainContent?.receivedWithoutCataloging || 0) * 100 / 1718,
                  "#F53D4C",
                  `/catalogo/skus/${paramsID}?receivedWithoutCataloging=true`
                )}

                {progressBarItem(
                  "sem movimentação",
                  mainContent?.skusWithoutMovement,
                  (mainContent?.skusWithoutMovement || 0) * 100 / 1718,
                  "#CC337F",
                  `/inventario/contagens/${paramsID}?skusWithoutMovement=true`
                )}
              </section>
            }
          </div>
        </div>
      </div>
    </section>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.user
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(UserActions, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SkusInfosArea);
