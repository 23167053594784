import getParamsID from 'services/getParamsID';
import localStorageManager from 'utils/localStorageManager';

const getInitialCompaniesActive = () => {
  const paramsID = getParamsID();
  const { companies } = JSON.parse(localStorage.getItem('companies'));
  if (!companies) return localStorageManager.getLocalStorage('companiesActive');
  const { groups = [] } = companies;
  const [group] = groups;
  const { subGroups = [] } = group;
  let activeStore = '';
  const stores = subGroups.map(({ stores }) => stores).flat(Infinity);

  if (typeof paramsID === 'string' && paramsID?.includes(',')) {
    const splittedParamsId = paramsID.split(',').map((el) => parseFloat(el));
    const isSubGroup = subGroups?.filter((subGroup) =>
      subGroup.stores
        .map((store) => store.id)
        .some((item) => splittedParamsId.includes(item))
    );

    activeStore =
      isSubGroup?.length === 1 || isSubGroup?.length > 1
        ? {
            id: paramsID.split(',').map(el => parseFloat(el)),
            name: isSubGroup?.length > 1 ? group?.name : isSubGroup[0]?.name
          }
        : activeStore;
  } else {
    activeStore = stores.find((store) => store.id === paramsID);
  }

  if (activeStore) {
    localStorageManager.setLocalStorage('companiesActive', {
      id: activeStore?.id,
      name: activeStore?.name,
      index: activeStore?.index,
      segment: activeStore?.segment,
      operationalUsers: activeStore?.operationalUsers,
      userMaster: activeStore?.userMaster
    });
    return activeStore;
  }
  return localStorageManager.getLocalStorage('companiesActive');
};

const INITIAL =
  localStorage.getItem('companies') != null
    ? {
        companiesActive: getInitialCompaniesActive(),
        user: JSON.parse(localStorage.getItem('companies')).user,
        profile: JSON.parse(localStorage.getItem('companies')).profile,
        configs: JSON.parse(localStorage.getItem('companies')).configs,
        companies: JSON.parse(localStorage.getItem('companies')).companies,
        firstSelectRestaurant: JSON.parse(localStorage.getItem('companies'))
          .firstSelectRestaurant,
        systemType: JSON.parse(localStorage.getItem('companies')).systemType,
        operationalUsers: JSON.parse(localStorage.getItem('companies')).operationalUsers,
        userMaster: JSON.parse(localStorage.getItem('companies')).userMaster
      }
    : {
        companiesActive: {
          id: 1,
          name: 'Restaurante ',
          index: 0
        },

        user: 'user',
        profile: 'profile',
        companies: {
          groups: [
            {
              id: 1,
              name: '',
              subGroups: [
                {
                  id: 1,
                  name: '',
                  stores: [
                    {
                      id: 1,
                      name: ''
                    }
                  ]
                }
              ]
            }
          ]
        }
      };

export default function userInfo(state = INITIAL, action) {
  switch (action.type) {
    case 'SET_COMPANIES_ACTIVE':
      localStorageManager.setLocalStorage('companiesActive', {
        id: action.id,
        name: action.name,
        index: action.index,
        segment: action.segment,
        operationalUsers: action.operationalUsers,
        userMaster: action.userMaster
      });
      return {
        ...state,
        companiesActive: {
          id: action.id,
          name: action.name,
          index: action.index,
          segment: action.segment,
          operationalUsers: action.operationalUsers,
          userMaster: action.userMaster
        }
      };
    case 'SET_COMPANIES':
      localStorage.setItem(
        'companies',
        JSON.stringify({
          user: action.user,
          configs: action.configs,
          companies: action.companies,
          profile: action.profile,
          systemType: action.systemType,
          segment: action.segment,
          operationalUsers: action.operationalUsers,
          userMaster: action.userMaster
        })
      );
      localStorageManager.setLocalStorage('companiesActive', {
        id: action.companies.groups[0].subGroups[0].stores[0].id,
        name: action.companies.groups[0].subGroups[0].stores[0].name,
        index: 0,
        keycloakId: action.keycloakId,
        systemType: action.systemType,
        segment: action.companies.groups[0].subGroups[0].stores[0].segment,
        operationalUsers: action.operationalUsers,
        userMaster: action.userMaster
      });

      return {
        ...state,
        user: action.user,
        companies: action.companies,
        configs: action.configs,
        profile: action.profile,
        companiesActive: {
          id: action.companies.groups[0].subGroups[0].stores[0].id,
          name: action.companies.groups[0].subGroups[0].stores[0].name,
          segment: action.companies.groups[0].subGroups[0].stores[0].segment,
          index: 0,
        },
        systemType: action.systemType,
        operationalUsers: action.operationalUsers,
        userMaster: action.userMaster
      };
    case 'SET_FIRST_RESTAURANT':
      localStorage.setItem(
        'companies',
        JSON.stringify({
          ...state,
          firstSelectRestaurant: action.firstSelectRestaurant || false
        })
      );

      return {
        ...state,
        firstSelectRestaurant: action.firstSelectRestaurant || false
      };

    default:
      return state;
  }
}
