import styled from 'styled-components';

export const ImageContainer = styled.div`
  width: ${({ width }) => width || '56px'};
  height: ${({ height }) => height || '56px'};
  background: ${({ solidBgColor }) => solidBgColor || 'transparent'};
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: ${({ borderType, borderWidth }) => `solid ${borderWidth} ${borderType}` || 'none'};
  border-bottom: ${({ borderType, borderWidth }) => `solid ${borderWidth} ${borderType}` || 'none'};
  border-left: ${({ borderType, borderWidth }) => `solid ${borderWidth} ${borderType}` || 'none'};
  border-right: ${({ borderType, borderWidth }) => `solid ${borderWidth} ${borderType}` || 'none'};
  box-shadow: ${({ boxShadow }) => boxShadow};
  border-radius: 4px;
  overflow: hidden;

  .MuiCircularProgress-root {
    width: ${({ width }) => width - '10px' || '20px'} !important;
    height: ${({ height }) => height - '8px' || '20px'} !important;
  }

  img {
    width: fit-content;
    height: fit-content;
    max-height: ${({ maxHeight }) => maxHeight || '56px'};
    max-width: 100%;
  }
`;
