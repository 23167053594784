import React from 'react';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as UserActions from 'store/actions/user';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

ChartJS.defaults.color = '#1F2445';
ChartJS.defaults.font.family = 'Lato'

function GroupedVerticalBarGraphic({ mainContent = [] }) {
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'bottom',
        align: 'end',
        labels: {
          boxHeight: 10,
          usePointStyle: true,
          pointStyle: 'circle',
          font: {
            weight: 700,
          }
        }
      },
      tooltip: {
        footerFont: 'normal',
        displayColors: mainContent?.contentFormatted?.some(el => el?.data?.length) ? !mainContent?.contentFormatted[0]?.dataFormatted[0].tooltipExtraInfo : false,
        callbacks: {
          title() {
            return null
          },
          label(context) {
            let currentLabel = ''

            mainContent?.contentFormatted?.map(el => {
              if (el?.title === context?.dataset?.label) {
                currentLabel = el?.dataFormatted?.find(item => item?.index === context?.dataIndex)?.item
              } 
            })

            return currentLabel
          },
          footer(context) {
            let currentLabel = ''
            
            if (!mainContent?.contentFormatted[0]?.dataFormatted[0].tooltipExtraInfo) return ''

            mainContent?.contentFormatted?.map(el => {
              if (el?.title === context[0]?.dataset?.label) {
                currentLabel = el?.dataFormatted?.find(item => item?.index === context[0]?.dataIndex)?.tooltipExtraInfo
              } 
            })

            return currentLabel
          }
        }
      }
    },
    scales: {
      x: {
        title: {
          color: '#915'
        },
        ticks: {
          font: {
            weight: 'bold'
          } 
        },
        grid: {
          color: '#FFF',
          borderColor: '#FFF',
          tickColor: '#FFF'
        },
        border: {
          color: '#FFF',
        }
      },
      y: {
        
        title: {
          color: '#915'
        },
        ticks: {
          font: {
            weight: 'bold'
          } 
        },
        grid: {
          color: '#FFF',
          borderColor: '#FFF',
          tickColor: '#FFF'
        },
        border: {
          color: '#FFF',
        }
      }
    }
  };
  
  const labels = mainContent?.labels || [];

  const data = {
    labels,
    datasets: mainContent?.contentFormatted?.length ? 
      mainContent?.contentFormatted?.map(el => (
        {
          label: el?.title,
          data: el?.data,
          dataFormatted: el?.dataFormatted,
          backgroundColor: el?.color,
          borderRadius: 15,
          barThickness: 10,
        }
      )) 
    : [],
  };

  return (
    <Bar options={options} data={data} />
  )
}

const mapStateToProps = (state) => ({
  userInfo: state.user
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(UserActions, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GroupedVerticalBarGraphic);
