/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
import React, { useState } from 'react';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
  Typography
} from '@material-ui/core';
import { DeleteOutline } from '@material-ui/icons';
import CurrencyTextField from '@unicef/material-ui-currency-textfield/dist/CurrencyTextField';
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Button from 'components/Button/button';
import PaperComponent from 'components/PaperComponent';
import CloseIcon from 'images/icons/signals/closesAndCancels/close.svg';
import * as ProductsActions from 'store/actions/products';

function UpdateDiscard({
  open,
  fullScreen,
  maxWidth,
  onClose,
  onSubmit,
  item,
  loading,
  product,
  discard,
  date,
  quantity,
  unity,
  extract,
  modalSettings,
  extractItems,
  setModalSettings
}) {
  const [body, setBody] = useState({
    quantity,
    discard,
    product,
    extract
  });
  const [multiBody, setMultiBody] = useState([]);
  return (
    <Dialog
      fullScreen={fullScreen}
      maxWidth={maxWidth}
      open={open}
      PaperComponent={PaperComponent}
      onClose={() => {
        onClose();
      }}
      aria-labelledby="draggable-dialog-title"
      className="defaultModal updateDiscardDialog"
    >
      <DialogTitle
        className="modalHeader bold textAlignCenter cursorMove"
        id="draggable-dialog-title"
      >
        <Typography>Editar Descarte</Typography>

        <IconButton
          edge="start"
          color="inherit"
          onClick={() => {
            onClose();
          }}
          aria-label="close"
          style={{ outline: '0', background: 'none', align: 'right' }}
        >
          <img src={CloseIcon} alt="CloseIcon" />
        </IconButton>
      </DialogTitle>

      <DialogContent className="modalContent">
        <div className="cardDefault pt0 pl0 pr0 overflowHidden">
          <p
            className="mb0 bold fontSizeDefault"
            style={{
              color: '#666770',
              background: '#F2F2F4',
              padding: '5px 16px'
            }}
          >
            {product?.description}
          </p>

          {extractItems &&
          modalSettings.filter((item) => item.name === 'updateDiscard')[0].extractItems ? (
            extractItems
              ?.filter(
                (extract) =>
                  extract?.description === 'Descarte' || extract?.type === 'DISCARD'
              )
              .map((itemExtract) => (
                <div
                  className="dFlex alignCenter pr16 pl16 justifyBetween"
                  style={{ marginTop: 30 }}
                >
                  <div className="dFlex flexColumn dateBox">
                    <span
                      className="bold fontSizeDefault"
                      style={{ margin: '-22px 0 9px 0' }}
                    >
                      Data
                    </span>

                    <span className="fontSizeDefault">
                      {moment(itemExtract?.date).format('YYYY-MM-DD')}
                    </span>
                  </div>

                  <CurrencyTextField
                    variant="standard"
                    className="textFieldSmall childTextAlignRight"
                    size="small"
                    label="Qtd"
                    currencySymbol=""
                    maximumValue="999999999999999"
                    outputFormat="string"
                    decimalCharacter=","
                    digitGroupSeparator="."
                    decimalPlaces={3}
                    decimalPlacesShownOnFocus={3}
                    type="text"
                    name="quantity"
                    fullWidth
                    placeholder="Qtd"
                    required
                    value={itemExtract?.quantity}
                    InputProps={{
                      maxLength: 8
                    }}
                    onBlur={(_, value) => {
                      const newBody = {
                        quantity: parseFloat(value),
                        discard: itemExtract?.discard,
                        product,
                        extract: itemExtract
                      };

                      const newArray = multiBody;

                      const index = newArray.findIndex(
                        (obj) => obj?.extract?.id === itemExtract.id
                      );

                      if (index !== -1) {
                        newArray[index] = newBody;
                      } else {
                        newArray.push(newBody);
                      }

                      setMultiBody([...newArray]);
                    }}
                  />

                  <span>{unity}</span>

                  <Tooltip title="Excluir descarte">
                    <IconButton
                      color="inherit"
                      onClick={() => {
                        const newBody = {
                          quantity,
                          discard: itemExtract?.discard,
                          product,
                          extract: itemExtract
                        };
                        setModalSettings(
                          modalSettings.map((modal) => {
                            modal.name === 'confirmDialog'
                              ? {
                                  ...modal,
                                  open: true,
                                  body: newBody
                                }
                              : modal;
                          })
                        );
                      }}
                      aria-label="add"
                      size="small"
                      style={{
                        outline: '0',
                        background: 'none',
                        marginLeft: 20
                      }}
                    >
                      <DeleteOutline />
                    </IconButton>
                  </Tooltip>
                </div>
              ))
          ) : (
            <div
              className="dFlex alignCenter pr16 pl16 justifyBetween"
              style={{ marginTop: 30 }}
            >
              <div className="dFlex flexColumn dateBox">
                <span
                  className="bold fontSizeDefault"
                  style={{ margin: '-22px 0 9px 0' }}
                >
                  Data
                </span>

                <span className="fontSizeDefault">
                  {moment(date).format('YYYY-MM-DD')}
                </span>
              </div>

              <CurrencyTextField
                variant="standard"
                className="textFieldSmall childTextAlignRight"
                size="small"
                label="Qtd"
                currencySymbol=""
                maximumValue="999999999999999"
                outputFormat="string"
                decimalCharacter=","
                digitGroupSeparator="."
                decimalPlaces={3}
                decimalPlacesShownOnFocus={3}
                type="text"
                name="quantity"
                fullWidth
                placeholder="Qtd"
                required
                value={body?.quantity}
                InputProps={{
                  maxLength: 8
                }}
                onBlur={(_, value) => {
                  setBody({
                    ...body,
                    quantity: parseFloat(value)
                  });
                }}
              />

              <span>{unity}</span>

              <Tooltip title="Excluir descarte">
                <IconButton
                  color="inherit"
                  onClick={() => {
                    setModalSettings(
                      modalSettings.map((modal) =>
                        modal.name === 'confirmDialog'
                          ? {
                              ...modal,
                              open: true,
                              body
                            }
                          : modal
                      )
                    );
                  }}
                  aria-label="add"
                  size="small"
                  style={{ outline: '0', background: 'none', marginLeft: 20 }}
                >
                  <DeleteOutline />
                </IconButton>
              </Tooltip>
            </div>
          )}
        </div>

        <DialogActions className="pt16 pb0 pr0 pl0">
          <Button
            onClick={() => onClose()}
            className="defaultButton backButton"
            type="submit"
            design="contained"
            label="Voltar"
            style={{ outline: 0 }}
          />
          <Button
            onClick={() => {
              if (
                extractItems &&
                modalSettings.filter((item) => item.name === 'updateDiscard')[0]
                  .extractItems
              ) {
                onSubmit(multiBody);
              } else {
                onSubmit(body);
              }
            }}
            disabled={
              item?.discard?.some((el) => !el?.quantity || !el?.discardId) ||
              !body?.quantity ||
              loading
            }
            className="defaultButton submitButton"
            type="submit"
            design="contained"
            label={loading ? 'Salvando...' : 'Salvar'}
            style={{ outline: 0 }}
          />
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}

const mapStateToProps = (state) => ({
  productInfo: state.products
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(ProductsActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(UpdateDiscard);
