import Chip from '@material-ui/core/Chip';
import styled from 'styled-components';

import { handleColorType, handleBackgroundType } from './colors';

export const ButtonChip = styled(Chip)`
  width: 80px;
  height: 25px;
  font-family: 'Lato';
  font-size: 12px;
  margin: 0;
  padding: 0;
  line-height: 35px;
  border-radius: 4px;
  cursor: pointer;
  background: ${({ background }) => background && background};
  ${({ color }) => handleColorType(color)};

  :hover {
    ${({ background }) => handleBackgroundType(background)};
    ${({ color }) => handleColorType(color)};
  }

  @media (max-width: 1024px) {
    width: 100%;
    font-size: 12px;
  }

  span {
    padding: 0 8px;
  }
`;

export const ButtonStatus = styled.div`
  width: 100%;
  height: 45px;
  font-family: Lato;
  font-size: 11px;
  margin: 2px 0;
  padding: 2px 0;
  line-height: 10px;
  cursor: pointer;
  border-radius: 20px;
  justify-content: center;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  background: ${({ background }) => background && background};
  color: ${({ color }) => color && color};
  margin-left: 5px;
  box-shadow: 0px 4px 4px 0px #00000040;

  :hover {
    ${({ background }) => handleBackgroundType(background)};
    ${({ color }) => handleColorType(color)};
  }

  button,
  span {
    border-radius: 50px;
    padding: 0;
  }

  button {
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    overflow: hidden;
    height: 36px;
    width: 36px;
    margin-right: 8px;
  }

  img {
    height: 36px;
    width: 36px;
  }

  p {
    height: 35px;
    display: flex;
    flex-direction: column;
    line-height: 1.45;
    font-size: 12px;
    margin: auto 0;
  }

  @media (max-width: 1024px) {
    width: 90%;
    height: 33px;
    font-size: 12px;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p {
      margin: 0;
    }
  }
`;

export const ButtonStatusCustomColor = styled.div`
  width: 100%;
  height: 25px;
  font-family: Lato;
  font-size: 11px;
  margin: 2px 0;
  padding: 2px 0;
  line-height: 10px;
  cursor: pointer;
  border-radius: 16px;
  justify-content: center;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  background: ${({ background }) => background && background};
  color: ${({ color }) => color && color};

  :hover {
    background: ${({ background }) => background && background};
    color: ${({ color }) => color && color};
  }

  @media (max-width: 1024px) {
    width: 90%;
    height: 33px;
    font-size: 12px;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p {
      margin: 0;
    }
  }
`;
