/* eslint-disable prefer-destructuring */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable default-case */
/* eslint-disable no-new */
/* eslint-disable radix */
/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-nested-ternary */
import React, {
  useMemo,
  useState,
  useEffect,
  Suspense,
  useCallback,
  useRef
} from 'react';

import DateFnsUtils from '@date-io/date-fns';
import {
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  LinearProgress,
  Paper,
  Popover,
  Radio,
  Snackbar,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { Autocomplete } from '@material-ui/lab';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import axios from 'axios';
import format from 'date-fns/format';
import formatISO from 'date-fns/formatISO';
import ptLocale from 'date-fns/locale/pt-BR';
import moment from 'moment';
import Draggable from 'react-draggable';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams, useLocation, Link } from 'react-router-dom';
import { Alert } from 'reactstrap';
import { bindActionCreators } from 'redux';

import Button from 'components/Button/button';
import CommonAlert from 'components/CommonAlert/CommonAlert';
import CopilotDialog from 'components/Dialogs/Copilot';
import PdfViewer from 'components/Dialogs/PdfViewer';
import ImageWrapper from 'components/ImageWrapper';
import InputManufacture from 'components/Inputs/ManufactureInputs/InputManufacture';
import InputNumber from 'components/Inputs/ManufactureInputs/InputNumber';
import OrderBy from 'components/OrderBY';
import PageTitleAndFilter from 'components/PageTitleAndFilter';
import TreeSelectorFieldSquares from 'components/PageTitleAndFilter/TreeSelectorFieldSquares';
import TitleHead from 'components/TitleHead';
import Layout from 'containers/layouts/layout';
import SwitchTableIconGray from 'images/icons/arrows/switchTableIconGray.svg';
import CopilotEnableIcon from 'images/icons/configsAndSetups/configGreenBgBlue.svg';
import CopilotPendentIcon from 'images/icons/configsAndSetups/configYellowBgBlue.svg';
import ProductionFilled from 'images/icons/objects/bells/productionFilled.svg'
import ProductionOutline from 'images/icons/objects/bells/productionOutline.svg';
import ScheduleIcon from 'images/icons/objects/calendars/calendarIconOutlineRounded.svg';
import AddIcon from 'images/icons/signals/addsAndCreates/add.svg';
import CloseIconNavy from 'images/icons/signals/closesAndCancels/closeTransparent.svg';
import getOptions from 'services/getOptions';
import axiosInstanceManufactures from 'services/middlewares/apis/manufactures_validate';
import * as UserActions from 'store/actions/user';
import {
  changeFilter,
  clearFilter
} from 'store/reducers/productionsFilterSlice';
import { handleProductionSquare } from 'store/reducers/userConfigs';
import { Container, GlobalStyle } from 'styles/general';
import Environment from 'utils/environments';
import { getRestaurantbyId, getRestaurantsArray } from 'utils/getRestaurants';
import {
  getFilterOptions,
  handleFilterOptions,
  handleMultipleFilters
} from 'utils/handleFilterActions';
import { isEmpty } from 'utils/isEmpty';
import ValidationLength from 'utils/utils';

import DesktopRows from './DesktopRows';
import MobileProductionCards from './MobileProductionCards';
import MobileCardDetail from './MobileProductionCards/ProductionDialog';
import { CardModal } from './MobileProductionCards/style';
import {
  LocalStyle,
  useStyles,
  InputContainer,
  StyledTypography,
  ProductionCardFilter,
  ImgIcon,
  BoxPlate,
  PlateFilter,
  BorderLinearProgress
} from './styles';
import { handleFields } from './utils';

const environments = Environment(process.env.REACT_APP_ENV);
const DurationDialog = React.lazy(() =>
  import('components/Dialogs/DurationDialog')
);

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

function Producao({ userInfo }) {
  const DATE_FORMAT = 'DD/MM/YYYY';
  const fullWidth = true;
  const maxWidth = 'sm';
  const todayDate = new Date();
  const theme = useTheme();
  const dispatch = useDispatch();
  const isPhone = useMediaQuery(theme.breakpoints.down('xs'));
  const isMobileScreen = useMediaQuery('(max-width: 821px)');
  const isDesktopScreen = useMediaQuery('(min-width:1281px)');
  const [state, setState] = useState({ cards: [] });
  const [updateValue, setUpdateValue] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [productionInputControlAnchor, setProductionInputControlAnchor] = React.useState(null);
  const popoverOpen = Boolean(anchorEl);
  const productionInputControlPopoverOpen = Boolean(productionInputControlAnchor);
  const [currentCard, setCurrentCard] = useState('');
  const [loading, setLoading] = useState(false);
  const [manufactures, setManufactures] = useState([]);

  const [totalManufactures, setTotalManufactures] = useState()
  const [filterFinishManufactures, setFilterFinishManufactures] = useState()
  const [formatPercentValue, setFormatPercentValue] = useState()

  const [modalNewProduction, setModalNewProduction] = useState(false)
  const [selectedProduct, setSelectedProduct] = useState('');
  const [currentTransformationCardItems, setCurrentTransformationCardItems] =
    useState();
  const [manufacture, setManufacture] = useState();
  const [cardDate, setCardDate] = useState(new Date());
  const [updatedManufacture, setUpdatedManufacture] = useState();
  const [cancellationReason, setCancellationReason] = useState();
  const [optionsCards, setOptionsCards] = useState(null);
  const [updateQuantityInitialValue, setUpdateQuantityInitialValue] =
    useState();
  const [optionsDiscard, setOptionsDiscard] = useState([]);
  const [optionsDiscardManufactures, setOptionsDiscardManufactures] = useState([]);
  const [newProduction, setNewProduction] = useState({});
  const [open, setOpen] = useState(false);
  const [addConfirmModal, setAddConfirmModal] = useState(false);
  const [parametersInfos, setParametersInfos] = useState();
  const [copilotStatus, setCopilotStatus] = useState();
  const [loadingCloseProgramming, setLoadingCloseProgramming] = useState(false);
  const [controlProductionModal, setControlProductionModal] = useState(false);
  const [checkSumeItems, setCheckSumeItems] = useState(false);
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const [cardProductionDate, setCardProductionDate] = useState(new Date());
  const userLocal = JSON.parse(localStorage.getItem('companies'));

  const config = {
    headers: {
      User: `${userLocal?.user}`
    }
  };

  const { id: paramsID } = useParams();
  const history = useHistory();
  const prevPath = history?.location?.state?.prevPath;
  const queryParams = useLocation()?.search;
  const [cardFilterValue, setCardFilterValue] = useState('');
  const [courtOptions, setCourtOptions] = useState([]);
  const [storeOptions, setStoreOptions] = useState([]);
  const [orderOptions, setOrderOptions] = useState([
    {
      description: 'Inclusão',
      value: 'included'
    },
    {
      description: 'Inicialização',
      value: 'started'
    },
    {
      description: 'Finalização',
      value: 'finished'
    }
  ])
  const [storeOrderOption, setStoreOrderOption] = useState(null)
  const storeOrderInput = useRef(null)
  const storeInput = useRef(null);
  const [storeFilterValue, setStoreFilterValue] = useState(null);
  const [courtFilterValue, setCourtFilterValue] = useState();
  const [productIdQueryParam, setProductIdQueryParam] = useState(
    queryParams?.includes('productId')
      ? queryParams
          ?.split('&')
          ?.find((el) => el.includes('productId'))
          ?.split('=')[1]
      : ''
  );
  const [productDescriptionQueryParam, setProductDescriptionQueryParam] =
    useState(
      queryParams?.includes('productDescription')
        ? decodeURI(
            queryParams
              ?.split('&')
              ?.find((el) => el.includes('productDescription'))
              ?.split('=')[1]
          )
        : ''
    );

  const [cardIdQueryParam, setCardIdQueryParam] = useState(
    queryParams?.includes('cardId')
      ? queryParams
          ?.split('&')
          ?.find((el) => el.includes('cardId'))
          ?.split('=')[1]
      : ''
  );

  const [cardDescriptionQueryParam, setCardDescriptionQueryParam] = useState(
    queryParams?.includes('cardDescription')
      ? decodeURI(
          queryParams
            ?.split('&')
            ?.find((el) => el.includes('cardDescription'))
            ?.split('=')[1]
        )
      : ''
  );

  const [statusQueryParam, setStatusQueryParam] = useState(
    queryParams?.includes('status')
      ? decodeURI(
          queryParams
            ?.split('&')
            ?.find((el) => el.includes('status'))
            ?.split('=')[1]
        )
      : ''
  );
  const [differenceQueryParam, setDifferenceQueryParam] = useState(
    queryParams?.includes('difference')
      ? queryParams
          ?.split('&')
          ?.find((el) => el.includes('difference'))
          ?.split('=')[1]
      : ''
  );
  const [scheduleQueryParam, setScheduleQueryParam] = useState(
    queryParams?.includes('shedule')
      ? queryParams
          ?.split('&')
          ?.find((el) => el.includes('schedule'))
          ?.split('=')[1]
      : ''
  );
  const [typeQueryParam, setTypeQueryParam] = useState(
    queryParams?.includes('type')
      ? queryParams
          ?.split('&')
          ?.find((el) => el.includes('type'))
          ?.split('=')[1]
      : ''
  );

  const today = moment()
  const yesterday = today.clone().subtract(1, 'days');
  const yesterdayFormat = yesterday.format('YYYY-MM-DD');

  const [initialDateFilterDefaultValue, setInitialDateFilterDefaultValue] =
    useState(
      queryParams?.includes('startAt')
        ? queryParams
            ?.split('&')
            ?.find((el) => el.includes('startAt'))
            ?.split('=')[1]
        :  ''
    );
  const [endDateFilterDefaultValue, setEndDateFilterDefaultValue] = useState(
    queryParams?.includes('endAt')
      ? queryParams
          ?.split('&')
          ?.find((el) => el.includes('endAt'))
          ?.split('=')[1]
      : format(new Date(), 'yyyy-MM-dd')
  );
  const deflectTypeFilterDefaultValue = queryParams?.includes('deflect')
    ? queryParams
        ?.split('&')
        ?.find((el) => el.includes('deflect'))
        ?.split('=')[1]
    : '';

  const statusFilterDefaultValue =
    statusQueryParam === 'WAITING'
      ? { title: 'Espera', value: 'WAITING' }
      : statusQueryParam === 'START'
      ? { title: 'Iniciado', value: 'START' }
      : statusQueryParam === 'PAUSE'
      ? { title: 'Pausado', value: 'PAUSE' }
      : statusQueryParam === 'FINISH'
      ? { title: 'Finalizado', value: 'FINISH' }
      : statusQueryParam === 'CANCELED'
      ? { title: 'Cancelado', value: 'CANCELED' }
      : { title: 'Todos', value: '' };

  const diffFilterDefaultValue =
    differenceQueryParam === 'leftover'
      ? { title: 'Sobra', value: 'leftover' }
      : differenceQueryParam === 'noDiff'
      ? { title: 'Sem diferença', value: 'noDiff' }
      : differenceQueryParam === 'all' 
      ? { title: 'Todos', value: ''}
      : differenceQueryParam
      ? { title: 'Falta', value: 'lack' }
      : undefined;

  const typeFilterDefaultValue =
    typeQueryParam === 'recipe'
      ? { title: 'Receita', value: 'recipe' }
      : typeQueryParam
      ? { title: 'Transformação', value: 'transformation' }
      : undefined;

      const schedFilterDefaultValue =
      scheduleQueryParam === 'scheduled'
        ? { title: 'Produção programada', value: 'scheduled' }
        : scheduleQueryParam === 'notscheduled'
        ? { title: 'Produção fora da programação', value: 'notscheduled' }
        : scheduleQueryParam === 'all' 
        ? { title: 'Todos', value: ''}
        : undefined;

  const DEFAULT_FILTERS = useMemo(
    () => [
      initialDateFilterDefaultValue && {
        param: 'initialDate',
        value: initialDateFilterDefaultValue,
        urlParam: `initialDate=${initialDateFilterDefaultValue}`
      },
      {
        param: 'finalDate',
        value: endDateFilterDefaultValue || format(new Date(), 'yyyy-MM-dd'),
        urlParam: `finalDate=${
          endDateFilterDefaultValue || format(new Date(), 'yyyy-MM-dd')
        }`
      },
      productIdQueryParam && {
        param: 'productId',
        value: productIdQueryParam,
        urlParam: `productId=${productIdQueryParam}`
      },
      cardIdQueryParam && {
        param: 'cardId',
        value: cardIdQueryParam,
        urlParam: `cardId=${cardIdQueryParam}`
      },
      statusQueryParam && {
        param: 'status',
        value: statusQueryParam,
        urlParam: `status=${statusQueryParam}`
      },
      diffFilterDefaultValue && {
        param: 'difference',
        value: diffFilterDefaultValue?.value,
        urlParam: `difference=${diffFilterDefaultValue?.value}`
      },
      typeFilterDefaultValue && {
        param: 'type',
        value: typeFilterDefaultValue?.value,
        urlParam: `type=${typeFilterDefaultValue?.value}`
      },
      deflectTypeFilterDefaultValue && {
        param: 'deflect',
        value: deflectTypeFilterDefaultValue,
        urlParam: `deflect=${deflectTypeFilterDefaultValue}`
      }
    ],
    [
      diffFilterDefaultValue,
      endDateFilterDefaultValue,
      initialDateFilterDefaultValue,
      typeFilterDefaultValue
    ]
  );
  const [getId, setGetId] = useState(paramsID);
  const [filtersChanged, setFiltersChanged] = useState(DEFAULT_FILTERS);
  const [getUser, setUser] = useState('');
  const [popoverData, setPopoverData] = useState();
  const companyActive = userInfo.companiesActive.name;
  const companyGroup = userInfo.companies?.groups.length
    ? userInfo.companies?.groups[0]?.name
    : '';
  const stores = useState(
    userInfo.companies.groups
      .reduce((acc, group) => [...acc, group.subGroups], [])
      .flat()
      .reduce((acc, subGroup) => [...acc, subGroup.stores], [])
      .flat()
  );
  const [durationData, setDurationData] = useState({
    product: {},
    data: {},
    type: '',
    newDuration: false
  });
  const [durationDialogLoading, setDurationDialogLoading] = useState(false);
  const classes = useStyles();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [manuallyViewOrder, setManuallyViewOrder] = useState(false);
  const [deleteData, setDeleteData] = useState();
  const id = open ? 'simple-popover' : undefined;
  const [deleteSuccess, handleDeleteSuccess] = useState(false);
  const [updateManufactureValues, setUpdateManufactureValues] = useState(false);
  const [mobileFocusIndex, setMobileFocusIndex] = useState('');
  const [deleteError, handleDeleteError] = useState(false);
  const [transformationInfos, setTransformationInfos] = useState();
  const [diffViewMonetary, setDiffViewMonetary] = useState(false);
  const [callSettings, setCallSettings] = useState({
    page: 1,
    totalPages: 0,
    size: 100,
    filters: DEFAULT_FILTERS
  });

  const [modalStates, setModalStates] = useState({
    modalDuration: false,
    modalInvalidQuantity: false,
    modalItemsQuantityGreaterThanCard: false,
    modalConfirmationOfFinish: false,
    modalAutomaticDown: false
  });
  const [modalSettings, setModalSettings] = useState([
    {
      id: 1,
      name: 'pdfViewer',
      open: false,
      fullScreen: false,
      maxWidth: 'sm',
      pdfFile: ''
    },
    {
      id: 2,
      name: 'modalDecimalsQuantityAlert',
      open: false,
      fullScreen: false,
      maxWidth: 'sm',
      items: []
    },
    {
      id: 3,
      name: 'copilotConfig',
      fullScreen: false,
      maxWidth: 'lg',
    }
  ]);
  const [message, setMessage] = useState({
    description: '',
    status: ''
  });
  const { page, filters } = callSettings;
  const [showTransformationErrorMsg, setShowTransformationErrorMsg] =
    useState(false);
  const [alertErr, setAlertErr] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [statusCode, setStatusCode] = useState(0);
  const [forceEnable, setForceEnable] = useState(false);
  const [getAlert, setAlert] = useState('');
  const [totalDiffInfos, setTotalDiffInfos] = useState(0);
  const [lastCountObj, setLastCountObj] = useState([]);
  const storedProductionsFilter = useSelector(
    (currState) => currState.productionsFilter.filters
  );
  const storedProductionsFilterFormatted = useMemo(
    () =>
      storedProductionsFilter?.some(
        (el) =>
          el?.filterName === 'initialDate' || el?.filterName === 'finalDate'
      )
        ? [
            ...storedProductionsFilter,
            !storedProductionsFilter.find(
              (item) => item.filterName === 'finalDate'
            )
              ? {
                  finalDate:
                    endDateFilterDefaultValue ||
                    format(new Date(), 'yyyy-MM-dd'),
                  filterName: 'finalDate',
                  textValue:
                    endDateFilterDefaultValue ||
                    format(new Date(), 'yyyy-MM-dd')
                }
              : null
          ]
        : [
            ...storedProductionsFilter,
            {
              initialDate: initialDateFilterDefaultValue,
              filterName: 'initialDate',
              textValue:
                initialDateFilterDefaultValue ||
                format(new Date(), 'yyyy-MM-dd')
            },
            {
              finalDate:
                endDateFilterDefaultValue || format(new Date(), 'yyyy-MM-dd'),
              filterName: 'finalDate',
              textValue:
                endDateFilterDefaultValue || format(new Date(), 'yyyy-MM-dd')
            }
          ],
    [
      endDateFilterDefaultValue,
      initialDateFilterDefaultValue,
      storedProductionsFilter
    ]
  );
  const shouldUseStoredFilters = useMemo(
    () => storedProductionsFilterFormatted && prevPath,
    [prevPath, storedProductionsFilterFormatted]
  );
  const isGroupManage = getId?.includes(',');
  const [showErrorManufacturedMessage, setShowErrorManufacturedMessage] =
    useState(false);
  const [orderAscOfManufacturesLoss, setOrderAscOfManufacturesLoss] =
    useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const onDismiss = () => {
    setAlertErr(false);
  };
  const query = '';
  const handleCloseModal = () => {
    setSelectedProduct('')
    
    setModalStates({
      ...modalStates,
      modalDuration: false,
      modalInvalidQuantity: false,
      modalItemsQuantityGreaterThanCard: false,
      modalConfirmationOfFinish: false
    });

    setModalSettings(
      modalSettings.map((modal) =>
        modal.name === 'modalDecimalsQuantityAlert'
          ? {
              ...modal,
              open: false,
              items: []
            }
          : modal
      )
    );
  };

  const [filterFields, setFilterFields] = useState([
    [
      {
        type: 'dateRangeField',
        size: 'small',
        filterNameFirst: 'initialDate',
        filterNameSecond: 'finalDate',
        initialDate: initialDateFilterDefaultValue
          ? moment(initialDateFilterDefaultValue).add(3, 'hours')
          : new Date(),
        endDate: endDateFilterDefaultValue
          ? moment(endDateFilterDefaultValue).add(3, 'hours')
          : new Date()
        // minDate: true,
        // maxDate: true
      },
      {
        label: 'Produto',
        filterName: 'productDescription',
        filterNameSecond: 'productId',
        placeHolder: 'Selecione...',
        type: 'autoComplete',
        urlPath: `${environments.catalog}/products`,
        key: 'products',
        optionReference: 'description',
        size: 'medium',
        options: [],
        storedValue: productDescriptionQueryParam || '',
        searchByTermMode: true
      },
      {
        label: 'Tipo',
        filterName: 'type',
        placeHolder: 'Selecione...',
        size: 'medium',
        type: 'autoComplete',
        key: 'type',
        // defaultValue: typeFilterDefaultValue,
        storedValue: typeFilterDefaultValue?.title || '',
        isSelect: true,
        allowClear: true,
        options: [
          { title: 'Todas', value: '' },
          { title: 'Receita', value: 'recipe' },
          { title: 'Transformação', value: 'transformation' }
        ]
      },
      // {
      //   label: 'Ficha',
      //   filterName: 'cardDescription',
      //   filterNameSecond: 'cardId',
      //   placeHolder: 'Selecione...',
      //   type: 'autoComplete',
      //   urlPath: `${environments.catalog}/cards`,
      //   key: 'cards',
      //   optionReference: 'description',
      //   options: [],
      //   storedValue: cardDescriptionQueryParam || '',
      //   size: 'medium',
      //   searchByTermMode: true
      // },
      // {
      //   label: 'Status',
      //   filterName: 'status',
      //   placeHolder: 'Selecione...',
      //   size: 'medium',
      //   type: 'autoComplete',
      //   key: 'providers',
      //   isSelect: true,
      //   allowClear: true,
      //   storedValue: statusFilterDefaultValue?.title || '',
      //   options: [
      //     { title: 'Todos', value: '' },
      //     { title: 'Espera', value: 'WAITING' },
      //     { title: 'Iniciado', value: 'START' },
      //     { title: 'Pausado', value: 'PAUSE' },
      //     { title: 'Finalizado', value: 'FINISH' },
      //     { title: 'Cancelado', value: 'CANCELED' }
      //   ]
      // },
      {
        label: 'Diferença',
        filterName: 'difference',
        placeHolder: 'Selecione...',
        size: 'medium',
        type: 'autoComplete',
        key: 'difference',
        // defaultValue: diffFilterDefaultValue,
        storedValue: diffFilterDefaultValue?.title || '',
        isSelect: true,
        allowClear: true,
        options: [
          { title: 'Todas', value: '' },
          { title: 'Falta', value: 'lack' },
          { title: 'Sobra', value: 'leftover' },
          { title: 'Sem diferença', value: 'noDiff' }
        ]
      },
      {
        label: 'Programação',
        filterName: 'schedule',
        placeHolder: 'Selecione...',
        size: 'medium',
        type: 'autoComplete',
        key: 'schedule',
        storedValue: schedFilterDefaultValue?.title || '',
        isSelect: true,
        allowClear: true,
        options: [
          { title: 'Todas', value: '' },
          { title: 'Produção programada', value: 'scheduled' },
          { title: 'Produção fora da programação', value: 'notscheduled' },
        ]
      },
    ],
    []
  ]);

  const [updatePlates, setUpdatePlates] = useState(false)
  const [plate, setPlate] = useState(
    [
      {
        active: false,
        id: 'WAITING',
        name: 'Iniciar',
        background: '#E6E7F0',
        value: 0,
      },
      {
        active: false,
        id: 'START',
        name: 'Produzindo',
        background: '#DADDF3',
        value: 0,
      },
      {
        active: false,
        id: 'PAUSE',
        name: 'Pausado',
        background: '#FFF2CC',
        value: 0,
      },
      {
        active: false,
        id: 'FINISH',
        name: 'Finalizado',
        background: '#D2F9D6',
        value: 0,
      },
      {
        active: false,
        id: 'CANCELED',
        name: 'Cancelado',
        background: '#F7D4D7',
        value: 0,
      }
    ]
  )

  const originDevice = () => {
    let device = '';

    if (isPhone) {
      device = 'phone';
    } else if (isMobileScreen) {
      device = 'tablet';
    } else {
      device = 'desktop';
    }

    return device;
  };

  const clickAway = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setAlertErr(false);
  };


  const handleUpdateViewMethod = (value) => {
    const body = {
      _id: userInfo?.user,
      configs: [
        {
          cards: [
            {
              cardId: currentCard?.cardId,
              viewMethod: value
            }
          ]
        }
      ]
    }

    axios
      .put(
        `${environments.auth}/users/${userInfo.user}/config`,
        body
      )
      .then((response) => {
        setManufactures(manufactures?.map(el => {
          if (el?._id === currentCard?._id) {
            return {
              ...el,
              viewMethod: value,
            }
          }
    
          return { ...el }
        }))

        setCurrentCard({
          ...currentCard,
          viewMethod: value
        })
      })
      .catch((error) => {
        setMessage({
          description: 'Erro ao atualizar tipo de visualiação da ficha',
          status: 'error'
        });
        setStatusCode(error?.response?.status);
      });
  }
  
  const getTotalDiffInfos = (params, originIds) => {
    axios
      .get(
        `${environments.dashBoard}/manufactures/listMonetaryDiff?originId=${originIds}&${params}`
      )
      .then((response) => {
        setTotalDiffInfos(response?.data);
        setUpdatePlates(!updatePlates)
      })
      .catch((error) => {
        setTotalDiffInfos(0);
        setAlertErr(true);
        setAlertMessage(error?.response?.data?.message);
        setStatusCode(error?.response?.status);
      });
  };

  const getParametersInfos = useCallback(() => {
    axios
      .get(
        `${environments.restaurants}/companies/${
          Array.isArray(userInfo.companiesActive.id)
            ? userInfo.companiesActive.id[0]
            : userInfo.companiesActive.id
        }/parameters`
      )
      .then((response) => {
        setParametersInfos(response?.data || {});
      })
      .catch((error) => {
        setParametersInfos({});
        setAlertErr(true);
        setAlertMessage(error?.response?.data?.message);
        setStatusCode(error?.response?.status);
      });
  }, [userInfo.companiesActive.id]);

  const getCopilotStatus = useCallback(() => {
    axios
      .get(
        `${environments.manufactures}/copilot/status?originId=${
          Array.isArray(userInfo.companiesActive.id)
            ? userInfo.companiesActive.id[0]
            : userInfo.companiesActive.id
        }&date=${moment().format('YYYY-MM-DD')}`
      )
      .then((response) => {
        setCopilotStatus(response?.data || {});
      })
      .catch((error) => {
        setCopilotStatus();
        setAlertErr(true);
        setAlertMessage(error?.response?.data?.message);
        setStatusCode(error?.response?.status);
      });
  }, [userInfo.companiesActive.id]);

  const closeProgramming = () => {
    setLoadingCloseProgramming(true)
    const uri = `${environments.manufactures}/copilot/refused?originId=${getId}&date=${moment().format('YYYY-MM-DD')}&user=${userInfo.user}`

    const link = document.createElement('a');
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    setTimeout(() => {
      setLoadingCloseProgramming(false)
    }, 1000);
  };

  const buildPlateInfos = (mainObj, firstLoad = false) => {
    const waitingPlates = mainObj.filter(
      (item) => item.status === 'WAITING'
    );
    const toFinishPlate = mainObj?.filter(
      (item) => item.status === 'FINISH'
    );
    const canceledPlate = mainObj?.filter(
      (item) => item.status === 'CANCELED'
    );
    const startPlate = mainObj?.filter(
      (item) => item.status === 'START'
    );
    const pausePlate = mainObj?.filter(
      (item) => item.status === 'PAUSE'
    );

    setPlate(
      plate.map((item) => {
        if (item?.name === 'Iniciar') {
          return {
            ...item,
            value: waitingPlates.length,
            active: firstLoad ? false : item?.active
          };
        }
        if (item?.name === 'Produzindo') {
          return {
            ...item,
            value: startPlate.length,
            active: firstLoad ? false : item?.active
          };
        }
        if (item?.name === 'Pausado') {
          return {
            ...item,
            value: pausePlate.length,
            active: firstLoad ? false : item?.active
          };
        }
        if (item?.name === 'Finalizado') {
          return {
            ...item,
            value: toFinishPlate.length,
            active: firstLoad ? false : item?.active
          };
        }
        if (item?.name === 'Cancelado') {
          return {
            ...item,
            value: canceledPlate.length,
            active: firstLoad ? false : item?.active
          };
        } 

        return {
          ...item,
          active: firstLoad ? false : item?.active
        };
      })
    );
  }

  const filterByStatus = (status, active) => {
    if (plate.filter(el => el.active)?.length === 1 && !active) {
      setManufactures(manufactures?.map(el => (
        {
          ...el,
          visible: true
        }
      )))

      return null;
    }

    if (active) {
      const activeFilters = [...new Set([plate?.filter(el => el.active)?.map(el => el.id), status].flat())]

      setManufactures(manufactures?.map(el => {
        if (activeFilters.includes(el.status)) {
          return {
            ...el,
            visible: true,
          }
        }
  
        return {
          ...el,
          visible: false
        }
      }))
    } else {
      const inactiveFilters = [...new Set([plate?.filter(el => !el.active)?.map(el => el.id), status].flat())]

      setManufactures(manufactures?.map(el => {
        if (inactiveFilters.includes(el.status)) {
          return {
            ...el,
            visible: false,
          }
        }
  
        return {
          ...el,
          visible: true
        }
      }))
    }
  };

  const getMainContent = useCallback(
    async (personalStoreId) => {
      setLoading(true);

      const filterStored = personalStoreId || paramsID
      let currentFilters = filtersChanged;
      let params = [];

      const statusFilter = filtersChanged
        ?.filter((filter) => filter)
        ?.find((item) => item?.param === 'status');

      if (statusFilter && !statusFilter?.value) {
        currentFilters = filtersChanged.filter(
          (item) => item?.param !== 'status'
        );
      }
      if (shouldUseStoredFilters) {
        const validFilters = storedProductionsFilterFormatted.filter((item) => {
          if (item?.courtId == 0) {
            item?.courtId && item?.filterName
          } 
          
          return item?.filterName
        });
        const parsedFilters = validFilters
          .map((filter) => {
            const key = Object.keys(filter)[0];
            const isFalse = filter[key] === false;

            if (filter[key]) {
              return `${key}=${filter[key]}&`;
            }
            return isFalse ? `${key}=false` : null;
          })
          .join('');

        params = parsedFilters;
      } else {
        params = currentFilters
          ?.filter((filter) => filter)
          ?.map((filter) => filter?.urlParam)
          .join('&');
      }

      let originIdParam = paramsID;

      if (personalStoreId) {
        originIdParam = personalStoreId;
      }

      const response = await axiosInstanceManufactures
        .get(
          `${environments.manufactures}/manufactures?originId=${filterStored}&${params}`
        )
        .catch((err) => {
          setAlertErr(true);
          setAlertMessage(err.response.data.message);
          setStatusCode(err.response.status);
        });

      const formattedItems = response?.data?.length
        ? response.data.map((el) => ({
            ...el,
            visible: true,
            initialQuantity: el?.quantity,
            items: el?.items?.map((item) => ({
              ...item,
              initialQuantity: item?.quantity || 0
            }))
          }))
        : [];

      if (!formattedItems.length) {
        setManufactures([]);
      } else {
        setManufactures(
          filtersChanged
            ?.filter((filter) => filter)
            ?.filter((item) => item?.param === 'orderBy').length
            ? formattedItems
            : formattedItems
                .sort((a, b) => (a.created_at < b.created_at ? -1 : 1))
                .sort((a, b) => (a.status > b.status ? -1 : 1))
        );
        setUpdatePlates(!updatePlates)
      }
      buildPlateInfos(formattedItems, true)
      getTotalDiffInfos(params, filterStored);

      setLoading(false);
    },
    [
      filtersChanged,
      page,
      paramsID,
      shouldUseStoredFilters,
      storedProductionsFilter,
      storeFilterValue
    ]
  );

  // Filter
  function handleFilter(param, value, filter, paramSecond) {
    const hasFilterWithFirstName = filtersChanged?.filter(
      (el) => el?.param === param
    )?.length;
    const hasFilterWithSecondName = filtersChanged?.filter(
      (el) => el?.param === paramSecond
    )?.length;

    let formattedFilters = hasFilterWithSecondName
      ? filtersChanged?.filter((el) => el?.param !== paramSecond)
      : filtersChanged;

    if (!value) {
      dispatch(clearFilter());

      setDifferenceQueryParam(
        param === 'difference' ? '' : differenceQueryParam
      );
      setTypeQueryParam(param === 'type' ? '' : typeQueryParam);
      setInitialDateFilterDefaultValue(
        param === 'initialDate' ? '' : initialDateFilterDefaultValue
      );
      setEndDateFilterDefaultValue(
        param === 'finalDate' ? '' : endDateFilterDefaultValue
      );
      setProductIdQueryParam(param === 'productId' ? '' : productIdQueryParam);
      setStatusQueryParam(param === 'status' ? '' : statusQueryParam);
      setScheduleQueryParam(
        param === 'schedule' ? '' : scheduleQueryParam
      );

      if (hasFilterWithFirstName || hasFilterWithSecondName) {
        formattedFilters = filtersChanged?.filter(
          (el) => el?.param !== paramSecond && el?.param !== param
        );
      }
    }

    dispatch(
      changeFilter({
        param,
        value,
        textValue: filter?.title
      })
    );

    const urlParams = new URLSearchParams(queryParams);

    if (urlParams.has(param)) {
      urlParams.delete(param);
      history.replace({
        search: urlParams.toString()
      });
    }

    handleFilterOptions(param, value, formattedFilters, setFiltersChanged);
  }

  const getFilterhandle = (urlPath, label, key, optionReference) => {
    getFilterOptions(
      urlPath,
      label,
      key,
      filterFields,
      setFilterFields,
      optionReference,
      getId,
      '',
      page
    );
  };

  const handleOrdernationFilter = (paramsAndValues) => {
    handleMultipleFilters(paramsAndValues, filtersChanged, setFiltersChanged);

    const param = paramsAndValues[0][0];
    const value = paramsAndValues[0][1];

    dispatch(
      changeFilter({
        param,
        value,
        textValue: value
      })
    );
  };

  const handleLossMonetary = (manufacture) => {
    const status = manufacture.status.toLowerCase();
    const lossManufactured = handleFields(manufacture).lossManufactured;
    const lossManufacturedCalc = handleFields(manufacture).lossManufacturedCalc;

    if (
      lossManufactured === null ||
      lossManufactured === '' ||
      status !== 'finish'
    ) {
      return `-`;
    }

    return lossManufacturedCalc;
  };

  const orderByDiff = () => {
    if (orderAscOfManufacturesLoss) {
      const parsedManufacturesLossValue = [...manufactures].map(
        (manufacture) => {
          const lossManufacturedCalc =
            handleFields(manufacture)?.lossManufacturedCalc;
          const diffMonetaryFormattedValue =
            lossManufacturedCalc < 0
              ? manufacture?.diffMonetary * -1
              : manufacture?.diffMonetary;

          return {
            ...manufacture,
            lossManufacturedParsedValue: diffViewMonetary
              ? diffMonetaryFormattedValue
              : handleLossMonetary(manufacture)
          };
        }
      );

      const sortedManufactures = parsedManufacturesLossValue.sort(
        (a, b) => a.lossManufacturedParsedValue - b.lossManufacturedParsedValue
      );

      setManufactures(sortedManufactures);
    } else {
      const parsedManufacturesLossValue = [...manufactures].map(
        (manufacture) => {
          const lossManufacturedCalc =
            handleFields(manufacture)?.lossManufacturedCalc;
          const diffMonetaryFormattedValue =
            lossManufacturedCalc < 0
              ? manufacture?.diffMonetary * -1
              : manufacture?.diffMonetary;

          return {
            ...manufacture,
            lossManufacturedParsedValue: diffViewMonetary
              ? diffMonetaryFormattedValue
              : handleLossMonetary(manufacture)
          };
        }
      );

      const sortedManufactures = parsedManufacturesLossValue.sort(
        (a, b) => b.lossManufacturedParsedValue - a.lossManufacturedParsedValue
      );

      setManufactures(sortedManufactures);
    }
    setOrderAscOfManufacturesLoss((currState) => !currState);
  };

  const totalDiffStyle = (value) => {
    let bg = '';
    let color = '';

    if (value === 0) {
      bg = '#E6E7F0';
      color = '#0E1337';
    } else if (value > 0) {
      bg = '#FFF2CC';
      color = '#FFBF3E';
    } else {
      bg = '#F7D4D7';
      color = '#F53D4C';
    }

    return {
      background: bg,
      color
    };
  };

  // Functions to add new card production
  function InsertData(value, startStatus) {
    setLoading(true);

    new Promise((resolve, reject) => {
      setTimeout(() => {
        const { cards } = state;
        const limitHours = value.endTime
          ? value.endTime.split(':').map((item) => parseInt(item))
          : null;
        const repeatedRecords = manufactures.filter(
          (item) => item.cardId === value.id && item.status === 'FINISH'
        );
        const repeatedRecordsLength = repeatedRecords.length;
        const lastRecordedItem =
          repeatedRecordsLength !== 0
            ? repeatedRecords.reduce((a, b) =>
                new Date(a.date) > new Date(b.date) ? a : b
              )
            : 0;
        
        const newData = {
          cardId: value?.cardId || value.id,
          user: userInfo.user,
          cardProductionDate: moment(cardProductionDate).format('YYYY-MM-DD'),
          originId: getId,
          status: startStatus || "WAITING",
          quantity: value?.quantity || '',
          yield: value?.yield || '',
          items: value?.items?.length 
            ? value?.items?.map(el => (
              {
                id: el?.id,
                quantity: el?.quantity
              }
            )) : [],
        };

        axios
          .post(`${environments.manufacturesV2}/manufactures`, newData, config)
          .then((response) => {
            {
              const data = manufactures;
              data.splice(data, 0, {...response.data, visible: true, open: true});
              setNewProduction(null);
              setState({ data, cards }, () => resolve());
              buildPlateInfos(manufactures, !plate?.some(el => el?.active))
              setMessage({
                description: 'Nova produção incluída com sucesso!',
                severity: 'success',
                autoHideDuration: 3000
              })
              setUpdatePlates(!updatePlates)
            }
            setLoading(false);
          })
          .catch((error) => {
            const errorMessage = error?.response?.data?.message
            
            setMessage({
              description: errorMessage?.charAt(0).toUpperCase() + errorMessage?.slice(1) || 'Erro ao incluir nova produção!',
              severity: 'error',
              autoHideDuration: 3000
            })
            setLoading(false);
          });
      }, 1000);
    });
  }

  const handleControlProduction = (card) => {
    const isRecipeBody = lastCountObj.filter((item) => !item.lastCount)
      ? {
          controlProduction: true,
          user: userInfo?.user,
          originId: paramsID,
          type: card.type,
          realStock: card.type === 'RECIPE' ? lastCountObj[0].realStock : '',
          storageLocations:
            card.type === 'RECIPE' ? lastCountObj[0].storageLocations : [],
          periodicities:
            card.type === 'RECIPE' ? lastCountObj[0].periodicities : [],
          items:
            card.type === 'RECIPE'
              ? []
              : lastCountObj
                  .filter((item) => !item.lastCount)
                  .map((item) => ({
                    productId: item.id,
                    realStock: item.realStock,
                    storageLocations: item.storageLocations,
                    periodicities: item.periodicities
                  }))
        }
      : {
          controlProduction: true
        };

    const isTransformationBody = lastCountObj.filter((item) => !item.lastCount)
      ? {
          controlProduction: true,
          user: userInfo?.user,
          originId: paramsID,
          type: card.type,
          items: lastCountObj.map((item) => ({
            productId: item.id,
            realStock: item.realStock,
            storageLocations: item.storageLocations,
            periodicities: item.periodicities
          }))
        }
      : {
          controlProduction: true
        };

    if (
      lastCountObj.filter((item) => !item.lastCount)
        ? lastCountObj
            .filter((item) => !item.lastCount)
            .map(
              (item) =>
                !item.periodicities.length ||
                !item.storageLocations.length ||
                !item.realStock
            )
            .includes(true)
        : false
    ) {
    } else {
      axios
        .put(
          `${environments.catalog}/cards/${card.id}/controlProduction`,
          card.type === 'RECIPE' ? isRecipeBody : isTransformationBody,
          config
        )
        .then((response) => {
          setControlProductionModal(false);
        })
        .catch((error) => {
          setAlertErr(true);
          setAlertMessage(error?.response?.data?.message);
          setStatusCode(error?.response?.status);
        });
    }
  };

  const renderTotalDiff = (value) => (
    <div
      className="dFlex alignCenter"
      style={{
        marginLeft: 'auto',
        marginRight: 45
      }}
    >
      <span className="fontSizeDefault bold" style={{ padding: '0px 6px' }}>
        Total de Diferenças:
      </span>

      <div
        className="dFlex justifyEnd alignCenter"
        style={{
          padding: '0px 10px',
          borderRadius: 10,
          background: totalDiffStyle(value).background,
          height: '20px',
          boxShadow: '0px 4px 4px 0px #00000040',
          fontWeight: 'bold'
        }}
      >
        <span
          style={{
            color: totalDiffStyle(value).color
          }}
          className="fontSizeDefault bold"
        >
          R${' '}
          {value?.toLocaleString('pt-br', {
            minimumFractionDigits: 3
          })}
        </span>
      </div>
    </div>
  );

  const renderLeftOverAndLack = (totalGain, totalLoss) => (
    <>
      <div
        className="dFlex alignCenter"
        style={{
          marginRight: 12
        }}
      >
        <span className="fontSizeDefault" style={{ padding: '0px 6px', fontWeight: 'bold' }}>
          Sobras:
        </span>

        <div
          className="dFlex justifyEnd alignCenter"
          style={{
            padding: '0px 10px',
            borderRadius: 10,
            background: totalGain === 0 ? '#E6E7F0' : '#FFF2CC',
            height: '20px',
            boxShadow: '0px 4px 4px 0px #00000040',
          }}
        >
          <span
            style={{
              color: totalGain === 0 ? '#0E1337' : '#F2B90D'
            }}
            className="fontSizeDefault"
          >
            R${' '}
            {totalGain?.toLocaleString('pt-br', {
              minimumFractionDigits: 3
            })}
          </span>
        </div>
      </div>

      <div
        className="dFlex alignCenter"
        style={{
          marginRight: 12
        }}
      >
        <span className="fontSizeDefault" style={{ padding: '0px 6px', fontWeight: 'bold' }}>
          Faltas:
        </span>

        <div
          className="dFlex justifyEnd alignCenter"
          style={{
            padding: '0px 10px',
            borderRadius: 10,
            background: totalLoss === 0 ? '#E6E7F0' : '#F7D4D7',
            height: '20px',
            boxShadow: '0px 4px 4px 0px #00000040'
          }}
        >
          <span
            style={{
              color: totalLoss === 0 ? '#0E1337' : '#F53D4C'
            }}
            className="fontSizeDefault"
          >
            R$ {totalDiffInfos?.totalLoss ? '-' : ''}
            {totalLoss?.toLocaleString('pt-br', {
              minimumFractionDigits: 3
            })}
          </span>
        </div>
      </div>
    </>
  );

  const renderManuallyViewOrder = useMemo(() => {
    const orderByAutocomplete = orderBy => {
      let allManufactures = []
      let manufacturesWithDate = []
      let manufacturesWithoutDate = []

      switch (orderBy) {
        case 'finished':
          allManufactures = manufactures.map(m => {
            const actionsFinished = m.actions.filter(act => act.type === "FINISH")[0]
            const actionsCanceled = m.actions.filter(act => act.type === "CANCELED")[0]

            return {
              ...m,
              dateFinish: !isEmpty(actionsCanceled) ? null : !isEmpty(actionsFinished) && !isEmpty(actionsFinished.date) ? actionsFinished.date : null
            }
          })

          manufacturesWithDate = allManufactures.filter(m => !isEmpty(m.dateFinish)).sort((a, b) => new Date(a.dateFinish) - new Date(b.dateFinish))
          manufacturesWithoutDate = allManufactures.filter(m => isEmpty(m.dateFinish))
          break
        case 'started':
          allManufactures = manufactures.map(m => {
            const actionsStarted = m.actions.filter(act => act.type === "START")[0]
            return {
              ...m,
              dateStart: !isEmpty(actionsStarted) && !isEmpty(actionsStarted.date) ? actionsStarted.date : null
            }
          })

          manufacturesWithDate = allManufactures.filter(m => !isEmpty(m.dateStart)).sort((a, b) => new Date(a.dateStart) - new Date(b.dateStart))
          manufacturesWithoutDate = allManufactures.filter(m => isEmpty(m.dateStart))
          break
        case 'included':
        case null:
        default:
          manufacturesWithDate = manufactures.sort((a, b) => {
            const dateA = a.dateAction ? moment(a.dateAction) : moment(a.created_at).add(3, 'hour')
            const dateB = b.dateAction ? moment(b.dateAction) : moment(b.created_at).add(3, 'hour')

            return dateA - dateB
          })
          break
      }

      setManufactures([...manufacturesWithDate, ...manufacturesWithoutDate])
    }

    const initialDate = filtersChanged.find((item) => item?.param === 'initialDate')?.value
    const finalDate = filtersChanged.find((item) => item?.param === 'finalDate')?.value

    return (
      isDesktopScreen ?
        <div className={`dFlex ${isDesktopScreen && 'alignCenter'}`} style={{ width: "220px", marginRight: isDesktopScreen ? "-34px" : "0px" }}>
          <label style={{ width: isDesktopScreen ? "70px" : "0px", marginBottom: "0px", marginRight: isDesktopScreen ? "8px" : "0px" }}>{isDesktopScreen ? `Ordenar por:` : ""}</label>

          <Autocomplete
            className='autocompleteSmall'
            style={{ width: '143px', height: '30px' }}
            options={orderOptions}
            getOptionLabel={(option) => option?.description || option}
            onChange={(_, value) => {
              setStoreOrderOption(value || null)
              orderByAutocomplete(value?.value)
            }}
            defaultValue={storeOrderOption?.description}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder={isDesktopScreen ? 'Selecione...' : 'Ordenar...'}
                inputProps={{
                  ...params.inputProps,
                  style: { paddingTop: 2, color: isDesktopScreen ? 'white' : 'black' },
                  className: isDesktopScreen ? "white-placeholder" : null
                }}
                ref={storeOrderInput}
              />
            )}
            renderOption={(params) => (
              <Typography style={{ fontSize: '12px', fontFamily: 'Lato' }}>
                {params?.description}
              </Typography>
            )}
          />
        </div>
        :
        <div
          className={`renderInMobile w100 mt5 mb5 ${isDesktopScreen && 'alignCenter'} `}
        >
          <div className="dFlex" style={{ gap: '45px', justifyContent: 'space-between' }}>
            <div style={{ fontSize: '16px', fontWeight: '700' }}>Produção</div>
            <div
              style={{ fontSize: '16px', fontWeight: '700', color: '#5062F0' }}
            >
              {initialDate
                ? moment(initialDate)
                  .add(3, 'hours')
                  .format('DD/MM')
                : moment(new Date()).format('DD/MM')}{' '}
              à{' '}
              {finalDate
                ? moment(finalDate)
                  .add(3, 'hours')
                  .format('DD/MM')
                : moment(new Date()).format('DD/MM')}
            </div>
          </div>

          <div className="w100">
            <Grid
              conteiner
              item
              xs={12}
              alignItems="center"
              style={{ display: 'flex', gap: '10px', justifyContent: 'space-between', }}
            >
              <BorderLinearProgress
                variant="determinate"
                color="primary"
                value={formatPercentValue || 0}
                className="borderProgressMobile"
              />
              
              <div className="lightBlue dFlex bold fontSize16">
                {filterFinishManufactures}/{totalManufactures} (
                {formatPercentValue || '0'}%)
              </div>
            </Grid>
          </div>

          <div className={`dFlex ${isDesktopScreen && 'alignCenter'}`} style={{ width: "100%", marginRight: isDesktopScreen ? "-45px" : "0px" }}>
            <label style={{ width: isDesktopScreen ? "70px" : "0px", marginBottom: "0px", marginRight: isDesktopScreen ? "8px" : "0px" }}>{isDesktopScreen ? `Ordenar por:` : ""}</label>
            <Autocomplete
              className='autocompleteSmall'
              style={{ width: '100%', height: '30px' }}
              options={orderOptions}
              getOptionLabel={(option) => option?.description || option}
              onChange={(_, value) => {
                setStoreOrderOption(value || null)
                orderByAutocomplete(value?.value)
              }}
              defaultValue={storeOrderOption?.description}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder={isDesktopScreen ? 'Selecione...' : 'Ordenar...'}
                  inputProps={{
                    ...params.inputProps,
                    style: { paddingTop: 2, color: isDesktopScreen ? 'white' : 'black' },
                    className: isDesktopScreen ? "white-placeholder" : null
                  }}
                  ref={storeOrderInput}
                />
              )}
              renderOption={(params) => (
                <Typography style={{ fontSize: '12px', fontFamily: 'Lato' }}>
                  {params?.description}
                </Typography>
              )}
            />
          </div>
        </div>
    );
  }, [isDesktopScreen, isMobileScreen, manuallyViewOrder, manufactures, filterFinishManufactures, totalManufactures, formatPercentValue]);

  const handleTime = (index, date, status) => {
    setState((state) => ({
      ...state,
      cards: state.cards.map((card) => {
        if (card.id === index) {
          switch (status) {
            case 'START':
              return { ...card, startTime: new Date() };
            case 'PAUSE':
              return { ...card, pauseTime: new Date() };
            case 'FINISH':
              return { ...card, finishTime: new Date() };
          }
        } else {
          return card;
        }
      })
    }));
  };

  // Functions to handle start production confirmation popover
  const handlePopoverClick = (event, { isMobile } = { isMobile: false }) => {
    setIsMobile(isMobileScreen);
    setAnchorEl(event.currentTarget);
    setDisableSubmitButton(false);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setUpdatedManufacture(false);
    setShowErrorManufacturedMessage(false);
    setCurrentTransformationCardItems();
  };

  function handleCloseAll(handle) {
    handle(false);
  }

  function capitalize(value) {
    return value ? value.charAt(0).toUpperCase() + value.slice(1) : null;
  }

  const handleUpdate = (
    value,
    action,
    e,
    initialYield = false,
    updatedManufacturedValue,
    lowChoice
  ) => {
    const { cards } = state;
    const isTransformation = value.type.toLowerCase() === 'transformation';
    const storedValue =
      isTransformation && action === 'FINISH'
        ? currentTransformationCardItems
        : manufactures.find((x) => x._id === value._id);
    const invalidDate =
      !moment(cardDate).isValid() || !moment(value.date).isValid();
    const invalidQuantity =
      isTransformation && (action === 'START' || action === 'WAITING')
        ? false
        : isTransformation && action === 'FINISH'
        ? storedValue?.items
            .map((item) => item.quantity == 0)
            .every((value) => value === true)
        : action === 'FINISH' || action === 'PAUSE'
        ? false
        : storedValue?.items.map((item) => item.quantity == 0).includes(true);
    const invalidDiscartOption =
      isTransformation && (action === 'START' || action === 'WAITING')
        ? false
        : storedValue?.items
            .map((item, index) =>
              item?.discard?.quantity
                ? !item.discard.justification?.description
                : false
            )
            .includes(true);

    if (invalidDate) {
      setModalStates({
        ...modalStates,
        modalConfirmationOfFinish: false
      });
      setAlertErr(true);
      setAlertMessage('Favor inserir uma data válida');
      return;
    }

    if ((invalidQuantity || invalidDiscartOption) && !initialYield) {
      setModalStates({
        ...modalStates,
        modalConfirmationOfFinish: false
      });
      setAlertErr(true);
      setShowTransformationErrorMsg(true);
      setAlertMessage('Favor preencher o campo de QTDE. do item/ingrediente');
      setDisableSubmitButton(false);
    } else {
      const id = value._id;
      const newStatus = {
        user: getUser,
        type: action,
        date: formatISO(new Date()),
        originDevice: originDevice()
      };
      const total = storedValue?.items.reduce(
        (total, value) =>
          value
            ? parseFloat(total) + parseFloat(value.quantity)
            : parseFloat(total),
        0
      );
      const cardQuantity = storedValue?.quantity || 1;
      const cardLostTotal = parseFloat(cardQuantity) - parseFloat(total);
      const body = {
        ...value,
        quantity: Number(parseFloat(storedValue?.quantity)) || 1,
        yieldLabelQuantity: storedValue?.yieldLabelQuantity,
        yield: Number(parseFloat(storedValue?.yield)),
        actions: [...value?.actions, newStatus],
        lossManufactured: 
          isTransformation && action === 'FINISH'
            ? (parseFloat(storedValue?.quantity) || 1) -
              storedValue?.items.reduce(
                (total, item) =>
                  item.quantity ? total + parseFloat(item.quantity) : total + 0,
                0
              )
            : storedValue?.yield -
                (updatedManufacturedValue || updatedManufacture || 0) >
              0
            ? storedValue?.yield -
              (updatedManufacturedValue || updatedManufacture || 0)
            : 0,
        status: action,
        lossTotal: isTransformation && action === 'FINISH' && parseFloat(100 * cardLostTotal) / parseFloat(cardQuantity),
        items: value?.items.map((item, index) => {
          const storedItem = storedValue?.items.find((x) => x.id === item.id)

          return {
            ...item,
            id: item.id,
            cost: item.cost,
            item: item.item,
            quantity: parseFloat(storedItem?.quantity || 0),
            labelQuantity: storedItem?.labelQuantity,
            yield: storedValue?.yield,
            manufactured: updatedManufacturedValue || updatedManufacture || 0,
            un: {
              id: item.un.id,
              description: item.un.description,
              abbreviation: item?.un?.abbreviation
            },
            discard:
              storedItem?.discard && storedItem?.discard.quantity
                ? {
                    quantity: storedItem?.discard.quantity
                      ? storedItem?.discard.quantity
                      : null,
                    justification: {
                      description: storedItem?.discard.justification
                        ? capitalize(
                            storedItem?.discard.justification.description
                          )
                        : null
                    }
                  }
                : null
          }
        }),
        manufactured:
          isTransformation && action === 'FINISH'
            ? Number(
                parseFloat(
                  storedValue?.items.reduce(
                    (total, value) =>
                      value
                        ? parseFloat(total) + parseFloat(value.quantity || 0)
                        : parseFloat(total),
                    0
                  )
                )?.toFixed(3)
              )
            : Number(
                parseFloat(
                  updatedManufacturedValue || updatedManufacture || 0
                )?.toFixed(3)
              ),
        date: 
          action === 'START' || action === 'WAITING' ? value.date : cardDate,
      }
      

      axios
        .put(`${environments.manufactures}/manufactures/${id}`, 
        {
          ...body,
          lowChoice,
        }, 
        config
        )
        .then((response) => {
          const data = manufactures;
          const index = manufactures.indexOf(value);
          data.find((x) => x._id === id).status = action;
          data.find((x) => x._id === id).user = newStatus.user;
          setUpdatePlates(!updatePlates)

          !initialYield && handleTime(index, formatISO(new Date()), action);

          if (action === 'FINISH') {
            setForceEnable(false);

            setCurrentTransformationCardItems();

            document.querySelector(`#print-${manufacture?._id}`)?.click();
            setModalStates({
              ...modalStates,
              modalItemsQuantityGreaterThanCard: false,
              modalConfirmationOfFinish: false,
              modalAutomaticDown: false
            });

            !initialYield && handleTime(index, formatISO(new Date()), action);
          }

          setManufactures(
            manufactures.map((manufacture) => {
              if (id === manufacture._id) {
                return response?.data
              }
              return manufacture;
            })
          );

          setState({ data, cards });
          setAlertErr(false);
          setShowTransformationErrorMsg(false);
          setLoading(false);
          setUpdatedManufacture();
          buildPlateInfos(manufactures, !plate?.some(el => el?.active))
          !initialYield && handleClose(e);
          // setDisableSubmitButton(false)

          if (
            mobileFocusIndex !== '' &&
            document.querySelector(`#manufacture-${mobileFocusIndex + 1}`)
          ) {
            setMobileFocusIndex('');
            document
              .querySelector(`#manufacture-${mobileFocusIndex + 1}`)
              .select();
          }
        })
        .catch((error) => {
          const errorMessage = error?.response?.data?.message

          setMessage({
            description: errorMessage?.charAt(0).toUpperCase() + errorMessage?.slice(1) || 'Erro ao executar ação.',
            severity: 'error',
            autoHideDuration: 3000
          })
        
          setModalStates({
            ...modalStates,
            modalConfirmationOfFinish: false
          });
          setLoading(false);
          setDisableSubmitButton(false);
        });
    }
  };

  // Functions to handle state of cards values (quantities of production and/or discards)
  const handleCardQuantity = (
    index,
    quantity,
    itemIndex,
    itemQuantity,
    totalYield,
    mobileFocusIndex,
    itemLabelQuantity = '',
    labelQuantity = '',
    newManufacture,
  ) => {
    // RENDIMENTO: handleCardQuantity(index, (manufacture.quantity || 1), undefined, undefined, parseFloat(value))
    // QUANTIDADE: handleCardQuantity(index, parseFloat(value), undefined, undefined, undefined)
    // INTERN QTDE: handleCardQuantity(cardIndex, manufacture.quantity, index, parseFloat(value), undefined)

    // RECEITA -> RENDIMENTO:
    // RENDIMENTO: totalYield
    // QUANTIDADE: totalYield * ((manufacture.quantity || 1) / manufacture.yield)
    // ITENS: item.quantity * totalYield / manufacture.yield

    // RECEITA -> QUANTIDADE:
    // RENDIMENTO: quantity * (manufacture.yield / (manufacture.quantity || 1))
    // QUANTIDADE: quantity
    // ITENS: (quantity * item.quantity) / (manufacture.quantity || 1)

    // RECEITA -> ITENS:
    // RENDIMENTO: itemQuantity * manufacture.yield / (manufacture.items.find((item, elementIndex) => elementIndex === itemIndex).quantity)
    // QUANTIDADE: itemQuantity * manufacture.quantity / (manufacture.items.find((item, elementIndex) => elementIndex === itemIndex).quantity)
    // ITENS: itemQuantity

    // TRANSF -> RENDIMENTO:
    // RENDIMENTO: totalYield
    // QUANTIDADE: totalYield * ((manufacture.quantity || 1) / manufacture.yield)
    // ITENS: item.quantity

    // TRANSF -> QUANTIDADE:
    // RENDIMENTO: quantity - (quantity * (manufacture.loss / 100))
    // QUANTIDADE: quantity
    // ITENS: item.quantity

    // TRANSF -> ITENS:
    // RENDIMENTO: itemQuantity * manufacture.yield / (manufacture.items.find((item, elementIndex) => elementIndex === itemIndex).quantity)
    // QUANTIDADE: quantity
    // ITENS: itemQuantity

    const currentManufacture = newManufacture || manufactures.find((_, manufactureIndex) => index === manufactureIndex)

    const manufactureItemsCalc = {
      ...currentManufacture,
      yieldLabelQuantity:
        itemIndex !== undefined
          ? currentManufacture.yieldLabelQuantity
          : labelQuantity,
      quantity:
        totalYield !== undefined
          ? currentManufacture.type.toLowerCase() === 'transformation'
            ? currentManufacture.loss
              ? (parseFloat(totalYield) / (100 - currentManufacture.loss)) * 100
              : totalYield
            : (parseFloat(totalYield) * (currentManufacture.quantity || 1)) /
              currentManufacture?.yield
          : currentManufacture.type.toLowerCase() === 'recipe'
          ? itemIndex !== undefined
            ? (itemQuantity * currentManufacture.quantity) /
              currentManufacture.items.find(
                (item, elementIndex) => elementIndex === itemIndex
              )?.quantity
            : quantity
          : itemIndex !== undefined
          ? currentManufacture.loss
            ? (parseFloat(
                currentManufacture.items
                  .filter((item, elementIndex) => elementIndex !== itemIndex)
                  ?.map((item) => item?.quantity || 0)
                  .reduce(
                    (total, value) =>
                      value
                        ? parseFloat(total) + parseFloat(value)
                        : parseFloat(total),
                    0
                  ) + itemQuantity
              ) /
                (100 - currentManufacture.loss)) *
              100
            : currentManufacture.items
                .filter((item, elementIndex) => elementIndex !== itemIndex)
                ?.map((item) => item?.quantity || 0)
                .reduce(
                  (total, value) =>
                    value
                      ? parseFloat(total) + parseFloat(value)
                      : parseFloat(total),
                  0
                ) + itemQuantity
          : quantity,
      initialYield: currentManufacture.initialYield
        ? currentManufacture.initialYield
        : currentManufacture.type.toLowerCase() === 'recipe'
        ? currentManufacture?.yield * 1
        : 1 - 1 * (currentManufacture.loss / 100),
      initialQuantity:
        itemQuantity !== undefined && !currentManufacture?.loss
          ? currentManufacture.items
              .filter((item, elementIndex) => elementIndex !== itemIndex)
              ?.map((item) => item?.quantity || 0)
              .reduce(
                (total, value) =>
                  value
                    ? parseFloat(total) + parseFloat(value)
                    : parseFloat(total),
                0
              ) + itemQuantity
          : currentManufacture?.initialQuantity,
      yield:
        totalYield !== undefined
          ? parseFloat(totalYield)
          : itemIndex !== undefined
          ? currentManufacture.type.toLowerCase() === 'transformation'
            ? currentManufacture.items
                .filter((item, elementIndex) => elementIndex !== itemIndex)
                ?.map((item) => item?.quantity || 0)
                .reduce(
                  (total, value) =>
                    value
                      ? parseFloat(total) + parseFloat(value)
                      : parseFloat(total),
                  0
                ) + itemQuantity
            : (itemQuantity * currentManufacture.quantity) /
                currentManufacture.items.find(
                  (item, elementIndex) => elementIndex === itemIndex
                )?.quantity >=
                0 && currentManufacture.type.toLowerCase() === 'recipe'
            ? (itemQuantity * currentManufacture?.yield) /
              currentManufacture.items.find(
                (item, elementIndex) => elementIndex === itemIndex
              )?.quantity
            : currentManufacture.initialYield
            ? currentManufacture.initialYield
            : 1 - 1 * (currentManufacture.loss / 100)
          : quantity >= 0 &&
            currentManufacture.type.toLowerCase() === 'transformation'
          ? currentManufacture?.items.some((el) => el?.quantity)
            ? currentManufacture.items
                .map(
                  (item) =>
                    quantity * (item?.quantity / currentManufacture?.quantity) || 0
                )
                .reduce(
                  (total, value) =>
                    value
                      ? parseFloat(total) + parseFloat(value)
                      : parseFloat(total),
                  0
                )
            : quantity * ((100 - currentManufacture?.loss) / 100)
          : quantity >= 0 && currentManufacture.type.toLowerCase() === 'recipe'
          ? (quantity * currentManufacture?.yield) / (currentManufacture.quantity || 1)
          : currentManufacture.initialYield
          ? currentManufacture.initialYield
          : 1 - 1 * (currentManufacture.loss / 100),
      items: currentManufacture.items.map((item, elementIndex) =>
        quantity >= 0 && currentManufacture.type.toLowerCase() === 'recipe'
          ? {
              ...item,
              labelQuantity:
                itemIndex !== undefined && itemIndex === elementIndex
                  ? itemLabelQuantity || item.labelQuantity
                  : '',
              quantity:
                itemIndex !== undefined && itemIndex === elementIndex
                  ? itemQuantity
                  : totalYield !== undefined
                  ? (item.quantity * parseFloat(totalYield)) /
                    currentManufacture?.yield
                  : itemIndex !== undefined
                  ? ((itemQuantity * 1) /
                      currentManufacture.items.find(
                        (item, elementIndex) => elementIndex === itemIndex
                      )?.quantity) *
                    item.quantity
                  : (quantity * item.quantity) / (currentManufacture.quantity || 1)
            }
          : {
              ...item,
              labelQuantity:
                itemIndex !== undefined && itemIndex === elementIndex
                  ? itemLabelQuantity || item.labelQuantity
                  : item.labelQuantity,
              initialQuantity:
                itemIndex === elementIndex
                  ? itemQuantity !== undefined
                    ? itemQuantity
                    : item?.initialQuantity
                  : item?.initialQuantity,
              quantity:
                totalYield !== undefined
                  ? currentManufacture?.loss
                    ? (totalYield / (100 - currentManufacture?.loss)) *
                        100 *
                        (item?.quantity / currentManufacture?.quantity) || 0
                    : totalYield * (item?.quantity / currentManufacture?.quantity) ||
                      0
                  : quantity !== undefined && itemQuantity === undefined
                  ? quantity * (item?.quantity / currentManufacture?.quantity) || 0
                  : itemIndex !== undefined && itemIndex === elementIndex
                  ? itemQuantity
                  : item.quantity || 0
            }
        )
    }

    const validateTransformationCard =
      manufactureItemsCalc?.quantity === 0
        ? manufactureItemsCalc?.items.length === 1
        : false;

    const isInvalidYield = ((quantity * currentManufacture?.yield) / (currentManufacture?.quantity || 1))?.toFixed(3) === "0.000"

    const hasInvalidItemsCalculated =
      validateTransformationCard &&
      manufactureItemsCalc?.items?.some(
        (el) => parseFloat(el.quantity?.toFixed(6)) === 0
      ) || isInvalidYield

    const hasMoreThanThreeDecimalZeros = manufactureItemsCalc?.items?.filter(
      (el) => parseFloat(el.quantity.toFixed(5)) < 0.00099
    );

    setManufactures(
      manufactures.map((manufacture, manufactureIndex) => {
        if (index === manufactureIndex && !hasInvalidItemsCalculated) {
          return manufactureItemsCalc;
        }

        return manufacture;
      })
    );

    if (hasMoreThanThreeDecimalZeros?.length && manufactureItemsCalc?.type === 'RECIPE' && !isInvalidYield) {
      setModalSettings(
        modalSettings.map((modal) =>
          modal.name === 'modalDecimalsQuantityAlert'
            ? {
                ...modal,
                open: true,
                items: hasMoreThanThreeDecimalZeros
              }
            : modal
        )
      );
    } else if (hasInvalidItemsCalculated || isInvalidYield) {
      setModalStates({
        ...modalStates,
        modalInvalidQuantity: true
      });
    }

    if (!newManufacture) {
      setUpdateManufactureValues(!updateManufactureValues);
    } else {
      setManufacture({
        ...manufacture,
        quantity: manufactureItemsCalc?.quantity,
        yield: manufactureItemsCalc?.yield,
        items: manufactureItemsCalc?.items
      })
    }

    mobileFocusIndex && setMobileFocusIndex(itemIndex);
  };

  const handleUpdateDiscard = (
    id,
    quantity,
    reason,
    product,
    itemIndex,
    discardId,
    discardDescription
  ) => {
    const body = {
      productId: product?.id,
      quantity,
      cost: product?.cost,
      discardId,
      description: discardDescription,
      user: userInfo?.user
    };

    axios
      .put(
        `${environments.manufactures}/manufactures/${id}/discards`,
        body,
        config
      )
      .then((response) => {
        setManufactures(
          manufactures.map((manufacture, manufactureIndex) => {
            if (id === manufacture._id) {
              return {
                ...manufacture,
                items: manufacture.items.map((item, elementIndex) => {
                  if (elementIndex === itemIndex) {
                    return {
                      ...item,
                      discard: [
                        {
                          quantity,
                          description: quantity === 0 ? '' : discardDescription,
                          justification: {
                            ...item.justification,
                            id: discardId,
                            description:
                              quantity === 0 ? '' : discardDescription
                          }
                        }
                      ]
                    };
                  }
                  return item;
                })
              };
            }
            return manufacture;
          })
        );

        setLoading(false);
        // setUpdatedManufacture();
      })
      .catch((error) => {
        const errorMessage = error?.response?.data?.message

        setMessage({
          description: errorMessage?.charAt(0).toUpperCase() + errorMessage?.slice(1) || 'Erro ao executar ação.',
          severity: 'error',
          autoHideDuration: 3000
        })
        setLoading(false);
        setDisableSubmitButton(false);
      });
  };

  const handleItemDiscard = (
    id,
    itemIndex,
    quantity,
    reason,
    updateDiscard,
    product
  ) => {
    const discardId =
      reason?.id ||
      (product?.discard?.length
        ? product?.discard[0]?.justification?.id
        : product?.discard?.justification?.id);
    const discardDescription =
      reason?.description ||
      (product?.discard?.length
        ? product?.discard[0]?.justification?.description
        : product?.discard?.justification?.description);

    if (updateDiscard) {
      handleUpdateDiscard(
        id,
        quantity,
        reason,
        product,
        itemIndex,
        discardId,
        discardDescription
      );
      return;
    }

    setManufactures(
      manufactures.map((manufacture, manufactureIndex) => {
        if (id === manufacture._id) {
          return {
            ...manufacture,
            items: manufacture.items.map((item, elementIndex) => {
              if (elementIndex === itemIndex) {
                return {
                  ...item,
                  discard: [
                    {
                      quantity,
                      description: discardDescription,
                      justification: {
                        ...item.justification,
                        id: discardId,
                        description: discardDescription
                      }
                    }
                  ]
                };
              }
              return item;
            })
          };
        }
        return manufacture;
      })
    );
  };

  const getDuration = (productId, stock, product, type, newDuration) => {
    setDurationDialogLoading(true);
    axios
      .get(
        `${environments.engine}/rupture?productId=${productId}&restaurantId=${getId}&days=8&stock=${stock}`
      )
      .then((response) => {
        const items = response.data;
        setDurationData({
          product,
          data: items,
          type,
          newDuration
        });
        setDurationDialogLoading(false);
      })
      .catch((error) => {
        setAlertErr(true);
        setAlertMessage(error?.response?.data?.message);
        setStatusCode(error?.response?.status);
        setDurationDialogLoading(false);
      });
  };

  // Functions to handle modals
  const handleClickOpenModal = (data) => {
    if (data?.type === 'TRANSFORMATION') {
      getOptions(
        `${environments.catalog}/products`,
        'products',
        selectedProduct,
        setSelectedProduct,
        getId,
        { productId: data?.productId, autoComplete: true },
        null,
        null,
        true
      );
    }

    setModalStates({
      ...modalStates,
      modalDuration: true
    });
  };

  const handleOpenItems = (id) => {
    setManufactures(
      manufactures?.map((manufacture) => {
        if (manufacture._id === id) {
          return {
            ...manufacture,
            open: !manufacture.open
          };
        }

        return { ...manufacture };
      })
    );
  };

  const handleDialogClickOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  function handleDelete() {
    const { cards } = state;
    const newStatus = {
      user: getUser,
      type: 'CANCELED',
      date: formatISO(new Date())
    };
    const id = deleteData._id;
    const body = {
      cancellationReason: cancellationReason?.description
    };

    new Promise((resolve, reject) => {
      const baseUrl = `${environments.manufactures}/manufactures`;

      axios
        .delete(`${baseUrl}/${deleteData._id}?user=${getUser}`, {
          data: {
            cancellationReason: body.cancellationReason,
            originDevice: originDevice()
          },
          headers: { User: `${userLocal?.user}` }
        })
        .then((response) => {
          {
            const data = manufactures;
            const index = manufactures.indexOf(deleteData);
            data.find((x) => x._id === id).status = 'CANCELED';
            setState({ data, cards });
            setManufactures(
              manufactures.map((manufacture) => {
                if (id === manufacture._id) {
                  return {
                    ...manufacture,
                    actions: [
                      ...manufacture.actions,
                      {
                        user: getUser,
                        type: 'CANCELED',
                        originDevice: originDevice()
                      }
                    ],
                    cancellationReason: cancellationReason?.description,
                    date: new Date()
                  };
                }
                return {
                  ...manufacture
                };
              })
            );
          }

          buildPlateInfos(manufactures)
          setCancellationReason('');
          resolve();
          handleDialogClose();
        })
        .catch((error) => {
          setAlertErr(true);
          setAlertMessage(error?.response?.data?.message);
          setStatusCode(error?.response?.status);
          reject();
        });
        setUpdatePlates(!updatePlates);
    });
  }

  const replaceDayDurationData = (data) => {
    if (data === 'D0') return 'Hoje';
    return data;
  };

  const onClearFilters = () => {
    const TODAY = new Date();

    const FILTERS = DEFAULT_FILTERS.map((filter) => {
      if (filter?.param === 'initialDate') {
        return {
          param: 'initialDate',
          value: '',
          urlParam: ''
        };
      }

      if (filter?.param === 'finalDate') {
        return {
          param: 'finalDate',
          value: format(TODAY, 'yyyy-MM-dd'),
          urlParam: `finalDate=${format(TODAY, 'yyyy-MM-dd')}`
        };
      }

      return { ...filter, value: '', urlParam: '' };
    });

    const FILTER_FIELDS = filterFields.map((filterLine, index) => {
      if (index === 0) {
        return filterLine.map((filter) => {
          if (filter.type === 'dateRangeField') {
            return {
              type: 'dateRangeField',
              size: 'small',
              filterNameFirst: 'startDate',
              filterNameSecond: 'endDate',
              initialDate: '',
              endDate: TODAY
            };
          }

          return filter;
        });
      }

      return filterLine;
    });

    setCallSettings((prevState) => ({
      ...prevState,
      filters: FILTERS
    }));
    setFiltersChanged(FILTERS);
    setFilterFields(FILTER_FIELDS);
  };

  const scheduleButton = (width) => (
    <Tooltip title="Visualizar agenda" aria-label="filters">
      <Link style={{ width: 25 }} className="dFlex alignCenter justifyCenter" target="_blank" to={`/agenda/${paramsID}?currentDate=true`}>
        <IconButton
          className="p0 dBlock m0"
          edge="start"
          color="inherit"
          aria-label="add"
        >
          <ImgIcon widthImg={width} src={ScheduleIcon} alt="Schedule" />
        </IconButton>
      </Link>
    </Tooltip>
  );

  const copilotConfigButton = () => (
    <Tooltip 
      title={
        <span>
          Copiloto<br/>
          Programação {copilotStatus?.message === 'Pendente' ? 'pendente' : 'finalizada'}
        </span> 
      }
      aria-label="filters"
    >
      <IconButton
        onClick={() => {
          setModalSettings(
            modalSettings.map((modal) =>
              modal.name === 'copilotConfig' ? { ...modal, open: true } : modal
            )
          );
        }}
        className="p0 mr10"
        edge="start"
        color="inherit"
        aria-label="add"
      >
        <ImgIcon widthImg='25px' src={copilotStatus?.message === 'Pendente' ? CopilotPendentIcon : CopilotEnableIcon} alt="Copilot Button" />
      </IconButton>
    </Tooltip>
  );

  const isOutOfValidPercentage = () => {
    const total = currentTransformationCardItems?.items?.reduce(
      (total, value) =>
        value
          ? parseFloat(total) + parseFloat(value.quantity || 0)
          : parseFloat(total),
      0
    );
    const cardLostTotal =
      parseFloat(currentTransformationCardItems?.quantity || 0) -
      parseFloat(total);

    return {
      invalid:
        parseFloat(100 * Math.abs(cardLostTotal)) /
          parseFloat(manufacture.yield) >
          parseFloat(parametersInfos?.manufactures?.percentageForAlert) &&
        currentTransformationCardItems?.items?.some((el) => el?.quantity),
      diffPercentage:
        parseFloat(100 * Math.abs(cardLostTotal)) /
          parseFloat(manufacture.yield) -
        parseFloat(parametersInfos?.manufactures?.percentageForAlert)
    };
  };

  const { companies, companiesActive } = useSelector((state) => state.user);

  const getTransformationInfos = (currentManufacture) => {
    axios
      .get(
        `${environments.catalog}/products/manufactures/transformation/${currentManufacture?.productId}?originId=${getId}`
      )
      .then((response) => {
        setTransformationInfos(response?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const getCardPreviewInfos = (card) => {
    axios
    .get(
      `${environments.manufactures}/manufactures/preview/${card.id}?originId=${getId}`
    )
      .then((response) => {
        const item = response.data;

        if (item?.type === "TRANSFORMATION") {
          getTransformationInfos(item)
        }

        const handleQuantityField = (value) =>
          value
            ? parseFloat(value).toLocaleString('pt-br', { minimumFractionDigits: 3 })
            : '0,000';

        const performance = handleQuantityField(
          item?.initialYield ||
            item?.status === 'FINISH' ||
            item?.status === 'CANCELED'
            ? item?.yield
            : item?.type === 'RECIPE'
            ? item?.yield
            : (item?.quantity || 1) -
              (item?.quantity || 1) * (item.loss / 100)
        );

        const cardYield =
          item?.type === 'recipe'
            ? item?.yield * (item?.quantity || 1)
            : (item?.quantity || 1) -
              (item?.quantity || 1) * (item?.loss / 100);
        const total = item?.items.reduce(
          (total, value) =>
            value
              ? parseFloat(total) + parseFloat(value.quantity)
              : parseFloat(total),
          0
        );
        const itemFormatted = {
          ...item,
          cardYield,
          total,
          performance
        }
        setManufacture(itemFormatted);
      })
      .catch((error) => {
        const errorMessage = error?.response?.data?.message
        
        setMessage({
          description: error?.response?.data?.message || 'Erro ao incluir nova produção!',
          severity: 'error',
          autoHideDuration: 3000
        })
        setLoading(false);
      });
  }

  const renderMidCourt = (mediaCourt) => (
    <div
      style={{
        width: mediaCourt ? '100%' : '50%',
        position: 'relative',
        display: 'flex',
        justifyContent: mediaCourt ? 'center' : '',
        alignItems: 'center',
        marginBottom: 5,
        paddingTop: isMobileScreen ? 7 : 0,
        paddingBottom: mediaCourt ? '10px' : '',
        color: mediaCourt ? '#fff' : '',
        background: mediaCourt && '#1F2445',
        boxShadow: mediaCourt && '5px 5px 4px 0px #0000004D'
      }}
    >
      <label
        className="alignEnd bold fontSizeDefault mr5 mb0 ml10"
        style={{
          marginTop: 7,
          lineHeight: 'normal',
          color: '#333541',
          width: 50,
        }}
      >
        {mediaCourt ? 'PRAÇA SELECIONADA:' : 'Praça:'}
      </label>

      <TreeSelectorFieldSquares
        filterName="courts"
        style={{ width: '30%', height: '25px' }}
        handleFilter={(_, value) => {
          handleFilter(`courtId`, value || '');
        }}
        key={(node) => node?.id}
        customSize="200px"
        getFilterhandle={() => {}}
        options={[]}
        onFormStyle
      />

      {isGroupManage ? (
        <>
          <label
            style={{
              fontSize: '16px',
              fontWeight: 700,
              margin: 0,
              marginRight: 5,
              marginLeft: 39
            }}
          >
            Loja:
          </label>

          <Autocomplete
            className={
              mediaCourt ? 'autocompleteCourtMobile' : 'autocompleteSmall'
            }
            placeholder="Selecione..."
            style={{ width: '30%', height: '25px' }}
            options={storeOptions}
            getOptionLabel={(option) => option?.description || option}
            onChange={(_, value) => {
              setStoreFilterValue(value || null);
              if (value !== null) {
                getMainContent(value?.id);
              } else {
                getMainContent(companiesActive.id);
              }
            }}
            value={storeFilterValue}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                inputProps={{
                  ...params.inputProps,
                  style: { paddingTop: 2 }
                }}
                ref={storeInput}
              />
            )}
            renderOption={(params) => (
              <Typography style={{ fontSize: '12px', fontFamily: 'Lato' }}>
                {params?.description}
              </Typography>
            )}
          />
        </>
      ) : null}
    </div>
  );

  useEffect(() => {
    const filterFinishManufacturesInfos = manufactures.filter((manufacture) => manufacture.status === "FINISH").length
    const totalManufacturesInfos = manufactures.filter((manufacture) => manufacture.status !== "CANCELED").length
    const percentValue = (filterFinishManufacturesInfos / totalManufacturesInfos) * 100

    setFilterFinishManufactures(filterFinishManufacturesInfos)
    setTotalManufactures(totalManufacturesInfos)
    setFormatPercentValue(Math.ceil(percentValue) || 0)
  }, [manufactures, plate])

  useEffect(() => {
    setCourtFilterValue('')
  }, [paramsID, getId])

  useEffect(() => {
    const newArray = [];
    if (!Array.isArray(companiesActive.id)) {
      newArray.push({
        id: companiesActive.id,
        description: companiesActive.name
      });
    } else {
      getRestaurantsArray(companies).map((item) => {
        if (companiesActive?.id?.includes(item?.id)) {
          return newArray.push({
            id: item.id,
            description: item?.name
          });
        }
      });
    }
    if (Array.isArray(companiesActive.id)) {
      setStoreFilterValue(null);
    } else {
      setStoreFilterValue({
        id: getRestaurantbyId(companiesActive?.id, companies)?.id,
        description: getRestaurantbyId(companiesActive?.id, companies)?.name
      });
    }
    setStoreOptions(newArray);
  }, [companiesActive]);

  useEffect(() => {
    if (manufacture) {
      handleUpdate(manufacture, manufacture.status, null, true);
    }
  }, [updateManufactureValues]);

  useEffect(() => {
    setTimeout(() => {
      if (document.querySelector('.quantityPopupBox input')) {
        document.querySelector('.quantityPopupBox input').select();
      } else if (document.querySelector('#popupInternalItem-0')) {
        document.querySelector('#popupInternalItem-0').select();
      }
    }, 100);
  }, [popoverOpen]);

  useEffect(() => {
    const isTransformation = manufacture?.type === 'TRANSFORMATION';
    const currentManufacture = isTransformation
      ? currentTransformationCardItems
      : manufacture;
    const sumeManufactureItems = currentManufacture?.items?.reduce(
      (total, value) =>
        value.quantity
          ? parseFloat(total) + parseFloat(value.quantity)
          : total + 0,
      0
    );
    const itemsAreGreaterThanCard =
      sumeManufactureItems > currentManufacture?.quantity;

    if (itemsAreGreaterThanCard && isTransformation && checkSumeItems) {
      setModalStates({
        ...modalStates,
        modalItemsQuantityGreaterThanCard: true
      });
    }
  }, [manufacture?.items, checkSumeItems]);

  useEffect(() => {
    setGetId(paramsID);
    setUser(userInfo?.user);
    getParametersInfos();
    getCopilotStatus()
  }, [paramsID, userInfo?.user]);

  // Cards
  useEffect(() => {
    (async () => {
      setLoading(true);
      await axios
      .get(
        `${environments.catalog}/cards?page=1&size=1000&originId=${getId}&description=${query}&type=RECIPE,TRANSFORMATION&autoComplete=true&controlProduction=true`
      )
        .then((response) => {
          const items = response.data.content;
          setOptionsCards(items);
          setLoading(false);
        })
        .catch((error) => {
          setAlertErr(true);
          setAlertMessage(error?.response?.data?.message);
          setStatusCode(error?.response?.status);
        });
    })();
  }, [getId, query, controlProductionModal]);

  // Discards Products
  useEffect(() => {
    (async () => {
      await axios
        .get(
          `${environments.catalog}/discards?type=PRODUCT&size=1000&page=1&originId=${getId}`
        )
        .then((response) => {
          const items = response.data.content;
          setOptionsDiscard(items);
        })
        .catch((error) => {
          setAlertErr(true);
          setAlertMessage(error?.response?.data?.message);
          setStatusCode(error?.response?.status);
        });
    })();
  }, [getId]);

   // Discards Manufactures
   useEffect(() => {
    (async () => {
      await axios
        .get(
          `${environments.catalog}/discards?type=MANUFACTURE&size=1000&page=1&originId=${getId}`
        )
        .then((response) => {
          const items = response.data.content;
          setOptionsDiscardManufactures(items);
        })
        .catch((error) => {
          setAlertErr(true);
          setAlertMessage(error?.response?.data?.message);
          setStatusCode(error?.response?.status);
        });
    })();
  }, [getId]);

  useEffect(() => {
    (async () => {
      if (productIdQueryParam) {
        await axios
          .get(
            `${environments.catalog}/products/${productIdQueryParam}?originId=${getId}`
          )
          .then((response) => {
            const items = response.data;
            const newFilters = [...filterFields];

            newFilters[0][1].storedValue = items?.description;

            setFilterFields(newFilters);
          })
          .catch((error) => {
            setAlertErr(true);
            setAlertMessage(error?.response?.data?.message);
            setStatusCode(error?.response?.status);
          });
      }
    })();
  }, [getId]);

  useEffect(() => {
    (async () => {
      if (cardIdQueryParam) {
        await axios
          .get(
            `${environments.catalog}/cards/${cardIdQueryParam}?originId=${getId}`
          )
          .then((response) => {
            const items = response.data;
            const newFilters = [...filterFields];

            newFilters[0][3].storedValue = items?.description;

            setFilterFields(newFilters);
          })
          .catch((error) => {
            setAlertErr(true);
            setAlertMessage(error?.response?.data?.message);
            setStatusCode(error?.response?.status);
          });
      }
    })();
  }, [getId]);

  useEffect(() => {
    if (
      !storedProductionsFilter?.filter((el) => el?.filterName.includes('Id'))
        ?.length
    )
      getMainContent(paramsID);
  }, [
    paramsID,
    page,
    filtersChanged,
    storedProductionsFilter?.filter((el) => el?.filterName.includes('Id'))
      ?.length,
  ]);

  useEffect(() => {
    if (
      storedProductionsFilter?.filter((el) => el?.filterName.includes('Id'))
        ?.length
    )
      getMainContent(paramsID);

  }, [storedProductionsFilter]);

  return (
    <Layout>
      {/* <Production /> */}
      <TitleHead title="Produção" />

      <GlobalStyle />

      <LocalStyle />

      <Container className="productionPage">
        {alertErr ? (
          <Snackbar
            className="alert-mobile hideAlertMobile"
            open={alertErr}
            onClose={clickAway}
          >
            <Alert
              className="alertMessage"
              style={{ width: '100%' }}
              color="danger"
              toggle={onDismiss}
            >
              {getAlert || 'Erro ao adicionar produção'}
            </Alert>
          </Snackbar>
        ) : null}

        <div className="desktopFilterInfo">
          <PageTitleAndFilter
            title={
              <div
                className="dFlex alignCenter"
                style={{
                  height: '30px',
                  width: 'auto',
                  gap: '10px',
                }}
              >
                <div>Produção</div>

                <Grid
                  conteiner
                  item
                  xs={12}
                  alignItems="center"
                  className="dFlex"
                  style={{ gap: '10px' }}
                >
                  <BorderLinearProgress
                    variant="determinate"
                    color="primary"
                    value={formatPercentValue || 0}
                    className="borderProgress"
                  />

                  <div className="dFlex lightBlue">
                    {filterFinishManufactures}/{totalManufactures} (
                    {formatPercentValue || '0'}%)
                  </div>
                </Grid>
              </div>
            }
            activeFilterFilds={filterFields}
            extrabutton={copilotConfigButton}
            additionalbutton={scheduleButton}
            handleFilter={handleFilter}
            handleMultipleFilters={handleOrdernationFilter}
            getFilterhandle={getFilterhandle}
            dynamicJustifyOff
            onClear={onClearFilters}
            filterDefaultStatus={false}
            customClassName="noMarginFilter"
          />
        </div>

        <div className="mobileFilterInfo">
          <div className="dFlex justifyBetween">
            <div className="dFlex flexColumn mt10 pr10">
              <h4 className="capitalize numberPreparations mb0 bold fontSizeDefault">
                {`${format(new Date(), 'EEEE, dd/MM/yyyy', {
                  locale: ptLocale
                }).replace('-feira', '')}`}

                <br />

                <span
                  className="lightBlue bold"
                  style={{
                    fontSize: '16px',
                    marginTop: '4px'
                  }}
                >
                  {`${
                    manufactures.filter(
                      (item) =>
                        item.status !== 'CANCELED' &&
                        moment(item?.date).format('DD/MM/YYYY') ===
                          moment(new Date()).format('DD/MM/YYYY')
                    ).length
                  } Preparos`}
                </span>
              </h4>

              {renderManuallyViewOrder}
            </div>

            <PageTitleAndFilter
              activeFilterFilds={filterFields}
              handleFilter={handleFilter}
              handleMultipleFilters={handleOrdernationFilter}
              getFilterhandle={getFilterhandle}
            />
          </div>

          <div className="mobilefilter" style={{ flexDirection: 'column' }}>
            <ProductionCardFilter className="alignStart mr0 w100">
              <div className="inputFilter flexColumn alignStart">
                <label
                  className="bold dFlex alignEnd mr8 mt10"
                  style={{
                    fontSize: '16px',
                    lineHeight: 'normal'
                  }}
                >
                  Produzir
                </label>

                <InputContainer
                  className="mb10 pRelative"
                  style={{
                    borderBottom: '1px solid rgba(0, 0, 0, 0.42)'
                  }}
                >
                  <Autocomplete
                    key="cardOption"
                    id="page-title-filter"
                    onChange={(_, value) => {
                      if (value) {
                        !value?.controlProduction &&
                          setControlProductionModal(true);
                        value
                          ? setNewProduction(value)
                          : setNewProduction(null);
                      } else {
                        setNewProduction(null)
                      }
                    }}
                    disablePortal
                    options={optionsCards || []}
                    getOptionLabel={(option) => option.description}
                    style={{ width: '100%' }}
                    value={newProduction}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Pesquisar Fichas..."
                        fullWidth
                        InputLabelProps={{
                          shrink: true
                        }}
                      />
                    )}
                    renderOption={(option) => (
                      <StyledTypography
                        noWrap
                        controlProduction={option.controlProduction}
                        style={{ fontSize: '12px' }}
                      >
                        {option.description}
                      </StyledTypography>
                    )}
                  />
                </InputContainer>

                <label
                  className="bold dFlex alignEnd"
                  style={{
                    fontSize: '16px',
                    lineHeight: 'normal',
                    margin: '3px 8px 0 0'
                  }}
                >
                  Data da produção
                </label>

                <InputContainer className="mr8" style={{ width: '50%' }}>
                  <MuiPickersUtilsProvider
                    utils={DateFnsUtils}
                    locale={ptLocale}
                  >
                    <KeyboardDatePicker
                      size="small"
                      id="cardProductionDateMobile"
                      ampm={false}
                      format="dd/MM"
                      value={cardProductionDate}
                      inputVariant="outlined"
                      className="dateSmall noBorder"
                      autoOk
                      InputProps={{
                        style: { fontSize: '12px' }
                      }}
                      onFocus={() => {
                        setTimeout(() => {
                          document
                            .querySelector(`#cardProductionDateMobile`)
                            .select();
                        }, 100);
                      }}
                      onChange={(date) => {
                        setCardProductionDate(date);
                      }}
                      KeyboardButtonProps={{
                        'aria-label': 'change time'
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </InputContainer>
              </div>

              <Tooltip title="Adicionar produção" aria-label="filters">
                <IconButton
                  color="inherit"
                  style={{ outline: 0, padding: '12px 0px 12px 12px' }}
                  aria-label="add"
                  edge="start"
                  disabled={loading}
                  onClick={(e) => {
                    if (newProduction) {
                      if (newProduction.type === 'TRANSFORMATION') {
                        InsertData(newProduction);
                      } else {
                        InsertData(newProduction);
                      }
                    }
                  }}
                >
                  <img style={{ width: '44px' }} src={AddIcon} alt="AddIcon" />
                </IconButton>
              </Tooltip>
            </ProductionCardFilter>

            {renderMidCourt(true)}
          </div>
        </div>

        <div className="tabletFilterInfo" style={{ flexDirection: 'column' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', gap: '10px' }}>
            <div className="dFlex flexColumn" style={{ width: "40%" }}>
              {/* <h4 className="capitalize numberPreparations mb0 bold fontSizeDefault">
                {`${format(new Date(), 'EEEE, dd/MM/yyyy', {
                  locale: ptLocale
                }).replace('-feira', '')}`}
                <br />
                <span
                  className="lightBlue bold"
                  style={{
                    fontSize: '16px',
                    marginTop: '4px'
                  }}
                >{`${
                  manufactures.filter(
                    (item) =>
                      item.status !== 'CANCELED' &&
                      moment(item?.date).format('DD/MM/YYYY') ===
                        moment(new Date()).format('DD/MM/YYYY')
                  ).length
                } Preparos`}</span>
              </h4> */}

              {renderManuallyViewOrder}
            </div>

            <div>
              <div className="mobilefilter alignCenter" style={{ gap: '20px' }}>
                <div className="dFlex flexColumn alignCenter">
                  <ImgIcon
                    widthImg='44px'
                    src={
                      !modalNewProduction ? ProductionFilled : ProductionOutline
                    }
                    alt="Schedule"
                    onClick={() => {
                      setModalNewProduction(!modalNewProduction)
                    }}
                  />

                  <span className="mt5" style={{ marginBottom: "-5px" }}>
                    Nova
                  </span>
                </div>

                <div className="dFlex flexColumn alignCenter">
                  {scheduleButton('44px')}

                  <span className="mt5" style={{ marginBottom: "-5px" }}>
                    Agenda
                  </span>
                </div>

                <div className="dFlex flexColumn alignCenter margin0">
                  <PageTitleAndFilter
                    className="mt10"
                    activeFilterFilds={filterFields}
                    handleFilter={handleFilter}
                    handleMultipleFilters={handleOrdernationFilter}
                    getFilterhandle={getFilterhandle}
                  />

                  <span>
                    Filtros
                  </span>
                </div>
              </div>
            </div>
          </div>

          {renderMidCourt(true)}
        </div>

        <div className="dFlex alignCenter pSticky" style={{ top: '-6px', background: '#fafafa', zIndex: 9 }}>
          <BoxPlate>
            {plate.map((item) => (
              <PlateFilter
                activeBorder={item?.active}
                background={item?.background}
                onClick={() => {
                  filterByStatus(item?.id, !item?.active);
                  setPlate(
                    plate.map((plateItem) => {
                      if (plateItem.id === item.id) {
                        return {
                          ...plateItem,
                          active: !plateItem?.active
                        };
                      }

                      return { ...plateItem }
                    })
                  );
                }}
              >
                {item?.name}: {item?.value}
              </PlateFilter>
            ))}
          </BoxPlate>

          <div className="dFlex alignCenter" style={{ width: '40%' }}>
            {renderMidCourt()}

            <label
              className="alignEnd bold fontSizeDefault mr5 mb0 ml10"
              style={{
                marginTop: 2,
                lineHeight: 'normal',
                color: '#333541',
                width: 35,
              }}
            >
              Ficha:
            </label>

            <Autocomplete
              key="productFilter"
              tabIndex={-1}
              options={optionsCards || []}
              style={{ width: '40%' }}
              onKeyDown={(event) => {
                if (event.keyCode === 8 || event.keyCode === 13) {
                  setCardFilterValue(event.target.value);
                }
              }}
              onFocus={() => {
                if (optionsCards.length) return;

                getOptions(
                  `${environments.catalog}/cards`,
                  'productOptions',
                  optionsCards,
                  setOptionsCards,
                  getId,
                  { autoComplete: true },
                  null,
                  2000
                );
              }}
              getOptionLabel={(option) => option?.description || option}
              className="autocompleteSmall"
              defaultValue={
                typeof cardFilterValue === 'string'
                  ? cardFilterValue
                  : cardFilterValue?.description || ''
              }
              inputValue={
                typeof cardFilterValue === 'string'
                  ? cardFilterValue
                  : cardFilterValue?.description || ''
              }
              onInputChange={(_, value) => {
                setCardFilterValue(value);
              }}
              size="small"
              onChange={(_, value) => {
                setCardFilterValue(value || '');
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  tabIndex={-1}
                  id={params.id}
                  type="text"
                  name="Products"
                  variant="outlined"
                  placeholder="Selecione..."
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              )}
              renderOption={(params) => (
                <Typography
                  style={{ fontSize: '12px', fontFamily: 'Lato' }}
                >
                  {params.description}
                </Typography>
              )}
            />
          </div>
        </div>

        <ProductionCardFilter className="pSticky" style={{ justifyContent: 'flex-start', top: "32px", zIndex: 999 }}>
          <label
            className="fontSizeDefault bold dFlex alignEnd mr8"
            style={{
              lineHeight: 'normal'
            }}
          >
            Adicionar Produção
          </label>

          <InputContainer
            style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.42)' }}
          >
            <Autocomplete
              key="cardOption"
              id="page-title-filter"
              onChange={(_, value) => {
                if (value) {
                  // !value?.controlProduction &&
                  //   handleControlProduction(newProduction || value);
                  value ? setNewProduction(value) : setNewProduction(null);
                } else {
                  setNewProduction('');
                }
              }}
              options={optionsCards || []}
              getOptionLabel={(option) => option.description}
              style={{ width: 210 }}
              value={newProduction}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Digite aqui..."
                  fullWidth
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              )}
              renderOption={(option) => (
                <StyledTypography
                  noWrap
                  controlProduction={option.controlProduction}
                  style={{ fontSize: '12px' }}
                >
                  {option.description}
                </StyledTypography>
              )}
            />
          </InputContainer>

          <label
            className="fontSizeDefault bold dFlex alignEnd"
            style={{
              lineHeight: 'normal',
              margin: '3px 8px 0 30px'
            }}
          >
            Data da produção
          </label>

          <InputContainer
            className="mr8"
            style={{
              borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
              width: '6%'
            }}
          >
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptLocale}>
              <KeyboardDatePicker
                size="small"
                id="time-picker"
                ampm={false}
                format="dd/MM"
                value={cardProductionDate}
                inputVariant="outlined"
                className="dateSmall noBorder"
                autoOk
                InputProps={{
                  style: { fontSize: '12px' }
                }}
                onChange={(date) => {
                  setCardProductionDate(date);
                }}
                KeyboardButtonProps={{
                  'aria-label': 'change time'
                }}
              />
            </MuiPickersUtilsProvider>
          </InputContainer>

          <Tooltip title="Adicionar produção" aria-label="filters">
            <IconButton
              color="inherit"
              style={{ outline: 0 }}
              aria-label="add"
              edge="start"
              disabled={loading}
              onClick={() => {
                if (newProduction) {
                  if (newProduction.type === 'TRANSFORMATION') {
                    InsertData(newProduction);
                  } else {
                    InsertData(newProduction);
                  }
                }
              }}
            >
              <img style={{ width: '25px' }} src={AddIcon} alt="AddIcon" />
            </IconButton>
          </Tooltip>

          {renderTotalDiff(totalDiffInfos?.totalDiff || 0)}

          {renderLeftOverAndLack(
            totalDiffInfos?.totalGain || 0,
            totalDiffInfos?.totalLoss || 0
          )}
        </ProductionCardFilter>

        <table
          className="desktop productionTable"
          width="100%"
          cellPadding="0"
          cellSpacing="0"
          border="0"
        >
          <tr>
            <th colSpan="10" style={{ backgroundColor: '#FFF' }}>
              <hr />
            </th>
          </tr>

          <tbody>
            <tr className="headerTitle">
              <th width="1%" className="subtitle p0" style={{ top: "77px", zIndex: 1 }}>
                &nbsp;
              </th>

              <th width="27.5%" className="subtitle" style={{ top: "77px", zIndex: 1 }}>
                FICHA{' '}
                <OrderBy
                  customMargin="0 0 0 5px"
                  filterName="description"
                  handleFilter={handleOrdernationFilter}
                />
              </th>

              <th width="7%" className="subtitle right p8" style={{ borderLeft: 'solid 1px #D0D3E2', top: "77px", zIndex: 1 }}>
                ESTOQUE{' '}
                <OrderBy
                  customMargin="0 0 0 5px"
                  filterName="stock"
                  handleFilter={handleOrdernationFilter}
                />
              </th>

              <th width="6%" className="subtitle right p8" style={{ borderLeft: 'solid 1px #D0D3E2', top: "77px", zIndex: 1 }}>
                DURAÇÃO{' '}
                <OrderBy
                  customMargin="0 0 0 5px"
                  filterName="duration"
                  handleFilter={handleOrdernationFilter}
                />
              </th>

              <th
                width="13%"
                className="subtitle center pr8"
                style={{ borderLeft: 'solid 1px #454A67', top: "77px", zIndex: 1 }}
              >
                PRODUZIR{' '}
                <OrderBy
                  customMargin="0 0 0 5px"
                  filterName="quantity"
                  handleFilter={handleOrdernationFilter}
                />
              </th>

              <th width="9%" className="subtitle right pr8" style={{ borderLeft: 'solid 1px #D0D3E2', top: "77px", zIndex: 1 }}>
                PRODUZIDO
              </th>

              <th width="9%" className="subtitle right pr8" style={{ top: "77px", zIndex: 1 }}>
                <Tooltip
                  title={
                    diffViewMonetary
                      ? 'Diferença em quantidade'
                      : 'Diferença em real'
                  }
                  aria-label="filters"
                >
                  <img
                    onClick={() => {
                      setDiffViewMonetary(!diffViewMonetary);
                    }}
                    className="switchIcon cursorPointer mr10"
                    src={SwitchTableIconGray}
                    alt="SwitchTableIconGray"
                    style={{ width: 10 }}
                  />
                </Tooltip>

                DIFERENÇA{' '}
                <OrderBy
                  customMargin="0 0 0 5px"
                  filterName="lossManufactured"
                  handleFilter={orderByDiff}
                />
              </th>

              <th
                width="22%"
                className="subtitle p8"
                style={{ borderLeft: 'solid 1px #454A67', top: "77px", zIndex: 1 }}
              >
                <div className="dFlex justifyBetween">
                  <div>
                    STATUS{' '}
                    <OrderBy
                      customMargin="0 0 0 5px"
                      filterName="status"
                      handleFilter={handleOrdernationFilter}
                    />
                  </div>

                  {renderManuallyViewOrder}
                </div>
              </th>

              <th width="4.5%" style={{ zIndex: 0, top: "77px", zIndex: 1 }}>
                &nbsp;
              </th>

              <th width="1%" className="subtitle p0" style={{ top: "77px", zIndex: 1 }}>
                &nbsp;
              </th>
            </tr>

            {loading && (
              <tr>
                <th className="mt10 textAlignCenter" colSpan="10">
                  <LinearProgress
                    style={{ height: '7px' }}
                    id="loading"
                    variant="query"
                  />
                </th>
              </tr>
            )}

            {manufactures.length === 0 ? (
              <tr>
                <th className="mt10 textAlignCenter" colSpan="10">
                  {' '}
                  Nenhuma produção encontrada{' '}
                </th>
              </tr>
            ) : null}

            <DesktopRows
              setCurrentCard={setCurrentCard}
              setProductionInputControlAnchor={setProductionInputControlAnchor}
              updateQuantityInitialValue={updateQuantityInitialValue}
              cards={state.cards}
              setManufacture={setManufacture}
              setCurrentTransformationCardItems={
                setCurrentTransformationCardItems
              }
              stores={stores}
              companyActive={companyActive}
              companyGroup={companyGroup}
              setUpdateValue={setUpdateValue}
              updateValue={updateValue}
              handleCardQuantity={handleCardQuantity}
              handleItemDiscard={handleItemDiscard}
              optionsDiscard={optionsDiscard}
              getDuration={getDuration}
              handleClickOpenModal={handleClickOpenModal}
              setLoading={setLoading}
              manufactures={manufactures}
              loading={loading}
              handleUpdate={handleUpdate}
              handlePopoverClick={handlePopoverClick}
              setCardDate={setCardDate}
              setPopoverData={setPopoverData}
              handleOpenItems={handleOpenItems}
              diffViewMonetary={diffViewMonetary}
              handleDialogClickOpen={handleDialogClickOpen}
              setDeleteData={setDeleteData}
              modalStates={modalStates}
              setModalStates={setModalStates}
            />
          </tbody>
        </table>

        <>
          <MobileProductionCards
            handleItemDiscard={handleItemDiscard}
            setCheckSumeItems={setCheckSumeItems}
            forceEnable={forceEnable}
            setForceEnable={setForceEnable}
            updateQuantityInitialValue={updateQuantityInitialValue}
            setModal={setModalStates}
            mainModalStates={modalStates}
            stores={stores}
            companyActive={companyActive}
            companyGroup={companyGroup}
            optionsCards={optionsCards}
            originId={paramsID}
            currentTransformationCardItems={currentTransformationCardItems}
            setCurrentTransformationCardItems={
              setCurrentTransformationCardItems
            }
            setManufacture={setManufacture}
            setCardDate={setCardDate}
            loading={loading}
            manufactures={manufactures}
            setManufactures={setManufactures}
            optionsDiscard={optionsDiscard}
            updatedManufacture={updatedManufacture}
            state={state}
            modalSettings={modalSettings}
            setModalSettings={setModalSettings}
            actions={{
              deleteButton: {
                handleDialogClickOpen,
                setDeleteData
              },
              statusButton: {
                setPopoverData,
                setManufacture,
                handlePopoverClick,
                isMobile: true
              },
              handleUpdate,
              handleCardQuantity
            }}
          />

          {modalNewProduction ? 
            <Dialog
              open={modalNewProduction}
              fullWidth
              maxWidth="sm"
              onClose={() => {
                setModalNewProduction(false)
                setNewProduction(null)
                setManufacture(null)
              }}
              PaperComponent={PaperComponent}
              className="addNewProductionDialog"
            >
              <div style={{ padding: '5px 10px 10px', gap: '5px', display: 'grid' }}>
                <div className='dFlex alignCenter' style={{gap: '10px' }}>
                  <div
                    className="bold dFlex alignEnd"
                    style={{
                      lineHeight: 'normal',
                      fontWeight: '700'
                    }}
                  >
                    DATA DA PRODUÇÃO
                  </div>

                  <InputContainer className='removePadding' style={{ width: '14%'}}>
                    <MuiPickersUtilsProvider
                      utils={DateFnsUtils}
                      locale={ptLocale}
                    >
                      <KeyboardDatePicker
                        size="small"
                        id="cardProductionDateMobile"
                        ampm={false}
                        format="dd/MM"
                        value={cardProductionDate}
                        inputVariant="outlined"
                        className="dateSmall"
                        autoOk
                        InputProps={{
                          style: { fontSize: '12px'}
                        }}
                        onFocus={() => {
                          setTimeout(() => {
                            document
                              .querySelector(`#cardProductionDateMobile`)
                              .select();
                          }, 100);
                        }}
                        onChange={(date) => {
                          setCardProductionDate(date);
                        }}
                        KeyboardButtonProps={{
                          'aria-label': 'change time'
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </InputContainer>
                </div>

                <div className='dFlex alignCenter' style={{gap: '10px'}}>
                  <div
                    className="bold dFlex alignEnd"
                    style={{
                      lineHeight: 'normal',
                      fontWeight: '700'
                    }}
                  >
                    PRODUZIR
                  </div>

                  <InputContainer
                    className="pRelative"
                    style={{
                      borderBottom: '1px solid rgba(0, 0, 0, 0.42)' 
                    }}
                  >
                    <Autocomplete
                      key="cardOption"
                      id="page-title-filter"
                      onChange={(_, value) => {
                        if (value) {
                          getCardPreviewInfos(value)
                          setNewProduction(value)

                          !value?.controlProduction &&
                            setControlProductionModal(true);
                        } else {
                          setNewProduction(null)
                          setManufacture(null)
                        }
                      }}
                      disablePortal
                      options={optionsCards || []}
                      getOptionLabel={(option) => option.description}
                      style={{ width: '100%'}}
                      value={newProduction}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Pesquisar Fichas..."
                          fullWidth
                          className='autocompleteList'
                          InputLabelProps={{
                            shrink: true
                          }}
                        />
                      )}
                      renderOption={(option) => (
                        <StyledTypography
                          noWrap
                          controlProduction={option.controlProduction}
                          style={{ fontSize: '12px'}}
                        >
                          {option.description}
                        </StyledTypography>
                      )}
                    />
                  </InputContainer>
                </div>
              </div>

              {newProduction?.description && manufacture?.description ? 
                <section className="addNewProductionContainer">
                  <div className="previewCardBox">
                    <CardModal cardType={manufacture?.type}>
                      <div className="leftFakeBorder" />
                      <div className="rightFakeBorder" />

                      <MobileCardDetail
                        handleItemDiscard={handleItemDiscard}
                        setCheckSumeItems={setCheckSumeItems}
                        setCurrentTransformationCardItems={setCurrentTransformationCardItems}
                        forceEnable={forceEnable}
                        updateQuantityInitialValue={updateQuantityInitialValue}
                        setModal={setModalStates}
                        noActionMode
                        // mainModalStates={mainModalStates}
                        setCardDate={setCardDate}
                        manufactures={manufactures}
                        setManufactures={setManufactures}
                        isOpen={manufacture?.description}
                        transformationInfos={transformationInfos}
                        index={manufactures?.findIndex(el => el?.id === manufacture?.cardId)}
                        description={manufacture?.description}
                        state={state}
                        manufactureYield={manufacture?.manufactureYield}
                        newDuration={manufacture?.newDuration}
                        limiteProd={manufacture?.limiteProd}
                        manufactureQuantity={manufacture?.quantity}
                        manufactureTotalYield={
                          manufacture.initialYield
                          ? manufacture.yield
                          : manufacture?.type === 'RECIPE'
                            ? manufacture.yield * (manufacture.quantity || 1)
                            : (manufacture.quantity || 1) -
                              (manufacture.quantity || 1) * (manufacture.loss / 100)
                        }
                        manufactureStatus={manufacture?.manufactureStatus}
                        dateStatus={manufacture?.dateStatus}
                        items={manufacture?.items}
                        actions={{
                          deleteButton: {
                            handleDialogClickOpen,
                            setDeleteData
                          },
                          statusButton: {
                            setPopoverData,
                            setManufacture,
                            handlePopoverClick,
                            isMobile: true
                          },
                          handleUpdate,
                          handleCardQuantity
                        }}
                        manufacture={manufacture}
                        optionsDiscard={optionsDiscard}
                        manufactureStock={manufacture?.manufactureStock}
                        manufactureDuration={manufacture?.manufactureDuration}
                        lossManufactured={manufacture?.lossManufactured}
                        manufactured={manufacture?.manufactured}
                        totalItemsQuantity={manufacture?.totalItemsQuantity}
                        cardYield={manufacture?.cardYield}
                        type={manufacture?.type}
                        loss={manufacture.loss}
                        income={manufacture?.performance}
                      />
                    </CardModal>
                  </div>

                  <div className="addNewProductionActions">
                    <Button
                      autoFocus
                      className="defaultButton submitButton mr10"
                      design="contained"
                      label="ADICIONAR E INICIAR"
                      onClick={() => {
                        InsertData(manufacture, "START")
                      }}
                      color="primary"
                    />

                    <Button
                      autoFocus
                      className="defaultButton submitButton"
                      design="contained"
                      label="ADICIONAR"
                      onClick={() => {
                        InsertData(manufacture, "WAITING")
                      }}
                      color="primary"
                    />
                  </div>
                </section>
              : null}
            </Dialog>
          : null}
        </>
      </Container>

      <Suspense fallback={<span />}>
        <DurationDialog
          openModal={modalStates}
          handleClose={handleCloseModal}
          durationData={durationData}
          loading={durationDialogLoading}
          selectedProduct={selectedProduct}
          page="Production"
        />
      </Suspense>

      <Popover
        id={id}
        open={popoverOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        className="productionPopover"
        PaperProps={{
          style: {
            padding: '10px',
            borderRadius: '4px',
            maxWidth: isMobileScreen ? '600px' : '400px'
          }
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        {popoverData === 'finishTransformation' && (
          <Grid
            container
            xs={12}
            justify="center"
            spacing={1}
            style={{ margin: 0, borderRadius: '8px', padding: '10px' }}
          >
            <Grid item xs={12}>
              <Typography
                className="dFlex flexColumn uppercase textAlignCenter"
                style={{
                  fontSize: isMobileScreen ? 20 : 16
                }}
              >
                <span
                  className="uppercase textAlignCenter"
                  style={{
                    fontWeight: 'normal'
                  }}
                >
                  Deseja finalizar a produção da ficha
                </span>

                <span className="uppercase bold textAlignCenter">
                  {manufacture?.description || null}?
                </span>
              </Typography>
            </Grid>

            <p
              className="textAlignCenter mb0 w100"
              style={{
                fontSize: isMobileScreen ? 20 : 16,
                marginTop: 30
              }}
            >
              INDIQUE A DATA E AS QTDES PRODUZIDAS
            </p>

            <Grid
              item
              xs={12}
              justify="center"
              spacing={1}
              className="pRelative dFlex flexColumn alignCenter mb10"
            >
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptLocale}>
                <Grid
                  className="mb20 datePopupBox"
                  item
                  xs={4}
                  sm={3}
                  justify="center"
                >
                  <KeyboardDatePicker
                    size="small"
                    id="finishProductionDate"
                    ampm={false}
                    format="dd/MM"
                    value={moment(cardDate || new Date()).add(3, 'hours')}
                    inputVariant="outlined"
                    className={`dateSmall ${
                      isMobileScreen
                        ? 'childInputFontSize20'
                        : 'childInputFontSize16'
                    }`}
                    autoOk
                    inputProps={{
                      style: {
                        textAlign: 'right'
                      }
                    }}
                    InputProps={{
                      style: {
                        fontSize: isMobileScreen ? '20px' : '16px'
                      },
                      inputProps: {
                        inputMode: 'numeric'
                      }
                    }}
                    onFocus={(e) => {
                      e.currentTarget.select();
                    }}
                    onChange={(date) => {
                      setCardDate(date);
                    }}
                    KeyboardButtonProps={{
                      'aria-label': 'change time'
                    }}
                    onKeyDown={(event) => {
                      if (
                        (event.keyCode === 9 || event.keyCode === 13) &&
                        manufacture.items.length
                      ) {
                        setTimeout(() => {
                          document
                            .querySelector(`#popupInternalItem-0`)
                            .select();
                        }, 100);
                      }
                    }}
                  />
                </Grid>
              </MuiPickersUtilsProvider>

              <div
                className={`dFlex flexColumn pt0 pb0 ${
                  !isPhone && 'pl10 pr10'
                } w100 mb20 mt10 popupProductionProductsTable`}
              >
                {manufacture.items.map((item, index) => (
                  <div className="dFlex flexColumn mb5">
                    <div className="dFlex alignCenter">
                      <div
                        style={{ width: '70%' }}
                        className={`${
                          isMobileScreen
                            ? 'childTdFontSize20'
                            : 'childTdFontSize16'
                        } bold`}
                      >
                        <div className="dFlex alignCenter">
                          <div style={{ width: "85%" }}>
                            {ValidationLength(item.item, isMobileScreen ? 45 : 20)}

                            {item.suggestionYield
                              ? `(${
                                  item.suggestionYield.toLocaleString('pt-br', {
                                    minimumFractionDigits: 3
                                  }) + item?.un?.abbreviation
                                })`
                              : ''
                            }
                          </div>

                          <div className="ml10 mr10" style={{ width: "30px" }}>
                            <ImageWrapper
                              width="100%"  
                              height="28px"
                              url={item?.image}
                              borderType={item?.type}
                            />
                          </div>
                        </div>
                      </div>

                      <div style={{ width: '30%' }}>
                        {manufacture.status === 'FINISH' ? (
                          item.quantity ? (
                            parseFloat(item.quantity).toLocaleString('pt-br', {
                              minimumFractionDigits: 3
                            })
                          ) : (
                            '0,000'
                          )
                        ) : isMobile ? (
                          <InputNumber
                            rootPage="production"
                            referenceItem={manufacture}
                            setUpdateValue={setUpdateValue}
                            setModalStates={setModalStates}
                            modalStates={modalStates}
                            updateValue={updateValue}
                            id={`popupInternalItem-${index}`}
                            initialValue="null"
                            unity={manufacture?.un?.abbreviation}
                            handleValue={(value) => {
                              setCurrentTransformationCardItems({
                                ...currentTransformationCardItems,
                                items:
                                  currentTransformationCardItems?.items?.map(
                                    (el, elIndex) => {
                                      if (index === elIndex) {
                                        return {
                                          ...el,
                                          quantity: parseFloat(value)
                                        };
                                      }
                                      return {
                                        ...el
                                      };
                                    }
                                  )
                              });
                              {
                                /* 
                               handleCardQuantity(
                                 manufactures.indexOf(
                                   manufactures.find(
                                     (el) => el._id === manufacture._id
                                   )
                                 ),
                                 manufacture.quantity,
                                 index,
                                 parseFloat(value),
                                 undefined
                               );
                               setManufacture({
                                 ...manufacture,
                                 items: manufacture.items.map((el, elIndex) => {
                                   if (index === elIndex) {
                                     return {
                                       ...el,
                                       quantity: parseFloat(value)
                                     };
                                   }
                                   return {
                                     ...el
                                   };
                                 })
                               }); */
                              }
                            }}
                            onKeyDown={(event) => {
                              if (event.keyCode === 9 || event.keyCode === 13) {
                                document.querySelector(
                                  `#popupInternalItem-${index + 1}`
                                )
                                  ? setTimeout(() => {
                                      document
                                        .querySelector(
                                          `#popupInternalItem-${index + 1}`
                                        )
                                        .select();
                                    }, 100)
                                  : setTimeout(() => {
                                      document
                                        .querySelector(
                                          `#popupInternalItem-${index}`
                                        )
                                        .blur();
                                      document
                                        .querySelector(`#finishPopupButton`)
                                        .focus();
                                    }, 500);
                              }
                            }}
                          />
                        ) : (
                          <CurrencyTextField
                            id={`popupInternalItem-${index}`}
                            className={`textFieldSmall right ${
                              isMobileScreen
                                ? 'childInputFontSize20'
                                : 'childInputFontSize16'
                            }
                            `}
                            margin="none"
                            variant="standard"
                            size="small"
                            currencySymbol=""
                            minimumValue="0"
                            maximumValue="9999999999999999999999"
                            outputFormat="string"
                            placeholder="Digite aqui..."
                            decimalCharacter=","
                            digitGroupSeparator="."
                            decimalPlaces={3}
                            decimalPlacesShownOnBlur={3}
                            decimalPlacesShownOnFocus={3}
                            type="text"
                            name="quantity"
                            fullWidth
                            disabled={
                              manufacture.status !== 'START' &&
                              manufacture.status !== 'WAITING'
                            }
                            defaultValue={null}
                            inputProps={{
                              style: {
                                fontSize: isMobileScreen ? '20px' : '16px'
                              }
                            }}
                            InputProps={{
                              maxLength: 8,
                              classes: { input: classes.input },
                              style: {
                                fontSize: isMobileScreen ? '20px' : '16px',
                                textAlign: 'right'
                              }
                            }}
                            onKeyDown={(event) => {
                              if (event.keyCode === 9 || event.keyCode === 13) {
                                document.querySelector(
                                  `#popupInternalItem-${index + 1}`
                                )
                                  ? setTimeout(() => {
                                      document
                                        .querySelector(
                                          `#popupInternalItem-${index + 1}`
                                        )
                                        .select();
                                    }, 100)
                                  : setTimeout(() => {
                                      document
                                        .querySelector(`#finishPopupButton`)
                                        .focus();
                                    }, 100);
                              }
                            }}
                            onChange={(e) => {
                              setUpdateValue(
                                parseFloat(e.target.value)?.toFixed(3) !==
                                  parseFloat(item.quantity?.toFixed(3))
                              );
                            }}
                            onBlur={(e, value) => {
                              if (
                                !updateValue &&
                                parseFloat(item.quantity?.toFixed(3)) ===
                                  parseFloat(value)?.toFixed(3)
                              )
                                return;

                              setCurrentTransformationCardItems({
                                ...currentTransformationCardItems,
                                items:
                                  currentTransformationCardItems?.items?.map(
                                    (el, elIndex) => {
                                      if (index === elIndex) {
                                        return {
                                          ...el,
                                          quantity: parseFloat(value)
                                        };
                                      }
                                      return {
                                        ...el
                                      };
                                    }
                                  )
                              });

                              {
                                /* 
                              handleCardQuantity(
                                manufactures.indexOf(
                                  manufactures.find(
                                    (el) => el._id === manufacture._id
                                  )
                                ),
                                manufacture.quantity,
                                index,
                                parseFloat(value),
                                undefined
                              );
                            */
                              }
                              setUpdateValue(false);
                            }}
                          />
                        )}
                      </div>
                    </div>

                    {/* <hr style={{ margin: "6px 0" }} /> */}
                  </div>
                ))}

                {isOutOfValidPercentage().invalid && (
                  <p
                    className="requiredWarning"
                    style={{ fontSize: isMobileScreen ? 20 : 16 }}
                  >
                    QTDE PRODUZIDA excede em{' '}
                    {isOutOfValidPercentage().diffPercentage?.toLocaleString(
                      'pt-br',
                      {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 1
                      }
                    )}
                    % a QTDE do RENDIMENTO ESPERADO, por favor, revise a QTDE
                    PRODUZIDA ou a QTDE INICIAL do produto.
                  </p>
                )}

                {currentTransformationCardItems?.items?.every(
                  (item) => !item.quantity
                ) && (
                  <p
                    className="requiredWarning"
                    style={{ fontSize: isMobileScreen ? 20 : 16 }}
                  >
                    Por favor preencha ao menos 1 dos itens
                  </p>
                )}
              </div>
            </Grid>

            <div className="mt10 dFlex">
              <Grid item xs={6} className="dFlex justifyEnd mr20">
                <Button
                  design="outlined"
                  className="productionPopupButton bold"
                  disabled={disableSubmitButton}
                  style={{
                    fontSize: '20px',
                    width: isMobileScreen ? '150px' : '40px',
                    borderRadius: '12px',
                    borderColor: '#5062F0',
                    color: '#5062F0',
                    outline: 0,
                    textTransform: 'initial',
                    padding: `${isMobileScreen ? '30px' : '20px'} 60px`
                  }}
                  onClick={(e) => {
                    setDisableSubmitButton(true);
                    setCurrentTransformationCardItems();

                    setTimeout(() => {
                      handleUpdate(manufacture, 'PAUSE', e);
                    }, 100);
                  }}
                  label="PAUSAR"
                  color="primary"
                />
              </Grid>

              <Grid item xs={6} className="dFlex justifyStart">
                <Button
                  id="finishPopupButton"
                  className="productionPopupButton bold"
                  disabled={
                    disableSubmitButton ||
                    isOutOfValidPercentage().invalid ||
                    currentTransformationCardItems?.items?.every(
                      (item) => !item.quantity
                    )
                  }
                  autoFocus
                  style={{
                    width: isMobileScreen ? '150px' : '40px',
                    borderRadius: '12px',
                    outline: 0,
                    padding: `${isMobileScreen ? '30px' : '20px'} 60px`,
                    fontSize: isMobileScreen ? 20 : 16
                  }}
                  design="contained"
                  onKeyDown={(e) => {
                    if (e.keyCode === 9 || e.keyCode === 13) {
                      const isRetroactive =
                        cardDate &&
                        moment(cardDate).add(3, 'hours').format(DATE_FORMAT) !==
                          moment().format(DATE_FORMAT);

                      if (isRetroactive) {
                        setModalStates({
                          ...modalStates,
                          modalConfirmationOfFinish: true
                        });

                        return;
                      }

                      setDisableSubmitButton(true);

                      setTimeout(() => {
                        handleUpdate(manufacture, 'FINISH', e);
                      }, 200);
                    }
                  }}
                  onClick={(e) => {
                    const isRetroactive =
                      cardDate &&
                      moment(cardDate).add(3, 'hours').format(DATE_FORMAT) !==
                        moment().format(DATE_FORMAT);

                    if (isRetroactive) {
                      setModalStates({
                        ...modalStates,
                        modalConfirmationOfFinish: true
                      });

                      return;
                    }

                    setDisableSubmitButton(true);

                    setTimeout(() => {
                      handleUpdate(manufacture, 'FINISH', e);
                    }, 200);
                  }}
                  label="FINALIZAR"
                  color="primary"
                />
              </Grid>
            </div>
          </Grid>
        )}

        {popoverData === 'start' && (
          <Grid
            container
            xs={12}
            spacing={1}
            style={{ padding: '10px', margin: 0 }}
          >
            <Grid item xs={12}>
              <Typography
                className="dFlex flexColumn uppercase textAlignCenter"
                style={{
                  fontSize: isMobileScreen ? 20 : 16
                }}
              >
                <span
                  className="uppercase textAlignCenter"
                  style={{
                    fontWeight: 'normal'
                  }}
                >
                  Deseja finalizar a produção da ficha
                </span>

                <span className="uppercase bold textAlignCenter">
                  {manufacture?.description || null}?
                </span>

                <div className="mt5" style={{ width: '40px', margin: "5px auto 7px auto" }}>
                  <ImageWrapper
                    width="100%"  
                    height="40px"
                    url={manufacture?.image}
                    borderType={manufacture?.type}
                  />
                </div>
              </Typography>
            </Grid>

            <Grid
              item
              xs={12}
              justify="center"
              spacing={1}
              className="pRelative dFlex flexWrap alignCenter mb10"
              style={{ marginTop: 30 }}
            >
              <p
                className="textAlignCenter mb5 w100"
                style={{
                  fontSize: isMobileScreen ? 20 : 16
                }}
              >
                INDIQUE A DATA E A QTDE PRODUZIDA
              </p>

              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptLocale}>
                <Grid className="mr20" item xs={4} sm={4} justify="center">
                  <KeyboardDatePicker
                    size="small"
                    id="finishProductionDate"
                    ampm={false}
                    format="dd/MM"
                    value={moment(cardDate || new Date()).add(3, 'hours')}
                    inputVariant="outlined"
                    className={`dateSmall ${
                      isMobileScreen
                        ? 'childInputFontSize20'
                        : 'childInputFontSize16'
                    }`}
                    onFocus={(e) => {
                      e.currentTarget.select();
                    }}
                    autoOk
                    InputProps={{
                      style: {
                        fontSize: isMobileScreen ? '20px' : '16px',
                        textAlign: 'right'
                      },
                      inputProps: {
                        inputMode: 'numeric'
                      }
                    }}
                    onChange={(date) => {
                      setCardDate(date);
                    }}
                    KeyboardButtonProps={{
                      'aria-label': 'change time'
                    }}
                  />
                </Grid>
              </MuiPickersUtilsProvider>

              <Grid
                item
                xs={5}
                sm={4}
                className={`quantityPopupBox ${isMobileScreen && 'mt5'}`}
              >
                <InputManufacture
                  objectToChange={manufacture}
                  updatedManufacture={updatedManufacture}
                  setUpdate={setUpdatedManufacture}
                  label="Quantidade produzida"
                  keyChange="manufactured"
                  isMobile={isMobile}
                  setModalStates={setModalStates}
                  modalStates={modalStates}
                  onKeyDown={(event) => {
                    if (event.keyCode === 9 || event.keyCode === 13) {
                      document.querySelector(`.quantityPopupBox input`).blur();

                      if (
                        !event?.target?.value ||
                        event?.target?.value === 0 ||
                        event?.target?.value === '0.000'
                      ) {
                        setShowErrorManufacturedMessage(true);
                        setManufactures(manufactures);
                        return;
                      }

                      setTimeout(() => {
                        isMobileScreen
                          ? handleUpdate(
                              manufacture,
                              'FINISH',
                              event,
                              false,
                              parseFloat(
                                event?.target?.value?.replace(',', '.')
                              )
                            )
                          : document
                              .querySelector(`#finishPopupRecipeButton`)
                              .focus();
                      }, 500);
                    }
                  }}
                />
              </Grid>
            </Grid>

            <div className="mt20 dFlex w100">
              <Grid item xs={6} className="dFlex justifyEnd mr20">
                <Button
                  design="outlined"
                  className="productionPopupButton bold"
                  disabled={disableSubmitButton}
                  style={{
                    fontSize: '20px',
                    width: isMobileScreen ? '150px' : '40px',
                    borderRadius: '12px',
                    borderColor: '#5062F0',
                    color: '#5062F0',
                    outline: 0,
                    textTransform: 'initial',
                    padding: `${isMobileScreen ? '30px' : '20px'} 60px`
                  }}
                  onClick={(e) => {
                    setDisableSubmitButton(true);

                    setTimeout(() => {
                      handleUpdate(manufacture, 'PAUSE', e);
                    }, 100);
                  }}
                  label="PAUSAR"
                  color="primary"
                />
              </Grid>

              <Grid item xs={6} className="dFlex justifyStart">
                <Tooltip
                  title="Insira o valor produzido para finalizar."
                  open={showErrorManufacturedMessage}
                >
                  <Button
                    id="finishPopupRecipeButton"
                    autoFocus
                    className="productionPopupButton bold"
                    disabled={disableSubmitButton || !updatedManufacture}
                    style={{
                      fontSize: '20px',
                      width: isMobileScreen ? '150px' : '40px',
                      borderRadius: '8px',
                      borderColor: '#5062F0',
                      color: '#fff',
                      outline: 0,
                      textTransform: 'initial',
                      padding: `${isMobileScreen ? '30px' : '20px'} 60px`
                    }}
                    design="contained"
                    onClick={(e) => {
                      // deixar essa função desativada.
                      // if(manufacture.lowType === "AUTOMATIC") {
                      //   setModalStates({
                      //     ...modalStates,
                      //     modalAutomaticDown: true
                      //   })
                      //   return;
                      // }
                      const isRetroactive =
                        cardDate &&
                        moment(cardDate).add(3, 'hours').format(DATE_FORMAT) !==
                          moment().format(DATE_FORMAT);

                      if (isRetroactive) {
                        setModalStates({
                          ...modalStates,
                          modalConfirmationOfFinish: true
                        });

                        return;
                      }

                      setDisableSubmitButton(true);

                      if (!updatedManufacture || updatedManufacture == 0) {
                        setShowErrorManufacturedMessage(true);
                        setManufactures(manufactures);
                        return;
                      }

                      setTimeout(() => {
                        handleUpdate(manufacture, 'FINISH', e);
                      }, 500);
                    }}
                    label="FINALIZAR"
                    color="primary"
                  />
                </Tooltip>
              </Grid>
            </div>
          </Grid>
        )}

        {popoverData === 'pause' && (
          <Grid
            container
            xs={12}
            justify="center"
            spacing={1}
            style={{ margin: 0, borderRadius: '8px' }}
          >
            <Grid item xs={12} className="mb10">
              <Typography
                className="dFlex flexColumn uppercase textAlignCenter"
                style={{
                  fontSize: isMobileScreen ? 20 : 16
                }}
              >
                <span className="uppercase" style={{ fontWeight: 'normal' }}>
                  Confirma o início da produção da ficha{' '}
                </span>
                <span className="uppercase bold">
                  {manufacture ? manufacture.description : null}?
                </span>
              </Typography>
            </Grid>

            <Grid item xs={6} className="dFlex justifyEnd">
              <Button
                design="outlined"
                style={{
                  width: isMobileScreen ? '150px' : '40px',
                  borderRadius: '12px',
                  borderColor: '#5062F0',
                  outline: 0,
                  textTransform: 'initial',
                  color: '#5062F0',
                  padding: `${isMobileScreen ? '30px' : '20px'} 60px`
                }}
                onClick={handleClose}
                label="CANCELAR"
                color="primary"
              />
            </Grid>

            <Grid
              item
              xs={6}
              className="dFlex justifyStart"
              style={{
                width: '96px'
              }}
            >
              <Button
                autoFocus
                className="productionPopupButton bold"
                disabled={disableSubmitButton}
                style={{
                  width: '96px',
                  borderRadius: '12px',
                  outline: 0,
                  textTransform: 'initial',
                  padding: `${isMobileScreen ? '30px' : '20px'} 60px`
                }}
                design="contained"
                onClick={(e) => {
                  setDisableSubmitButton(true);

                  setTimeout(() => {
                    handleUpdate(manufacture, 'START');
                  }, 100);
                }}
                label="CONFIRMAR"
                color="primary"
              />
            </Grid>
          </Grid>
        )}
      </Popover>

      <Popover
        open={productionInputControlPopoverOpen}
        anchorEl={productionInputControlAnchor}
        onClose={() => {
          setProductionInputControlAnchor(null);
          setCurrentCard('')
        }}
        PaperProps={{
          style: { padding: '10px', borderRadius: '4px', maxWidth: '400px', marginLeft: 60 }
        }}
        className="copilotQuantityOrYieldPopover"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div className="dFlex alignStart justifyStart flexColumn">
          <div className="dFlex flexColumn popoverCardInfo">
            <span className="fontSizeDefault bold">
              {currentCard?.description}
            </span>

            <span className="fontSizeDefault">
              1 Receita rende {" "}
              {(currentCard?.yield / currentCard?.quantity)?.toLocaleString('pt-br', {
                minimumFractionDigits: 3,
                maximunFractionDigits: 3
              })} {" "}
              {currentCard?.un?.abbreviation}
            </span>
          </div>

          <div className="dFlex flexColumn popoverCardActions">
            <FormControl className="dFlex flexRow ml5">
              <FormControlLabel
                value={2}
                control={
                  <Radio
                    onChange={() => {
                      handleUpdateViewMethod(2)
                    }}
                    checked={currentCard?.viewMethod === 2}
                    size="small"
                  />
                }
                label={
                  <span className="fontSizeDefault bold">
                    Rendimento
                  </span>
                }
              />
              <FormControlLabel
                value={1}
                control={
                  <Radio
                    onChange={() => {
                      handleUpdateViewMethod(1)
                    }}
                    checked={currentCard?.viewMethod === 1}
                    size="small"
                  />
                }
                label={
                  <span className="fontSizeDefault bold">
                    Quantidade
                  </span>
                }
              />
            </FormControl>

            <div className="popoverCardValues">
              <span className="fontSizeDefault bold lightBlue">
                {currentCard?.viewMethod === 2 ?
                  `${currentCard?.yield?.toLocaleString('pt-br', {
                    minimumFractionDigits: 3,
                    maximunFractionDigits: 3
                  })}
                  ${currentCard?.un?.abbreviation} = 
                  ${currentCard?.quantity?.toLocaleString('pt-br', {
                    minimumFractionDigits: 3,
                    maximunFractionDigits: 3
                  })} receitas`
                :
                  `${currentCard?.quantity?.toLocaleString('pt-br', {
                    minimumFractionDigits: 3,
                    maximunFractionDigits: 3
                  })} Receitas = 
                  ${currentCard?.yield?.toLocaleString('pt-br', {
                    minimumFractionDigits: 3,
                    maximunFractionDigits: 3
                  })} 
                  ${currentCard?.un?.abbreviation}`
                }
              </span>
            </div>
          </div>
        </div>
      </Popover>

      {modalSettings.find((modal) => modal.name === 'pdfViewer').open && (
        <PdfViewer
          modalSettings={modalSettings}
          setModalSettings={setModalSettings}
          pdfFile={
            modalSettings.find((modal) => modal.name === 'pdfViewer').pdfFile
          }
        />
      )}

      {modalSettings.find(modal => modal.name === 'copilotConfig').open && (
        <CopilotDialog
          open={modalSettings.find((modal) => modal.name === 'copilotConfig').open}
          modalSettings={modalSettings}
          closeProgramming={closeProgramming}
          loadingCloseProgramming={loadingCloseProgramming}
          setModalSettings={setModalSettings}
          handleClose={() => {
            setModalSettings(
              modalSettings.map((modal) =>
                modal.name === 'copilotConfig'
                  ? { ...modal, open: false }
                  : modal
              )
            );
          }}
        />
      )}

      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={modalStates.modalInvalidQuantity}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        className="defaultModal bigModal invalidQuantityDialog w100"
        transitionDuration={0}
      >
        <DialogContent className="modalContent dFlex alignCenter justifyCenter flexColumn">
          <Typography
            className="mb10"
            style={{ fontSize: 16, color: '#010311' }}
          >
            VALOR INFORMADO INVÁLIDO!
          </Typography>

          <Typography
            className="mb20 dFlex flexColumn alignCenter justifyCenter"
            style={{ fontSize: 16, color: '#454A67' }}
          >
            <span className="textAlignCenter mb10">
              O VALOR INFORMADO RESULTA PROPORCIONALMENTE NO VALOR ZERADO DE UM
              DOS ITENS.
            </span>
            INFORME UM VALOR DIFERENTE
          </Typography>

          <Button
            autoFocus
            className="defaultButton submitButton"
            design="contained"
            label="OK"
            onClick={() => {
              setUpdateQuantityInitialValue(Math.random());
              handleCloseModal();
            }}
            color="primary"
          />
        </DialogContent>
      </Dialog>

      {modalSettings.find(
        (modal) => modal.name === 'modalDecimalsQuantityAlert'
      ).open && (
        <Dialog
          fullWidth={fullWidth}
          maxWidth={maxWidth}
          onClose={() => {
            handleCloseModal();
          }}
          open={
            modalSettings.find(
              (modal) => modal.name === 'modalDecimalsQuantityAlert'
            ).open
          }
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
          className="defaultModal bigModal invalidQuantityDialog w100"
          transitionDuration={0}
        >
          <DialogContent className="modalContent dFlex alignCenter justifyCenter flexColumn">
            <Typography
              className="mb10"
              style={{ fontSize: 16, color: '#010311' }}
            >
              Atenção!
            </Typography>

            <Typography
              className="mb20 dFlex flexColumn alignCenter justifyCenter"
              style={{ fontSize: 16, color: '#454A67' }}
            >
              <span className="textAlignCenter mb10">
                O valor informado resulta em uma quantidade baixa para os seguintes itens:
              </span>
            </Typography>

            <section className="dFlex flexColumn mb10">
              {
                modalSettings.find(
                  (modal) => modal.name === 'modalDecimalsQuantityAlert'
                ).items?.map(el => (
                  <div className="dFlex alignCenter">
                    <span className='mr5 bold'>
                      {el?.item}:
                    </span>

                    <span>
                      {el?.quantity?.toLocaleString('pt-br', {
                        minimumFractionDigits: 6
                      })}
                    </span>
                  </div>
                ))
              }
            </section>

            <Button
              autoFocus
              className="defaultButton submitButton"
              design="contained"
              label="OK"
              onClick={() => {
                setUpdateQuantityInitialValue(Math.random());
                handleCloseModal();
              }}
              color="primary"
            />
          </DialogContent>
        </Dialog>
      )}

      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={modalStates.modalConfirmationOfFinish}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        className="defaultModal bigModal invalidQuantityDialog w100"
        transitionDuration={0}
        onClose={() => handleCloseModal()}
      >
        <DialogContent className="modalContent dFlex alignCenter justifyCenter flexColumn bgWhite">
          <Typography
            className="mb10 bold"
            style={{ fontSize: 16, color: '#010311' }}
          >
            ATENÇÃO!
          </Typography>

          <Typography
            className="mb20 dFlex flexColumn alignCenter justifyCenter"
            style={{ fontSize: 16, color: '#454A67' }}
          >
            Produção com Data Retroativa. Confirma a finalização?
          </Typography>
        </DialogContent>

        <DialogActions className="dFlex justifyCenter">
          <Button
            className="mobile-btn-no"
            design="outlined"
            disabled={disableSubmitButton}
            onClick={() => handleCloseModal()}
            label="CANCELAR"
            color="primary"
          />

          <Button
            autoFocus
            className="mobile-btn-yes"
            disabled={disableSubmitButton}
            design="contained"
            label="FINALIZAR"
            onClick={(e) => {
              setDisableSubmitButton(true);

              setTimeout(() => {
                handleUpdate(manufacture, 'FINISH', e);
              }, 200);
            }}
            color="primary"
          />
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={modalStates.modalItemsQuantityGreaterThanCard}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        className="defaultModal bigModal invalidQuantityDialog w100"
        transitionDuration={0}
      >
        <DialogContent className="modalContent dFlex alignCenter justifyCenter flexColumn">
          <Typography
            className="mb10"
            style={{ fontSize: 16, color: '#010311' }}
          >
            Qtde produzida superior ao Rendimento esperado!
          </Typography>

          <Typography
            className="mb20 dFlex flexColumn alignCenter justifyCenter"
            style={{ fontSize: 16, color: '#454A67' }}
          >
            <span className="textAlignCenter mb10">
              A soma dos items é maior que a quantidade da ficha, deseja alterar
              a quantidade da ficha?
            </span>
          </Typography>

          <div className="dFlex">
            <Button
              autoFocus
              className="defaultButton backButton mr20"
              design="contained"
              label="NÃO"
              onClick={() => {
                setModalStates({
                  ...modalStates,
                  modalItemsQuantityGreaterThanCard: false
                });

                setForceEnable(false);
              }}
              color="primary"
            />

            <Button
              autoFocus
              className="defaultButton submitButton"
              design="contained"
              label="SIM"
              onClick={() => {
                setForceEnable(true);

                setModalStates({
                  ...modalStates,
                  modalItemsQuantityGreaterThanCard: false
                });
                handleClose();

                setTimeout(() => {
                  if (document.querySelector('#yieldCardField input')) {
                    document.querySelector('#yieldCardField input').select();
                  }
                }, 400);
              }}
              color="primary"
            />
          </div>
        </DialogContent>
      </Dialog>

      {/* Delete confirmation modal */}
      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="responsive-dialog-title"
        className="delete-modal-mobile"
      >
        <DialogTitle
          className="modalHeader navyBlue bold textAlignCenter cursorMove dNone"
          id="draggable-dialog-title"
        >
          <Typography
            className="uppercase"
            style={{ fontSize: isMobileScreen ? 20 : 16 }}
          >
            Deseja realmente cancelar essa produção?
          </Typography>

          <IconButton
            edge="start"
            color="inherit"
            onClick={() => {
              handleDialogClose();
            }}
            aria-label="close"
            style={{ outline: '0', background: 'none', align: 'right' }}
          >
            <img
              style={{ width: '16px' }}
              src={CloseIconNavy}
              alt="CloseIconNavy"
            />
          </IconButton>
        </DialogTitle>

        <DialogContent className="modalContent">
          <p
            className="uppercase textAlignCenter"
            style={{
              fontSize: isMobileScreen ? '20px' : '16px',
              color: '#333541'
            }}
          >
            Deseja realmente cancelar esta produção?
          </p>

          <p
            className="mb0 uppercase textAlignCenter"
            style={{
              fontSize: isMobileScreen ? '20px' : '16px',
              color: '#A0A1A7'
            }}
          >
            Esta ação não pode ser desfeita.
          </p>

          <p
            className="uppercase textAlignCenter"
            style={{
              fontSize: isMobileScreen ? '20px' : '16px',
              color: '#A0A1A7',
              marginBottom: 40
            }}
          >
            Selecione o motivo do cancelamento.
          </p>

          <Autocomplete
            getOptionLabel={(option) => option.description}
            options={optionsDiscardManufactures}
            value={cancellationReason}
            className="autocompleteSmall"
            classes={{
              inputRoot: classes.inputRoot,
              option: classes.option
            }}
            size="small"
            onChange={(option, value) => {
              setCancellationReason(value);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                id={params.id}
                type="text"
                name="cancellationReason"
                label="DESCRIÇÃO"
                placeholder="Selecione..."
                variant="outlined"
                required
                InputLabelProps={{
                  shrink: true
                }}
              />
            )}
            renderOption={(params) => (
              <Typography style={{ fontSize: '12px' }}>
                {params.description}
              </Typography>
            )}
          />
        </DialogContent>

        <div className="dFlex justifyCenter alignCenter mt10">
          <DialogActions
            className="dFlex justifyEnd"
            style={{
              width: 'auto',
              padding: '4px 4px 12px 4px'
            }}
          >
            <Button
              design="outlined"
              className="bold"
              style={{
                width: '68px',
                borderRadius: '12px',
                borderColor: '#5062F0',
                color: '#5062F0',
                textTransform: 'initial'
              }}
              onClick={handleDialogClose}
              label="VOLTAR"
              color="primary"
            />
          </DialogActions>

          <DialogActions
            className="dFlex justifyStart"
            style={{
              width: 'auto',
              padding: '4px 4px 12px 4px'
            }}
          >
            <Button
              autoFocus
              className="productionPopupButton bold"
              style={{
                width: '96px',
                borderRadius: '12px',
                textTransform: 'initial'
              }}
              design="contained"
              label="CONFIRMAR"
              onClick={() => handleDelete()}
              disabled={!cancellationReason}
              color="primary"
            />
          </DialogActions>
        </div>
      </Dialog>

      {/* Deixar modal de baixa automatica comentado. */}
      {/* <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={modalStates.modalAutomaticDown}
        onClose={() => {
          setModalStates({
            ...modalStates,
            modalAutomaticDown: false
          })
        }}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        className="defaultModal bigModal invalidQuantityDialog w100"
        transitionDuration={0}
      >
        <DialogContent className="modalContent dFlex alignCenter justifyCenter flexColumn">
          <Typography
            className="mb10"
            style={{ fontSize: 20, color: '#0B1A8E' }}
          >
            Ficha configurada como Baixa automática de Venda
          </Typography>

          <Typography
            className="mb10 dFlex flexColumn alignCenter justifyCenter text-center"
            style={{ fontSize: 20, color: '#333541' }}
          >
            Deseja realmente efetuar a baixa automática<br />
            desta produção?
          </Typography>

          <Typography
            className="mb20 dFlex flexColumn alignCenter justifyCenter text-center"
            style={{ fontSize: 16, color: '#A0A1A7' }}
          >
            Esta ação não poderá ser desfeita
          </Typography>

          <div className="dFlex">
            <Button
              autoFocus
              className="defaultButton backButton mr20"
              design="contained"
              label="Estocar"
              onClick={(e) => {
                const isRetroactive =
                  cardDate &&
                  moment(cardDate).add(3, 'hours').format(DATE_FORMAT) !==
                    moment().format(DATE_FORMAT);

                if (isRetroactive) {
                  setModalStates({
                    ...modalStates,
                    modalConfirmationOfFinish: true
                  });

                  return;
                }

                if (!updatedManufacture || updatedManufacture == 0) {
                  setShowErrorManufacturedMessage(true);
                  setManufactures(manufactures);
                  return;
                }

                setTimeout(() => {
                  handleUpdate(manufacture, 'FINISH', e, null, null, "STOCK");

                  setModalStates({
                    ...modalStates,
                    modalAutomaticDown: false
                  })
                }, 500);
              }}
              color="primary"
            />

            <Button
              autoFocus
              className="defaultButton submitButton"
              design="contained"
              label="Baixa Venda"
              onClick={(e) => {
                const isRetroactive =
                  cardDate &&
                  moment(cardDate).add(3, 'hours').format(DATE_FORMAT) !==
                    moment().format(DATE_FORMAT);

                if (isRetroactive) {
                  setModalStates({
                    ...modalStates,
                    modalConfirmationOfFinish: true
                  });

                  return;
                }

                if (!updatedManufacture || updatedManufacture == 0) {
                  setShowErrorManufacturedMessage(true);
                  setManufactures(manufactures);
                  return;
                }

                setTimeout(() => {
                  handleUpdate(manufacture, 'FINISH', e, null, null, "SALE");

                  setModalStates({
                    ...modalStates,
                    modalAutomaticDown: false
                  })
                }, 500);
              }}
              color="primary"
            />
          </div>
        </DialogContent>
      </Dialog> */}

      <Snackbar
        open={deleteSuccess}
        autoHideDuration={2000}
        onClose={() => handleCloseAll(handleDeleteSuccess)}
      >
        <Alert
          onClose={() => handleCloseAll(handleDeleteSuccess)}
          severity="success"
        >
          Produção cancelada com sucesso!
        </Alert>
      </Snackbar>

      <Snackbar
        open={deleteError}
        autoHideDuration={2000}
        onClose={() => handleCloseAll(handleDeleteError)}
      >
        <Alert
          onClose={() => handleCloseAll(handleDeleteError)}
          severity="error"
        >
          Ocorreu um erro ao cancelar a produção.
        </Alert>
      </Snackbar>

      <CommonAlert
        open={message?.description}
        onClose={() => {
          setMessage({
            description: '',
            status: '',
            severity: '',
          })
        }}
        indexMessage={message?.description}
        messagePersonal={message?.description}
        severity={message?.severity}
        autoHideDuration={message?.autoHideDuration || 2000}
      />
    </Layout>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.user
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(UserActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Producao);
