const userLocalize = {
    "need to send at least one restaurant when user is not admin": "É necessário enviar pelo menos um restaurante quando o usuário não é administrador.",
    "incorrect password": "Senha incorreta",
    "not found profile": "Perfil não encontrado",
    "Not Found users information": "Informações de usuários não encontrados",
    "Fail login users": "Falha no login dos usuários",
    "Can't change this information": "Não é possível alterar esta informação",
    "The new passwords entered do not match": "As novas senhas inseridas não correspondem",
    "not found user with id ${id}": "Usuário não encontrado",
    "Fail delete users": 'Não foi possível remover este usuário',
    "E11000 duplicate key error collection": "Usuario ja existente",
    "ChangeUserTypePassword": "Para trocar para um usuário gestor, você deve antes informar a senha"
};

export default userLocalize;