/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-expressions */
import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';

import DateFnsUtils from '@date-io/date-fns';
import {
  FormControlLabel,
  Grid,
  MenuItem,
  Switch,
  TextField,
  Typography
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';
import { format } from 'date-fns';
import moment from 'moment';

import getOptions from 'services/getOptions';
import Environment from 'utils/environments';

const environments = Environment(process.env.REACT_APP_ENV);

function TheoryStockMenu({
  extractStartDate,
  extractEndDate,
  extractType,
  filtersExtractModal,
  productModal,
  setFiltersExtractModal,
  setExtractStartDate,
  setCardsRelatedInitialDate,
  setExtractEndDate,
  setExtractType,
  analyticFilter,
  setAnalyticFilter,
  autocompleteOptions = [],
  setAutocompleteOptions,
  user,
  getId,
  handleAutocompleteChange,
  filterSelectedProduct,
  autocompleteDescription,
  setAutocompleteDescription
}) {
  const [debounceTimeList, setDebounceTimeList] = useState([]);

  const handleFilterExtractModal = (filters) => {
    setFiltersExtractModal(filters);
  };

  const getProductsOptions = (value) => {
    debounceTimeList.forEach((el) => clearTimeout(el));

    const newTimer = setTimeout(() => {
      getOptions(
        `${environments.catalog}/stocks`,
        'products',
        autocompleteOptions,
        setAutocompleteOptions,
        getId || user.companiesActive.id,
        value ? { productDescription: value } : null,
        null,
        10
      );
    }, 1500);

    setDebounceTimeList([...debounceTimeList, newTimer]);
  };

  if (!extractStartDate) {
    return null;
  }

  return (
    <>
      <div 
        className={`dFlex flexColumn ${extractStartDate && 'cardTable'}`}
        style={{ position: 'sticky', top: '-16px', zIndex: 1 }}
      >
        <Grid container spacing={4} className="m0 w100">
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid item xs={3} sm={3} className="pl16 pb0">
              <KeyboardDatePicker
                size="small"
                id="time-picker"
                label="Data Início"
                ampm={false}
                clearable
                format="dd/MM/yyyy"
                value={moment(extractStartDate)}
                inputVariant="outlined"
                className="dateSmall"
                autoOk
                InputProps={{
                  style: { fontFamily: 'Lato', fontSize: '12px' }
                }}
                onChange={(date) => {
                  const formattedDate = moment(date).subtract(1, 'day').format('YYYY-MM-DD');
                  let formattedEndDate =
                    moment(extractEndDate).format('YYYY-MM-DD');  

                  if (formattedDate >= formattedEndDate) {
                    formattedEndDate = moment(date)
                      .add(1, 'days')
                      .format('YYYY-MM-DD');
                    setExtractEndDate(formattedEndDate);
                  }

                  setExtractStartDate(date);

                  if (moment(date).isValid()) {
                    setCardsRelatedInitialDate(null)
                    handleFilterExtractModal([
                      {
                        param: 'date',
                        value:
                          formattedDate !== '1969-12-31' ? formattedDate : null,
                        urlParam: `date=${formattedDate}`
                      },
                      {
                        param: 'dateEnd',
                        value:
                          formattedEndDate !== '1969-12-31'
                            ? formattedEndDate
                            : '',
                        urlParam: `dateEnd=${formattedEndDate}`
                      },
                      {
                        param: 'type',
                        value: extractType !== 'ALL' ? extractType : '',
                        urlParam: `type=${
                          extractType !== 'ALL' ? extractType : ''
                        }`
                      }
                    ]);
                  }
                }}
                KeyboardButtonProps={{
                  'aria-label': 'change time'
                }}
              />
            </Grid>

            <Grid item xs={3} sm={3} className="pr16 pb0">
              <KeyboardDatePicker
                size="small"
                id="time-picker"
                label="Data Fim"
                ampm={false}
                clearable
                format="dd/MM/yyyy"
                value={moment(extractEndDate).add(3, 'hours')}
                inputVariant="outlined"
                className="dateSmall"
                autoOk
                InputProps={{
                  style: { fontFamily: 'Lato', fontSize: '12px' }
                }}
                onChange={(date) => {
                  const formattedDate = moment(date).format('YYYY-MM-DD');
                  let formattedStartDate =
                    moment(extractStartDate).format('YYYY-MM-DD');

                  if (formattedDate <= formattedStartDate) {
                    formattedStartDate = moment(date)
                      .subtract(1, 'days')
                      .format('YYYY-MM-DD');
                    setExtractStartDate(formattedStartDate);
                  }

                  setExtractEndDate(date);
                  
                  if (moment(date).isValid()) {
                    handleFilterExtractModal([
                      {
                        param: 'date',
                        value:
                          formattedStartDate !== '1969-12-31'
                            ? formattedStartDate
                            : null,
                        urlParam: `date=${formattedStartDate}`
                      },
                      {
                        param: 'dateEnd',
                        value:
                          formattedDate !== '1969-12-31' ? formattedDate : '',
                        urlParam: `dateEnd=${formattedDate}`
                      },
                      {
                        param: 'type',
                        value: extractType !== 'ALL' ? extractType : '',
                        urlParam: `type=${
                          extractType !== 'ALL' ? extractType : ''
                        }`
                      }
                    ]);
                  }
                }}
                KeyboardButtonProps={{
                  'aria-label': 'change time'
                }}
              />
            </Grid>
          </MuiPickersUtilsProvider>

          <Grid item xs={12} sm={4} className="pb0">
            <TextField
              fullWidth
              select
              size="small"
              variant="outlined"
              onChange={(_, value) => {
                const formattedStartDate =
                  moment(extractStartDate).format('YYYY-MM-DD');
                const formattedEndDate =
                  moment(extractEndDate).format('YYYY-MM-DD');

                handleFilterExtractModal([
                  {
                    param: 'date',
                    value:
                      formattedStartDate !== '1969-12-31'
                        ? formattedStartDate
                        : null,
                    urlParam: `date=${formattedStartDate}`
                  },
                  {
                    param: 'dateEnd',
                    value:
                      formattedEndDate !== '1969-12-31' ? formattedEndDate : '',
                    urlParam: `dateEnd=${formattedEndDate}`
                  },
                  {
                    param: 'type',
                    value: value.props.value !== 'ALL' ? value.props.value : '',
                    urlParam: `type=${
                      value.props.value !== 'ALL' ? value.props.value : ''
                    }`
                  }
                ]);
                setExtractType(value.props.value);
              }}
              label="Tipo"
              placeholder="Selecione..."
              className="textFieldSmall"
              InputLabelProps={{
                shrink: true
              }}
              value={extractType}
            >
              <MenuItem key={0} value="ALL">
                Todos
              </MenuItem>
              <MenuItem key={1} value="SHOPPING">
                Compra
              </MenuItem>
              <MenuItem key={2} value="SALES">
                Venda
              </MenuItem>
              <MenuItem key={3} value="DISCARD">
                Descarte
              </MenuItem>
              <MenuItem key={4} value="CONSUMED_PRODUCTION">
                Consumido em produção
              </MenuItem>
              <MenuItem key={5} value="PRODUCTION_PRODUCED">
                Produzido
              </MenuItem>
              <MenuItem key={6} value="TRANSFER_ENTRY">
                Transferido De
              </MenuItem>
              <MenuItem key={7} value="TRANSFER_OUT">
                Transferido Para
              </MenuItem>
              <MenuItem key={8} value="TRANSFER_CANCELED_ENTRY">
                Devolvido para
              </MenuItem>
              <MenuItem key={9} value="TRANSFER_CANCELED_OUT">
                Devolvido de
              </MenuItem>
              <MenuItem key={10} value="STOCK">
                Recomposição de estoque
              </MenuItem>
            </TextField>
          </Grid>

          <Grid item xs={12} sm={2} className="pb0">
            <FormControlLabel
              className="toggleDefault m0 dFlex"
              control={
                <Switch
                  checked={analyticFilter}
                  onChange={() => {
                    setAnalyticFilter(!analyticFilter);
                  }}
                  name="View Mode"
                />
              }
              label={analyticFilter ? 'Analítico' : 'Sintético'}
            />
          </Grid>
        </Grid>
      </div>

      <div
        className={`dFlex alignCenter mt16 pt5 ${
          extractStartDate && 'cardTable'
        }`}
        style={{ position: 'sticky', top: '66px', zIndex: 1 }}
      >
        <Autocomplete
          onChange={(_, value) => {
            handleAutocompleteChange(_, value);
            setAutocompleteDescription(value?.description);
          }}
          clearable
          className="autocompleteSmall productFilterAutocomplete"
          fullWidth
          size="small"
          variant="outlined"
          getOptionLabel={(option) => option?.description}
          options={autocompleteOptions}
          value={filterSelectedProduct || ''}
          inputValue={autocompleteDescription || ''}
          onInputChange={(_, value, reason) => {
            if (value && reason === 'input') {
              setAutocompleteDescription(value);
            }

            getProductsOptions(value);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              id={params.id}
              name="produtos"
              onKeyDown={(event) => {
                if (event.keyCode === 8 || event.keyCode === 46) {
                  if (event.target.value.length === 1) {
                    setAutocompleteDescription('');
                    getProductsOptions('');
                    handleAutocompleteChange(null, '');
                  } else {
                    setAutocompleteDescription(event.target.value);
                  }
                }
              }}
              style={{
                fontFamily: 'Lato',
                fontSize: '12px',
                paddingLeft: '16px',
                width: '40%'
              }}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {params.InputProps.endAdornment}

                    <Close
                      className="pAbsolute customClearIcon cursorPointer"
                      onClick={() => {
                        setAutocompleteDescription('');
                        handleAutocompleteChange(null, '');
                        getProductsOptions('');
                      }}
                      style={{ width: '19px', right: '25px' }}
                    />
                  </>
                )
              }}
            />
          )}
          renderOption={(params) => (
            <Typography style={{ fontSize: '12px', fontFamily: 'Lato' }}>
              {params?.description}
            </Typography>
          )}
        />

        <div style={{ width: '195px' }}>
          <span
            onClick={() => {
              // (05/05/2024) Quando clicamos em visualizar ulitmos 7 dias devemos considerar data inicial d-8 e data final d-1 (ontem) 
              const formattedStartDate = moment().subtract(8, 'days').format('YYYY-MM-DD')
              const formattedStartDateParam = moment().subtract(9, 'days').format('YYYY-MM-DD')
              const formattedEndDate = moment().subtract(1, 'day').format('YYYY-MM-DD')

              setExtractStartDate(formattedStartDate)
              setExtractEndDate(formattedEndDate)
              setCardsRelatedInitialDate(null)
              handleFilterExtractModal([
                {
                  param: 'date',
                  value: formattedStartDate !== '1969-12-31' ? formattedStartDate : '',
                  urlParam: `date=${formattedStartDateParam}`
                },
                {
                  param: 'dateEnd',
                  value: formattedEndDate !== '1969-12-31' ? formattedEndDate : '',
                  urlParam: `dateEnd=${formattedEndDate}`
                },
              ]);
            }}
            className='cursorPointer filterDays'
          >
            Visualizar últimos 7 dias
          </span>
        </div>
      </div>
    </>
  );
}

export default TheoryStockMenu;
