/* eslint-disable array-callback-return */
/* eslint-disable no-nested-ternary */
import React from 'react';

import { format } from 'date-fns';

function CountRow({ extractItems, productModal, parameters }) {
  return (
    <tr>
      <td className="description pr12 pl16">&nbsp;</td>

      <td colSpan={6} className="description right pl12 pr16">
        <div className="dFlex alignCenter justifyEnd">
          <span>
            <b className="mr10">Última contagem:</b>{' '}
            {extractItems.find(
              (extract) =>
                extract.type === 'COUNT' &&
                extract.date ===
                  format(
                    new Date(new Date().valueOf() - 86400000),
                    'yyyy-MM-dd'
                  )
            )
              ? format(
                  new Date(
                    `${
                      extractItems.find((extract) => extract.type === 'COUNT')
                        .date
                    }T03:00:00.000Z`
                  ),
                  'dd/MM/yyyy'
                )
              : productModal?.lastCount
              ? format(new Date(productModal?.lastCount), 'dd/MM/yyyy')
              : productModal?.setups &&
                productModal?.setups?.length &&
                productModal?.setups[0].config?.lastCount
              ? format(
                  new Date(productModal.setups[0]?.config?.lastCount),
                  'dd/MM/yyyy'
                )
              : '-'}
          </span>

          {extractItems.map((item, index) => {
            const countItem =
              item.type === 'COUNT' &&
              item.date ===
                format(new Date(new Date().valueOf() - 86400000), 'yyyy-MM-dd')
                ? item
                : null;

            if (countItem !== null) {
              return (
                <span
                  style={{ marginLeft: '5px' }}
                  id={`dialogLastStockItem-${index}`}
                >
                  {countItem.quantity
                    ? parseFloat(countItem.quantity).toLocaleString('pt-br', {
                        minimumFractionDigits: 3
                      })
                    : '0,000'}{' '}
                  {productModal.consumptionUnitsPrimary &&
                    productModal.consumptionUnitsPrimary.abbreviation}
                </span>
              );
            }
          })}
        </div>
        
        {extractItems.map((item) => {
          const countItem =
            item.type === 'COUNT' &&
            item.date ===
              format(new Date(new Date().valueOf() - 86400000), 'yyyy-MM-dd')
              ? item
              : null;

          if (countItem !== null) {
            return (
              <span style={{ marginLeft: '5px' }}>
                R$
                {parameters === 'LAST_PURCHASE'
                  ? productModal.lastCostStock
                    ? parseFloat(
                        productModal.lastCostStock * countItem.quantity
                      ).toLocaleString('pt-br', {
                        minimumFractionDigits: 2
                      })
                    : productModal.lastCost
                    ? parseFloat(
                        productModal.lastCost * countItem.quantity
                      ).toLocaleString('pt-br', {
                        minimumFractionDigits: 2
                      })
                    : '0,00'
                  : productModal.averageCostStock
                  ? parseFloat(
                      productModal.averageCostStock * countItem.quantity
                    ).toLocaleString('pt-br', {
                      minimumFractionDigits: 2
                    })
                  : productModal.averageCost
                  ? parseFloat(
                      productModal.averageCost * countItem.quantity
                    ).toLocaleString('pt-br', {
                      minimumFractionDigits: 2
                    })
                  : '0,00'}
              </span>
            );
          }
        })}
      </td>
    </tr>
  );
}

export default CountRow;
