/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useState } from 'react';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography
} from '@material-ui/core';
import Axios from 'axios';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import Button from 'components/BloomForms/Button';
import { WrapperContainer } from 'components/BloomForms/WrapperContainer.style';
import SkuModal from 'components/Dialogs/SkuModal/SkuModal';
import PaperComponent from 'components/PaperComponent';
import CloseIconNavy from 'images/icons/signals/closesAndCancels/closeTransparent.svg';
import getOptions from 'services/getOptions';
import makeGet from 'services/useFetch/makeGet';
import makePut from 'services/useFetch/makePut';
import * as SkusActions from 'store/actions/skus';
import Environment from 'utils/environments';

import ComparationCard from './ComparationCard';
import { FormWrapper, ButtonsWrapper } from './index.style';
import RejectConfirmModal from './rejectConfirmModal';
import { handleAprovedSku } from './services';

const environments = Environment(process.env.REACT_APP_ENV);

function ComparationDialog({
  enableOpenSkuModal,
  setEnableOpenSkuModal,
  setRefreshData,
  openModal,
  handleClose,
  isEditable,
  comparationCurrentData,
  setComparationCurrentData,
  setToastProps,
  setToastOpen,
  userInfo,
  brandsOptions,
  productsOptions,
  setUpdateMainList,
  callSettings,
  setCallSettings,
  mainContent,
  findEan,
  freeCatalogParamAssociate,
  setSimilarSkusOpen,
  setSimilarSkuFilterValue,
  setSimilarSkuProvider,
  comparationCardFilterValue,
  setComparationCardFilterValue,
  setSimilarSkuCurrentData,
  similarSkuCurrentData,
  storeOptions,
  selectedStores
}) {
  const { id: paramsID } = useParams();
  const { modalComparation } = openModal;
  const isAdminMaster = userInfo?.profile?.[0]?.[0]?.name === 'admin_master';
  const isDraftParam = isAdminMaster ? '' : '?draft=true'
  const [modalStates, setModalStates] = useState({
    modalSku: false,
    rejectConfirmDialog: false
  });
  const [selectedSku, setSelectedSku] = useState([]);
  const [totalSelectedSku, setTotalSelectedSku] = useState();
  const [confirmAssociation, setConfirmAssociation] = useState(false);
  const [similarOptions, setSimilarOptions] = useState([]);
  const [categories, setCategories] = useState([]);
  const [packageTypes, setPackageTypes] = useState([]);
  const [consumptionUnits, setConsumptionUnits] = useState([]);
  const [providers, setProviders] = useState([]);
  const [skus, setSkus] = useState([]);
  const [similarSku, setSimilarSku] = useState([]);
  const [comparationEan, setComparationEan] = useState('');
  const [similarSkuByEan, setSimilarSkuByEan] = useState([])
  const [searchByEan, setSearchByEan] = useState(false);
  const [isLoadingSimilarByEan, setIsLoadingSimilarByEan] = useState(true);
  const [debounceList, setDebounceList] = useState([]);
  const [equalsFields, setEqualsFields] = useState({
    description: false,
    ean: false,
    quantity: false,
    averagePrice: false,
    lastPrice: false
  });
  const [saveOnlyOnce, setSaveOnlyOnce] = useState(false);

  const closeToast = (timeout = 1000) => {
    setTimeout(() => {
      setToastOpen(false);
    }, timeout);
  };

  const handleComparationActions = (
    action,
    justificationValue,
    justificationDescription
  ) => {
    const getAssociatedFlag = () => selectedSku.length === totalSelectedSku;

    const getRefusedFlag = () => selectedSku.length === totalSelectedSku;

    const isAssociated = action === 'ASSOCIATED';
    const messageAction = isAssociated ? 'associar' : 'rejeitar';
    const associatedFlag = getAssociatedFlag();

    if (!selectedSku.length && totalSelectedSku !== 0) {
      setToastProps({
        severity: 'error',
        message: `Selecione ao menos um fornecedor, para ${messageAction}.`
      });
      setToastOpen(true);
      closeToast(1500);
      setUpdateMainList(true);

      return;
    }

    const handleResponseConnectSku = (status, response) => {
      if (status) {
        const successMessage = isAssociated
          ? 'Associação realizada com sucesso.'
          : 'Sku rejeitado com sucesso';
        setConfirmAssociation(false);
        setToastProps({
          severity: 'success',
          message: successMessage
        });
        setToastOpen(true);

        setTimeout(() => {
          selectedSku.forEach((sku) => {
            sku.isDisableSku = true;
          });

          setSelectedSku([]);
          setToastOpen(false);
          setUpdateMainList(true);

          setCallSettings({
            ...callSettings,
            mainContent: mainContent?.filter((el) => {
              const currentProvider = el?.providers?.length ? el?.providers[0] : ''
              const skuProvider = comparationCurrentData?.providers?.length ? comparationCurrentData?.providers[0] : ''
      
              return el?.description !== comparationCurrentData?.description 
                || currentProvider?.providerId !== skuProvider?.providerId 
                || currentProvider?.codeProvider !== skuProvider?.codeProvider
            })
          })

          const shouldCloseModal = isAssociated
            ? getAssociatedFlag()
            : getRefusedFlag();

          if (shouldCloseModal) {
            handleClose();
          }
        }, 800);

        return;
      }

      const errorMessage = `Tivemos um problema ao tentar ${messageAction}, por favor tente mais tarde.`;
      setConfirmAssociation(false);
      setToastProps({
        severity: 'error',
        message: errorMessage
      });
      setToastOpen(true);
      closeToast(1500);
    };

    if (!similarSkuCurrentData && isAssociated) {
      setToastProps({
        severity: 'error',
        message: 'Selecione um sku para ser associado.'
      });
      setToastOpen(true);
      closeToast(1500);

      return;
    }

    const { id: similarSkuId } = similarSkuCurrentData || {};
    const { id: comparationSkuId } = comparationCurrentData || {};
    const similarPackageSelected = fetchPackageDestinationSelected(
      similarSkuCurrentData
    );
    const [{ id: similarPackageId }] =
      similarPackageSelected && similarPackageSelected.length > 0
        ? similarPackageSelected
        : [{ id: null }];

    const body = {
      skuDestinationId: similarSkuId,
      user: 'admin',
      ...(similarPackageId && { packageDestinationId: similarPackageId }),
      providers: [...selectedSku],
      associateSku: associatedFlag
    };

    const { id: rejectionTypeId = '' } = justificationValue || {};

    const rejectionBody = {
      user: 'admin',
      justification: {
        typeId: rejectionTypeId,
        description: justificationDescription
      },
      allPackages: getRefusedFlag(),
      providers: [...selectedSku]
    };
    setModalStates({ ...modalStates, rejectConfirmDialog: false });

    const getFlagString = () =>
      isAssociated
        ? `/?associateSku=${associatedFlag}`
        : `/?allPackages=${getRefusedFlag()}`;

    makePut(
      'skuCards',
      isAssociated ? body : rejectionBody,
      handleResponseConnectSku,
      `${comparationSkuId}/status/${action}${getFlagString()}${isDraftParam}`
    );
  };

  const fetchPackageDestinationSelected = (similarSkus) => {
    if (similarSkus) {
      const { providers } = similarSkus;
      return providers.filter((provider) => provider.isSelected);
    }
  };

  const handleResponseAproved = (status, response) => {
    if (status) {
      setToastProps({
        severity: 'success',
        message: 'Cadastro aprovado com sucesso.'
      });
      setToastOpen(true);
      setModalStates((prevState) => ({ ...prevState, modalSku: false }));

      setTimeout(() => {
        selectedSku.forEach((sku) => {
          sku.isDisableSku = true;
        });

        setSelectedSku([]);
        setToastOpen(false);

        setCallSettings({
          ...callSettings,
          mainContent: mainContent?.filter((el) => {
            const currentProvider = el?.providers?.length ? el?.providers[0] : ''
            const skuProvider = comparationCurrentData?.providers?.length ? comparationCurrentData?.providers[0] : ''
    
            return el?.description !== comparationCurrentData?.description 
              || currentProvider?.providerId !== skuProvider?.providerId 
              || currentProvider?.codeProvider !== skuProvider?.codeProvider
          })
        })

        if (totalSelectedSku === selectedSku.length) {
          handleClose();
        }
      }, 800);

      return;
    }

    setToastProps({
      severity: 'error',
      message:
        response?.data?.message
    });
    setToastOpen(true);
    closeToast();
  };

  const handleSkuModal = () => {
    if (!selectedSku.length) {
      setToastProps({
        severity: 'error',
        message: 'Selecione ao menos um fornecedor, para cadastrar.'
      });
      setToastOpen(true);
      closeToast(1500);
      setUpdateMainList(true);

      return;
    }

    setModalStates((prevState) => ({ ...prevState, modalSku: true }));
  };

  const buildValidWord = (word) => {
    const invalidWords = [
      'SEM',
      'S/',
      'COM',
      'C/',
      '(',
      ')',
      'PRE-',
      'A',
      'E',
      'I',
      'O',
      'U',
      'DA',
      'DE',
      'DO',
      'EM',
      'PARA',
      'POR',
      'SOBRE',
      'SOB',
      'ATE',
      'ENTRE',
      '-',
      '.',
      '/',
      'P/'
    ];
    const validWords = word
      ?.split(' ')
      ?.map((item) => item.toUpperCase())
      ?.filter((el) => !invalidWords.includes(el));

    return validWords?.slice(0, 2).join(' ');
  };

  const buildUniqueProviders = (skuProviders) => {
    const uniques = [];

    skuProviders
      ?.filter((provider) =>
        similarSkuCurrentData
          ? provider.statusCataloguing === 'APPROVED'
          : provider.statusCataloguing !== 'REFUSED' &&
            provider.statusCataloguing !== 'APPROVED'
      )
      .forEach((provider) => {
        const check = uniques.filter(
          (arr) => arr.codeProvider === provider.codeProvider && arr?.providerId === provider?.providerId
        );

        if (!check.length) {
          provider.isSelected = !similarSkuCurrentData;
          uniques.push(provider);
        }
      });

    return uniques;
  };

  const setValuesOnStateEqualsFields = ({ left, right }) => {
    const leftObj = left || { products: [] };
    const rightObj = right || { products: [] };
    const {
      products: [
        {
          priceUc: leftPriceUc = 0,
          products: {
            consumptionUnitsPrimary: {
              abbreviation: leftAbbreviation = null
            } = {}
          } = {}
        } = {}
      ] = []
    } = leftObj;
    const {
      products: [
        {
          priceUc: rightPriceUc = 0,
          products: {
            consumptionUnitsPrimary: {
              abbreviation: rightAbbreviation = null
            } = {}
          } = {}
        } = {}
      ] = []
    } = rightObj;

    const equals = {
      description: false,
      ean: false,
      quantity: false,
      averagePrice: false,
      lastPrice: false,
      consumptionUnit: false
    };

    const leftObjPurchaseAverage =
      leftObj?.restaurants?.length && leftObj?.restaurants[0].purchaseAverage
        ? leftObj?.restaurants[0].purchaseAverage.toFixed(3)
        : 0;
    const rightObjPurchaseAverage =
      rightObj?.restaurants?.length && rightObj?.restaurants[0].purchaseAverage
        ? rightObj?.restaurants[0].purchaseAverage.toFixed(3)
        : 0;

    const leftObjPurchaseLast =
      leftObj?.restaurants?.length && leftObj?.restaurants[0].purchaseLast
        ? leftObj?.restaurants[0].purchaseLast.toFixed(3)
        : 0;
    const rightObjPurchaseLast =
      rightObj?.restaurants?.length && rightObj?.restaurants[0].purchaseLast
        ? rightObj?.restaurants[0].purchaseLast.toFixed(3)
        : 0;

    if (
      leftObj &&
      leftObj?.products?.length &&
      rightObj &&
      rightObj?.products?.length
    ) {
      equals.description = leftObj.description === rightObj.description;
      equals.ean =
        leftObj.ean && rightObj.ean ? leftObj.ean === rightObj.ean : false;
      equals.quantity = leftObj.quantity === rightObj.quantity;
      equals.averagePrice = leftObjPurchaseAverage === rightObjPurchaseAverage;
      equals.lastPrice = leftObjPurchaseLast === rightObjPurchaseLast;
    }

    if (leftPriceUc && rightPriceUc && leftAbbreviation && rightAbbreviation) {
      if (
        leftPriceUc === rightPriceUc &&
        leftAbbreviation === rightAbbreviation
      ) {
        equals.consumptionUnit = true;
      }
    }

    setEqualsFields(equals);
  };

  const getSkuSimilarByEan = () => {
    if (comparationCurrentData?.ean) {
      Axios.get(
        `${environments.catalog}/skus/platform/similar?originId=0&page=1&ean=${comparationCurrentData?.ean}`
      )
        .then((response) => {
          setSimilarSkuByEan(response?.data)
        })
        .catch((error) => {
          console.log(error);
          setSimilarSkuByEan([])
          setSimilarSkuCurrentData(null);
          setSearchByEan(false);
          setIsLoadingSimilarByEan(false);
        });
    } else {
      setSimilarSkuByEan([])
      setSimilarSkuCurrentData(null);
      setSearchByEan(false);
      setIsLoadingSimilarByEan(false);
    }
  }

  useEffect(() => {
    comparationCurrentData.user = userInfo?.user;
    const { probablySku } = comparationCurrentData;

    if (
      !probablySku &&
      !similarSkuByEan.length &&
      !comparationCurrentData?.similar &&
      !isLoadingSimilarByEan
    ) {
      const firstTwoDescriptionWords = buildValidWord(
        comparationCurrentData?.description
      );

      const uniqueProviders = buildUniqueProviders(
        comparationCurrentData?.providers
      );

      if (!similarSkuCurrentData) {
        setSimilarSkusOpen(true);
      } else {
        setEnableOpenSkuModal(true);
      }

      setSimilarSkuFilterValue(firstTwoDescriptionWords);
      setSimilarSkuProvider(
        uniqueProviders?.length ? uniqueProviders[0]?.descriptionSku : ''
      );

      return;
    }

    const handleResponse = (data) => {
      setEnableOpenSkuModal(true);

      if (data.id) {
        setSimilarSkuCurrentData(data);
      } else if (similarSkuByEan.length) {
        setSimilarSkuCurrentData(similarSkuByEan[0]);
      } else if (comparationCurrentData?.similar && similarOptions.length) {
        setSimilarSkuCurrentData(
          similarOptions.find(
            (item) => item.id === comparationCurrentData?.similar
          )
        );
      } else {
        return { providers: [] };
      }
    };

    if (probablySku) {
      makeGet(
        'skuCards',
        [{ urlParam: paramsID }],
        handleResponse,
        `${probablySku}`
      );
    }
  }, [comparationCurrentData, similarOptions, isLoadingSimilarByEan, similarSkuByEan]);

  useEffect(() => {
    getSkuSimilarByEan()

    if (!comparationCurrentData?.ean) {
      setComparationEan('');
      return;
    }
    setComparationEan(comparationCurrentData?.ean);
  }, [comparationCurrentData]);

  useEffect(() => {
    if (!modalStates.modalSku) return;

    getOptions(
      `${environments.catalog}/categories`,
      'categories',
      categories,
      setCategories,
      paramsID
    );
    getOptions(
      `${environments.catalog}/typePackages`,
      'packageTypes',
      packageTypes,
      setPackageTypes,
      paramsID
    );
    getOptions(
      `${environments.catalog}/consumptionUnits`,
      'consumptionUnits',
      consumptionUnits,
      setConsumptionUnits,
      paramsID
    );
  }, [modalStates.modalSku]);

  useEffect(() => {
    setIsLoadingSimilarByEan(true);

    if (comparationEan) {
      const handleResponseEan = (response) => {
        if (response?.content[0]?.ean == comparationEan) {
          setSimilarSkuCurrentData(response?.content[0]);
          setSearchByEan(true);
          setIsLoadingSimilarByEan(false);
        } else {
          setSimilarSkuCurrentData(null);
          setSearchByEan(false);
          setIsLoadingSimilarByEan(false);
        }
      }

      if (debounceList.length) {
        debounceList.forEach((el) => {
          clearTimeout(el);
        });
      }

      const newTimer = setTimeout(() => {
        makeGet(
          'skuCard',
          [{ urlParam: `ean=${comparationEan}` }],
          handleResponseEan,
          `/platform`
        );
      }, 200);

      setDebounceList([...debounceList, newTimer]);
    } else {
      setSimilarSkuCurrentData(null);
      setSearchByEan(false);
      setIsLoadingSimilarByEan(false);
    }
  }, [comparationEan]);

  return (
    <Dialog
      fullWidth
      maxWidth={null}
      open={modalComparation}
      onClose={() => {
        if (!enableOpenSkuModal) return;

        handleClose();
      }}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      className="defaultModal comparationDialog w100"
      transitionDuration={0}
    >
      <DialogTitle
        className="modalHeader bold textAlignCenter cursorMove"
        id="draggable-dialog-title"
      >
        <Typography>COMPARAÇÃO</Typography>

        <IconButton
          edge="start"
          color="inherit"
          onClick={() => {
            if (!enableOpenSkuModal) return;

            handleClose();
          }}
          id="skuComparationDialogClose"
        >
          <img
            style={{ width: '16px', height: '16px' }}
            src={CloseIconNavy}
            alt="CloseIconNavy"
          />
        </IconButton>
      </DialogTitle>

      <WrapperContainer>
        <FormWrapper>
          <ComparationCard
            card={comparationCurrentData}
            setComparationCurrentData={setComparationCurrentData}
            selectProviderMode
            selectedSkus={selectedSku}
            setSelectedSkus={setSelectedSku}
            comparationEan={comparationEan}
            setComparationEan={setComparationEan}
            setTotalSelectedSKU={setTotalSelectedSku}
            hasEqualsFields={equalsFields}
            searchByEan={searchByEan}
            setSearchByEan={setSearchByEan}
            skuDialogOpen={modalStates.modalSku}
          />
          <ComparationCard
            setEnableOpenSkuModal={setEnableOpenSkuModal}
            setSimilarSkusOpen={setSimilarSkusOpen}
            card={similarSkuCurrentData}
            similarSkuComparation
            setSimilarSkuCurrentData={setSimilarSkuCurrentData}
            comparationEan={similarSkuCurrentData?.ean}
            setComparationEan={setComparationEan}
            searchByEan={searchByEan}
            setSearchByEan={setSearchByEan}
            setSimilarSku={setSimilarSku}
            setValuesOnStateEqualsFields={setValuesOnStateEqualsFields}
            leftValuesComparation={comparationCurrentData}
            hasEqualsFields={equalsFields}
            similarOptions={similarOptions}
            setSimilarOptions={setSimilarOptions}
            comparationCardFilterValue={comparationCardFilterValue}
            setComparationCardFilterValue={setComparationCardFilterValue}
          />
        </FormWrapper>

        <ButtonsWrapper>
          <Button
            id="rejectedSkuComparationSku"
            label="Rejeitar"
            redlink
            redlinkDisabled={!!similarSkuCurrentData || !isEditable}
            disable={!!similarSkuCurrentData || !isEditable}
            onClick={() => {
              setModalStates({ ...modalStates, rejectConfirmDialog: true });
            }}
          />

          <Button
            id="recordSkuComparationSku"
            label="Cadastrar"
            secondary
            // disable={!isEditable || findEan}
            disable={!isEditable || findEan || !enableOpenSkuModal}
            onClick={() => {
              if (!enableOpenSkuModal) return;

              handleSkuModal();
            }}
          />

          <Button
            id="associatedSkuComparationSku"
            label="Associar"
            primary
            disable={!similarSkuCurrentData || !isEditable}
            onClick={() => setConfirmAssociation(true)}
          />
        </ButtonsWrapper>
      </WrapperContainer>

      {modalStates.modalSku && (
        <SkuModal
          storeOptions={storeOptions}
          selectedStores={selectedStores}
          selectedEan={comparationCurrentData?.ean}
          callSettings={callSettings}
          setCallSettings={setCallSettings}
          mainContent={mainContent}
          fullScreen
          maxWidth={null}
          fullWidth
          open={modalStates.modalSku}
          data={selectedSku}
          getId={paramsID}
          editMode
          sku={comparationCurrentData}
          setComparationCurrentData={setComparationCurrentData}
          userInfo={userInfo}
          providers={providers}
          packageTypes={packageTypes}
          products={productsOptions}
          categories={categories}
          brands={brandsOptions}
          consumptionUnits={consumptionUnits}
          skus={skus}
          platformRegisterMode
          onClose={() => {
            setModalStates((prevState) => ({ ...prevState, modalSku: false }));

            setEnableOpenSkuModal(true);
          }}
          handleOnSave={({ body, isEditMode, multipleSkuData, product }) => {
            if (multipleSkuData) {
              body.product = product;
            }

            handleAprovedSku({
              body,
              isEditMode,
              totalSelectedSKU: totalSelectedSku,
              handleResponseAproved,
              multipleSkuData,
              isDraft: isDraftParam
            });
          }}
          freeCatalogParamAssociate={freeCatalogParamAssociate}
          adminCatalogModal
          originPage='catalog'
        />
      )}

      {modalStates.rejectConfirmDialog && (
        <RejectConfirmModal
          modalStates={modalStates.rejectConfirmDialog}
          handleOnclose={() => {
            setModalStates({ ...modalStates, rejectConfirmDialog: false });
            setUpdateMainList(true);
          }}
          handleRejection={handleComparationActions}
        />
      )}

      <Dialog
        open={confirmAssociation}
        onClose={() => {
          setConfirmAssociation(false);
          setSaveOnlyOnce(false);
          setUpdateMainList(true);
        }}
        aria-labelledby="responsive-dialog-title"
        PaperComponent={(props) => (
          <PaperComponent {...props} handleId="#associationConfirmation" />
        )}
      >
        <DialogTitle
          className="modalHeader navyBlue bold cursorMove"
          id="associationConfirmation"
        >
          <IconButton
            edge="start"
            color="inherit"
            className="pAbsolute"
            onClick={() => {
              setConfirmAssociation(false);
              setSaveOnlyOnce(false);
            }}
            aria-label="close"
            style={{
              outline: '0',
              background: 'none',
              align: 'right',
              right: 15,
              top: 15
            }}
          >
            <img
              style={{ width: '16px' }}
              src={CloseIconNavy}
              alt="CloseIconNavy"
            />
          </IconButton>
        </DialogTitle>

        <DialogContent className="modalContent">
          <Typography className="textAlignCenter">
            Tem certeza que deseja associar este código ao código da embalagem
            cadastrada?
          </Typography>
        </DialogContent>

        <DialogActions className="justifyCenter">
          <Button
            id="confirmCancelAssociatedSkuDialog"
            className="defaultButton backButton"
            design="outlined"
            onClick={() => setConfirmAssociation(false)}
            label="Cancelar"
            color="primary"
          />

          <Button
            id="confirmAssociatedSkuDialog"
            primary
            autoFocus
            disable={saveOnlyOnce}
            className="defaultButton submitButton"
            design="contained"
            label="Confirmar"
            onClick={() => {
              handleComparationActions('ASSOCIATED');
              setSaveOnlyOnce(true);
            }}
            color="primary"
          />
        </DialogActions>
      </Dialog>
    </Dialog>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.user
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(SkusActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ComparationDialog);
