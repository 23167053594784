/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-case-declarations */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable default-case */
/* eslint-disable no-new */
/* eslint-disable radix */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-nested-ternary */
import React from 'react';

import {
  Collapse,
  Fade,
  Grid,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import format from 'date-fns/format';
import capitalize from 'lodash.capitalize';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import ImageWrapper from 'components/ImageWrapper';
import InputNumber from 'components/Inputs/ManufactureInputs/InputNumber';
import { PrintLabel } from 'components/PrintLabel';
import EngineIconGrey from 'images/icons/configsAndSetups/configIconGrey.svg';
import EngineIcon from 'images/icons/configsAndSetups/configLightBlueOutline.svg';
import storeIconWS from 'images/icons/objects/stores/storeIconWS.svg';
import VirtualProduction from 'images/icons/signals/alerts/virtualProductionIcon.svg';
import CheckedCircleIcon from 'images/icons/signals/checks/checkWhiteGreenBgRoundedNavyBorder.svg';
import CancelProduction from 'images/icons/signals/closesAndCancels/cancelWhiteRedBgRoundedNavyBorder.svg';
import DeleteIcon from 'images/icons/signals/closesAndCancels/deleteBlanc2.svg';
import ProductionIcon from 'images/icons/signals/moreInfos/threeDotsWhiteLightBlueBgRoundedNavyBorder.svg';
import PauseProductionIcon from 'images/icons/signals/pausesAndStops/pauseWhiteYellowMustardBgRoundedNavyBorder.svg';
import PlayIcon from 'images/icons/signals/playsAndStarts/playWhiteNavyBlueBgRoundedNavyBorder.svg';
import { ButtonStatus } from 'styles/buttons';
import { increaseDate } from 'utils/formatDate';
import { getRestaurantbyId } from 'utils/getRestaurants';
import { checkIsBefore } from 'utils/isBefore';
import ValidationLength from 'utils/utils';

import { useStyles, PercentBox, TooltipFinish } from './styles';
import { handleFields } from './utils';

function DesktopRows({
  cards,
  setManufacture,
  setCurrentTransformationCardItems,
  setUpdateValue,
  updateValue,
  handleCardQuantity,
  handleItemDiscard,
  optionsDiscard,
  getDuration,
  handleClickOpenModal,
  setLoading,
  manufactures,
  loading,
  handleUpdate,
  handlePopoverClick,
  setCardDate,
  setPopoverData,
  handleOpenItems,
  diffViewMonetary,
  handleDialogClickOpen,
  setDeleteData,
  modalStates,
  setModalStates,
  stores,
  companyActive,
  companyGroup,
  updateQuantityInitialValue,
  setCurrentCard,
  setProductionInputControlAnchor
}) {
  const classes = useStyles();
  const {companies, companiesActive} = useSelector(state => state.user);

  const renderCardDescriptionInfos = (currentManufacture, type, handlePreparationClick) => (
    <div className="dFlex alignCenter w100">
      <div className="dFlex alignCenter" style={{ width: "74%" }}>
        <div
          style={{ padding: '0 5px 0 0' }}
          onClick={() => handleOpenItems(currentManufacture._id)}
        >
          {manufactures.find((el) => el._id === currentManufacture._id)
            ?.open ? (
            <ExpandLess style={{ marginLeft: "-6px" }} />
          ) : (
            <ExpandMore style={{ marginLeft: "-6px" }} />
          )}
        </div>

        <div
          className="justifyBetween dFlex w100"
          style={{ padding: '0' }}
          onClick={() => {
            if (currentManufacture?.preparationMethod?.url) return;
            handleOpenItems(currentManufacture._id);
          }}
        >
          <div className="dFlex alignCenter w100">
            <Tooltip
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 600 }}
              placement="right"
              title={
                <span
                  className="dBlock"
                  style={{ padding: '4px 7px' }}
                >
                  {currentManufacture?.cardId} -{' '}
                  {handleFields(currentManufacture).description}
                </span>
              }
            >
              <Link
                to={`/preparation/${currentManufacture?.preparationMethod?.name}`}
                target="_blank"
                rel="noopener noreferrer"
                className={`${!currentManufacture?.preparationMethod?.url && 'disabledLink'} mr10`}
                style={{ width: "89.5%" }}
              >
                <span
                  style={{
                    color: currentManufacture?.preparationMethod?.url
                      ? '#5062F0'
                      : '#0F1225'
                  }}
                  onClick={() => {
                    if (!currentManufacture?.preparationMethod?.url) return;

                    handlePreparationClick(
                      currentManufacture?.preparationMethod?.url
                    )
                  }}
                >
                  {handleFields(currentManufacture).description?.length > 35
                    ? `${handleFields(
                        currentManufacture
                      ).description?.substring(0, 30)}`
                    : handleFields(currentManufacture).description
                  }

                  {handleFields(currentManufacture).description?.length > 35 && '...'}
                </span>
              </Link>
            </Tooltip>
            
            <div className="dFlex justifyCenter alignCenter" style={{ width: "10.5%" }}>
              {type === 'recipe' &&
                <ImageWrapper
                  width="30px"
                  height="28px"
                  url={currentManufacture?.image}
                  borderType="INTERNAL"
                />
              }
            </div>
          </div>
        </div>
      </div>
      
      <div className="dFlex alignCenter justifyEnd" style={{ width: "26%" }}>
        <div className="dFlex alignCenter">
          {currentManufacture?.engine?.quantity && currentManufacture?.engine?.quantity === currentManufacture?.quantity ? (
            <Tooltip
              title={
                <p style={{ padding: '3px 8px', margin: 0 }}>
                  <b>Sugestão Copiloto:</b><br/>
                  <b>Qtde de acordo com a sugestão</b>
                </p>
              }
              aria-label="filters"
              placement="right-start"
            >
              <img
                src={EngineIcon}
                style={{ width: 16, height: 15 }}
              />
            </Tooltip> 
          ) : currentManufacture.origin === 'S' && currentManufacture?.engine?.quantity !== currentManufacture?.quantity || currentManufacture.origin === 'S' ? (
            <Tooltip
              title={
                <p style={{ padding: '3px 8px', margin: 0 }}>
                  <b>Sugestão Copiloto:</b><br/>
                  <b>Qtde sugerida alterada pelo usuário</b>
                </p>
              }
              aria-label="filters"
              placement="right-start"
            >
              <img
                src={EngineIconGrey}
                style={{ width: 16, height: 15 }}
              />
            </Tooltip> 
          ): currentManufacture.origin === 'M' ? (
            <Tooltip
              title={
                <div style={{ padding: '4px'}}>
                  <p style={{ margin: '0'}}>Produção fora da programação</p>
                </div>
              }
              aria-label="filters"
              placement="right-start"
            >
              <img 
                src={VirtualProduction}
                style={{ width: 16, height: 15 }}
                className="mr3"
              />
            </Tooltip>
          ) : null}
        </div>

        <div className="pAbsolute dFlex justifyCenter" style={{ right: '-10px', height: '100%' }}>
          <span className="dFlex alignCenter">
            {Array.isArray(companiesActive.id) ? (
              <Tooltip
                title={
                  <p style={{ padding: '3px 8px', margin: 0 }}>
                    <b>{getRestaurantbyId(currentManufacture?.restaurantId, companies)?.name}</b>
                  </p>
                }
                aria-label="filters"
                placement="right-start"
              >
                <img
                  src={storeIconWS}
                  style={{ width: 16, height: 15, marginLeft: '8px' }}
                />
              </Tooltip>
            ) : null}
          </span>
        </div>
      </div>
    </div>
  )

  const renderItemTransformationInfos = (currentManufacture, itemTransformation, index) => (
    <div className="dFlex alignCenter w100" style={{ paddingLeft: 23 }}>
      <div className="justifyBetween dFlex" style={{ width: "75.3%" }}>
        <Tooltip
          TransitionComponent={Fade}
          TransitionProps={{ timeout: 600 }}
          placement="right"
          title={
            <span
              className="dBlock"
              style={{ padding: '4px 7px' }}
            >
              Produto à ser transformado
            </span>
          }
        >
          <span
            style={{
              color: currentManufacture?.preparationMethod?.url
                ? '#5062F0'
                : '#0F1225'
            }}
          >
            {handleFields(itemTransformation).onlyDescription?.length > 14
              ? `${handleFields(
                  itemTransformation
                ).onlyDescription?.substring(0, 28)}`
              : handleFields(itemTransformation).onlyDescription
            }

            {handleFields(itemTransformation).onlyDescription?.length > 32 && '...'}
          </span>
        </Tooltip>

        <ImageWrapper
          width="30px"
          height="28px"
          url={itemTransformation?.image?.url}
          borderType={itemTransformation?.type}
        />
      </div>
      
      <div className="ml10" style={{ width: '19.7%' }}>
        <InputNumber
          updateQuantityInitialValue={updateQuantityInitialValue}
          setModalStates={setModalStates}
          modalStates={modalStates}
          setUpdateValue={setUpdateValue}
          updateValue={updateValue}
          disabled={
            status !== 'waiting' && status !== 'start' && status !== 'pause'
          }
          minimumValue="0"
          maximumValue="999999999"
          initialValue={currentManufacture?.quantity?.toFixed(3)}
          // validUnit={manufacture?.un?.abbreviation === 'un'}
          handleValue={(value) => {
            setManufacture(currentManufacture);

            handleCardQuantity(
              index,
              parseFloat(value),
              undefined,
              undefined,
              undefined
            );
          }}
          fontSize="12px"
          fontWeight="normal"
          width="100%"
          height="28px"
        />
      </div>

      <span className="ml3 dFlex justifyCenter" style={{ width: "5%" }}>
        {itemTransformation?.consumptionUnits?.abbreviation || ''}
      </span>
    </div>
  )

  const renderCardStock = (currentManufacture, itemTransformation) => (
    <div className="dFlex justifyEnd w100">
      {itemTransformation
        ? handleFields(itemTransformation)?.theoreticalStock
        : handleFields(currentManufacture)?.stock}

      <span className="ml3">
        {currentManufacture.type === 'TRANSFORMATION'
          ? currentManufacture?.items?.[0]?.un?.abbreviation || ''
          : currentManufacture?.un?.abbreviation || ''
        }
      </span>
    </div>
  )

  const renderCardDuration = (currentManufacture, disabled, itemTransformation) => (
    <span
      className={`lightBlue w100 ${disabled ? 'cursorAuto' : 'cursorPointer'}`}
      onClick={() => {
        if (disabled) return null

        getDuration(
          currentManufacture.productId ? currentManufacture.productId : null,
          itemTransformation ? itemTransformation?.theoreticalStock : currentManufacture.stock,
          currentManufacture
        );
        handleClickOpenModal(currentManufacture);
      }}
    >
      {handleFields(itemTransformation || currentManufacture).duration}
    </span>
  )

  const renderRecipeProductionInput = (currentManufacture, index) => (
    currentManufacture?.viewMethod === 1 ?
      <InputNumber
        updateQuantityInitialValue={updateQuantityInitialValue}
        setModalStates={setModalStates}
        modalStates={modalStates}
        setUpdateValue={setUpdateValue}
        updateValue={updateValue}
        disabled={
          status !== 'waiting' && status !== 'start' && status !== 'pause'
        }
        minimumValue="0"
        maximumValue="999999999"
        initialValue={currentManufacture?.quantity?.toFixed(3)}
        // validUnit={manufacture?.un?.abbreviation === 'un'}
        handleValue={(value) => {
          setManufacture(currentManufacture);

          handleCardQuantity(
            index,
            parseFloat(value),
            undefined,
            undefined,
            undefined
          );
        }}
        fontSize="12px"
        fontWeight="normal"
        width="100%"
        height="28px"
      />
    :
      <InputNumber
        updateQuantityInitialValue={updateQuantityInitialValue}
        setModalStates={setModalStates}
        modalStates={modalStates}
        setUpdateValue={setUpdateValue}
        updateValue={updateValue}
        disabled={
          status !== 'waiting' &&
          status !== 'start' &&
          status !== 'pause'
        }
        minimumValue="0"
        maximumValue="999999999"
        // validUnit={manufacture?.un?.abbreviation === 'un'}
        initialValue={currentManufacture?.yield?.toFixed(3)}
        handleValue={(value) => {
          setManufacture(currentManufacture);

          handleCardQuantity(
            index,
            currentManufacture?.quantity || 1,
            undefined,
            undefined,
            parseFloat(value)
          );
        }}
        fontSize="12px"
        fontWeight="normal"
        width="100%"
        height="28px"
      /> 
  )

  const renderCardProduction = (currentManufacture, type, index) => (
    <div className="dFlex w100">
      {type === 'TRANSFORMATION'
      ?
        <span 
          className="dFlex justifyEnd" 
          style={{ 
            width: '30%', 
          }}
        >
          {currentManufacture?.loss
            ? parseFloat(currentManufacture?.loss).toLocaleString('pt-br', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })
            : '0,00'}
          %
        </span>
      :
        <span 
          className="dFlex justifyEnd bold lightBlue cursorPointer" 
          style={{ width: '30%' }}
          onClick={(event) => {
            setProductionInputControlAnchor(event.currentTarget)
            setCurrentCard(currentManufacture)
          }}
        >
          {currentManufacture?.viewMethod === 1 ? 'Q' : 'R'}
        </span>
      }

      <div style={{ width: "50%", margin: '0 5px' }}>
        {type === 'TRANSFORMATION' ?
          <InputNumber
            updateQuantityInitialValue={updateQuantityInitialValue}
            setModalStates={setModalStates}
            modalStates={modalStates}
            setUpdateValue={setUpdateValue}
            updateValue={updateValue}
            disabled={
              status !== 'waiting' &&
              status !== 'start' &&
              status !== 'pause'
            }
            minimumValue="0"
            maximumValue="999999999"
            // validUnit={manufacture?.un?.abbreviation === 'un'}
            initialValue={currentManufacture?.yield?.toFixed(3)}
            handleValue={(value) => {
              setManufacture(currentManufacture);

              handleCardQuantity(
                index,
                currentManufacture?.quantity || 1,
                undefined,
                undefined,
                parseFloat(value)
              );
            }}
            fontSize="12px"
            fontWeight="normal"
            width="100%"
            height="28px"
          /> 
        :
          renderRecipeProductionInput(currentManufacture, index)
        }
      </div>
      
      <span className="dFlex" style={{ width: "20%" }}>
        {type === 'TRANSFORMATION'
          ? currentManufacture?.items?.[0]?.un?.abbreviation || ''
          : ''
        }
      </span>
    </div>
  )

  function handleRecipe(manufacture, open, cardIndex) {
    return (
      <tr>
        <td>
          <Collapse
            in={open}
            timeout="0"
            unmountOnExit
            className={open && 'expanded'}
          >
            &nbsp;
          </Collapse>
        </td>

        <td colSpan="8" className="tableColorHover">
          <Collapse
            in={open}
            timeout="0"
            unmountOnExit
            className={open && 'expanded'}
          >
            <table
              cellPadding="0"
              cellSpacing="0"
              border="0"
              style={{ width: "100%", border: 'solid 1px #1F2445', borderBottom: 'none', background: 'rgba(242, 243, 248, .8)' }}
              className="dropDownTable"
            >
              <tbody>
                {manufacture.items?.map((item, index) => {
                  const isLastElement = index === manufacture.items?.length - 1; 
                  const handleItemQuantity = (value, labelQuantity = '') => {
                    if (!value) {
                      return;
                    }

                    setManufacture(manufacture);

                    handleCardQuantity(
                      cardIndex,
                      manufacture.quantity,
                      index,
                      parseFloat(value),
                      undefined,
                      true,
                      labelQuantity
                    );
                  };

                  const exists = cards[cardIndex].items ? item : null;

                  if (
                    cards[cardIndex].items.length < manufacture.items.length &&
                    !exists
                  ) {
                    cards[cardIndex].items.push({
                      id: index,
                      quantity: item.quantity,
                      initialQuantity: item.quantity,
                      discard: { quantity: 0, reason: null }
                    });
                  }

                  const discardReason = item?.discard?.length
                    ? capitalize(
                        item?.discard[0]?.justification?.description
                      ) || ''
                    : capitalize(item?.discard?.justification?.description) ||
                      '';

                  return (
                    <>
                      <tr>
                        <td
                          className={`description itemProductionTableDescription ${index === 0 ? 'pt5' : 'pt3'} ${item?.main && 'productMain'}`}
                          style={{ paddingLeft: '22px', width: "28%" }}
                        >
                          <div className={`dFlex justifyBetween alignCenter ${isLastElement ? 'pb5' : 'pb3'}`}>
                            <div className="dFlex mr8" style={{ width: "73.5%" }}>
                              <div style={{ width: "88%" }}>
                                {ValidationLength(
                                  item.item, 
                                  28, 
                                  null, 
                                  null, 
                                  null, 
                                  null, 
                                  item?.itemProcess?.description ? `- ${item?.itemProcess?.description}` : '',
                                )}
                              </div>

                              <div className="ml10" style={{ width: "12%" }}>
                                <ImageWrapper
                                  width="30px"
                                  height="28px"
                                  url={item?.image}
                                  borderType={item?.type}
                                />
                              </div>
                            </div>
                            
                            <div className="dFlex alignCenter" style={{ width: "26.5%" }}>
                              <div className="dFlex" style={{ width: "70%" }} >
                                {manufacture.status !== 'WAITING' ? (
                                  <span>
                                    {item.quantity ? (
                                      parseFloat(item.quantity).toLocaleString('pt-br', {
                                        minimumFractionDigits: 3
                                      })
                                    ) : (
                                      '0,000'
                                    )}
                                  </span>
                                ) : (
                                  <>
                                    <InputNumber
                                      updateQuantityInitialValue={updateQuantityInitialValue}
                                      setModalStates={setModalStates}
                                      modalStates={modalStates}
                                      setUpdateValue={setUpdateValue}
                                      updateValue={updateValue}
                                      disabled={
                                        manufacture?.status !== 'WAITING' &&
                                        manufacture?.status !== 'START' &&
                                        manufacture?.status !== 'PAUSE'
                                      }
                                      initialValue={item.quantity}
                                      // validUnit={item?.un?.abbreviation === 'un'}
                                      handleValue={handleItemQuantity}
                                      fontSize="12px"
                                      fontWeight="normal"
                                      width="100%"
                                    />
                                    {/* {(item.initialQuantity
                                        ? item.quantity == 0
                                        : item.quantity * manufacture.quantity == 0
                                      ) &&
                                        <p className="requiredWarning">
                                          Não pode ser 0
                                        </p>
                                      } */}
                                  </>
                                )}
                              </div>

                              <span className="dFlex alignCenter pl5" style={{ width: "30%" }}>
                                {item?.un?.abbreviation}
                              </span>
                            </div>
                          </div>
                        </td>

                        <td className="description right" style={{ width: "7.2%", borderLeft: 'solid 1px rgba(208, 211, 226, .5)' }}>
                          <div className={`${isLastElement ? 'pb5' : 'pb3'} pr8 dFlex justifyEnd`}>
                            {`${
                              (item?.stock || 0)?.toLocaleString('pt-br', {
                                minimumFractionDigits: 3
                              })
                            }`}

                            <span className="ml3 dFlex alignCenter justifyCenter" style={{ width: "13%" }}>
                              {item?.un?.abbreviation || ''}
                            </span>
                          </div>
                        </td>

                        <td style={{ width: "6.1%", borderLeft: 'solid 1px rgba(208, 211, 226, .5)' }}>
                          <div className={`${isLastElement ? 'pb5' : 'pb3'}`}/>
                        </td>

                        <td style={{ width: "13.3%", borderLeft: 'solid 1px rgba(69, 74, 103, .5)' }}>
                          <div className={`${isLastElement ? 'pb5' : 'pb3'}`}/>
                        </td>

                        <td style={{ width: "9.2%", borderLeft: 'solid 1px rgba(208, 211, 226, .5)' }}>
                          <div className={`${isLastElement ? 'pb5' : 'pb3'}`}/>
                        </td>

                        <td style={{ width: "9.2%" }}>
                          <div className={`${isLastElement ? 'pb5' : 'pb3'}`}/>
                        </td>

                        <td className={`description right ${index === 0 ? 'pt5' : 'pt3'}`} style={{ width: "11%", borderLeft: 'solid 1px rgba(69, 74, 103, .5)' }}>
                          <div className={`dFlex alignCenter ${isLastElement ? 'pb5' : 'pb3'} pr8 pl12`}>
                            <Tooltip
                              title="Lançar Descarte de Produto"
                              placement="bottom"
                            >
                              <div>
                                <CurrencyTextField
                                  margin="none"
                                  variant="standard"
                                  size="small"
                                  currencySymbol=""
                                  minimumValue="0"
                                  maximumValue="999999999"
                                  outputFormat="string"
                                  decimalCharacter=","
                                  digitGroupSeparator="."
                                  decimalPlaces={3}
                                  decimalPlacesShownOnBlur={3}
                                  decimalPlacesShownOnFocus={3}
                                  disabled={
                                    manufacture?.status !== 'WAITING' &&
                                    manufacture?.status !== 'START' &&
                                    manufacture?.status !== 'PAUSE'
                                  }
                                  type="text"
                                  name="quantity"
                                  fullWidth
                                  value={
                                    item?.discard?.length
                                      ? item?.discard[0]?.quantity || ''
                                      : item?.discard?.quantity || ''
                                  }
                                  style={{ width: '100%' }}
                                  InputProps={{
                                    maxLength: 8,
                                    classes: { input: classes.input }
                                  }}
                                  onBlur={(_, value) => {
                                    handleItemDiscard(
                                      manufacture._id,
                                      index,
                                      value,
                                      discardReason,
                                      discardReason && value,
                                      item
                                    );
                                  }}
                                />
                              </div>
                            </Tooltip>

                            <span className="dFlex alignCenter justifyCenter ml3">
                              {item?.un?.abbreviation}
                            </span>
                          </div>
                        </td>

                        <td className={`description ${index === 0 ? 'pt5' : 'pt3'}`} style={{ paddingRight: '12px', width: "16%" }}>
                          <div className={`dFlex alignCenter ${isLastElement ? 'pb5' : 'pb3'}`}>
                            <Autocomplete
                              size="small"
                              classes={{
                                inputRoot: classes.inputRoot,
                                option: classes.option
                              }}
                              id="asynchronous-demo"
                              className="autocompleteSmall autocompletePtChild0 w100"
                              onChange={(option, value) => {
                                if (value) {
                                  handleItemDiscard(
                                    manufacture._id,
                                    index,
                                    item?.discard?.length
                                      ? item?.discard[0]?.quantity
                                      : item?.discard?.quantity,
                                    value,
                                    (item?.discard?.length
                                      ? item?.discard[0]?.quantity
                                      : item?.discard?.quantity) && value,
                                    item
                                  );
                                } else {
                                  handleItemDiscard(
                                    manufacture._id,
                                    index,
                                    item?.discard?.length
                                      ? item?.discard[0]?.quantity
                                      : item?.discard?.quantity,
                                    '',
                                    false,
                                    item
                                  );
                                }
                              }}
                              getOptionLabel={(option) => option.description}
                              options={optionsDiscard.map((option) => ({
                                ...option,
                                description: capitalize(option?.description)
                              }))}
                              value={
                                optionsDiscard
                                  .map((option) => ({
                                    ...option,
                                    description: capitalize(option?.description)
                                  }))
                                  .filter(
                                    (option) => option.description === discardReason
                                  )[0]
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  type="text"
                                  required
                                  style={{ width: '100%', height: 20 }}
                                  placeholder="Justifique..."
                                  InputLabelProps={{
                                    style: { fontFamily: 'Lato', fontSize: '12px' }
                                  }}
                                />
                              )}
                            />

                            {(item?.discard?.length
                              ? item?.discard[0]?.quantity || 0
                              : item?.discard?.quantity || 0) !== 0 &&
                              !discardReason &&
                              <Tooltip
                                title="Preencha este campo."
                                placement="bottom"
                                open={
                                  (item?.discard?.length
                                    ? item?.discard[0]?.quantity || 0
                                    : item?.discard?.quantity || 0) !== 0 &&
                                  !discardReason
                                }
                                disableFocusListener
                                disableHoverListener
                                disableTouchListener
                                arrow
                              >
                                <p className="m0 w100" />
                              </Tooltip>
                            }
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td colSpan="10" className={`${isLastElement && 'dNone'}`} style={{ padding: '0 12px 0 20px' }}>
                          <hr style={{ backgroundColor: 'rgba(69, 74, 103, 1)' }} />
                        </td>
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </table>
          </Collapse>
        </td>

        <td>
          <Collapse
            in={open}
            timeout="0"
            unmountOnExit
            className={open && 'expanded'}
          >
            &nbsp;
          </Collapse>
        </td>
      </tr>
    );
  }

  function handleTransformation(manufacture, open, cardIndex) {
    const cardYield =
      manufacture.type === 'recipe'
        ? manufacture.yield * (manufacture.quantity || 1)
        : (manufacture.quantity || 1) -
          (manufacture.quantity || 1) * (manufacture.loss / 100);
    const { loss } = manufacture;
    const total = manufacture.items.reduce(
      (total, value) =>
        value
          ? parseFloat(total) + parseFloat(value.quantity)
          : parseFloat(total),
      0
    );
    const cardQuantity = manufacture.quantity || 1;
    const cardLostTotal = parseFloat(cardQuantity) - parseFloat(total);
    const disableDurationDialog = checkIsBefore(moment(manufacture?.date).format('YYYY-MM-DD'), moment().format('YYYY-MM-DD'))
    
    return (
      <tr valign="center">
        <td>
          <Collapse
            in={open}
            timeout="0"
            unmountOnExit
            className={open && 'expanded'}
          >
            &nbsp;
          </Collapse>
        </td>

        <td colSpan="8" className="tableColorHover">
          <Collapse
            in={open}
            timeout="0"
            unmountOnExit
            className={open && 'expanded'}
          >
            <table
              cellPadding="0"
              cellSpacing="0"
              border="0"
              style={{ width: "100%", border: 'solid 1px #1F2445', borderBottom: 'none', background: 'rgba(242, 243, 248, .8)' }}
              className="dropDownTable"
            >
              <tbody>
                {manufacture.items.map((item, index) => {
                  const isLastElement = index === manufacture.items?.length - 1; 
                  const exists = cards[cardIndex].items ? item : null;

                  if (
                    cards[cardIndex].items.length < manufacture.items.length &&
                    !exists
                  ) {
                    cards[cardIndex].items.push({
                      id: index,
                      quantity: item.quantity,
                      initialQuantity: item.quantity,
                      discard: {
                        quantity: 0,
                        reason: {
                          id: null,
                          description: null
                        }
                      }
                    });
                  }

                  const discardReason = item?.discard?.length
                    ? capitalize(
                        item?.discard[0]?.justification?.description
                      ) || ''
                    : capitalize(item?.discard?.justification?.description) ||
                      '';

                  return (
                    <>
                      <tr>
                        <td
                          className={`description ${index === 0 ? 'pt5' : 'pt3'} ${item?.main && 'productMain'}`}
                          style={{ paddingLeft: '22px', width: "28%" }}
                        >
                          <div className="dFlex alignCenter justifyBetween">
                            <div className="dFlex alignCenter" style={{ width: "62.5%" }}>
                              {ValidationLength(item.item, 23)}

                              {item.suggestionYield
                              ? `(${
                                  item.suggestionYield.toLocaleString('pt-br', {
                                    minimumFractionDigits: 3
                                  }) + (item?.un?.abbreviation || '')
                                })`
                              : ''}
                            </div>
                            
                            <div className="ml10" style={{ width: "37.5%" }}>
                              <ImageWrapper
                                width="30px"
                                height="28px"
                                url={item?.image}
                                borderType={item?.type}
                              />
                            </div>
                          </div>
                        </td>

                        <td className="description right" style={{ width: "7.2%", borderLeft: 'solid 1px rgba(208, 211, 226, .5)' }}>
                          <div className={`${isLastElement ? 'pb5' : 'pb3'} pr8 dFlex justifyEnd`}>
                            {`${
                              (item?.stock || 0)?.toLocaleString('pt-br', {
                                minimumFractionDigits: 3
                              })
                            }`}

                            <span className="ml3 dFlex alignCenter justifyCenter" style={{ width: "13%" }}>
                              {item?.un?.abbreviation || ''}
                            </span>
                          </div>
                        </td>

                        <td className="description pr8" style={{ width: "6.1%", borderLeft: 'solid 1px rgba(208, 211, 226, .5)' }}>
                          <span
                            className={`lightBlue ${disableDurationDialog ? 'cursorAuto' : 'cursorPointer'} dBlock w100 textAlignRight`}
                            onClick={() => {
                              if (disableDurationDialog) return null
                              
                              getDuration(
                                item.id ? item.id : null,
                                item.stock,
                                item
                              );
                              handleClickOpenModal(manufacture);
                            }}
                          >
                            {`${item.duration ? item.duration : 0} ${
                              item.duration > 1 ? 'dias' : 'dia'
                            }`}
                          </span>
                        </td>

                        <td className="description p8" style={{ width: "13.3%", borderLeft: 'solid 1px rgba(69, 74, 103, .5)', verticalAlign: 'middle' }}>
                          <div className={`dFlex alignCenter ${isLastElement ? 'pb5' : 'pb0'}`}>
                            <div style={{ width: '31.5%' }}/>

                            <div className="dFlex alignCenter" style={{ width: "48.3%" }}>
                              {manufacture.status !== 'WAITING' ? (
                                item.quantity ? (
                                  parseFloat(item.quantity).toLocaleString(
                                    'pt-br',
                                    { minimumFractionDigits: 3 }
                                  )
                                ) : (
                                  '0,000'
                                )
                              ) : (
                                <CurrencyTextField
                                  margin="none"
                                  variant="standard"
                                  size="small"
                                  currencySymbol=""
                                  minimumValue="0"
                                  maximumValue="999999999"
                                  outputFormat="string"
                                  decimalCharacter=","
                                  digitGroupSeparator="."
                                  decimalPlaces={3}
                                  decimalPlacesShownOnBlur={3}
                                  decimalPlacesShownOnFocus={3}
                                  type="text"
                                  name="quantity"
                                  fullWidth
                                  disabled={
                                    manufacture?.status !== 'WAITING' &&
                                    manufacture?.status !== 'START' &&
                                    manufacture?.status !== 'PAUSE'
                                  }
                                  value={item.quantity}
                                  InputProps={{
                                    maxLength: 8,
                                    classes: { input: classes.input }
                                  }}
                                  onChange={(e) => {
                                    setManufacture(manufacture);
                                    setCurrentTransformationCardItems({
                                      ...manufacture,
                                      items: manufacture?.items?.map((el) => ({
                                        ...el,
                                        quantity: null
                                      }))
                                    });
                                    setUpdateValue(
                                      parseFloat(e.target.value).toFixed(3) !==
                                        parseFloat(item.quantity.toFixed(3))
                                    );
                                  }}
                                  onBlur={(e, value) => {
                                    if (
                                      !updateValue &&
                                      parseFloat(item.quantity).toFixed(3) ===
                                        parseFloat(value).toFixed(3)
                                    )
                                      return;

                                    handleCardQuantity(
                                      cardIndex,
                                      manufacture.quantity,
                                      index,
                                      parseFloat(value),
                                      undefined
                                    );
                                    setUpdateValue(false);
                                  }}
                                />
                              )}
                            </div>
                            
                            <span className="ml3 dFlex alignCenter" style={{ width: "20.2%" }}> 
                              {item?.un?.abbreviation}
                            </span>
                          </div>
                        </td>

                        <td style={{ width: "9.2%", borderLeft: 'solid 1px rgba(208, 211, 226, .5)' }}>
                          &nbsp;
                        </td>

                        <td className="description right" style={{ width: "9.2%" }}>
                          <Link
                            onClick={() => {
                              getDuration(
                                item.id ? item.id : null,
                                (item.stock || 0) + item.quantity,
                                item,
                                'transformationItem',
                                true
                              );
                              handleClickOpenModal(manufacture);
                            }}
                          >
                            {item.newDuration && item.newDuration > 1
                              ? `${item.newDuration} dias`
                              : item.newDuration
                              ? `${item.newDuration} dia`
                              : null
                            }
                          </Link>
                        </td>

                        <td className={`description right ${index === 0 ? 'pt5' : 'pt3'}`} style={{ width: "11%", borderLeft: 'solid 1px rgba(69, 74, 103, .5)' }}>
                          <div className={`dFlex alignCenter ${isLastElement ? 'pb5' : 'pb3'} pr8 pl12`}>
                            <Tooltip
                              title="Lançar Descarte de Produto"
                              placement="bottom"
                            >
                              <div>
                                <CurrencyTextField
                                  margin="none"
                                  variant="standard"
                                  size="small"
                                  currencySymbol=""
                                  minimumValue="0"
                                  maximumValue="999999999"
                                  outputFormat="string"
                                  decimalCharacter=","
                                  digitGroupSeparator="."
                                  decimalPlaces={3}
                                  decimalPlacesShownOnBlur={3}
                                  decimalPlacesShownOnFocus={3}
                                  type="text"
                                  name="quantity"
                                  fullWidth
                                  value={
                                    item?.discard?.length
                                      ? item?.discard[0]?.quantity || ''
                                      : item?.discard?.quantity || ''
                                  }
                                  style={{ width: '100%' }}
                                  InputProps={{
                                    maxLength: 8,
                                    classes: { input: classes.input }
                                  }}
                                  onBlur={(_, value) => {
                                    handleItemDiscard(
                                      manufacture._id,
                                      index,
                                      value,
                                      discardReason,
                                      discardReason && value,
                                      item
                                    );
                                  }}
                                />
                              </div>
                            </Tooltip>

                            <span className="dFlex alignCenter justifyCenter ml3">
                              {item?.un?.abbreviation}
                            </span>
                          </div>
                        </td>

                        <td className={`description ${index === 0 ? 'pt5' : 'pt3'}`} style={{ paddingRight: '12px', width: "16%" }}>
                          <div className={`dFlex alignCenter ${isLastElement ? 'pb5' : 'pb3'}`}>
                            <Autocomplete
                              size="small"
                              classes={{
                                inputRoot: classes.inputRoot,
                                option: classes.option
                              }}
                              id="asynchronous-demo"
                              className="autocompleteSmall autocompletePtChild0 w100"
                              onChange={(option, value) => {
                                if (value) {
                                  handleItemDiscard(
                                    manufacture._id,
                                    index,
                                    item?.discard?.length
                                      ? item?.discard[0]?.quantity
                                      : item?.discard?.quantity,
                                    value,
                                    (item?.discard?.length
                                      ? item?.discard[0]?.quantity
                                      : item?.discard?.quantity) && value,
                                    item
                                  );
                                } else {
                                  handleItemDiscard(
                                    manufacture._id,
                                    index,
                                    item?.discard?.length
                                      ? item?.discard[0]?.quantity
                                      : item?.discard?.quantity,
                                    '',
                                    false,
                                    item
                                  );
                                }
                              }}
                              getOptionLabel={(option) => option.description}
                              options={optionsDiscard.map((option) => ({
                                ...option,
                                description: capitalize(option?.description)
                              }))}
                              value={
                                optionsDiscard
                                  .map((option) => ({
                                    ...option,
                                    description: capitalize(option?.description)
                                  }))
                                  .filter(
                                    (option) =>
                                      option.description === discardReason
                                  )[0]
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  type="text"
                                  required
                                  margin="none"
                                  style={{ width: '100%', height: 20 }}
                                  placeholder="Justifique..."
                                  InputLabelProps={{
                                    style: {
                                      fontFamily: 'Lato',
                                      fontSize: '12px'
                                    }
                                  }}
                                />
                              )}
                            />

                            {(item?.discard?.length
                              ? item?.discard[0]?.quantity || 0
                              : item?.discard?.quantity || 0) !== 0 &&
                            !discardReason &&
                              <Tooltip
                                title="Preencha este campo."
                                open={
                                  (item?.discard?.length
                                    ? item?.discard[0]?.quantity || 0
                                    : item?.discard?.quantity || 0) !== 0 &&
                                  !discardReason
                                }
                                disableFocusListener
                                disableHoverListener
                                disableTouchListener
                                placement="bottom"
                                arrow
                              >
                                <p className="m0 w100" />
                              </Tooltip>
                            }
                          </div>
                        </td>

                        {/* <td
                          className="description"
                          colSpan="3"
                          style={{
                            color:
                              parseFloat(100 * cardLostTotal) /
                                parseFloat(cardQuantity) >
                              parseFloat(loss)
                                ? 'red'
                                : 'black'
                          }}
                        >
                          {total !== 0 &&
                            `${(
                              parseFloat(100 * cardLostTotal) /
                              parseFloat(cardQuantity)
                            ).toLocaleString('pt-br', {
                              minimumFractionDigits: 3
                            })}%`
                          }
                        </td> */}
                      </tr>

                      <tr>
                        <td colSpan="8">
                          <hr />
                        </td>
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </table>
          </Collapse>
        </td>

        <td>
          <Collapse
            in={open}
            timeout="0"
            unmountOnExit
            className={open && 'expanded'}
          >
            &nbsp;
          </Collapse>
        </td>
      </tr>
    );
  }

  if (!manufactures) {
    return (
      <>
        <tr>
          <td colSpan="8" valign="center" align="center">
            {loading ? (
              <Typography
                style={{
                  verticalAlign: 'center',
                  margin: '10px 0'
                }}
              >
                <LinearProgress variant="query" />
              </Typography>
            ) : (
              <Typography
                style={{
                  verticalAlign: 'center',
                  margin: '10px 0'
                }}
              >
                Não há nada para ser exibido
              </Typography>
            )}
          </td>
        </tr>

        <tr>
          <td colSpan="8">
            <hr />
          </td>
        </tr>
      </>
    );
  }
  
  return manufactures.map((manufacture, index) => {
    const type = manufacture?.type?.toLowerCase();
    const status = manufacture?.status?.toLowerCase();
    const lossManufactured = handleFields(manufacture).lossManufactured;
    const lossManufacturedMonetary = handleFields(manufacture).lossManufacturedMonetary;
    const hideManufacturedAndDiffInfos = handleFields(manufacture).manufactured === null || handleFields(manufacture).manufactured === '' || status !== 'finish'

    const isIncluded = manufacture?.status?.toUpperCase() === 'WAITING'
    const isToProduce = manufacture?.statusAction?.toUpperCase() === 'TO_PRODUCE'
    const isCanceled = manufacture?.status?.toUpperCase() === 'CANCELED'
    const isFinished = manufacture?.status?.toUpperCase() === 'FINISH'
    const actionIncluded = manufacture?.actions?.find(action => action?.type?.toUpperCase() === 'TO_PRODUCE') || manufacture?.actions?.find(action => action?.type?.toUpperCase() === 'INCLUDE') || null
    const actionToProduce = isToProduce ? manufacture?.actions?.find(action => action?.type?.toUpperCase() === 'TO_PRODUCE') : null
    const actionCanceled = isCanceled ? manufacture?.actions?.find(action => action?.type?.toUpperCase() === 'CANCELED') : null
    const actionFinished = isFinished ? manufacture?.actions?.find(action => action?.type?.toUpperCase() === 'FINISH') : null
    const actionStarted = manufacture?.actions?.find(action => action?.type?.toUpperCase() === 'START') || null

    const inclusion = manufacture?.dateAction ? moment(manufacture?.dateAction) : moment(manufacture?.created_at).add(3, 'hour')
    const hourInclusion = moment(inclusion).format('LT');
    const dateInclusion = moment(inclusion).format('DD/MM/YYYY');
    const userInclusion = actionToProduce ? actionToProduce?.user : manufacture?.actions?.find(action => action?.type?.toUpperCase() === 'INCLUDE').user || ''

    let hourStart = actionStarted ? moment(actionStarted.date).format('LT') : null

    if(actionStarted && moment(actionStarted?.date).isBefore(moment(inclusion), 'hour'))
      hourStart = moment(actionStarted.date).add(3, 'hour').format('LT')

    const dateStart = actionStarted ? moment(actionStarted.date).format('DD/MM/YYYY') : null
    const userStart = actionStarted ? actionStarted?.user : null

    const hourFinished = isFinished && actionFinished ? moment(actionFinished.date).format('LT') : null
    const dateFinished = isFinished && actionFinished ? moment(actionFinished.date).format('DD/MM/YYYY') : null
    const userFinished = isFinished && actionFinished ? actionFinished?.user : null

    const hourCanceled = isCanceled && actionCanceled ? moment(actionCanceled.date).format('LT') : null
    const dateCanceled = isCanceled && actionCanceled ? moment(actionCanceled.date).format('DD/MM/YYYY') : null
    const userCanceled = isCanceled && actionCanceled ? actionCanceled?.user : null

    let statusColor = '';
    let statusBackground = '';
    let actionButton = null;
    let onClick = null;
    let tooltip = '';
    let duration = ''
    let diffHours = ''
    let diffMinutes = ''
    let action = null;
    let pluralText = '';
    let singularText = '';
    let statusText;
    const exists = cards ? cards.find((x) => x?.id === index) : null;
    const disableDurationDialog = checkIsBefore(moment(manufacture?.date).format('YYYY-MM-DD'), moment().format('YYYY-MM-DD'))

    if (cards && cards.length < manufactures.length && !exists) {
      cards
        .sort((a, b) => (a.status > b.status ? -1 : 1))
        .push({
          id: index,
          open: false,
          quantity: manufacture.quantity,
          type,
          startTime: manufacture.actions.reverse().find((x) => x.type === 'START')
            ? manufacture.actions.reverse().find((x) => x.type === 'START').date
            : null,
          pauseTime: manufacture.actions.reverse().find((x) => x.type === 'PAUSE')
            ? manufacture.actions.reverse().find((x) => x.type === 'PAUSE').date
            : null,
          finishTime: manufacture.actions.reverse().find((x) => x.type === 'FINISH')
            ? manufacture.actions.reverse().find((x) => x.type === 'FINISH').date
            : null,
          manufactured: manufacture.manufactured,
          totalLoss: null,
          loss: manufacture.loss ? manufacture.loss : 0,
          totalYield:
            type === 'recipe'
              ? manufacture.yield * (manufacture.quantity || 1)
              : (manufacture.quantity || 1) - (manufacture.quantity || 1) * (manufacture.loss / 100),
          initialYield:
            type === 'recipe'
              ? manufacture.yield * 1
              : 1 - 1 * (manufacture.loss / 100),
          items: []
        });
    }

    switch (type) {
      case 'recipe':
        pluralText = 'Ingredientes';
        singularText = 'Ingrediente';
        break;
      case 'transformation':
        pluralText = 'Produtos';
        singularText = 'Produto';
        break;
      default:
        pluralText = 'Itens';
        singularText = 'Item';
        break;
    }

    const startTime = manufacture.actions.length
      ? manufacture.actions
          .filter((item) => item.type === 'START')
          .sort((a, b) => (a.date < b.date ? 1 : -1))[0]?.date || new Date()
      : new Date();
    const pauseTime = manufacture.actions.length
      ? manufacture.actions
          .filter((item) => item.type === 'PAUSE')
          .sort((a, b) => (a.date < b.date ? 1 : -1))[0]?.date || new Date()
      : new Date();
    const totalYield =
      cards && cards.find((item, cardIndex) => cardIndex === index)
        ? cards.find((item, cardIndex) => cardIndex === index).totalYield
        : manufacture.yield;
    const quantity =
      cards && cards.find((item, cardIndex) => cardIndex === index)
        ? cards.find((item, cardIndex) => cardIndex === index).quantity
          ? cards.find((item, cardIndex) => cardIndex === index).quantity
          : 1
        : 1;

    const handleActionButton = (status, index, quantity, fakeReturn = false) => {
      switch (status) {
        case 'canceled':
          const dateFormatted = manufacture?.actions?.find(
            (action) => action?.type === 'CANCELED'
          )?.date
            ? new Date(
                manufacture?.actions?.find(
                  (action) => action?.type === 'CANCELED'
                )?.date
              )
            : new Date();
          tooltip = manufacture?.cancellationReason;
          statusText = 'Cancelado';
          actionButton = (
            <img src={CancelProduction} />
          );
          statusColor = '#660008';
          statusBackground = '#F7D4D7';
          statusText = (
            <p>
              <b>Cancelado</b>
              {` às ${format(dateFormatted, 'kk:mm')}
                    ${
                      manufacture.actions.find(
                        (action) => action.type === 'CANCELED'
                      )
                        ? `(${
                            manufacture.actions.find(
                              (action) => action.type === 'CANCELED'
                            ).user
                          })`
                        : ''
                    }`}
            </p>
          );
          break;
        case 'waiting':
          if (quantity) {
            statusText = (
              <p>
                <b>Iniciar</b>
              </p>
            );
            statusColor = '#1F2445';
            statusBackground = '#E6E7F0';
            actionButton = <img src={PlayIcon} />;
            action = 'start';
            onClick = () => {
              setManufacture(manufacture);
              setCurrentTransformationCardItems({
                ...manufacture,
                items: manufacture?.items?.map((el) => ({
                  ...el,
                  quantity: null
                }))
              });

              setTimeout(() => {
                handleUpdate(manufacture, 'START');
              }, 100);
            };
          } else {
            statusText = (
              <p>
                <b>Iniciar</b>
              </p>
            );
            statusColor = '#1F2445';
            statusBackground = '#E6E7F0';
            actionButton = <img src={PlayIcon} />;
            action = 'start';
            onClick = (e) => {
              handlePopoverClick(e);
              setManufacture(manufacture);
              setCurrentTransformationCardItems({
                ...manufacture,
                items: manufacture?.items?.map((el) => ({
                  ...el,
                  quantity: null
                }))
              });
              setCardDate(manufacture.date);
              setPopoverData(
                <Grid
                  container
                  xs={12}
                  justify="center"
                  spacing={1}
                  style={{ padding: '10px', margin: 0 }}
                >
                  <Grid item xs={12}>
                    <Typography className="bold textAlignCenter">
                      Necessário preencher a quantidade
                    </Typography>
                  </Grid>
                </Grid>
              );
            };
          }

          break;
        case 'start':
          statusText = (
            <p>
              <b>Produzindo...</b>
              {` Iniciado às ${hourStart} ${
                manufacture.actions.find((action) => action.type === 'START')
                  ? `(${
                      manufacture.actions.find(
                        (action) => action.type === 'START'
                      ).user
                    })`
                  : ''
              }`}
            </p>
          );
          statusColor = '#0E1337';
          statusBackground = '#DADDF3';
          actionButton = <img src={ProductionIcon} />;
          manufacture.manufactured = null;
          onClick = (e) => {
            handlePopoverClick(e);
            setManufacture(manufacture);
            setCurrentTransformationCardItems({
              ...manufacture,
              items: manufacture?.items?.map((el) => ({
                ...el,
                quantity: null
              }))
            });
            setCardDate(manufacture.date);
            type === 'recipe'
              ? setPopoverData('start')
              : setPopoverData('finishTransformation');
          };
          break;
        case 'pause':
          statusText = (
            <p>
              <b>Pausado...</b>
              {` às ${format(new Date(pauseTime), 'HH:mm')} ${
                manufacture.actions.find((action) => action.type === 'PAUSE')
                  ? `(${
                      manufacture.actions.find(
                        (action) => action.type === 'PAUSE'
                      ).user
                    })`
                  : ''
              }`}
            </p>
          );
          statusColor = '#664C00';
          statusBackground = '#FFF2CC';
          actionButton = (
            <img src={PauseProductionIcon} />
          );
          onClick = (e) => {
            handlePopoverClick(e);
            setManufacture(manufacture);
            setCurrentTransformationCardItems({
              ...manufacture,
              items: manufacture?.items?.map((el) => ({
                ...el,
                quantity: null
              }))
            });
            setCardDate(manufacture.date);
            setPopoverData('pause');
          };
          break;
        case 'finish':
          let textStart = ''
          let textFinish = ''
          const dateStartedFormatted = moment(actionStarted?.date || manufacture?.created_at).format('DD/MM')

          if (actionStarted || manufacture?.created_at) {
            textStart = hourStart || hourInclusion

            if (dateStart !== dateFinished) {
              textStart = `${dateStartedFormatted} ${hourStart || hourInclusion}`
            }
          } 

          if (actionFinished) {
            textFinish = ` às ${hourFinished} (${actionFinished.user})`
            moment(dateStartedFormatted).diff(moment(), 'hours')
            if (dateStart !== dateFinished) {
              const dateFinishedFormatted = moment(actionFinished.date).format('DD/MM')
              textFinish = ` às ${dateFinishedFormatted} ${hourFinished} (${actionFinished.user})`
            }
          }

          duration = moment.duration(moment(actionFinished?.date).diff(actionStarted?.date || manufacture?.created_at));
          diffHours = parseInt(duration.asHours());
          diffMinutes = parseInt(duration.asMinutes()) % 60;

          statusText = (
            <p>
              <b>Finalizado :)</b>

              {textStart}

              {textFinish}
            </p>
          );
          statusColor = '#004D33';
          statusBackground = '#D2F9D6';
          actionButton = (
            <img src={CheckedCircleIcon} />
          );
          break;
      }

      if (fakeReturn) return;

      return (
        <Tooltip
          title={
            <p className="mb0" style={{ padding: '2px 6px' }}>
              {tooltip}
            </p>
          }
          aria-label="filters"
          placement="right-start"
          disableHoverListener={!tooltip}
        >
          <IconButton
            edge="start"
            disabled={status === 'finish'}
            size="small"
            color="inherit"
            onClick={onClick}
            aria-label="close"
            style={{
              outline: '0',
              cursor: status === 'canceled' && 'auto'
            }}
          >
            {actionButton}
          </IconButton>
        </Tooltip>
      );
    };

    const handleQuantityField = (status, initialValue, type) => {
      if (status?.toLowerCase() === 'waiting') {
        return (
          <CurrencyTextField
            margin="none"
            variant="standard"
            size="small"
            currencySymbol=""
            minimumValue="0"
            maximumValue="999999999"
            outputFormat="string"
            decimalCharacter=","
            digitGroupSeparator="."
            decimalPlaces={3}
            decimalPlacesShownOnBlur={3}
            placeholder="Qtd"
            type="text"
            name="quantity"
            fullWidth
            value={initialValue || null}
            textAlign="center"
            align="center"
            style={{
              align: 'center',
              textAlign: 'right',
              marginTop: '5.95px'
            }}
            InputProps={{
              maxLength: 8,
              classes: { input: classes.input },
              style: { textAlign: 'right' }
            }}
            onChange={(e) => {
              setManufacture(manufacture);
              setUpdateValue(
                parseFloat(e.target.value) !==
                  parseFloat(initialValue.toFixed(3))
              );
            }}
            onBlur={(e, value) => {
              if (
                !updateValue &&
                parseFloat(initialValue.toFixed(3)) === parseFloat(value)
              )
                return;

              if (type === 'yield') {
                handleCardQuantity(
                  index,
                  manufacture.quantity || 1,
                  undefined,
                  undefined,
                  parseFloat(value)
                );
              } else {
                handleCardQuantity(
                  index,
                  parseFloat(value),
                  undefined,
                  undefined,
                  undefined
                );
              }

              setUpdateValue(false);
            }}
          />
        );
      }
      return initialValue
        ? parseFloat(initialValue).toLocaleString('pt-br', {
            minimumFractionDigits: 3
          })
        : '0,000';
    };

    const handleLossMonetary = () => {
      if (
        lossManufactured === null ||
        lossManufactured === '' ||
        status !== 'finish'
      ) {
        return `-`;
      }

      if (!diffViewMonetary) {
        return lossManufactured || '-';
      }

      return lossManufacturedMonetary || '-';
    };

    const handlePreparationClick = (url) => {
      const encodedUrl = btoa(url);
      localStorage.setItem('preparationUrl', encodedUrl);
    };

    return (
      <>
        <tr valign="center" className={`${!manufacture?.visible && 'dNone'}`}>
          <td className="description center">
            &nbsp;
          </td>

          <td className="description pr8">
            <List component="nav" className="list">
              <ListItem className="listItem p0">
                <div className="defaultBox w100">
                  <div className="upperLine">
                    {renderCardDescriptionInfos(manufacture, type, handlePreparationClick)}
                  </div>

                  <div className="bottomLine">
                    {manufacture?.type === "TRANSFORMATION" 
                      ? renderItemTransformationInfos(manufacture, manufacture?.itemTransformation, index)
                      : ''
                    }
                  </div>
                </div>
              </ListItem>
            </List>
          </td>

          <td className="description right p8" style={{ borderLeft: 'solid 1px #D0D3E2' }}>
            <div className="defaultBox">
              <div className="upperLine">
                {manufacture?.type === "TRANSFORMATION" 
                  ? ''
                  : renderCardStock(manufacture)
                }
              </div>

              <div className="bottomLine">
                {manufacture?.type === "TRANSFORMATION" 
                  ? renderCardStock(manufacture, manufacture?.itemTransformation)
                  : ''
                }
              </div>
            </div>
          </td>

          <td className="description right p8" style={{ borderLeft: 'solid 1px #D0D3E2' }}>
            <div className="defaultBox">
              <div className="upperLine">
                {manufacture?.type === "TRANSFORMATION" 
                  ? ''
                  : renderCardDuration(manufacture, disableDurationDialog)
                }
              </div>

              <div className="bottomLine">
                 {manufacture?.type === "TRANSFORMATION" 
                  ? renderCardDuration(manufacture, disableDurationDialog, manufacture?.itemTransformation)
                  : ''
                }
              </div>
            </div>
          </td>

          <td
            className="description right p8"
            style={{ background: 'rgba(117, 131, 240, .2)', borderLeft: 'solid 1px #454A67' }}
          >
            <div className="defaultBox">
              <div className="upperLine">
                {manufacture?.type === "TRANSFORMATION" 
                  ? ''
                  : renderCardProduction(manufacture, 'RECIPE', index)
                }
              </div>

              <div className="bottomLine">
                 {manufacture?.type === "TRANSFORMATION" 
                  ? renderCardProduction(manufacture, "TRANSFORMATION", index)
                  : ''
                }
              </div>
            </div>
          </td>

          <td className="description right p8 font-weight-bold" style={{ background: 'rgba(117, 131, 240, .2)', borderLeft: 'solid 1px #D0D3E2' }}>
            <div className="defaultBox">
              <div className="upperLine justifyEnd alignCenter">
                {hideManufacturedAndDiffInfos
                  ? `-`
                  : handleFields(manufacture).manufactured || '-'
                }
              </div>

              <div className="bottomLine"/>
            </div>
          </td>

          <td className="description right p8" style={{ background: 'rgba(117, 131, 240, .2)' }}>
            <div className="defaultBox">
              <div className="upperLine justifyEnd alignCenter">
                {hideManufacturedAndDiffInfos
                  ? `-`
                  : 
                    <div
                      className="dFlex alignCenter justifyBetween bold"
                      style={{
                        height: 20,
                        lineHeight: 2.15,
                        width: '100%',
                        margin: '0 0 0 auto',
                        borderRadius: 10,
                        boxShadow: '0px 4px 4px 0px #00000040',
                        color: handleFields(manufacture)?.lossManufacturedStyle?.textColor,
                        background: handleFields(manufacture)?.lossManufacturedStyle?.bgColor
                      }}
                    >
                      {handleLossMonetary() ?
                        <>
                          <div
                            className="dFlex alignCenter justifyEnd pr8"
                            style={{
                              width: '70%',
                              fontWeight: 700,
                              fontSize: '12px',
                              color: handleFields(manufacture)?.lossManufacturedStyle?.textColor,
                            }}
                          >
                            {handleLossMonetary()}
                          </div>

                          <PercentBox background={handleFields(manufacture)?.lossManufacturedStyle?.background}>
                            {handleFields(manufacture).diffPercentage && status === 'finish' 
                              ? handleFields(manufacture).formattedDiffPercentage.replace('-', '') 
                              : '-'
                            }

                            {handleFields(manufacture).diffPercentage && status === 'finish' ? '%' : ''}
                          </PercentBox>
                        </>
                        : '-'
                      }
                    </div>
                }
              </div>

              <div className="bottomLine"/>
            </div>
          </td>

          <td
            className="description pl8"
            style={{ borderLeft: 'solid 1px #454A67' }}
          >
            <div className="dFlex w100 mb5">
              {handleActionButton(status, index, quantity, true)}

              <Tooltip
                title={
                  <TooltipFinish>
                    <b>INCLUSÃO DA FICHA</b>
                    <p>Data: {dateInclusion}</p>
                    <p>Horário: {hourInclusion}hs</p>
                    <p>Usuário: {userInclusion}</p>

                    {actionStarted && <br />}
                    {actionStarted && <b>INÍCIO DA PRODUÇÃO</b>}
                    {actionStarted && <p>Data: {dateStart}</p>}
                    {actionStarted && <p>Horário: {hourStart}hs</p>}
                    {actionStarted && <p>Usuário: {userStart}</p>}

                    {isFinished && actionFinished && <br />}
                    {isFinished && actionFinished && <b>FINALIZAÇÃO</b>}
                    {isFinished && actionFinished && <p>Data: {dateFinished}</p>}
                    {isFinished && actionFinished && <p>Horário: {hourFinished}hs</p>}
                    {isFinished && actionFinished && <p>Usuário: {userFinished}</p>}

                    {isCanceled && actionCanceled && <br />}
                    {isCanceled && actionCanceled && <b>CANCELAMENTO</b>}
                    {isCanceled && actionCanceled && <p>Data: {dateCanceled}</p>}
                    {isCanceled && actionCanceled && <p>Horário: {hourCanceled}hs</p>}
                    {isCanceled && actionCanceled && <p>Usuário: {userCanceled}</p>}
                  </TooltipFinish>
                }
                placement='right-start'
              >
                <ButtonStatus
                  className="productionButtonStatus"
                  color={statusColor}
                  background={statusBackground}
                >
                  {handleActionButton(status, index, quantity)}

                  {statusText}

                  {isFinished &&
                    <div className={`finishedTimeCircle ${diffHours || diffMinutes ? 'positiveTime' : 'noTime'}`}>
                      <span>
                        {`
                          ${diffHours > 0 && diffHours}
                          ${diffHours > 0 && diffMinutes > 0 && ':'}
                          ${diffMinutes > 0 && diffMinutes}
                        `}
                      </span>

                      <span className="mt8">
                        {diffHours > 0 
                        ? diffHours === 1 
                          ? 'h'
                          : 'hs'
                        : 'min'
                        }
                      </span>
                    </div>
                  }
                </ButtonStatus>
              </Tooltip>
            </div>
          </td>

          <td className="description center p8">
            <div className="dFlex justifyCenter alignCenter flexColumn">
              <div style={{ fontSize: 10, lineHeight: 1.2 }}>
                {moment(manufacture.date)?.format('DD/MM/YY')}
              </div>
              
              <div className="dFlex justifyBetween">
                <Tooltip
                  disableHoverListener={status === 'finish'}
                  title="Cancelar produção"
                >
                  <div>
                    <IconButton
                      aria-label="delete"
                      disabled={
                        manufacture.status ===
                        'CANCELED' /* || manufacture.status === "FINISH" */
                      }
                    onClick={() => {
                      handleDialogClickOpen();
                      setDeleteData(manufacture);
                      }}
                    style={{ padding: 0, outline: 0 }}
                    >
                      <img src={DeleteIcon} style={{ width: '16px' }} alt="Delete" />
                    </IconButton>
                  </div>
                </Tooltip>

                {/* <Tooltip
                  disableHoverListener={status === 'finish'}
                  title="Finalize a produção para imprimir a etiqueta"
                >
                  <div>
                    <PrintLabel
                      title={
                        stores?.flat().length > 1 ? companyActive : companyGroup
                      }
                      item={manufacture}
                      enabled={status === 'finish'}
                      iconSize="20px"
                    />
                  </div>
                </Tooltip> */}
              </div>
            </div>
          </td>

          <td className="description center">
            &nbsp;
          </td>
        </tr>

        {
          type === 'recipe' &&
          cards &&
          cards.find((item, cardIndex) => cardIndex === index)
            ? handleRecipe(
                manufacture,
                manufactures.find((el) => el._id === manufacture._id)?.open,
                index
              )
            : type === 'transformation' && cards?.find((item, cardIndex) => cardIndex === index)
              ? handleTransformation(
                  manufacture,
                  manufactures.find((el) => el._id === manufacture._id)?.open,
                  index
                )
              : null
        }

        <tr>
          <td colSpan="10" className={`${!manufacture?.visible && 'dNone'}`}>
            <hr style={{ backgroundColor: '#666770' }} />
          </td>
        </tr>
      </>
    );
  });
}

export default DesktopRows;
