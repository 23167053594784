/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unstable-nested-components */
import React, { useState, useEffect, useMemo, useCallback } from 'react';

import DateFnsUtils from '@date-io/date-fns';
import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  LinearProgress,
  Paper,
  Popover,
  Snackbar,
  Tooltip,
  Typography
} from '@material-ui/core';
import { default as MaterialButton } from '@material-ui/core/Button';
import { ChevronRight, Delete, ExpandMore } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';
import axios from 'axios';
import { format } from 'date-fns';
import ptLocale from 'date-fns/locale/pt-BR';
import moment, { now } from 'moment';
import Draggable from 'react-draggable';
import { connect, useDispatch } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import Button from 'components/Button/button';
import CommonAlert from 'components/CommonAlert/CommonAlert';
import Discard from 'components/Dialogs/Discard';
import InvoicePerItem from 'components/Dialogs/InvoicePerItem';
import ProcessNonIntegratedSales from 'components/Dialogs/ProcessNonIntegratedSales';
import PageTitleAndFilter from 'components/PageTitleAndFilter';
import MultipleCheckbox from 'components/PageTitleAndFilter/MultipleCheckbox/MultipleCheckbox';
import RadioGroup from 'components/RadioGroup/radioGroup';
import Register from 'components/Register/index';
import TitleHead from 'components/TitleHead';
import Layout from 'containers/layouts/layout';
import ConfigColumnsIcon from 'images/icons/columns/collumnsWhiteOutline.svg';
import DownloadIconExport from 'images/icons/downloadUpload/download.svg';
import DownloadIcon from 'images/icons/downloadUpload/upload.svg';
import UploadIcon from 'images/icons/downloadUpload/uploadBgBlueRounded.svg';
import ProcessNonIntegratedIcon from 'images/icons/objects/currencies/dolarBgBlueRounded.svg';
import ItemSalesIcon from 'images/icons/objects/currencies/dolarGrayOutline.svg';
import AddIcon from 'images/icons/signals/addsAndCreates/add.svg';
import CloseIcon from 'images/icons/signals/closesAndCancels/close.svg';
import DeleteItemIcon from 'images/icons/signals/closesAndCancels/closeNavyGrayOutline.svg';
import TrashIcon from 'images/icons/signals/removesAndDeletes/trashIconSecondary.svg';
import TrashLightGrayIcon from 'images/icons/signals/removesAndDeletes/trashLightGrayOutline.svg';
import { OffSpan } from 'pages/admin/stores/styles';
import { InputFile } from 'pages/quotesUpload/style';
import * as MenuActions from 'store/actions/menu';
import { handleMenusColumns } from 'store/reducers/userConfigs';
import { Container, GlobalStyle } from 'styles/general';
import convertToBase64 from 'utils/convertToBase64';
import Environment from 'utils/environments';
import {
  getFilterOptions,
  handleFilterOptions,
  handleMultipleFilters
} from 'utils/handleFilterActions';
import { ShortenStringTooltip } from 'utils/shortenString';
import ValidationLength from 'utils/utils';

import MenuTable from './MenuTable';

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

// Define a custom filter filter function!
function filterGreaterThan(rows, id, filterValue) {
  return rows.filter((row) => {
    const rowValue = row.values[id];
    return rowValue >= filterValue;
  });
}

filterGreaterThan.autoRemove = (val) => typeof val !== 'number';

function App({ userInfo, setMenu, menuInfo, cardsInfo }) {
  const dispatch = useDispatch();
  const actualDate = new Date();
  const dateLessSeven = new Date().setDate(actualDate.getDate() - 7);
  const dateLessOne = new Date().setDate(new Date().getDate() - 1);
  const userLocal = JSON.parse(localStorage.getItem('companies'));
  const storeSegment = userInfo?.companiesActive?.segment;

  const [loading, setLoading] = useState(true);
  const [toastProps, setToastProps] = useState({});
  const [loadingInvoicesSequence, setLoadingInvoicesSequence] = useState(false);
  const [salesInvoicesSequenceInfos, setSalesInvoicesSequenceInfos] = useState();
  const [loadingDiscard, setLoadingDiscard] = useState(false);
  const [cardapio, setCardapio] = useState([]);
  const [currentDiscardReason, setCurrentDiscardReason] = useState('');
  const [date, setDate] = useState(new Date());
  const { id: paramsID } = useParams();
  const [anchorEl, setAnchorEl] = useState(null);
  const storedUserMenusConfig = localStorage.getItem('menusConfigs')?.length
    ? localStorage.getItem('menusConfigs')?.split(',')
    : [];
  const [showColumns, setShowColumns] = useState(storedUserMenusConfig || []);
  const popoverOpen = Boolean(anchorEl);
  const queryParams = useLocation()?.search;
  const initialDateFilterDefaultValue = queryParams?.includes('startAt')
    ? queryParams?.split('&')[0].split('=')[1]
    : '';
  const endDateFilterDefaultValue = queryParams?.includes('endAt')
    ? queryParams?.split('&')[1].split('=')[1]
    : '';

  const dateInitial = moment().subtract(3, 'months')
  const dateFinal = moment().add(3, 'months')

  const DEFAULT_FILTERS = useMemo(
    () => [
      {
        param: 'dateStart',
        value:
          initialDateFilterDefaultValue || format(dateLessSeven, 'yyyy-MM-dd'),
        urlParam: `dateStart=${initialDateFilterDefaultValue || format(dateLessSeven, 'yyyy-MM-dd')
          }`
      },
      {
        param: 'dateEnd',
        value: endDateFilterDefaultValue || format(dateLessOne, 'yyyy-MM-dd'),
        urlParam: `dateEnd=${endDateFilterDefaultValue || format(dateLessOne, 'yyyy-MM-dd')
          }`
      }
    ],
    [endDateFilterDefaultValue, initialDateFilterDefaultValue]
  );
  const [getId, setId] = useState(paramsID);
  const [cardsId, setCardsId] = useState();
  const [valueType, setValueType] = useState('projected');
  const [option, setOption] = useState('cmv');
  const [card, setCard] = useState([]);
  const [filtersChanged, setFiltersChanged] = useState(DEFAULT_FILTERS);
  const [openModalMenu, setOpenModalMenu] = useState(false);
  const [selectedFileAnalitycal, setSelectedFileAnalitycal] = useState(null);
  const [selectedFileSynthetic, setSelectedFileSynthetic] = useState(null);
  const [selectDateToDelete, setSelectDateToDelete] = useState('')
  const [rangeDate, setRangeDate] = useState({ startDate: '', endDate: '' });
  const [rangeDateEx, setRangeDateEx] = useState({
    startDate: '',
    endDate: ''
  });
  const [productFilter, setProductFilter] = useState('');
  const [currentCodePdv, setCurrentCodePdv] = useState('');
  const environments = Environment(process.env.REACT_APP_ENV);
  const [modalSettings, setModalSettings] = useState([
    { id: 1, name: 'filters', open: false, fullScreen: null, maxWidth: null },
    { id: 2, name: 'newMenu', open: false, fullScreen: null, maxWidth: 'md' },
    { id: 3, name: 'onSuccess', open: false, fullScreen: null, maxWidth: null },
    { id: 4, name: 'onError', open: false, fullScreen: null, maxWidth: null },
    { id: 5, name: 'discard', open: false, fullScreen: null, maxWidth: 'sm' },
    {
      id: 6,
      name: 'onSuccessDiscard',
      open: false,
      fullScreen: null,
      maxWidth: null
    },
    {
      id: 7,
      name: 'onErrorDiscard',
      open: false,
      fullScreen: null,
      maxWidth: null
    },
    {
      id: 8,
      name: 'deleteMenu',
      open: false,
      fullScreen: null,
      maxWidth: 'md',
      data: null
    },
    {
      id: 9,
      name: 'deleteSuccess',
      open: false,
      loading: false,
      fullScreen: false,
      maxWidth: 'sm'
    },
    {
      id: 10,
      name: 'deleteError',
      open: false,
      loading: false,
      fullScreen: false,
      maxWidth: 'sm'
    },
    {
      id: 11,
      name: 'uploadSalesSpreadsheet',
      open: false,
      loading: false,
      fullScreen: false,
    },
    {
      id: 12,
      name: 'uploadSalesSpreadsheetHistoric',
      open: false,
      loading: false,
      fullScreen: false,
    },
    {
      id: 13,
      name: 'uploadSalesSpreadsheetDelete',
      open: false,
      loading: false,
      fullScreen: false,
    },
    {
      id: 14,
      name: 'processNonIntegratedSales',
      open: false,
      loading: false,
      fullScreen: false
    },
    {
      id: 15,
      name: 'invoicePerItem',
      open: false,
      loading: false,
      fullScreen: false
    }
  ]);
  const [options, setOptions] = useState({
    cards: [],
    unitsMeasurements: [],
    periods: [],
    menu: [],
    categories: [],
    status: [
      { id: 1, value: 'active', description: 'Ativo' },
      { id: 1, value: 'inactive', description: 'Inativo' }
    ]
  });
  const { cards } = options;
  const [newData, setNewData] = useState({
    card: null,
    data: {
      description: null,
      originId: getId,
      unitsMeasurementsId: null,
      categoryId: null,
      cardsId: null,
      period: null,
      startDateCurrent: null,
      endDateCurrent: null,
      user: userInfo.user,
      pdv: {
        integrated: false,
        code: null,
        description: null
      }
    },
    discard: []
  });
  const [menuId, setMenuId] = useState();
  const [filterFields, setFilterFields] = useState([
    [
      {
        label: 'Item',
        filterName: 'description',
        placeHolder: 'Digite aqui...',
        type: 'fieldSearch',
        marginRight: "20px",
        size: 'small'
      },
      {
        label: 'Ficha',
        filterName: 'cardsId',
        placeHolder: 'Selecione...',
        type: 'autoComplete',
        customSize: "120px",
        marginRight: "20px",
        urlPath: `${environments.catalog}/cards`,
        key: 'cards',
        optionReference: 'description',
        size: 'small',
        internalCheckBox: {
          label: 'Sem ficha',
          filterName: 'missingCards'
        },
        options: []
      },
      {
        label: 'Categoria',
        filterName: 'category',
        placeHolder: 'Digite aqui...',
        type: 'fieldSearch',
        marginRight: "20px",
        size: 'small'
      },
      {
        label: 'Modo de venda',
        filterName: 'salesType',
        placeHolder: 'Selecione...',
        size: 'small',
        type: 'autoComplete',
        customSize: "120px",
        marginRight: "20px",
        key: 'providers',
        optionReference: 'providerName',
        options: [
          { title: 'Todos', value: '' },
          { title: 'Loja', value: 'store' },
          { title: 'Delivery', value: 'delivery' }
        ]
      },
      {
        label: 'Período',
        filterName: 'periodId',
        placeHolder: 'Selecione',
        size: 'small',
        type: 'autoComplete',
        customSize: "120px",
        marginRight: "20px",
        urlPath: `${environments.catalog}/periods`,
        key: 'periods',
        optionReference: 'description',
        options: []
      },
      {
        type: 'dateRangeField',
        size: 'small',
        filterNameFirst: 'dateStart',
        filterNameSecond: 'dateEnd',
        initialDate: initialDateFilterDefaultValue
          ? moment(initialDateFilterDefaultValue).add(3, 'hours')
          : dateLessSeven,
        endDate: endDateFilterDefaultValue
          ? moment(endDateFilterDefaultValue).add(3, 'hours')
          : dateLessOne,
        minDate: dateInitial,
        maxDate: dateFinal,
        marginRight: "10px",
        customMinStartDate: {
          type: 'subtract',
          quantity: 3,
          period: 'months'
        }
      },
      {
        label: 'Status',
        filterName: 'status',
        placeHolder: 'Selecione...',
        size: 'small',
        type: 'autoComplete',
        customSize: "120px",
        marginRight: "20px",
        key: 'providers',
        optionReference: 'providerName',
        options: [
          { title: 'Ativo', value: 'active' },
          { title: 'Inativo', value: 'inactive' }
        ]
      },
      {
        label: '',
        type: 'multipleCheckbox',
        checkBoxInputs: [{ name: 'Com Vendas', referenceName: 'onlyHasSales'}]
      },
      {
        label: '',
        type: 'multipleCheckbox',
        checkBoxInputs: [{ name: 'Vigente', referenceName: 'valid', checked: true }]
      },
      {
        label: '',
        type: 'multipleCheckbox',
        checkBoxInputs: [{ name: 'Rascunho', referenceName: 'draft' }]
      }
    ],
  ]);
  const [currentItem, setCurrentItem] = useState();
  const [salesAverageDiscount, setSalesAverageDiscount] = useState();
  const [menuLowAutomaticParams, setMenuLowAutomaticParams] = useState();
  const [percentageDiscountValue, setPercentageDiscountValue] = useState();
  const [hasMenuModalChange, setHasMenuModalChange] = useState(false);
  const [confirmCloseMenuDialog, setConfirmCloseMenuDialog] = useState(false);
  const [dataHistoricList, setDataHistoricList] = useState([]);
  const [alertErr, setAlertErr] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [errFileMessageindexOne, setErrFileMessageIndexOne] = useState('');
  const [errFileMessageindexTwo, setErrFileMessageIndexTwo] = useState('');
  const [statusCode, setStatusCode] = useState();
  const [sucess, setSucess] = useState('')

  const handleDefaultFilters = () => {
    const cardsIdWithoutValue =
      filtersChanged.find((item) => item.param === 'cardsId') &&
      !filtersChanged.find((item) => item.param === 'cardsId').value;

    if (cardsIdWithoutValue) {
      const index = filtersChanged.indexOf(
        filtersChanged.find((item) => item.name === 'cardsId')
      );

      filtersChanged.splice(index, 1);
    }
  };

  const config = {
    headers: {
      User: `${userLocal?.user}`
    }
  };

  const getSalesSequenceInvoices = () => {
    setLoadingInvoicesSequence(true)

    axios
      .get(`${environments.sales}/sales/sequenceInvoices?restaurantId=${getId}`)
      .then((response) => {
        setSalesInvoicesSequenceInfos({
          showAlert: response?.data?.message === "Some invoices are out of sequence"
        });
        setLoadingInvoicesSequence(false)
      })
      .catch((error) => {
        setAlertErr(true);
        setAlertMessage(error?.response?.data?.message);
        setStatusCode(error.response.status);
        setLoadingInvoicesSequence(false)
      });
  }

  const getCardapio = (SetData) => {
    setLoading(true);

    handleDefaultFilters();

    const params = filtersChanged.map((filter) => filter.urlParam).join('&');
    axios
      .get(`${environments.catalog}/menu?originId=${getId}&${params}`)
      .then((response) => {
        SetData(response?.data);
        setRangeDate({
          startDate: params.split('&')[0].split('dateStart=')[1],
          endDate: params.split('&')[1].split('dateEnd=')[1]
        });
        setRangeDateEx({
          startDate: params.split('&')[0].split('dateStart=')[1],
          endDate: params.split('&')[1].split('dateEnd=')[1]
        });
        setLoading(false);
      })
      .catch((error) => {
        setAlertErr(true);
        setAlertMessage(error?.response?.data?.message);
        setStatusCode(error.response.status);
      });
  };

  const handleClickFakeAnalitycal = () => {
    document.querySelector('#xlsxAnalitycal').click();
  };

  const handleClickFakeSynthetic = () => {
    document.querySelector('#xlsxSynthetic').click();
  };

  const handleFileAnalitycal = (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileNameParts = file.name.split('.');
      const fileExtension = fileNameParts[fileNameParts.length - 1];

      if (fileExtension.toLowerCase() === 'xlsx') {
        setSelectedFileAnalitycal(file);
      } else {
        setAlertErr(true);
        setAlertMessage('Por favor, selecione um arquivo XLSX válido.');
      }

    }
  };

  const handleFileSynthetic = (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileNameParts = file.name.split('.');
      const fileExtension = fileNameParts[fileNameParts.length - 1];

      if (fileExtension.toLowerCase() === 'xlsx') {
        setSelectedFileSynthetic(file);
      } else {
        setAlertErr(true);
        setAlertMessage('Por favor, selecione um arquivo XLSX válido.');
      }

    }
  };

  const getImportHistoric = () => {
    axios.get(`${environments.sales}/sales/${paramsID}/importFiles`)
      .then((res) => {
        setDataHistoricList(res?.data?.content)
      })
  }

  const handleSendArchiverSeler = async () => {
    let spreadsheetAnalitycalBase64 = null;
    let spreadsheetSyntheticBase64 = null;

    if (selectedFileAnalitycal) {
      spreadsheetAnalitycalBase64 = await convertToBase64(selectedFileAnalitycal);
    }

    if (selectedFileSynthetic) {
      spreadsheetSyntheticBase64 = await convertToBase64(selectedFileSynthetic);
    }

    const body = [];

    if (spreadsheetAnalitycalBase64) {
      body.push({
        date,
        file: spreadsheetAnalitycalBase64,
        fileName: selectedFileAnalitycal?.name,
        fileType: 'analytical',
      });
    }

    if (spreadsheetSyntheticBase64) {
      body.push({
        date,
        file: spreadsheetSyntheticBase64,
        fileName: selectedFileSynthetic?.name,
        fileType: 'synthetic',
      });
    }

    axios.post(`${environments.sales}/sales/${paramsID}/importSales`, body)
      .then(() => {
        setModalSettings((modal) =>
          modal.map((item) =>
            item.name === 'uploadSalesSpreadsheet'
              ? {
                ...item,
                open: false,
                data: null
              }
              : item
          )
        );
        setAlertErr(true);
        setSucess('success');
        setAlertMessage('Arquivo(s) enviado(s) com sucesso!');
        setSelectedFileAnalitycal(null);
        setSelectedFileSynthetic(null);
        setDate(new Date());
      }).catch((error) => {
        const invalidFiles = error?.response?.data;

        if (invalidFiles.length > 0) {
          setErrFileMessageIndexOne(
            `${invalidFiles[0]?.fileName}: ${
              invalidFiles[0]?.message === 'Invalid file' && 'Arquivo Inválido'
            }`
          );
          invalidFiles[1] &&
            setErrFileMessageIndexTwo(
              `${invalidFiles[1]?.fileName}: ${
                invalidFiles[1]?.message === 'Invalid file' &&
                'Arquivo Inválido'
              }`
            );
        }
      })
  }

  const handleCloseImportSales = () => {
    setSelectedFileAnalitycal(null);
    setSelectedFileSynthetic(null);
    setDate(new Date());

    setModalSettings((modal) =>
      modal.map((item) =>
        item.name === 'uploadSalesSpreadsheet'
          ? {
            ...item,
            open: false,
            data: null
          }
          : item
      )
    );
  }

  const translateType = (type) => {
    let content;

    switch (type) {
      case 'analytical':
        content = 'Venda de produtos';
        break;
      case 'synthetic':
        content = 'Promoção combinada';
        break;
    }

    return content;
  }

  const formatDateAndHours = (dateTime) => {
    const dataCompleta = new Date(dateTime);

    const optionsData = { year: 'numeric', month: '2-digit', day: '2-digit' };
    const dataFormatada = dataCompleta?.toLocaleDateString('pt-BR', optionsData);

    const optionsHora = { hour: '2-digit', minute: '2-digit' };
    const horaFormatada = dataCompleta?.toLocaleTimeString('pt-BR', optionsHora);

    const horaFinal = `${horaFormatada  }hs`;

    return `${dataFormatada} ${horaFinal}`
  }

  const groupedData = dataHistoricList.reduce((acc, item) => {
    const dateWithoutTime = item.date.split('T')[0];
    if (!acc[dateWithoutTime]) {
      acc[dateWithoutTime] = [];
    }
    acc[dateWithoutTime].push(item);
    return acc;
  }, {});

  const handleDeleteHistoric = (date) => {
    axios.delete(`${environments.sales}/sales/${paramsID}/importFiles/?initialDate=${format(new Date(date), 'yyyy-MM-dd')}`)
      .then(() => {
        setAlertErr(true);
        setSucess('success');
        getImportHistoric();
        setAlertMessage('Arquivo(s) deletados(s) com sucesso!');
        setModalSettings((modal) =>
          modal.map((item) =>
            item.name === 'uploadSalesSpreadsheetDelete'
              ? {
                ...item,
                open: false,
                data: null
              }
              : item
          )
        );
      }).catch((error) => {
        setAlertErr(true);
        setAlertMessage(error?.response?.data?.message);
        setStatusCode(error.response.status);
        setModalSettings((modal) =>
          modal.map((item) =>
            item.name === 'uploadSalesSpreadsheetDelete'
              ? {
                ...item,
                open: false,
                data: null
              }
              : item
          )
        );
      })
  };

  const updateList = useCallback(() => {
    setLoading(true);
    getCardapio(setCardapio);
    setLoading(false);
  }, [filtersChanged]);

  const handleModalMenuToggle = (confirmClose = false) => {
    if (openModalMenu && hasMenuModalChange && !confirmClose) {
      setConfirmCloseMenuDialog(true);
    } else {
      setConfirmCloseMenuDialog(false);
      setHasMenuModalChange(false);
      setOpenModalMenu(!openModalMenu);
    }
  };

  const BodyModal = (
    <Register
      userInfo={userInfo}
      menuInfo={menuInfo}
      handleModalToggle={handleModalMenuToggle}
      data={cardapio}
      menuId={menuId}
      salesAverageDiscount={salesAverageDiscount}
      percentageDiscountValue={percentageDiscountValue}
      getCardapio={getCardapio}
      updateList={updateList}
      openModalMenu={openModalMenu}
      setHasMenuModalChange={setHasMenuModalChange}
      hasMenuModalChange={hasMenuModalChange}
      setSalesAverageDiscount={setSalesAverageDiscount}
      showInactiveItems={
        filtersChanged.find((el) => el.param === 'status')?.value
      }
      menuLowAutomaticParams={menuLowAutomaticParams}
      setMenuLowAutomaticParams={setMenuLowAutomaticParams}
    />
  );

  const handleMultipleFiltersFunc = (paramsAndValues) => {
    handleMultipleFilters(paramsAndValues, filtersChanged, setFiltersChanged);
  };

  function handleFilter(param, value) {
    handleFilterOptions(param, value, filtersChanged, setFiltersChanged);
  }

  const getFilterhandle = (urlPath, label, key, optionReference) => {
    getFilterOptions(
      urlPath,
      label,
      key,
      filterFields,
      setFilterFields,
      optionReference,
      getId,
      filtersChanged
    );
  };

  const handleSaveMenuConfig = (columnsFormatted) => {
    const body = {
      configs: [
        {
          menus: {
            showColumns: columnsFormatted
          }
        }
      ],
      user: userInfo?.user
    };

    axios
      .put(`${environments.auth}/users/${userInfo?.user}/config`, body)
      .then(() => {
        setShowColumns(columnsFormatted);
        dispatch(handleMenusColumns({ configs: body?.configs }));
      })
      .catch((error) => {
        console.log('error', error);
        setToastProps({
          message: 'Erro ao atualizar configurações',
          severity: 'error'
        });
      });
  };

  const renderShowColumnItem = (column, label) => {
    const handleChangeColumnsView = (checked) => {
      const columnsFormatted = checked
        ? [...showColumns, column]
        : showColumns?.filter((el) => el !== column);

        handleSaveMenuConfig(columnsFormatted);
    };

    const showCurrentColumn = showColumns?.includes(column);

    return (
      <div className="dFlex alignCenter" style={{ marginLeft: '-2px' }}>
        <Checkbox
          style={{
            marginTop: 4,
            marginLeft: 0,
            color: '#FFF'
          }}
          name="View Mode"
          checked={showCurrentColumn}
          className="checkBoxSmall"
          onChange={(_, checked) => {
            handleChangeColumnsView(checked);
          }}
          size="small"
        />

        <label
          onClick={() => handleChangeColumnsView(!showCurrentColumn)}
          className="initialTransform fontWeightNormal fontSizeDefault dFlex alignEnd cursorPointer 
           mb0
          "
          style={{
            lineHeight: 'normal',
            color: '#0F1225'
          }}
        >
          {label}
        </label>
      </div>
    );
  };

  function handleDelete(obj) {
    new Promise((resolve, reject) => {
      axios
        .delete(`${environments.catalog}/menu/${obj.id}`, config)
        .then((response) => {
          getCardapio(setCardapio);
          setModalSettings(
            modalSettings.map((modal) =>
              modal.name === 'deleteSuccess' ? { ...modal, open: true } : modal
            )
          );
          resolve();
        })
        .catch((error) => {
          setAlertErr(true);
          setAlertMessage(error.response.data.message);
          setStatusCode(error.response.status);
          reject();
        });
    });
  }

  function putDiscard() {
    new Promise((resolve, reject) => {
      setTimeout(() => {
        setLoadingDiscard(true);
        axios
          .post(`${environments.catalog}/extracts`, newData.discard, config)
          .then((response) => {
            setModalSettings((modal) =>
              modal.map((item) =>
                item.name === 'onSuccessDiscard'
                  ? { ...item, open: true }
                  : item
              )
            );
            setModalSettings((modal) =>
              modal.map((item) =>
                item.name === 'discard' ? { ...item, open: false } : item
              )
            );
            setLoadingDiscard(false);
            resolve();
          })
          .catch((error) => {
            setModalSettings((modal) =>
              modal.map((item) =>
                item.name === 'onErrorDiscard' ? { ...item, open: true } : item
              )
            );
            setAlertErr(true);
            setAlertMessage(error.response.data.message);
            setStatusCode(error.response.status);
            setLoadingDiscard(false);
            reject();
          });
      }, 1000);
    });
  }

  const renderSalesColumns = () => !showColumns?.includes('CARD. R$') &&
    !showColumns?.includes('MÉD. R$') &&
    !showColumns?.includes('DESC.') &&
    !showColumns?.includes('QTDE.') &&
    !showColumns?.includes('TOTAL R$') &&
    !showColumns?.includes('MARG.')

  const renderCostColumns = () => !showColumns?.includes('CUSTO TOTAL R$') &&
    !showColumns?.includes('CUSTO NOMINAL') &&
    !showColumns?.includes('CUSTO % PROJ.') &&
    !showColumns?.includes('CUSTO % REAL')

  const [inBase, setInBase] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      setInBase(document?.querySelector('main')?.scrollTop <= 190);
    };

    if (!document.querySelector('main')) return;

    document.querySelector('main').addEventListener('scroll', handleScroll);

    return () => {
      document
        ?.querySelector('main')
        ?.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    getCardapio(setCardapio);
    getSalesSequenceInvoices();
  }, [getId, filtersChanged]);

  useEffect(() => {
    setId(paramsID);
  }, [paramsID]);

  const columns = useMemo(
    () => [
      {
        id: 'expander',
        Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
          <span {...getToggleAllRowsExpandedProps()}>
            {isAllRowsExpanded ? (
              <ExpandMore style={{ color: '#FFF', marginLeft: '-8px', marginTop: '-10px', marginBottom: '-10px', height: 22 }} />
            ) : (
              <ChevronRight style={{ color: '#FFF', marginLeft: '-8px', marginTop: '-10px', marginBottom: '-10px', height: 22 }} />
            )}
            CATEGORIA
          </span>
        ),
        className: 'defaultHeader categoryHeader',
        columns: [
          {
            Header: '',
            accessor: 'category',
            className:
              'defaultSubtitle withLeftDefaultBorder withoutRightBorder categoryColumn',
            Cell: ({ row }) => {
              if (row.original.pdv === undefined) {
                return (
                  <Grid container spacing={1} alignItems="center">
                    <Grid container item xs={12} justify="flex-start">
                      <span
                        {...row.getToggleRowExpandedProps({
                          style: {
                            paddingLeft: `${row.depth * 0.6}rem`
                          }
                        })}
                      >
                        {row.isExpanded ? (
                          <ExpandMore style={{ color: '#313347' }} />
                        ) : (
                          <ChevronRight style={{ color: '#313347' }} />
                        )}
                      </span>

                      <Typography
                        className="m0 textAlignCenter"
                        style={{
                          fontSize: '11px',
                          padding: '3px 0'
                        }}
                      >
                        {row.original.description === 'Não categorizado'
                          ? row.original.description
                          : ValidationLength(
                            row.original.description,
                            row.depth + 1 < 6 ? 20 : 15 - row.depth
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                );
              }
              return <span>&nbsp;</span>;
            }
          }
        ]
      },
      {
        Header: 'ITEM',
        accessor: 'geral',
        className: 'defaultHeader itens withoutLeftBorder',
        columns: [
          {
            Header: '',
            accessor: 'description',
            className:
              'defaultSubtitle column-item withoutLeftBorder withoutRightBorder',
            Cell: (props) => {
              if (props.row.original.pdv !== undefined) {
                const menuItem = props.row.original;
                const showSaleOutOfDateInfo = menuItem?.quantity && menuItem?.outOfDate

                if (!props.row.original.description) {
                  return (
                    <div 
                      className="dFlex alignCenter" 
                      style={{ paddingLeft: '45px' }}
                    >
                      <IconButton size="small" style={{ outline: 0 }} disabled>
                        <Delete
                          style={{ width: 15, height: 15, color: '#313347' }}
                        />
                      </IconButton>

                      <span
                        className="cursorPointer blackLink"
                        onClick={() => {
                          setCardsId(
                            props.row.original.cards
                              ? props.row.original.cards.id
                              : ''
                          );
                          setMenuId(props.row.original.id);
                          setMenu({
                            card,
                            data: {
                              ...props.row.original,
                              description: props.row.original.pdv.description
                            }
                          });
                          setSalesAverageDiscount(
                            props.row.original.salesAverageDiscount
                          );
                          setPercentageDiscountValue(
                            props.row.original.percentageDiscount
                          );
                          handleModalMenuToggle();
                          setMenuLowAutomaticParams({
                            startDate: filtersChanged?.find(el => el.param === 'dateStart')?.value,
                            endDate: filtersChanged?.find(el => el.param === 'dateEnd')?.value,
                            lowAutomatic: props.row.original.lowAutomatic
                          })
                        }}
                      >
                        <ShortenStringTooltip
                          string={props.row.original.pdv.description}
                          length={35}
                        />
                      </span>
                    </div>
                  );
                }

                return (
                  <div 
                    className="dFlex alignCenter"
                  >
                    {menuItem?.images?.url ?
                      <Tooltip
                        arrow
                        placement="right-start"
                        title={
                          <div className='cropDatatooltip'>
                            <img src={menuItem?.images?.url} alt="cropDatatooltip" />
                          </div>
                        }
                      >
                        <div
                          className="cropDataVerySmallBox"
                        >
                          <img src={menuItem?.images?.url} alt="cropDataVerySmallBox" />
                        </div>
                      </Tooltip>
                      :
                      <div
                        style={{ width: '35px', height: '26.25px', marginRight: '10px' }}
                      />
                    }
                    
                    <span
                      className="cursorPointer blackLink mr10"
                      onClick={() => {
                        setCardsId(
                          props.row.original.cards
                            ? props.row.original.cards.id
                            : ''
                        );
                        setMenuId(props.row.original.id);
                        setMenu({ card, data: props.row.original });
                        handleModalMenuToggle();
                        setSalesAverageDiscount(
                          props.row.original.salesAverageDiscount
                        );
                        setPercentageDiscountValue(
                          props.row.original.percentageDiscount
                        );
                        setMenuLowAutomaticParams({
                          startDate: filtersChanged?.find(el => el.param === 'dateStart')?.value,
                          endDate: filtersChanged?.find(el => el.param === 'dateEnd')?.value,
                          lowAutomatic: props.row.original.lowAutomatic
                        })
                      }}
                    >
                      {props.value}
                      {showSaleOutOfDateInfo ?
                        <Tooltip title="Venda fora da vigência">
                          <span> *</span>
                        </Tooltip>
                      : ''}
                    </span>

                    <div 
                      className="dFlex inactive mlAuto"
                    >
                      <Tooltip title="Vendas deste item">
                        <div
                          onClick={() => {
                            setModalSettings((modal) =>
                              modal.map((item) =>
                                item.name === 'invoicePerItem'
                                  ? {
                                    ...item,
                                    open: true,
                                  }
                                  : item
                              )
                            )
                            setCurrentCodePdv(menuItem?.pdv?.code)
                          }} 
                          className="dFlex alignCenter notesDialogIcon cursorPointer mr8"
                        >
                          <img style={{ width: 17, height: 17 }} src={ItemSalesIcon} alt="Item Sales Icon" />
                        </div>
                      </Tooltip>

                      <Tooltip title="Lançar descarte">
                        <IconButton
                          size="small"
                          className="mr8"
                          style={{ outline: 0 }}
                          disabled={!menuItem.cards}
                          onClick={() => {
                            setModalSettings((modal) =>
                              modal.map((item) =>
                                item.name === 'discard'
                                  ? {
                                    ...item,
                                    open: true
                                  }
                                  : item
                              )
                            );
                            setNewData({
                              ...newData,
                              discard: menuItem.cards.items.map((item) => ({
                                id: item.productId,
                                typeEvent: 'DISCARD',
                                cost: item.cost,
                                salesPrice: item.products.salePrice,
                                originId: getId,
                                quantity: item.quantity,
                                initialQuantity: item.quantity,
                                eventDate: format(new Date(), 'yyyy-MM-dd'),
                                un: item.products.consumptionUnitsPrimary
                                  .abbreviation,
                                user: userInfo.user
                              })),
                              card: menuItem.cards
                                ? cards.find((x) => x.id === menuItem.cards.id)
                                : null,
                              data: {
                                id: menuItem.id ? menuItem.id : null,
                                description: menuItem.description
                                  ? menuItem.description
                                  : null,
                                originId: getId,
                                categoryId: menuItem ? menuItem.categoryId : null,
                                cardsId: menuItem.cards
                                  ? menuItem.cards.id
                                  : null,
                                startDateCurrent: menuItem.startDateCurrent
                                  ? menuItem.startDateCurrent
                                  : new Date(),
                                endDateCurrent: menuItem.endDateCurrent
                                  ? menuItem.endDateCurrent
                                  : new Date(),
                                unitsMeasurementsId: menuItem.unitsMeasurements
                                  ? menuItem.unitsMeasurements.id
                                  : null,
                                unitsMeasurementsAbbreviation:
                                  menuItem.unitsMeasurements
                                    ? menuItem.unitsMeasurements.abbreviation
                                    : null,
                                pdv: menuItem.pdv ? menuItem.pdv : null,
                                similarMenu: menuItem.similarMenu
                                  ? menuItem.similarMenu
                                  : null,
                                period: menuItem.periods
                                  ? menuItem.periods.map((x) => ({
                                    periodId: x.id
                                  }))
                                  : null
                              }
                            });
                          }}
                        >
                          <img style={{ width: 15, height: 15 }} src={TrashLightGrayIcon} alt="Discard Icon" />
                        </IconButton>
                      </Tooltip>

                      <Tooltip title="Deletar">
                        <IconButton
                          size="small"
                          className="mr5"
                          style={{ outline: 0 }}
                          disabled={false}
                          onClick={() => {
                            setModalSettings((modal) =>
                              modal.map((item) =>
                                item.name === 'deleteMenu'
                                  ? {
                                    ...item,
                                    open: true,
                                    data: props.row.original
                                  }
                                  : item
                              )
                            );
                          }}
                        >
                          <img style={{ width: 12, height: 12 }} src={DeleteItemIcon} alt="Delete Item Icon" />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </div>
                );
              }
              return <span>&nbsp;</span>;
            }
          }
        ]
      },
      {
        Header: 'VENDAS',
        className: `vendas defaultHeader ${renderSalesColumns() && 'dNone'}`,
        columns: [
          {
            Header: 'PREÇO VENDA',
            accessor: 'salesAverage',
            className: `defaultSubtitle column-sales-average ${!showColumns?.includes('CARD. R$') && 'dNone'}`,
            Cell: (props) => (
              <p className='textAlignRight m0'>
                {props?.value?.toLocaleString('pt-br', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                }) || '0,00'}
              </p>
            )
          },
          {
            Header: 'MÉD. R$',
            accessor: 'salesAverageDiscount',
            className: `defaultSubtitle column-sales-average-discount ${!showColumns?.includes('MÉD. R$') && 'dNone'}`,
            Cell: (props) => (
              <p className="textAlignRight m0">
                {props?.value?.toLocaleString('pt-br', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                }) || '0,00'}
              </p>
            )
          },
          {
            Header: 'DESC.',
            accessor: 'percentageDiscount',
            className: `defaultSubtitle column-percentage-discount ${!showColumns?.includes('DESC.') && 'dNone'}`,
            Cell: (props) => (
              <p className="textAlignRight m0">
                {Number(props?.value)?.toLocaleString('pt-br', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                }) || '0,00'}
                %
              </p>
            )
          },
          {
            Header: 'QTDE.',
            accessor: 'quantity',
            className: `defaultSubtitle column-quantity ${!showColumns?.includes('QTDE.') && 'dNone'}`,
            Cell: (props) => (
              <p className="textAlignRight m0">
                {(props?.value || 0)?.toLocaleString('pt-br', {
                  minimumFractionDigits: 1,
                  maximumFractionDigits: 1
                }) || '0,0'}
              </p>
            )
          },
          {
            Header: 'TOTAL R$',
            accessor: 'total',
            className: `defaultSubtitle column-total ${!showColumns?.includes('TOTAL R$') && 'dNone'}`,
            Cell: (props) => (
              <p className="textAlignRight m0">
                {(props?.value || 0)?.toLocaleString('pt-br', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                }) || '0,00'}
              </p>
            )
          },
          {
            Header: 'MARG.',
            accessor: 'margin',
            className: `defaultSubtitle column-margin withoutRightBorder ${!showColumns?.includes('MARG.') && 'dNone'}`,
            Cell: (props) => (
              <p className="textAlignRight m0">
                {(props?.value || 0)?.toLocaleString('pt-br', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                }) || '0,00'}
              </p>
            )
          }
        ]
      },
      {
        Header: 'CUSTOS',
        className: `custo defaultHeader ${renderCostColumns() && 'dNone'}`,
        columns: [
          {
            Header: 'TOTAL R$',
            accessor: valueType === 'projected' ? 'costTotal' : 'realTotalCost',
            className: `defaultSubtitle column-total-cost ${!showColumns?.includes('CUSTO TOTAL R$') && 'dNone'}`,
            Cell: (props) => (
              <p className="textAlignRight m0">
                {(props?.value || 0)?.toLocaleString('pt-br', {
                  minimumFractionDigits: 3,
                  maximumFractionDigits: 3
                }) || '0,000'}
              </p>
            )
          },
          {
            Header: 'NOMINAL',
            accessor: valueType === 'projected' ? 'cost' : 'realCost',
            className: `defaultSubtitle column-cost ${!showColumns?.includes('CUSTO NOMINAL') && 'dNone'}`,
            Cell: (props) => (
              <p className="textAlignRight m0">
                {(props?.value || 0)?.toLocaleString('pt-br', {
                  minimumFractionDigits: 3,
                  maximumFractionDigits: 3
                }) || '0,000'}
              </p>
            )
          },
          {
            Header: `${option === 'cmv' ? '%' : ''} PROJ.`,
            accessor: option === 'cmv' ? 'cmv' : 'mkp',
            className: `defaultSubtitle column-mkp ${!showColumns?.includes('CUSTO % PROJ.') && 'dNone'}`,
            Cell: (props) => (
              <p className="textAlignRight m0">
                {(props?.value || 0)?.toLocaleString('pt-br', {
                  minimumFractionDigits: 3,
                  maximumFractionDigits: 3
                }) || '0,000'}
                {`${option === 'cmv' ? '%' : ''}`}
              </p>
            )
          },
          {
            Header: `${option === 'cmv' ? '%' : ''} REAL`,
            accessor: option === 'cmv' ? 'realCmv' : 'realMkp',
            className: `defaultSubtitle column-cmv withRightDefaultBorder ${!showColumns?.includes('CUSTO % REAL') && 'dNone'}`,
            Cell: (props) => (
              <p className="textAlignRight m0">
                {(props?.value || 0)?.toLocaleString('pt-br', {
                  minimumFractionDigits: 3,
                  maximumFractionDigits: 3
                }) || '0,000'}
                {`${option === 'cmv' ? '%' : ''}`}
              </p>
            )
          }
        ]
      }
    ],
    [option, valueType, filtersChanged, currentItem, showColumns]
  );

  const processNonIntegratedSalesButton = () => (
    <Tooltip
      title={
        <div className="dFlex alignCenter flexColumn">
          <p className="mb0">
            Processsar vendas
            não integradas
          </p>
        </div>
      }
      aria-label="info"
    >
      <img
        className="cursorPointer"
        style={{
          width: '25px',
          marginRight: '10px',
        }}
        onClick={() => setModalSettings((modal) =>
          modal.map((item) =>
            item.name === 'processNonIntegratedSales'
              ? {
                ...item,
                open: true,
              }
              : item
          )
        )}
        src={ProcessNonIntegratedIcon}
        alt="ProcessNonIntegratedIcon"
      />
    </Tooltip>
  )

  const addMenuButton = () => (
    <Tooltip title="Novo cardápio" aria-label="add">
      <span
        className="cursorPointer lightBlue"
        onClick={() => {
          handleModalMenuToggle();
          setMenu(null);
          setMenuId(null);
        }}
        id="menuPageAddNew"
      >
        <IconButton
          className="p0"
          edge="start"
          color="inherit"
          style={{ outline: 0 }}
          aria-label="add"
        >
          <img style={{ width: '25px' }} src={AddIcon} alt="AddIcon" />
        </IconButton>
      </span>
    </Tooltip>
  );

  const exportExcel = () => {
    const params = filtersChanged.map((filter) => filter.urlParam).join('&');

    const url = `${environments.catalog}/menu/spreadsheet/export?originId=${getId}&${params}`

    const link = document.createElement('a');
    link.href = url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const exportExcelButton = () => (
    <Tooltip title="Baixar dados em excel" aria-label="add" style={{ marginRight: 21 }}>
      <span
        className="cursorPointer lightBlue"
        onClick={() => {
          exportExcel()
        }}
        id="menuPageAddNew"
      >
        <IconButton
          className="p0"
          edge="start"
          color="inherit"
          style={{ outline: 0 }}
          aria-label="add"
        >
          <img style={{ width: '25px' }} src={DownloadIconExport} alt="DownloadIconExport" />
        </IconButton>
      </span>
    </Tooltip>
  )

  const exportUpload = () => (
    <Tooltip title="Arquivo de vendas" aria-label="add">
      <IconButton
        style={{ marginRight: 21 }}
        className="p0"
        color="primary"
        aria-label="upload picture"
        component="span"
        onClick={() => setModalSettings((modal) =>
          modal.map((item) =>
            item.name === 'uploadSalesSpreadsheet'
              ? {
                ...item,
                open: true,
                data: null
              }
              : item
          )
        )}
      >
        <img style={{ width: '25px' }} src={UploadIcon} alt="UploadIcon" />
      </IconButton>
    </Tooltip>
  );

  const curveABC = {
    label: 'Curva',
    type: 'multipleCheckbox',
    checkBoxInputs: [{ name: 'A' }, { name: 'B' }, { name: 'C' }]
  };

  return (
    <Layout>
      <TitleHead title="Cardapio" />

      <GlobalStyle />

      <Container>
        <PageTitleAndFilter
          title="Cardápios"
          activeFilterFilds={filterFields}
          handleFilter={handleFilter}
          handleMultipleFilters={handleMultipleFiltersFunc}
          additionalbutton={addMenuButton}
          extrabutton={exportExcelButton}
          getFilterhandle={getFilterhandle}
          complementarybutton={storeSegment === 'Restaurante/Rodizio' && processNonIntegratedSalesButton}
          supplementarybutton={storeSegment === 'Restaurante/Rodizio' && exportUpload}
        />

        {/* <div className={`MultipleCheckBoxAside ${!inBase && 'sticky'}`}>
          <MultipleCheckbox
            title=""
            checkBoxInputs={curveABC.checkBoxInputs}
            handleFilter={handleFilter}
          />
        </div> */}

        <div className="subFilterArea">
          <div className={`salesInvoicesSequenceAlert ${salesInvoicesSequenceInfos?.showAlert ? 'visibilityVisible' : 'visibilityHidden'}`}>
            <span className="white bold">
              ATENÇÃO! Identificamos falha no sequenciamento das notas, por favor, verifique se há falha na entrega dos XML’s na pasta desta loja 
            </span>
          </div>

          <div className="costSubFiltersBox">
            <span className="bold fontSizeDefault">
              Custos
            </span>

            <div
              className="toggleDefault small projRealRadio"
            >
              <RadioGroup
                id="cmvSwitch"
                properties={{
                  labelPlacement: 'end',
                  name: 'cmv-mkp-values',
                  radioSize: 'small',
                  row: true
                }}
                options={[
                  { label: 'Projetado', value: 'projected', id: 'projOption' },
                  { label: 'Real', value: 'real', id: 'realOption' }
                ]}
                option={valueType}
                setOption={setValueType}
              />
            </div>

            <div
              className="toggleDefault small cmvMkpRadio"
            >
              <RadioGroup
                id="cmvSwitch"
                properties={{
                  labelPlacement: 'end',
                  name: 'cmv-mkp-values',
                  radioSize: 'small',
                  row: true
                }}
                options={[
                  { label: 'CMV', value: 'cmv', id: 'cmvOption' },
                  // { label: 'MARKUP', value: 'mkp', id: 'mkpOption' }
                ]}
                option={option}
                setOption={setOption}
              />
            </div>
          </div>
        </div>

        <div className="pRelative">
          <div
            className={`cursorPointer dFlex ${!inBase && 'pSticky'}`}
            style={{
              color: 'rgba(80,98,240,1)',
              textDecoration: 'underline',
              position: 'absolute',
              zIndex: 9999,
              left: '99px',
              top: !inBase ? '-4px' : '6px',
              marginLeft: !inBase ? '93px' : '0',
            }}
            onClick={(e) => setAnchorEl(e.currentTarget)}
          >
            <Tooltip
              placement="top"
              title={
                <p className="mb0" style={{ padding: '4px 2px' }}>
                  Exibir/Ocultar colunas
                </p>
              }
            >
              <img src={ConfigColumnsIcon} alt="Config Show Columns" />
            </Tooltip>
          </div>

          <MenuTable
            columns={columns}
            loading={loading}
            data={cardapio}
            rangeDate={rangeDate}
            rangeDateEx={rangeDateEx}
            setProductFilter={setProductFilter}
            productFilter={productFilter}
            setRangeDate={setRangeDate}
            restaurantId={getId}
            className="desktop menuListTable"
            projOrReal={valueType}
            showColumns={showColumns}
            filters={filtersChanged}
          />
        </div>

        {modalSettings.find((modal) => modal.name === 'discard').open && (
          <Discard
            isMenu
            originPage="MENU"
            loading={loadingDiscard}
            fullScreen={
              modalSettings.find((modal) => modal.name === 'discard').fullScreen
            }
            maxWidth={
              modalSettings.find((modal) => modal.name === 'discard').maxWidth
            }
            open={modalSettings.find((modal) => modal.name === 'discard').open}
            onSubmit={() => putDiscard()}
            onClose={() => {
              setModalSettings((modal) =>
                modal.map((item) =>
                  item.name === 'discard'
                    ? {
                      ...item,
                      open: false
                    }
                    : item
                )
              );
            }}
            item={newData}
            setItem={setNewData}
            currentDiscardReason={currentDiscardReason}
            setCurrentDiscardReason={setCurrentDiscardReason}
            initialDate={now}
          />
        )}

        {modalSettings.find((modal) => modal.name === 'processNonIntegratedSales').open && (
          <ProcessNonIntegratedSales
            fullScreen={
              modalSettings.find((modal) => modal.name === 'processNonIntegratedSales').fullScreen
            }
            maxWidth={
              modalSettings.find((modal) => modal.name === 'processNonIntegratedSales').maxWidth
            }
            open={modalSettings.find((modal) => modal.name === 'processNonIntegratedSales').open}
            restaurantId={getId}
            onClose={() => {
              setModalSettings((modal) =>
                modal.map((item) =>
                  item.name === 'processNonIntegratedSales'
                    ? {
                      ...item,
                      open: false
                    }
                    : item
                )
              );
            }}
          />
        )}

        {modalSettings.find((modal) => modal.name === 'invoicePerItem').open && (
          <InvoicePerItem
            fullScreen={
              modalSettings.find((modal) => modal.name === 'invoicePerItem').fullScreen
            }
            maxWidth={
              modalSettings.find((modal) => modal.name === 'invoicePerItem').maxWidth
            }
            open={modalSettings.find((modal) => modal.name === 'invoicePerItem').open}
            modalSettings={modalSettings}
            getId={getId}
            onClose={() => {
              setModalSettings(
                modalSettings.map((modal) =>
                  modal.name === 'invoicePerItem'
                    ? { ...modal, open: false }
                    : modal
                )
              );
            }}
            defaultStartDate={filtersChanged?.find(el => el?.param === 'dateStart')?.value}
            defaultEndDate={filtersChanged?.find(el => el?.param === 'dateEnd')?.value}
            codePdv={currentCodePdv}
          />
        )}

        <Dialog
          fullScreen={
            modalSettings.find((x) => x.name === 'deleteMenu').fullScreen
          }
          maxWidth={modalSettings.find((x) => x.name === 'deleteMenu').maxWidth}
          open={modalSettings.find((x) => x.name === 'deleteMenu').open}
          PaperComponent={PaperComponent}
          onClose={() => {
            setModalSettings((modal) =>
              modal.map((item) =>
                item.name === 'deleteMenu'
                  ? {
                    ...item,
                    open: false,
                    data: null
                  }
                  : item
              )
            );
          }}
          PaperProps={{ style: { borderRadius: 20 } }}
          aria-labelledby="draggable-dialog-title"
        >
          <DialogTitle
            className="modalHeader navyBlue"
            style={{ fontWeight: 'bold', cursor: 'move', fontFamily: 'Lato' }}
            id="draggable-dialog-title"
          >
            <Typography>Deseja realmente excluir esse cardápio?</Typography>
          </DialogTitle>

          <DialogContent className="modalContent">
            <Typography>
              Esta ação não pode ser desfeita. Deseja continuar?
            </Typography>
          </DialogContent>

          <DialogActions className="justifyCenter">
            <Button
              design="outlined"
              className="defaultButton backButton"
              onClick={() => {
                setModalSettings((modal) =>
                  modal.map((item) =>
                    item.name === 'deleteMenu'
                      ? {
                        ...item,
                        open: false,
                        data: null
                      }
                      : item
                  )
                );
              }}
              label="Cancelar"
              color="primary"
              style={{ outline: 0 }}
            />

            <Button
              autoFocus
              className="defaultButton submitButton"
              style={{ outline: 0 }}
              design="contained"
              label="Confirmar"
              onClick={() => {
                const itemToDelete = modalSettings.filter(
                  (item) => item.name === 'deleteMenu'
                )[0].data;

                handleDelete(itemToDelete);
              }}
              color="primary"
            />
          </DialogActions>

          <Snackbar
            open={modalSettings.find((x) => x.name === 'deleteSuccess').open}
            autoHideDuration={1000}
            onClose={() => {
              setModalSettings(
                modalSettings.map((modal) =>
                  modal.name === 'deleteMenu'
                    ? { ...modal, open: false, data: null }
                    : modal
                )
              );
              setModalSettings((modal) =>
                modal.map((item) =>
                  item.name === 'deleteSuccess'
                    ? { ...item, open: false }
                    : item
                )
              );
            }}
          >
            <Alert severity="success">Cardápio deletado com sucesso!</Alert>
          </Snackbar>

          <Snackbar
            open={modalSettings.find((x) => x.name === 'deleteError').open}
            autoHideDuration={1000}
            onClose={() => {
              setModalSettings((modal) =>
                modal.map((item) =>
                  item.name === 'deleteError' ? { ...item, open: false } : item
                )
              );
            }}
          >
            <Alert severity="error">
              Ocorreu um erro ao salvar as informações.
            </Alert>
          </Snackbar>
        </Dialog>
      </Container>

      {modalSettings.find((m) => m.name === 'uploadSalesSpreadsheet').open && (
        <Dialog
          fullScreen={
            modalSettings.find((x) => x.name === 'uploadSalesSpreadsheet').fullScreen
          }
          maxWidth="lg"
          open={modalSettings.find((m) => m.name === 'uploadSalesSpreadsheet').open}
          onClose={handleCloseImportSales}
          aria-labelledby="responsive-dialog-title"
          transitionDuration={0}
          PaperComponent={PaperComponent}
        >
          <DialogTitle
            className="modalHeader bold cursorMove"
            id="draggable-dialog-title"
          >
            <Typography>IMPORTAR ARQUIVO DE VENDAS</Typography>

            <IconButton
              edge="start"
              color="inherit"
              onClick={handleCloseImportSales}
              aria-label="close"
              style={{ outline: '0', background: 'none' }}
            >
              <img src={CloseIcon} alt="CloseIcon" />
            </IconButton>
          </DialogTitle>

          <DialogContent className="modalContent">
            <Grid
              container
              style={{
                width: '600px',
                flexDirection: 'column'
              }}
              className="cardDefault withoutInputs mr10"
            >
              <Box>
                <div style={{ display: 'flex', justifyContent: "space-around", alignItems: "center" }}>
                  <div>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptLocale}>
                      <Grid style={{ width: '40%' }}>
                        <KeyboardDatePicker
                          size="small"
                          id="time-picker"
                          label="Data"
                          placeholder="00/00"
                          format="dd/MM"
                          ampm={false}
                          clearable
                          fullWidth
                          value={date}
                          inputVariant="outlined"
                          className="dateSmall"
                          onChange={(date) => setDate(date)}
                          InputProps={{
                            style: { fontFamily: 'Lato', fontSize: '12px' }
                          }}
                          KeyboardButtonProps={{
                            size: 'small',
                            'aria-label': 'change time',
                            style: { outline: 0 }
                          }}
                          PopoverProps={{ style: { outline: 0 } }}
                        />
                      </Grid>
                    </MuiPickersUtilsProvider>
                  </div>
                  <div style={{ marginRight: '45px' }}>
                    <Typography style={{ fontWeight: 'bold' }}>Tipo de arquivo</Typography>
                    <span>Venda de produtos</span>
                  </div>

                  <div>
                    <MaterialButton
                      onClick={handleClickFakeAnalitycal}
                      className="defaultButton submitButton"
                      style={{ height: '45px' }}
                    >
                      <InputFile
                        type="file"
                        title="Enviar"
                        id="xlsxAnalitycal"
                        name="xlsxAnalitycal"
                        accept='.xlsx'
                        onChange={handleFileAnalitycal}
                        aria-label="upload"
                        className="dNone"
                      />
                      <img style={{ width: '25px' }} src={DownloadIcon} alt="DownloadIcon" />
                      <span>Selecionar arquivo</span>
                    </MaterialButton>
                  </div>
                </div>

                <div style={{ marginTop: 10 }}>
                  {selectedFileAnalitycal ? (
                    <span style={{ marginLeft: '11.8rem', color: '#5062F0' }}>
                      {selectedFileAnalitycal?.name}
                    </span>
                  ) : (
                    <OffSpan style={{ marginLeft: '11.8rem' }}>
                      Nenhum arquivo selecionado
                    </OffSpan>
                  )}

                </div>

                <Divider style={{ background: '#99A4FF', margin: "15px 0" }} />

                <div style={{ display: 'flex', justifyContent: "flex-end", alignItems: "center" }}>
                  <div style={{ marginRight: '47px' }}>
                    <Typography style={{ fontWeight: 'bold' }}>Tipo de arquivo</Typography>
                    <span>Promoção combinada</span>
                  </div>

                  <div style={{ marginRight: '12px' }}>
                    <MaterialButton
                      onClick={handleClickFakeSynthetic}
                      className="defaultButton submitButton"
                      style={{ height: '45px' }}
                    >
                      <InputFile
                        type="file"
                        title="Enviar"
                        id="xlsxSynthetic"
                        name="xlsxSynthetic"
                        onChange={handleFileSynthetic}
                        accept='.xlsx'
                        aria-label="upload"
                        className="dNone"
                      />
                      <img style={{ width: '25px' }} src={DownloadIcon} alt="DownloadIcon" />
                      <span>Selecionar arquivo</span>
                    </MaterialButton>
                  </div>
                </div>

                <div style={{ marginTop: 10 }}>
                  {selectedFileSynthetic ? (
                    <span style={{ marginLeft: '11.8rem', color: '#5062F0' }}>
                      {selectedFileSynthetic?.name}
                    </span>
                  ) : (
                    <OffSpan style={{ marginLeft: '11.8rem' }}>
                      Nenhum arquivo selecionado
                    </OffSpan>
                  )}
                </div>
              </Box>
            </Grid>
          </DialogContent>

          <DialogActions className="justifyBetween">
            <div style={{ marginLeft: '16px' }}>
              <button
                onClick={() => {
                  getImportHistoric()
                  setModalSettings((modal) =>
                    modal.map((item) =>
                      item.name === 'uploadSalesSpreadsheetHistoric'
                        ? {
                          ...item,
                          open: true,
                          data: null
                        }
                        : item
                    )
                  );
                }}
                style={{
                  color: "#5062F0",
                  border: '1px solid #5062F0',
                  borderRadius: 8,
                  background: "transparent",
                  padding: "12px 16px"
                }}
              >
                Historico
              </button>
            </div>

            <div style={{ display: "flex", gap: 10, marginRight: '23px' }}>
              <Button
                className="defaultButton backButton"
                design="outlined"
                onClick={handleCloseImportSales}
                label="Voltar"
                color="primary"
              />
              <Button
                className="defaultButton submitButton"
                design="contained"
                label="Enviar"
                onClick={handleSendArchiverSeler}
                color="primary"
                autoFocus
              />
            </div>

          </DialogActions>
        </Dialog>
      )}

      {modalSettings.find((m) => m.name === 'uploadSalesSpreadsheetHistoric').open && (
        <Dialog
          fullScreen={
            modalSettings.find((x) => x.name === 'uploadSalesSpreadsheetHistoric').fullScreen
          }
          maxWidth="lg"
          open={modalSettings.find((m) => m.name === 'uploadSalesSpreadsheetHistoric').open}
          onClose={() => {
            setModalSettings((modal) =>
              modal.map((item) =>
                item.name === 'uploadSalesSpreadsheetHistoric'
                  ? {
                    ...item,
                    open: false,
                    data: null
                  }
                  : item
              )
            );
          }}
          aria-labelledby="responsive-dialog-title"
          transitionDuration={0}
        >
          <DialogTitle
            className="modalHeader bold cursorMove"
            id="draggable-dialog-title"
          >
            <Typography>ARQUIVOS DE VENDAS ÚLTIMOS 30 DIAS</Typography>
            
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                setModalSettings((modal) =>
                  modal.map((item) =>
                    item.name === 'uploadSalesSpreadsheetHistoric'
                      ? {
                        ...item,
                        open: false,
                        data: null
                      }
                      : item
                  )
                );
              }}
              aria-label="close"
              style={{ outline: '0', background: 'none' }}
            >
              <img src={CloseIcon} alt="CloseIcon" />
            </IconButton>
          </DialogTitle>

          <DialogContent className="modalContent">
            <Box sx={{ width: '1200px' }}>
              <table
                className="desktop"
                width="100%"
                cellPadding="0"
                cellSpacing="0"
                border="0"
              >
                <thead>
                  <tr className="headerTitle" style={{ fontSize: '12px' }}>
                    <th width="20%" className="subtitle-modal">
                      DATA/HORA
                    </th>

                    <th width="40%" className="subtitle-modal ">
                      <span style={{ marginLeft: 10 }}>ARQUIVO</span>
                    </th>

                    <th width="20%" className="subtitle-modal">
                      <span style={{ marginLeft: 10 }}>TIPO</span>
                    </th>

                    <th width="20%" className="subtitle-modal">
                      <span style={{ marginLeft: 10 }}>VALOR TOTAL</span>
                    </th>

                    <th width="10%" />
                  </tr>
                </thead>

                <tbody>
                  {loading && (
                    <tr>
                      <th className="m10 textAlignCenter" colSpan="18">
                        <LinearProgress
                          style={{ height: '7px' }}
                          id="loading"
                          variant="query"
                        />
                      </th>
                    </tr>
                  )}

                  {dataHistoricList.length ? (
                    <>
                      {Object.keys(groupedData).map((date) => (
                        groupedData[date].map((item, index) => (
                          <tr key={`${date}-${index}`} style={{ borderBottom: index === groupedData[date].length - 1 ? '1px solid #000' : '1px solid #D2D3D7' }}>
                            <td className="subtitle-modal p12" style={{ borderRight: '1px solid' }}>
                              {formatDateAndHours(item.date)}
                            </td>

                            <td
                              className="subtitle-modal"
                              style={{ maxWidth: '350px', borderRight: '1px solid' }}
                            >
                              <span style={{ marginLeft: 10 }}>
                                {item.name}
                              </span>

                            </td>

                            <td className="subtitle-modal" style={{ borderRight: '1px solid' }}>
                              <span style={{ marginLeft: 10 }}>{translateType(item.fileType)}</span>
                            </td>

                            <td className="subtitle-modal" style={{ borderRight: '1px solid' }}>
                              <span style={{ marginLeft: 10 }}>{item.totalValue > 0 ? `R$ ${item.totalValue?.toLocaleString('pt-br', { minimumFractionDigits: 2 })}` : '-'}</span>
                            </td>

                            {index === 0 ?
                              <td style={{ borderBottom: index === groupedData[date].length - 1 ? '1px solid #000' : '1px solid #fff' }}>
                                <IconButton
                                  className="p0 ml5"
                                  onClick={() => {
                                    setModalSettings((modal) =>
                                      modal.map((item) =>
                                        item.name === 'uploadSalesSpreadsheetDelete'
                                          ? {
                                            ...item,
                                            open: true,
                                            data: null
                                          }
                                          : item
                                      )
                                    );
                                    setSelectDateToDelete(item.date);
                                  }}
                                >
                                  <img style={{ width: 12 }} src={TrashIcon} alt="TrashIcon" />
                                </IconButton>
                              </td> : <td style={{ borderBottom: index === groupedData[date].length - 1 ? '1px solid #000' : '1px solid #fff' }} />}
                          </tr>
                        ))
                      ))}

                      <tr className="tr-hr">
                        <td colSpan="10">
                          <hr />
                        </td>
                      </tr>
                    </>
                  ) : (
                    <tr>
                      <th
                        colSpan="13"
                        className="m10 textAlignCenter"
                        style={{
                          background: '#fff',
                          color: '#000',
                          fontSize: '12px'
                        }}
                      >
                        Não há dados a serem exibidos.
                      </th>
                    </tr>
                  )}
                </tbody>
              </table>
            </Box>

          </DialogContent>

        </Dialog>
      )}

      {modalSettings.find((m) => m.name === 'uploadSalesSpreadsheetDelete').open && (
        <Dialog
          fullScreen={
            modalSettings.find((x) => x.name === 'uploadSalesSpreadsheetDelete').fullScreen
          }
          maxWidth="lg"
          open={modalSettings.find((m) => m.name === 'uploadSalesSpreadsheetDelete').open}
          onClose={() => {
            setModalSettings((modal) =>
              modal.map((item) =>
                item.name === 'uploadSalesSpreadsheetDelete'
                  ? {
                    ...item,
                    open: false,
                    data: null
                  }
                  : item
              )
            );
          }}
          aria-labelledby="responsive-dialog-title"
          transitionDuration={0}
        >
          <DialogTitle
            className="modalHeader bold cursorMove"
            id="draggable-dialog-title"
          >
            <Typography>Excluir</Typography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                setModalSettings((modal) =>
                  modal.map((item) =>
                    item.name === 'uploadSalesSpreadsheetDelete'
                      ? {
                        ...item,
                        open: false,
                        data: null
                      }
                      : item
                  )
                );
              }}
              aria-label="close"
              style={{ outline: '0', background: 'none' }}
            >
              <img src={CloseIcon} alt="CloseIcon" />
            </IconButton>
          </DialogTitle>

          <DialogContent className="modalContent">
            <Typography style={{ textAlign: 'center' }}>
              Atenção! <br />
              Esta operação não poderá ser desfeita. <br />
              As vendas serão reprocessadas e o tempo para conclusão pode variar<br />
              de acordo com a data selecionada/volume de dados
            </Typography>
          </DialogContent>

          <DialogActions className="justifyCenter">
            <Button
              className="defaultButton backButton"
              design="outlined"
              onClick={() => {
                setModalSettings((modal) =>
                  modal.map((item) =>
                    item.name === 'uploadSalesSpreadsheetDelete'
                      ? {
                        ...item,
                        open: false,
                        data: null
                      }
                      : item
                  )
                );
              }}
              label="Cancelar"
              color="primary"
            />
            <Button
              className="defaultButton submitButton"
              design="contained"
              label="Confirmar"
              onClick={() => handleDeleteHistoric(selectDateToDelete)}
              color="primary"
              autoFocus
            />
          </DialogActions>
        </Dialog>
      )}

      {confirmCloseMenuDialog && (
        <Dialog
          fullScreen={
            modalSettings.find((x) => x.name === 'deleteMenu').fullScreen
          }
          maxWidth={modalSettings.find((x) => x.name === 'deleteMenu').maxWidth}
          open={confirmCloseMenuDialog}
          onClose={() => handleModalMenuToggle(true)}
          aria-labelledby="responsive-dialog-title"
          transitionDuration={0}
        >
          <DialogTitle
            className="modalHeader navyBlue bold cursorMove"
            id="draggable-dialog-title"
          />

          <DialogContent className="modalContent">
            <Typography>
              Deseja realmente cancelar a edição desse item?
            </Typography>
          </DialogContent>

          <DialogActions className="justifyCenter">
            <Button
              className="defaultButton backButton"
              design="outlined"
              onClick={() => setConfirmCloseMenuDialog(false)}
              label="Cancelar"
              color="primary"
            />
            <Button
              className="defaultButton submitButton"
              design="contained"
              label="Confirmar"
              onClick={() => handleModalMenuToggle(true)}
              color="primary"
              autoFocus
            />
          </DialogActions>
        </Dialog>
      )}

      {openModalMenu && (
        <Dialog
          open={openModalMenu}
          onClose={() => handleModalMenuToggle(false)}
          className="defaultModal menuModal"
          maxWidth="md"
          fullWidth
          PaperComponent={PaperComponent}
        >
          <DialogTitle
            style={{ cursor: 'move' }}
            id="draggable-dialog-title"
            className="modalHeader"
          >
            <Typography
              className="bold textAlignCenter"
              style={{
                fontSize: 16
              }}
            >
              CADASTRO DE CARDÁPIO
            </Typography>

            <IconButton
              edge="start"
              color="inherit"
              onClick={() => handleModalMenuToggle(false)}
              aria-label="close"
              style={{ outline: '0', background: 'none' }}
            >
              <img src={CloseIcon} alt="CloseIcon" />
            </IconButton>
          </DialogTitle>

          <DialogContent className="p16">{BodyModal}</DialogContent>
        </Dialog>
      )}

      <Popover
        open={popoverOpen}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        PaperProps={{
          style: { padding: '10px', borderRadius: '4px', maxWidth: '400px' }
        }}
        className="columnConfigPopover menuColumnConfig"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <div className="dFlex alignStart justifyStart flexColumn">
          <span className="fontSizeDefault w100 pb5 mb2" style={{ borderBottom: 'solid 1px #A0A1A7' }}>
            VENDAS
          </span>
          {renderShowColumnItem('CARD. R$', 'PREÇO VENDA')}
          {renderShowColumnItem('MÉD. R$', 'MÉDIO R$')}
          {renderShowColumnItem('DESC.', 'DESCONTO')}
          {renderShowColumnItem('QTDE.', 'QTDE')}
          {renderShowColumnItem('TOTAL R$', 'TOTAL R$')}
          {renderShowColumnItem('MARG.', 'MARGEM')}
          
          <span className="fontSizeDefault w100 pb5 mt8 mb2" style={{ borderBottom: 'solid 1px #A0A1A7' }}>
            CUSTOS
          </span>
          {renderShowColumnItem('CUSTO TOTAL R$', 'TOTAL R$')}
          {renderShowColumnItem('CUSTO NOMINAL', 'NOMINAL')}
          {renderShowColumnItem('CUSTO % PROJ.', '% PROJETADO')}
          {renderShowColumnItem('CUSTO % REAL', '% REAL')}
        </div>
      </Popover>

      <Snackbar
        open={errFileMessageindexOne}
        autoHideDuration={5000}
        onClose={() => setErrFileMessageIndexOne('')}
      >
        <Alert
          onClose={() => setErrFileMessageIndexOne('')}
          severity="error"
        >
          {errFileMessageindexOne}
        </Alert>
      </Snackbar>

      {errFileMessageindexTwo && (
        <Snackbar
          open={!!errFileMessageindexTwo}
          autoHideDuration={5000}
          onClose={() => setErrFileMessageIndexTwo('')}
          style={{
            bottom: '100px'
          }}
        >
          <Alert
            onClose={() => setErrFileMessageIndexTwo('')}
            severity="error"
          >
            {errFileMessageindexTwo}
          </Alert>
        </Snackbar>
      )}

      <Snackbar
        open={toastProps?.message}
        autoHideDuration={2000}
        onClose={() => {
          setToastProps({
            message: '',
            severity: ''
          });
        }}
      >
        <Alert 
          onClose={() => {
            setToastProps({
              message: '',
              severity: ''
            });
          }} 
          severity={toastProps?.severity}
        >
          {toastProps?.message}
        </Alert>
      </Snackbar>

      <CommonAlert
        open={alertErr}
        onClose={() => {
          setAlertErr(false);
        }}
        severity={sucess}
        indexMessage={alertMessage}
        statusCode={statusCode}
        messagePersonal={alertMessage}
      />
    </Layout>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.user,
  menuInfo: state.menu,
  cardsInfo: state.cards
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(MenuActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(App);
