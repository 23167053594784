/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/no-unstable-nested-components */
import React, { useState, useEffect, useCallback } from 'react';

import {
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
  Dialog
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import axios from 'axios';
import companies from 'data/companies';
import InputMask from 'react-input-mask';
import { connect } from 'react-redux';

import Button from 'components/Button/button';
import FullScreenLoading from 'components/FullScreenLoading';
import PaperComponent from 'components/PaperComponent';
import CloseIconNavy from 'images/icons/signals/closesAndCancels/closeTransparent.svg';
import StepView from 'pages/admin/stores/components/StepView';
import { Container } from 'styles/general';
import Environment from 'utils/environments';

function UserForm({
  currentUser,
  onClose,
  onCancel,
  open,
  translateProfile,
  setToast,
  setMessage,
  setStatusCode,
  getUsers,
  callSettings,
  userInfo,
  userObj,
  setUserObj,
  selectedGroupObj,
  openedByUserSelect
}) {
  const environments = Environment(process.env.REACT_APP_ENV);
  const [loading, setLoading] = useState(false);
  const [profiles, setProfiles] = useState([]);
  const [operationalUsers, setOperationalUsers] = useState([])
  const [restaurantOptions, setRestaurantOptions] = useState([]);
  const [requiredFields, setRequiredFields] = useState([]);
  const [user, setUser] = useState({
    companies: currentUser?.companies || {},
    email: currentUser?.email || '',
    firstName: currentUser?.firstName || '',
    id: currentUser?.id || currentUser?._id || '',
    lastName: currentUser?.lastName || '',
    password: currentUser?.password || '',
    profile: currentUser?.profile || '',
    telephone: currentUser?.telephone || '',
    groups: selectedGroupObj
      ? [selectedGroupObj?.group]
      : currentUser?.companies?.groups || [],
    subgroups:
      currentUser?.companies?.groups?.map((el) => el?.subgroups).flat() || [],
    stores:
      currentUser?.companies?.groups
        ?.map((el) => el?.subgroups?.map((item) => item?.stores))
        .flat()
        .flat() || [],
    workplaces: currentUser?.workplaces || '',
    operationalUsers: currentUser?.operationalUsers || [],
    countdownRenewOperationalUsers: 600
  });
  const userLocal = JSON.parse(localStorage.getItem('companies'));

  const config = {
    headers: {
      User: `${userLocal?.user}`
    }
  };

  const validateRequiredFields = () => {
    let emptyItems = [];
    const isAdminProfile = user?.profile?.name === 'admin';

    if (!user.email) {
      emptyItems = [...emptyItems, 'email'];
    }

    if (!user.firstName) {
      emptyItems = [...emptyItems, 'firstName'];
    }

    if (!user?.id) {
      emptyItems = [...emptyItems, 'id'];
    }

    if (!user.firstName) {
      emptyItems = [...emptyItems, 'firstName'];
    }

    if (!user.lastName) {
      emptyItems = [...emptyItems, 'lastName'];
    }

    if (!user.password) {
      emptyItems = [...emptyItems, 'password'];
    }

    if (!user.profile) {
      emptyItems = [...emptyItems, 'profile'];
    }

    if (!user.telephone) {
      emptyItems = [...emptyItems, 'telephone'];
    }

    if (!user.groups?.length && !isAdminProfile) {
      emptyItems = [...emptyItems, 'groups'];
    }

    if (!user.subgroups?.length && !isAdminProfile) {
      emptyItems = [...emptyItems, 'subgroups'];
    }

    if (!user.stores?.length && !isAdminProfile) {
      emptyItems = [...emptyItems, 'stores'];
    }

    setRequiredFields(emptyItems);

    return (
      !!user.email &&
      !!user.firstName &&
      !!user?.id &&
      !!user.firstName &&
      !!user.lastName &&
      !!user.password &&
      !!user.profile &&
      !!user.telephone &&
      (isAdminProfile || (setUserObj ? !selectedGroupObj?.group : true)
        ? true
        : user.groups.length && user.subgroups.length && user.stores.length)
    );
  };

  /* Restaurant Groups Obj Builder */
  const pluckIds = (arr, param) => arr.map((obj) => obj[param]);

  const buildStores = (subGroup) => {
    const stores = subGroup?.stores?.filter((store) =>
      pluckIds(user?.stores, 'storeId').includes(store?.storeId)
    );

    return stores.map((store) => ({
      storeId: store?.storeId,
      description: store?.description
    }));
  };

  const buildSubGroups = (group) => {
    const subGroups = group?.subgroups?.filter((subGroup) =>
      pluckIds(user?.subgroups, 'subgroupId').includes(subGroup?.subgroupId)
    );

    return subGroups.map((subGroup) => ({
      subgroupId: subGroup?.subgroupId,
      description: subGroup?.description,
      stores: buildStores(subGroup)
    }));
  };

  const onSubmitUser = () => {
    if (!validateRequiredFields()) {
      setMessage({
        description: 'Por favor preencha os campos obrigatórios',
        status: 'error'
      });

      return;
    }

    setLoading(true);
    setRequiredFields([]);

    const formattedGroups = [
      user?.groups.length > 1
        ? user?.groups?.map((group) => ({
            _id: group?._id,
            description: group?.description,
            subgroups: buildSubGroups(group)
          }))
        : user?.groups?._id
        ? {
            _id: user?.groups?._id,
            description: user?.groups?.description,
            subgroups: buildSubGroups(user?.groups)
          }
        : null
    ];

    const body = {
      email: user?.email,
      firstName: user?.firstName,
      id: user?.id,
      lastName: user?.lastName,
      password: user?.password,
      telephone: user?.telephone,
      companies:
        user?.groups?.length || user?.groups?._id
          ? {
              groups: formattedGroups
            }
          : null,
      profile: [user?.profile],
      workplaces: user?.workplaces ? [user?.workplaces] : [],
      user: userInfo.user,
      operationalUsers: user?.operationalUsers ? user?.operationalUsers : [],
      countdownRenewOperationalUsers: 600
    };

    if (currentUser) {
      const userId = user?.id || user?._id;
      axios
        .put(
          `${environments.auth}/users/${userId}/profile/${user?.profile?.name}`,
          body,
          config
        )
        .then(() => {
          setLoading(false);
          setToast(true);

          setMessage({
            description: 'Usuário atualizado com sucesso',
            status: 'success'
          });

          onClose();

          getUsers && getUsers(callSettings?.page);
        })
        .catch((error) => {
          setLoading(false);
          setToast(true)
          setMessage({
            description: error.response.data.message,
            status: 'error'
          });

          setStatusCode(error?.response?.status);
        });
    } else {
      axios
        .post(`${environments.auth}/users`, body, config)
        .then((response) => {
          setLoading(false);

          if (setUserObj) {
            setUserObj({
              ...userObj,
              subgroup: body?.companies?.groups?.length
                ? body?.companies?.groups[0].subgroups[0]
                : null,
              users: userObj?.users?.length
                ? [...userObj?.users, response?.data].flat()
                : [response?.data]
            });
          }

          onClose();

          setToast(true);
          setMessage({
            description: 'Usuário criado com sucesso',
            status: 'success'
          });

          getUsers && getUsers(callSettings?.page);
        })
        .catch((error) => {
          setLoading(false);
          setToast(true);
          setMessage({
            description: error?.response?.data?.message,
            status: 'error'
          });
        });
    }
  };

  const isAdmin = userInfo?.profile?.[0]?.[0]?.name === 'admin';
  const getProfiles = () => {
    const isAdmin = userInfo?.profile?.[0]?.[0]?.name === 'admin';

    axios
      .get(`${environments.auth}/groups${isAdmin ? '?restaurantId=0' : ''}`)
      .then((response) => {
        setProfiles(
          !isAdmin
            ? response.data?.filter((el) => el.name !== 'admin')
            : response.data
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getOperationalUsers = () => {
    // Vamos buscar os usuários operacionais apenas quando for usuário gestor ou admin restaurante
    const canSearch = (userInfo?.profile?.[0]?.[0]?.name === 'managers' || userInfo?.profile?.[0]?.[0]?.name === 'restaurants')
    const getId = userInfo.companiesActive.id

    if (canSearch) {
      // (11/09) Vamos buscar apenas por usuários do tipo "produção (producers)", "cozinha (manufactures)" e "estoquista (stockists)"
      const acceptedProfiles = "producers,manufactures,stockists"

      axios.get(`${environments.auth}/users/?restaurantIds=${getId}&profile=${acceptedProfiles}&page=1&size=1000`)
      .then((response) => {
        if (response?.data?.content) {
          setOperationalUsers(response?.data?.content)
        }
      })
      .catch((error) => {
        console.log(`>>> Erro ao buscar usuários operacionais`)
        console.log(error)
      })
    }
  }

  const groupsOptions = userInfo?.companies?.groups?.map((item) => item);

  const optionsGroupNotAdmin = restaurantOptions?.filter((el) =>
    groupsOptions?.map((item) => item?.id)?.includes(el?._id)
  );

  const optionsSubgroup = groupsOptions
    .map((group) => group.subGroups)
    .flat()
    .filter((item) =>
      restaurantOptions
        .map((el) => el?.subgroups)
        .flat()
        .map((el) => el?.subgroupId)
        .includes(item?.id)
    );
  const formatSubgroupOptions = restaurantOptions
    .map((el) => el?.subgroups)
    .flat()
    .filter((item) =>
      optionsSubgroup.map((subgroup) => subgroup.id).includes(item.subgroupId)
    );
  const optionStores = groupsOptions
    .map((group) => group.subGroups)
    .flat()
    .map((subgroup) => subgroup.stores)
    .flat();
  const formatStoreOptions = restaurantOptions
    .map((el) => el.subgroups)
    .flat()
    .map((item) => item.stores)
    .flat()
    .filter((store) =>
      optionStores.map((option) => option.id).includes(store.storeId)
    );

  const getOptions = useCallback(() => {
    if (isAdmin) {
      return restaurantOptions;
    }
    if (!isAdmin) {
      return optionsGroupNotAdmin;
    }
  }, [isAdmin, optionsGroupNotAdmin, restaurantOptions]);

  const getRestaurantsOptions = () => {
    axios
      .get(`${environments.restaurants}/groups?size=100`)
      .then((response) => {
        setRestaurantOptions(response?.data?.content);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getProfiles();
    getRestaurantsOptions();
    getOperationalUsers()
  }, []);

  useEffect(() => {
    const restaurantOptionsAccordingUser = restaurantOptions?.find((el) =>
      currentUser?.companies?.groups?.map((item) => item?.id)?.includes(el?._id)
    );

    setUser({
      ...user,
      profile:
        setUserObj && !openedByUserSelect
          ? profiles?.find((el) => el?.name === 'restaurants')
          : profiles?.find(
              (el) => currentUser?.profile[0]?.name === el?.name
            ) || '',
      workplaces: currentUser?.workplaces?.length
        ? companies?.workplaces?.find(
            (el) => currentUser?.workplaces[0]?.name === el?.name
          )
        : '',
      groups: selectedGroupObj
        ? [
            restaurantOptions?.find(
              (el) => el?._id === selectedGroupObj?.group?._id
            )
          ]
        : restaurantOptionsAccordingUser || [],
      subgroups:
        restaurantOptionsAccordingUser?.subgroups?.filter((item) =>
          currentUser?.companies?.groups
            .map((el) => el?.subGroups)
            .flat()
            .map((el) => el?.id)
            .includes(item?.subgroupId)
        ) || [],
      stores:
        restaurantOptionsAccordingUser?.subgroups
          ?.map((subgroup) => subgroup.stores)
          .flat()
          .filter((item) =>
            currentUser?.companies?.groups
              ?.map((el) => el?.subGroups)
              .flat()
              .map((el) => el.stores)
              .flat()
              .map((el) => el?.id)
              .includes(item?.storeId)
          ) || []
    });
  }, [profiles, restaurantOptions]);

  return (
    <Dialog
      className="defaultModal userFormDialog largeModal"
      fullWidth
      open={open}
      onClose={() => (onCancel ? onCancel() : onClose())}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      transitionDuration={0}
    >
      <Container className="w100">
        <DialogTitle
          className="modalHeader bold textAlignCenter cursorMove"
          id="draggable-dialog-title"
        >
          <Typography>
            {setUserObj && !openedByUserSelect
              ? 'CADASTRE O PRIMEIRO USUÁRIO PARA ESTA LOJA'
              : 'CADASTRO DE USUÁRIO'}{' '}
          </Typography>

          {setUserObj && (
            <div style={{ marginLeft: 'auto', marginRight: 30 }}>
              <StepView currentStep="5" />
            </div>
          )}

          <IconButton
            edge="start"
            color="inherit"
            onClick={() => {
              onCancel ? onCancel() : onClose();
            }}
            aria-label="close"
            style={{ outline: '0', background: 'none', align: 'right' }}
          >
            <img
              style={{ width: '16px' }}
              src={CloseIconNavy}
              alt="CloseIconNavy"
            />
          </IconButton>
        </DialogTitle>

        <FullScreenLoading display={loading} description="Salvando" />

        <div className="cardDefault pt16" style={{ borderRadius: '0' }}>
          <Grid className="m0" container xs={12} sm={12} spacing={4}>
            <Grid
              item
              xs={4}
              sm={4}
              className={`pl0 ${
                requiredFields?.includes('firstName') && 'labelError'
              }`}
            >
              <TextField
                label="Primeiro Nome"
                onChange={(e) => {
                  setUser({
                    ...user,
                    firstName: e?.target?.value
                  });
                }}
                name="description"
                variant="outlined"
                size="small"
                value={user?.firstName}
                fullWidth
                required="required"
                inputProps={{ maxLength: 50 }}
                className="textFieldSmall"
                placeholder="Digite aqui..."
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>

            <Grid
              item
              xs={4}
              sm={4}
              className={requiredFields?.includes('lastName') && 'labelError'}
            >
              <TextField
                label="Sobrenome"
                onChange={(e) => {
                  setUser({
                    ...user,
                    lastName: e?.target?.value
                  });
                }}
                name="description"
                variant="outlined"
                size="small"
                value={user?.lastName}
                fullWidth
                required
                inputProps={{ maxLength: 50 }}
                className="textFieldSmall"
                placeholder="Digite aqui..."
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>

            <Grid
              item
              xs={4}
              sm={4}
              className={`pr0 ${
                requiredFields?.includes('telephone') && 'labelError'
              }`}
            >
              <InputMask
                mask="(99) 99999-9999"
                maskChar=""
                value={user?.telephone}
                onChange={(e) => {
                  setUser({
                    ...user,
                    telephone: e?.target?.value
                  });
                }}
              >
                {() => (
                  <TextField
                    label="Número de Telefone"
                    name="description"
                    variant="outlined"
                    size="small"
                    fullWidth
                    required="required"
                    inputProps={{ maxLength: 50 }}
                    className="textFieldSmall"
                    placeholder="Digite aqui..."
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                )}
              </InputMask>
            </Grid>
          </Grid>

          <Grid className="m0 pt12" container xs={12} sm={12} spacing={4}>
            <Grid
              item
              xs={4}
              sm={4}
              className={`pl0 ${
                requiredFields?.includes('email') && 'labelError'
              }`}
            >
              <TextField
                label="E-mail"
                onChange={(e) => {
                  setUser({
                    ...user,
                    email: e?.target?.value
                  });
                }}
                name="description"
                variant="outlined"
                size="small"
                value={user?.email}
                fullWidth
                required="required"
                inputProps={{ maxLength: 50 }}
                className="textFieldSmall"
                placeholder="Digite aqui..."
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>

            <Grid
              item
              xs={4}
              sm={4}
              className={requiredFields?.includes('id') && 'labelError'}
            >
              <TextField
                label="Usuário"
                autoComplete="off"
                onChange={(e) => {
                  setUser({
                    ...user,
                    id: e?.target?.value
                  });
                }}
                name="description"
                variant="outlined"
                size="small"
                value={user?.id || user?._id}
                fullWidth
                required="required"
                inputProps={{ maxLength: 50 }}
                className="textFieldSmall lowercaseInput"
                placeholder="Digite aqui..."
                InputLabelProps={{
                  shrink: true
                }}
                disabled={currentUser}
              />
            </Grid>

            <Grid
              item
              xs={4}
              sm={4}
              className={`pr0 ${
                requiredFields?.includes('password') && 'labelError'
              }`}
            >
              <TextField
                label="Senha"
                type="password"
                autoComplete="off"
                onChange={(e) => {
                  setUser({
                    ...user,
                    password: e?.target?.value
                  });
                }}
                name="description"
                variant="outlined"
                size="small"
                value={user?.password}
                fullWidth
                required="required"
                inputProps={{ maxLength: 50 }}
                className="textFieldSmall"
                placeholder="Digite aqui..."
                InputLabelProps={{
                  shrink: true
                }}
                disabled={currentUser}
              />
            </Grid>
          </Grid>

          <Grid className="m0 pt12" container xs={12} sm={12} spacing={4}>
            <Grid
              key={Math.random()}
              item
              xs={4}
              sm={4}
              className={`pl0 ${
                requiredFields?.includes('profile') && 'labelError'
              }`}
            >
              <Autocomplete
                required
                options={
                  setUserObj && !openedByUserSelect
                    ? profiles?.filter((el) => el?.name === 'restaurants')
                    : profiles
                }
                value={user?.profile}
                getOptionLabel={(option) => translateProfile(option?.name)}
                onChange={(_, value) => {
                  setUser({
                    ...user,
                    profile: {
                      id: value.id,
                      name: value.name
                    }
                  });
                }}
                renderOption={(params) => (
                  <Typography style={{ fontSize: '12px', fontFamily: 'Lato' }}>
                    {translateProfile(params?.name)}
                  </Typography>
                )}
                className="autocompleteSmall"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    id={params.id}
                    type="text"
                    name="profile"
                    label="Perfil"
                    variant="outlined"
                    required
                    placeholder="Selecione..."
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                )}
              />
            </Grid>

            {(user?.profile?.name === 'managers' || user?.profile?.name === 'restaurants') && (
              <Grid item xs={4} sm={4}>
                <Autocomplete
                  key={Math.random()}
                  options={operationalUsers}
                  multiple
                  limitTags={2}
                  value={user?.operationalUsers}
                  getOptionLabel={(option) => option?._id}
                  onChange={(_, value) => {
                    if (value?.length) {
                      setUser({
                        ...user,
                        operationalUsers: value
                      });
                    } else {
                      setUser({
                        ...user,
                        operationalUsers: []
                      });
                    }
                  }}
                  renderOption={(params) => (
                    <Typography
                      style={{ fontSize: '12px', fontFamily: 'Lato' }}
                    >
                      {params?._id}
                    </Typography>
                  )}
                  className={`autocompleteSmall`}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      id={params.id}
                      type="text"
                      required
                      name="operationalUsers"
                      label="Usuários Operação"
                      variant="outlined"
                      placeholder="Selecione..."
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  )}
                />
              </Grid>
            )}

            {user?.profile?.name === 'producers' && (
              <Grid item xs={4} sm={4}>
                <Autocomplete
                  required
                  options={companies?.workplaces}
                  value={user?.workplaces}
                  getOptionLabel={(option) => option?.name}
                  onChange={(_, value) => {
                    setUser({
                      ...user,
                      workplaces: value
                    });
                  }}
                  renderOption={(params) => (
                    <Typography
                      style={{ fontSize: '12px', fontFamily: 'Lato' }}
                    >
                      {params?.name}
                    </Typography>
                  )}
                  className="autocompleteSmall"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      id={params.id}
                      type="text"
                      name="workplaces"
                      label="Local de Trabalho"
                      variant="outlined"
                      placeholder="Selecione..."
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  )}
                />
              </Grid>
            )}
          </Grid>

          {user?.profile?.name !== 'admin' &&
            (setUserObj ? selectedGroupObj?.group : true) && (
              <Grid className="m0 pt12" container xs={12} sm={12} spacing={4}>
                <Grid item xs={4} sm={4} className="pl0">
                  <Autocomplete
                    required
                    options={getOptions()}
                    value={
                      selectedGroupObj ? selectedGroupObj?.group : user?.groups
                    }
                    getOptionLabel={(option) => option?.description}
                    disabled={selectedGroupObj}
                    onChange={(_, value) => {
                      setUser({
                        ...user,
                        groups: value,
                        subgroups: [],
                        stores: []
                      });
                    }}
                    renderOption={(params) => (
                      <Typography
                        style={{ fontSize: '12px', fontFamily: 'Lato' }}
                      >
                        {params?.description}
                      </Typography>
                    )}
                    className={`autocompleteSmall ${
                      requiredFields?.includes('groups') && 'labelError'
                    }`}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        id={params.id}
                        type="text"
                        required
                        name="groups"
                        label="Grupos"
                        variant="outlined"
                        placeholder="Selecione..."
                        InputLabelProps={{
                          shrink: true
                        }}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={4} sm={4}>
                  <Autocomplete
                    key={Math.random()}
                    options={
                      isAdmin
                        ? selectedGroupObj?.group?.subgroups ||
                          user?.groups?.subgroups ||
                          []
                        : formatSubgroupOptions
                    }
                    multiple
                    limitTags={2}
                    value={user?.subgroups}
                    disabled={selectedGroupObj ? false : !user?.groups}
                    getOptionLabel={(option) => option?.description}
                    onChange={(_, value) => {
                      if (value?.length) {
                        setUser({
                          ...user,
                          subgroups: value
                        });
                      } else {
                        setUser({
                          ...user,
                          subgroups: [],
                          stores: []
                        });
                      }
                    }}
                    renderOption={(params) => (
                      <Typography
                        style={{ fontSize: '12px', fontFamily: 'Lato' }}
                      >
                        {params?.description}
                      </Typography>
                    )}
                    className={`autocompleteSmall ${
                      requiredFields?.includes('subgroups') && 'labelError'
                    }`}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        id={params.id}
                        type="text"
                        required
                        name="subgroups"
                        label="Subgrupos"
                        variant="outlined"
                        placeholder="Selecione..."
                        InputLabelProps={{
                          shrink: true
                        }}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={4} sm={4} className="pr0">
                  <Autocomplete
                    key={Math.random()}
                    options={
                      isAdmin
                        ? user?.subgroups?.map((el) => el?.stores).flat()
                        : formatStoreOptions
                    }
                    multiple
                    limitTags={1}
                    value={user?.stores}
                    disabled={!user?.subgroups.length}
                    getOptionLabel={(option) => option?.description}
                    onChange={(_, value) => {
                      setUser({
                        ...user,
                        stores: value
                      });
                    }}
                    renderOption={(params) => (
                      <Typography
                        style={{ fontSize: '12px', fontFamily: 'Lato' }}
                      >
                        {params?.description}
                      </Typography>
                    )}
                    className={`autocompleteSmall ${
                      requiredFields?.includes('stores') && 'labelError'
                    }`}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        id={params.id}
                        type="text"
                        required
                        name="restaurants"
                        label="Restaurantes"
                        variant="outlined"
                        placeholder="Selecione..."
                        InputLabelProps={{
                          shrink: true
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            )}
        </div>

        <Grid
          className="dFlex justifyEnd bgWhite pr16 pt0 pb0 pl0"
          item
          xs={12}
          sm={12}
        >
          <Button
            className="defaultButton backButton mr20"
            design="outlined"
            label="Voltar"
            onClick={() => onClose()}
          />

          <Button
            className="defaultButton submitButton"
            type="submit"
            label="Salvar"
            design="contained"
            onClick={() => onSubmitUser()}
          />
        </Grid>
      </Container>
    </Dialog>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.user
});

export default connect(mapStateToProps)(UserForm);
