/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
import React, { useState, useEffect, Suspense } from 'react';

import {
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  Fab,
  Fade,
  IconButton,
  LinearProgress,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Add } from '@material-ui/icons';
import { Autocomplete, Pagination } from '@material-ui/lab';
import axios from 'axios';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Button from 'components/Button/button';
import CommonAlert from 'components/CommonAlert/CommonAlert';
import ProvidersAssociation from 'components/Dialogs/ProvidersAssociation';
import RestaurantsDialog from 'components/Dialogs/RestaurantsDialog';
import SkuModal from 'components/Dialogs/SkuModal/SkuModal';
import SkuPreview from 'components/Dialogs/SkuPreview';
import ItemsPerPage from 'components/ItemsPerPage';
import OrderBy from 'components/OrderBY';
import PageTitleAndFilter from 'components/PageTitleAndFilter';
import PaperComponent from 'components/PaperComponent';
import TitleHead from 'components/TitleHead';
import Layout from 'containers/layouts/layout';
import editEan from 'images/icons/arrows/editEan.svg';
import AddIcon from 'images/icons/signals/addsAndCreates/add.svg';
import addEan from 'images/icons/signals/addsAndCreates/addEan.svg';
import RegisterProductModal from 'pages/catalog/products/ProductForm';
import getOptions from 'services/getOptions';
import { getClientIp } from 'services/getPublicIp';
import axiosInstanceCatalog from 'services/middlewares/apis/catalog_validate';
import makePost from 'services/useFetch/makePost';
import makePut from 'services/useFetch/makePut';
import * as EanActions from 'store/actions/eanInfos';
import * as ProductsActions from 'store/actions/products';
import { Container, GlobalStyle } from 'styles/general';
import Environment from 'utils/environments';
import {
  getFilterOptions,
  handleFilterOptions,
  handleMultipleFilters
} from 'utils/handleFilterActions';

import ComparationDialog from './dialogs/comparationSkuDialog';
import EditEan from './dialogs/EditEanDialogs';
import RestaurantsDialogs from './dialogs/RestaurantsDialogs';
import { ButtonStatus } from './styles';

const SimilarSkusDialog = React.lazy(() =>
  import('components/Dialogs/SimilarSkus')
);

export function ValidationLength(data, length = 24) {
  if (!data) {
    return (
      <td
        style={{ border: 'none', fontFamily: 'Lato' }}
        align="center"
        variant="body"
      >
        -
      </td>
    );
  }

  if (data.length > length) {
    return (
      <Tooltip
        TransitionComponent={Fade}
        TransitionProps={{ timeout: 600 }}
        title={data}
      >
        <td
          style={{ border: 'none', fontFamily: 'Lato' }}
          align="left"
          variant="body"
        >
          {`${data.substring(0, length)}...`}
        </td>
      </Tooltip>
    );
  }

  return (
    <td
      style={{ border: 'none', fontFamily: 'Lato' }}
      align="left"
      variant="body"
    >
      {data}
    </td>
  );
}

export function ValidationProducers(data) {
  if (!data) {
    return (
      <td
        style={{ border: 'none', fontFamily: 'Lato' }}
        align="center"
        variant="body"
      >
        -
      </td>
    );
  }

  if (data.length > 10) {
    return (
      <Tooltip
        TransitionComponent={Fade}
        TransitionProps={{ timeout: 600 }}
        title={data}
      >
        <td
          style={{ border: 'none', fontFamily: 'Lato' }}
          align="left"
          variant="body"
        >
          {`${data.substring(0, 10)}...`}
        </td>
      </Tooltip>
    );
  }

  return (
    <td
      style={{ border: 'none', fontFamily: 'Lato' }}
      align="left"
      variant="body"
    >
      {data}
    </td>
  );
}

export function ValidationCategories(data) {
  if (!data) {
    return (
      <td
        style={{ border: 'none', fontFamily: 'Lato' }}
        align="center"
        variant="body"
      >
        -
      </td>
    );
  }

  if (data.length > 10) {
    return (
      <Tooltip
        TransitionComponent={Fade}
        TransitionProps={{ timeout: 600 }}
        title={data}
      >
        <td
          style={{ border: 'none', fontFamily: 'Lato' }}
          align="left"
          variant="body"
        >
          {`${data.substring(0, 10)}...`}
        </td>
      </Tooltip>
    );
  }

  return (
    <td
      style={{ border: 'none', fontFamily: 'Lato' }}
      align="left"
      variant="body"
    >
      {data}
    </td>
  );
}

export function FormatDate(date) {
  if (date) {
    const p = date.split(/\D/g);
    return [p[2], p[1], p[0]].join('/');
  }

  return '-';
}

function Catalog({ userInfo, setEanLocalInfo, setEditProducts, productInfo }) {
  const smallScreen = useMediaQuery('(max-width: 1366px)');
  const isAdmin = userInfo?.profile?.[0]?.[0]?.name === 'admin';
  const isAdminMaster = userInfo?.profile?.[0]?.[0]?.name === 'admin_master';
  const [callSettings, setCallSettings] = useState({
    getId: userInfo.companiesActive.id,
    page: 1,
    totalPages: 0,
    totalElements: 0,
    size: 100,
    mainContent: [],
    numberOfElements: 0,
    filters: [],
    loading: false
  });
  const {
    getId,
    page,
    size,
    totalPages,
    mainContent,
    loading,
    totalElements,
    numberOfElements,
    filters
  } = callSettings;

  const [filtersChanged, setFiltersChanged] = useState(filters);
  const [modalSettings, setModalSettings] = useState([
    { id: 1, name: 'filters', open: false, fullScreen: null, maxWidth: null },
    {
      id: 2,
      name: 'restaurantsAssociation',
      open: false,
      fullScreen: false,
      maxWidth: 'md',
      data: [],
      descriptionSku: null
    },
    {
      id: 3,
      name: 'productsAssociation',
      open: false,
      fullScreen: false,
      maxWidth: 'sm'
    },
    {
      id: 4,
      name: 'providersAssociation',
      open: false,
      fullScreen: false,
      maxWidth: 'sm',
      sku: null
    }
  ]);

  const [skusList, setSkusList] = useState([]);
  const [products, setProducts] = useState([]);
  const [items, setItems] = useState([]);
  const [packageTypes, setPackageTypes] = useState([]);
  const [categories, setCategories] = useState([]);
  const [brands, setBrands] = useState('');
  const [consumptionUnits, setConsumptionUnits] = useState([]);
  const [storageLocations, setStorageLocations] = useState([]);
  const [periodicities, setPeriodicities] = useState([]);
  const [providers, setProviders] = useState([]);
  const [brandsOptions, setBrandsOptions] = useState();
  const [selectedEan, setSelectedEan] = useState();
  const [eanFilterValue, setEanFilterValue] = useState();
  const [updateMainList, setUpdateMainList] = useState(false);
  const environments = Environment(process.env.REACT_APP_ENV);
  const [unitsMeasurements, setUnitsMeasurements] = useState([]);
  const [selectedSku, setSelectedSku] = useState();
  const [eanInfo, setEanInfo] = useState();
  const [similarSkuCurrentData, setSimilarSkuCurrentData] = useState(null);
  const [modalStates, setModalStates] = useState({
    modalComparation: false,
    modalSku: false,
    modalRestaurants: false,
    editEan: false
  });
  const [updateOptions, setUpdateOptions] = useState({
    brands: false,
    packageTypes: false
  });
  const [comparationCurrentData, setComparationCurrentData] = useState({});
  const [toastOpen, setToastOpen] = useState(false);
  const [toastProps, setToastProps] = useState();
  const [associateSkuPopover, setAssociateSkuPopover] = useState({
    open: false,
    currentSku: '',
    loading: false
  });
  const [skuPreviewDialog, setSkuPreviewDialog] = useState({
    open: false,
    currentSku: '',
    loading: false
  });
  const [storeOptions, setStoreOptions] = useState([]);
  const [selectedStores, setSelectedStores] = useState([]);
  const [isEditable, setIsEditable] = useState(true);
  const [findEan, setFindEan] = useState(false);
  const [groupIdFilter, setGroupIdFilter] = useState('');
  const [canAssociateCurrentSku, setCanAssociateCurrentSku] = useState(false);
  const [restaurantIdFilter, setRestaurantIdFilter] = useState('');
  const [publicIp, setPublicIp] = useState();
  const [similarSkusOpen, setSimilarSkusOpen] = useState(false);
  const [isOnlyViewMode, setIsOnlyViewMode] = useState(false);
  const [similarSkuFilterValue, setSimilarSkuFilterValue] = useState('');
  const [similarSkuProvider, setSimilarSkuProvider] = useState('');
  const [skuAssociation, setSkuAssociation] = useState();
  const [enableOpenSkuModal, setEnableOpenSkuModal] = useState(false);
  const [comparationCardFilterValue, setComparationCardFilterValue] =
    useState();

  const [dialogRestaurantSettings, setDialogRestaurantSettings] =
    useState(false);
  const [dialogRestaurantData, setDialogRestaurantData] = useState([]);
  const [noteMeasurement, setNoteMeasurement] = useState('')

  const [unifyItemsPopover, setUnifyItemsPopover] = useState({
    open: false,
    items: [],
    refItem: ''
  });
  const [
    selectedItemsToUnifyInfos,
    setSelectedItemToUnifyInfos
  ] = useState([]);
  const [filterFields, setFilterFields] = useState([
    [
      {
        label: 'EAN',
        filterName: 'ean',
        placeHolder: 'Digite aqui...',
        type: 'fieldSearch',
        size: 'large',
        clearable: true
      },
      {
        label: 'SKU',
        filterName: 'skuDescription',
        filterNameSecond: 'skuId',
        placeHolder: 'Selecione...',
        type: 'autoComplete',
        urlPath: `${environments.catalog}/skus/platform/crossbloom`,
        key: 'skus',
        optionReference: 'description',
        options: [],
        size: 'large',
        searchByTermMode: true,
        isBloom: true,
        disableFilterNameSecond: false
      },
      {
        label: 'RESTAURANTES',
        filterName: 'restaurantId',
        placeHolder: 'Selecione...',
        type: 'autoComplete',
        urlPath: `${environments.restaurants}/companies`,
        key: 'stores',
        page: 1,
        optionReference: 'companyName',
        options: [],
        size: 'large'
      },
      {
        defaultValue: 'Não catalogados',
        type: 'multipleRadiobox',
        checkBoxInputs: [
          {
            name: 'Catalogados',
            referenceName: 'showPackages',
            value: 'false'
          },
          {
            name: 'Não catalogados',
            referenceName: 'showPackages',
            value: 'true'
          }
        ]
      },
      // {
      //   label: 'Com sugestão',
      //   filterName: 'wCrawlerInfo',
      //   type: 'switchField'
      // }
    ],
    [
      {
        label: 'GRUPO',
        filterName: 'groupId',
        placeHolder: 'Selecione...',
        ignoreFilterContent: true,
        type: 'autoComplete',
        urlPath: `${environments.restaurants}/groups`,
        key: 'groups',
        page: 1,
        optionReference: 'description',
        options: [],
        size: 'large'
      },
      {
        label: 'FORNECEDOR',
        filterName: 'providerName',
        filterNameSecond: 'providerIds',
        placeHolder: 'Selecione...',
        type: 'autoComplete',
        urlPath: `${environments.catalog}/suppliers/groups`,
        key: 'providersIdList',
        optionReference: 'description',
        options: [],
        size: 'large',
        filterParamsOff: true,
        disableFilterNameSecond: false,
        searchByTermMode: true,
      },
      {
        label: 'CÓDIGO FORNECEDOR',
        filterName: 'codeProvider',
        placeHolder: 'Digite aqui...',
        type: 'fieldSearch',
        size: 'large'
      },
      {
        label: "MARCA",
        filterName: "brandsDescription",
        filterNameSecond: "brandsDescription",
        placeHolder: "Selecione...",
        type: "autoComplete",
        urlPath: `${environments.catalog}/brands`,
        key: "brandsDescription",
        optionReference: "description",
        options: [],
        size: "large",
        filterParamsOff: true,
        disableFilterNameSecond: false,
        searchByTermMode: true
      }
      // {
      //   defaultValue: 'Restaurantes',
      //   checkBoxInputs: [
      //     {
      //       name: 'Ambos',
      //       referenceName: 'showOnlist',
      //       value: ''
      //     },
      //     {
      //       name: 'Restaurantes',
      //       referenceName: 'showOnlist',
      //       value: 'restaurants'
      //     },
      //     {
      //       name: 'Fornecedores',
      //       referenceName: 'showOnlist',
      //       value: 'providers'
      //     }
      //   ],
      //   type: 'multipleRadiobox'
      // }
    ]
  ]);

  const [paramsFilterQueue, setParamsFilterQueue] = useState([]);
  const [isEditSku, setIsEditSku] = useState(true);
  const [enableOpenDialog, setEnableOpenDialog] = useState(true);
  const [refreshData, setRefreshData] = useState(0);
  const [codeEan, setCodeEan] = useState(null);
  const [skuBloomId, setSkuBloomId] = useState();
  const [canApproveSku, setCanApproveSku] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState('');
  const [onlyViewProduct, setOnlyViewProduct] = useState(false);
  const [freeCatalogParamAssociate, setFreeCatalogParamAssociate] =
    useState(null);

  const handleIsEditable = async (skuId, canEdit, providerIds) =>
    axios.put(
      `${environments.catalog}/skus/${skuId}/canEdit${providerIds?.length ? `?providersIds=${providerIds}` : ''
      }`,
      {
        canEdit,
        user: userInfo.user,
        ipAddress: publicIp
      }
    );

  const handleCloseModal = async () => {
    // setUnifyItemsPopover({
    //   ...unifyItemsPopover,
    //   items: []
    // })
    setTimeout(() => {
      document.querySelector(`#eanBox .searchByTermMode #ean`).select();
    }, 200);
    setIsOnlyViewMode(false)
    setSimilarSkuCurrentData(null);
    setSimilarSkuFilterValue(null);
    setComparationCurrentData(null);
    setModalStates({
      modalComparation: false
    });

    const showPackagesOption =
      filtersChanged.find((filter) => filter.param === 'showPackages')
        ?.value === 'true';

    const providerIds =
      showPackagesOption &&
      comparationCurrentData?.providers?.map((provider) => provider.id);

    const { data } = await axios.get(
      `${environments.catalog}/skus/${comparationCurrentData?.id}/canEdit${showPackagesOption ? `?providersIds=${providerIds}` : ''
      }`
    );
    const { canEdit, ipAddress } = data;

    if (!canEdit || publicIp === ipAddress) {
      await handleIsEditable(comparationCurrentData?.id, true, providerIds);
      setIsEditable(true);
    }

    if (updateMainList) {
      setCallSettings({ ...callSettings, page: 1 }) ||
        setFiltersChanged(filtersChanged);
    }

    setProducts([]);
  };

  const handleOpenModalStates = (modal, comparationData) => {
    const newModalStates = { ...modalStates, [modal]: true };
    setModalStates(newModalStates);
    setComparationCurrentData(comparationData);
  };

  const handleAssociateSkuToRestaurants = (value = false) => {
    if (value && !skuPreviewDialog.open) return;

    setAssociateSkuPopover((prevState) => ({
      ...prevState,
      loading: true
    }));

    const body = {
      companies: selectedStores
        .filter(
          (item) =>
            !(
              associateSkuPopover || skuPreviewDialog
            )?.currentSku?.restaurantsIds
              ?.split(',')
              ?.map((el) => parseInt(el, 10))
              .includes(item._id)
        )
        ?.map((store) => ({
          id: store?._id,
          description: store?.companyName || store?.description,
          groups: store?.groups
        })),
      user: userInfo.user
    };

    axios
      .put(
        `${environments.catalog}/skus/platform/${associateSkuPopover?.currentSku?.skuBloomId ||
        skuPreviewDialog?.currentSku?.skuBloomId
        }/clone`,
        body
      )
      .then(() => {
        setEnableOpenDialog(false);
        setAssociateSkuPopover({
          ...associateSkuPopover,
          open: false,
          currentSku: '',
          loading: false
        });
        setToastOpen(true);
        setToastProps({
          message: 'Sku associado com sucesso.',
          severity: 'success',
          timeOutOff: true
        });
        setSelectedStores([]);
      })
      .catch(() => {
        setAssociateSkuPopover({
          ...associateSkuPopover,
          loading: false
        });
        setToastOpen(true);
        setToastProps({
          message: 'Não foi possível associar o sku.',
          severity: 'error',
          timeOutOff: true
        });
      });
  };

  function handleFilter(param, value) {
    const index = filtersChanged.findIndex((filter) => filter.param === param);

    if (index < 0) {
      if (value) {
        if (param === 'updated_at') {
          for (let i = 0; i < filtersChanged.length; i += 1) {
            if (filtersChanged[i].param === 'status')
              filtersChanged.splice(i, 1);
          }
          filtersChanged.push({
            param,
            value,
            urlParam: `${param}=${value}`
          });
        } else if (param === 'status') {
          for (let i = 0; i < filtersChanged.length; i += 1) {
            if (filtersChanged[i].param === 'updated_at')
              filtersChanged.splice(i, 1);
          }

          const existsParam = filtersChanged.find(
            (fil) => fil.param === 'status'
          );

          if (!existsParam) {
            filtersChanged.push({
              param,
              value,
              urlParam: `${param}=${value}`
            });
          }
        } else {
          filtersChanged.push({
            param,
            value,
            urlParam: `${param}=${value}`
          });
        }
      }
    } else if (value !== null) {
      filtersChanged[index] = {
        ...filtersChanged[index],
        value,
        urlParam: `${param}=${value}`
      };
    } else {
      filtersChanged.splice(index, 1);
    }

    setFiltersChanged(filtersChanged);
    setCallSettings({ ...callSettings, page: 1 });
  }

  const getSelectedStores = (sku, isUnified = false) => {
    const restaurantsIds = isUnified ?
      sku.map(el => el?.restaurants.map(
        (restaurant) => restaurant.restaurantId
      )).flat()
      : sku?.restaurants?.map(el => el?.restaurantId)

    const associatedRestaurants = storeOptions.filter((option) =>
      restaurantsIds.includes(option.companyId)
    );

    return associatedRestaurants;
  };

  const getInfoProvider = (provider, code) => {
    axios.get(`${environments.orders}/orders/skus/provider/${provider}?codeProvider=${code}&restaurantId=${getId}`)
      .then((response) => {
        setNoteMeasurement(response.data[0].noteMeasurementUnit)
      }).catch(() => {
        setNoteMeasurement('')
      })
  }

  const handleSelectedSku = (sku, canAssociate = false, onlyViewMode = false, isAdminMaster = false) => {
    const associatedRestaurants = getSelectedStores(sku);
    setIsOnlyViewMode(onlyViewMode)
    setCanApproveSku(isAdminMaster)
    setSelectedStores(associatedRestaurants);
    getOptions(
      `${environments.catalog}/brands`,
      'brands',
      brands,
      setBrands,
      getId,
      { description: sku?.brands?.description },
      null,
      10
    );
    setCanAssociateCurrentSku(canAssociate);
    setSelectedEan(sku?.ean || '');
    setSelectedSku(sku);
    setIsEditSku(true);
    setUpdateOptions((prevState) => ({
      ...prevState,
      brands: false,
      packageTypes: false
    }));
    setModalStates((prevState) => ({ ...prevState, modalSku: true }));
  };

  const handleCloseToast = (_, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setModalStates({ ...modalStates, modalComparation: false });
    setSkuPreviewDialog({ ...skuPreviewDialog, open: false, currentSku: {} });
    setToastOpen(false);
    setRefreshData(Math.random());

    setTimeout(() => {
      document.querySelector(`#eanBox .searchByTermMode #ean`).select();
    }, 200);
  };

  const getFilterhandle = (urlPath, label, key, optionReference) => {
    const pagesFilter = label === 'Restaurantes' ? '0' : '1';
    const showPackagesOption = filtersChanged.find(
      (filter) => filter.param === 'showPackages'
    );

    const filterShowPackages = showPackagesOption
      ? { showPackages: showPackagesOption?.value }
      : false;

    getFilterOptions(
      urlPath,
      label,
      key,
      filterFields,
      setFilterFields,
      optionReference,
      getId,
      filterShowPackages,
      pagesFilter
    );
  };

  const getEanInfo = (value) => {
    axios
      .get(`${environments.crawler}/skus/search/${value}`)
      .then((response) => {
        const data = response.data.content;

        setEanInfo(data);
        setEanLocalInfo(data);
      })
      .catch((error) => {
        console.log('error =>', error);
      });
  };

  const handleFilterComponent = (param, value, _, paramSecond) => {
    const isRestaurantOrGroupFilter =
      param === 'restaurantId' || param === 'groupId';
    let restaurantValue;
    let groupValue = '';

    const hasFilterWithFirstName = filtersChanged?.filter(
      (el) => el?.param === param
    )?.length;
    const hasFilterWithSecondName = filtersChanged?.filter(
      (el) => el?.param === paramSecond
    )?.length;

    let formattedFilters = hasFilterWithSecondName
      ? filtersChanged?.filter((el) => el?.param !== paramSecond)
      : filtersChanged;

    if (!value && (hasFilterWithFirstName || hasFilterWithSecondName)) {
      formattedFilters = filtersChanged?.filter(
        (el) => el?.param !== paramSecond && el?.param !== param
      );
    }

    setEnableOpenDialog(
      value &&
      (param === 'ean' ||
        isRestaurantOrGroupFilter ||
        param === 'descriptionSku')
    );

    if (param === 'ean') {
      setSelectedEan(value);
      setEanFilterValue(value);

      value ? getEanInfo(value) : setFindEan(false);

      !value &&
        setTimeout(() => {
          document.querySelector(`#eanBox .searchByTermMode #ean`).select();
        }, 200);
    } else if (param === 'restaurantId') {
      setRestaurantIdFilter(value);
      restaurantValue = value;
    } else if (param === 'groupId') {
      setGroupIdFilter(value);
      groupValue = value;
    }

    if (isRestaurantOrGroupFilter) {
      if (value !== '' && value !== null && value !== undefined) {
        if (value instanceof Array) {
          setFreeCatalogParamAssociate(value);
        } else {
          setFreeCatalogParamAssociate([value]);
        }
      } else {
        setFreeCatalogParamAssociate(null);
        param === 'restaurantId'
          ? setRestaurantIdFilter('')
          : setGroupIdFilter('');
        param === 'restaurantId' ? (restaurantValue = '') : (groupValue = '');
      }
    } else if (!formattedFilters.map((f) => f.param).includes('restaurantId')) {
      setFreeCatalogParamAssociate(null);
      param === 'restaurantId'
        ? setRestaurantIdFilter('')
        : setGroupIdFilter('');
      param === 'restaurantId' ? (restaurantValue = '') : (groupValue = '');
    }

    if (loading) {
      setParamsFilterQueue([
        ...paramsFilterQueue,
        [param === 'groupId' ? 'restaurantId' : param, value]
      ]);
      return;
    }

    filterFields.forEach((fieldsGroup) => {
      const skuField = fieldsGroup.find((fields) => fields.label === 'SKU');
      if (skuField) {
        skuField.options = [];
      }
    });

    if (param === 'showPackages') {
      setFilterFields([
        [
          {
            ...filterFields[0][0]
          },
          {
            ...filterFields[0][1],
            disableFilterNameSecond: value === 'true'
          },
          {
            ...filterFields[0][2]
          },
          {
            ...filterFields[0][3]
          }
        ],
        filterFields[1]
      ]);
    }

    handleFilterOptions(
      param === 'groupId' ? 'restaurantId' : param,
      param === 'groupId'
        ? !value
          ? restaurantIdFilter || restaurantValue || ''
          : value
        : param === 'restaurantId'
          ? !value
            ? groupIdFilter || groupValue || ''
            : value
          : value,
      formattedFilters,
      setFiltersChanged,
      null,
      false,
      '1',
      { callSettings, setCallSettings }
    );
  };

  const handleSaveSku = ({ body, isEditMode }) => {
    const handleResponse = (status, response) => {
      const verb = isEditMode ? 'atualizado' : 'criado';

      if (status) {
        setToastProps({
          severity: 'success',
          message: `SKU ${verb} com sucesso`,
          timeOutOff: true
        });
        setEnableOpenDialog(false);
        setToastOpen(true);
        setModalStates({ modalSku: false });
        setFiltersChanged(filtersChanged);
        setSkuPreviewDialog({
          ...skuPreviewDialog,
          open: false,
          currentSku: {}
        });
        setTimeout(() => {
          document.querySelector(`#eanBox .searchByTermMode #ean`).select();
        }, 200);
        updateOptions?.brands &&
          getOptions(
            `${environments.catalog}/brands`,
            'brands',
            brands,
            setBrands,
            getId
          );
        updateOptions?.packageTypes &&
          getOptions(
            `${environments.catalog}/typePackages`,
            'packageTypes',
            packageTypes,
            setPackageTypes,
            getId
          );
        return;
      }
      setToastProps({
        severity: 'error',
        message: 'Algo deu errado, por favor tente mais tarde',
        timeOutOff: true
      });
      setToastOpen(true);
      setModalStates({ modalSku: false });
    };

    const { id } = body;
    const isDraftParam = isAdminMaster ? '' : '?draft=true'

    if (isEditMode) {
      makePut('skuCards', body, handleResponse, `/platform/${id}${isDraftParam}`);
    } else {
      makePost('skuCards', body, handleResponse, `/platform${isDraftParam}`);
    }
  };

  const calcTotal = ({ purchaseLast, quantity }) => {
    let total = 0;

    if (purchaseLast && quantity) {
      total = purchaseLast * quantity;
    }

    return total;
  };

  const handleOpenComparisonModal = async (sku, isUnified = false) => {
    const showPackagesOption =
      filtersChanged.find((filter) => filter.param === 'showPackages')
        ?.value === 'true';

    const formattedProviderIds = isUnified
      ? sku?.map(el => el?.providers?.map((provider) => provider.id)).flat()
      : sku?.providers?.map((provider) => provider.id);

    const formattedSku = isUnified ? sku[0] : sku

    const providerIds =
      showPackagesOption && formattedProviderIds;

    const { data } = await axios
      .get(
        `${environments.catalog}/skus/${formattedSku.id}/canEdit${showPackagesOption ? `?providersIds=${providerIds}` : ''
        }`
      )
      .catch((err) => console.log(err));

    const { canEdit, ipAddress } = data;

    if (canEdit || publicIp === ipAddress) {
      await handleIsEditable(formattedSku.id, false, providerIds);
      setIsEditable(canEdit || formattedSku?.ipAddress === publicIp);
    }

    if (formattedSku.status === "ANALYZE") {
      const associatedRestaurants = getSelectedStores(sku, isUnified);
      setSelectedStores(associatedRestaurants);
      const formattedComparationData = isUnified
        ?
        {
          ...sku[0],
          providers: sku?.map(el => el?.providers).flat()
        }
        :
        {
          ...sku,
          ean: selectedEan || sku.ean
        }

      handleOpenModalStates('modalComparation', formattedComparationData);
    }
  };

  function ButtonShipStatus(sku, index, isButtonActive) {
    let [statusText, statusColor, statusBackground, tooltip] = ''

    switch (sku.statusBloom) {
      case 'ANALYZE':
        statusText = 'A';
        statusColor = '#856404';
        statusBackground = '#fff3cd';
        tooltip = 'Análise';
        break;
      case 'APPROVED':
        statusText = 'A';
        statusColor = '#155724';
        statusBackground = '#d4edda';
        tooltip = "Aprovado";
        break;
      case 'REFUSED':
        statusText = 'R';
        statusColor = '#721c24';
        statusBackground = '#f8d7da';
        tooltip = "Rejeitado";
        break;
      case 'INACTIVE':
        statusText = 'I';
        statusColor = '#1b1e21';
        statusBackground = '#d6d8d9';
        tooltip = "Inativo";
        break;
      case 'BLOOM':
        statusText = 'B';
        statusColor = '#5062F0';
        statusBackground = 'transparent';
        tooltip = "SKU Bloom";
        break;
      default:
        break;
    }

    return (
      <Tooltip
        title={tooltip}
      >
        <ButtonStatus
          id={`CatalogAdminAnaliseStatus-${publicIp === sku.ipAddress || !sku.ipAddress ? index : ''
            }`}
          data-enableStatus={
            (publicIp === sku.ipAddress || !sku.ipAddress
              ? statusBackground
              : 'grey') === 'warning' && 'CatalogAdminAnaliseStatus'
          }
          style={{
            height: '38px',
            color: isButtonActive ? statusColor : '#1b1e21',
            background: isButtonActive ? statusBackground : '#d6d8d9',
            padding: '0 10px'
          }}
          color={statusColor}
          className="bold m0"
          background={isButtonActive ? statusBackground : 'grey'}
          onClick={() =>
            sku.statusBloom === 'BLOOM'
              ? handleSelectedSku(sku)
              : isButtonActive
                ? handleOpenComparisonModal(sku)
                : null
          }
        >
          {statusText}
        </ButtonStatus>
      </Tooltip>
    );
  }

  const handleOrdernationFilter = (paramsAndValues) => {
    handleMultipleFilters(paramsAndValues, filtersChanged, setFiltersChanged);
  };

  function removeDuplicateProviders(provider) {
    return provider.reduce((acc, provider) => {
      const hasProduct = !!acc.find(
        (uniqueProvider) => uniqueProvider.providerId === provider.providerId
      );

      if (!hasProduct) {
        return [...acc, provider];
      }

      return acc;
    }, []);
  }

  function CountRestaurants(sku) {
    let restaurants = []

    if (Array.isArray(sku.restaurants)) {
      restaurants = sku.restaurants.filter(r => r.description && r.restaurantId !== 0)
    }

    if (!restaurants || restaurants?.length < 1) {
      return (
        <td
          onClick={() => handleOpenComparisonModal(sku)}
          className="description"
        >
          -
        </td>
      );
    }

    return (
      <td
        className="description"
      >
        <Tooltip
          placement="right"
          title={
            <div className="dFlex flexColumn" style={{ padding: '7px 7px 0' }}>
              {restaurants?.map(el => (
                <span style={{ marginBottom: 7 }}>
                  {el?.description}
                </span>
              ))}
            </div>
          }
        >
          <span
            className="cursorPointer lightBlue ml0 textAlignRight dBlock pr12"
            onClick={() => {
              setModalSettings(
                modalSettings.map((modal) =>
                  modal.name === 'restaurantsAssociation'
                    ? {
                      ...modal,
                      data: restaurants,
                      open: true,
                      descriptionSku: sku.description
                    }
                    : modal
                )
              );
              setModalStates((prevState) => ({
                ...prevState,
                modalRestaurants: true
              }));
            }}
          >
            {restaurants?.length}
          </span>
        </Tooltip>
      </td>
    );
  }

  const createSku = () => {
    if (!findEan) {
      return (
        <Tooltip title="Catalogar Novo SKU" aria-label="add">
          <span
            onClick={() => {
              setSelectedEan(
                filtersChanged.find((item) => item.param === 'ean')?.value || ''
              );
              setSelectedSku({});
              setIsEditSku(false);
              setModalStates({
                modalSku: true
              });
            }}
          >
            <IconButton
              className="p0 cursorPointer"
              edge="start"
              color="inherit"
              style={{ outline: 0 }}
              aria-label="add"
            >
              <img style={{ width: '25px' }} src={AddIcon} alt="AddIcon" />
            </IconButton>
          </span>
        </Tooltip>
      );
    }
  };

  const handleSelectItemsToUnify = (sku) => {
    const isAlreadySelected = unifyItemsPopover?.items?.find(
      (el) => {
        const currentProvider = el?.providers?.length ? el?.providers[0] : ''
        const skuProvider = sku?.providers?.length ? sku?.providers[0] : ''

        return el?.description === sku?.description
          && currentProvider?.providerId === skuProvider?.providerId
          && currentProvider?.codeProvider === skuProvider?.codeProvider
      })

    const filteredItems = unifyItemsPopover.items.filter(
      (el) => {
        const currentProvider = el?.providers?.length ? el?.providers[0] : ''
        const skuProvider = sku?.providers?.length ? sku?.providers[0] : ''

        return el?.description !== sku?.description
          || currentProvider?.providerId !== skuProvider?.providerId
          || currentProvider?.codeProvider !== skuProvider?.codeProvider
      })

    if (isAlreadySelected) {
      setUnifyItemsPopover({
        ...unifyItemsPopover,
        items: filteredItems
      });

      setCallSettings({
        ...callSettings,
        mainContent: mainContent?.map((el) => ({
          ...el,
          disableSelection: false
        })),
      });
    } else {
      setUnifyItemsPopover({
        ...unifyItemsPopover,
        items: [...unifyItemsPopover.items, sku]
      });
    }
  };

  const { t } = useTranslation('translations');

  useEffect(() => {
    document.querySelector('main').scrollTo({ top: 0, behavior: 'smooth' });
  }, [callSettings.page]);

  useEffect(() => {
    setCallSettings({ ...callSettings, getId: userInfo.companiesActive.id });
  }, [userInfo]);

  useEffect(() => {
    if (selectedProduct) {
      const arraySkus = [];
      selectedProduct.skus.forEach((skus) => {
        arraySkus.push({
          id: skus.skus ? skus.skus.id : null,
          description: skus.skus ? skus.skus.description : null,
          quantity: skus.skus
            ? parseFloat(skus.skus.quantity).toLocaleString('pt-br', {
              minimumFractionDigits: 3
            })
            : null,
          unitsMeasurements: skus.skus
            ? skus.skus.unitsMeasurements.abbreviation
            : null,
          purchaseAverage:
            skus.skus && skus.skus.restaurants.length
              ? skus.skus.restaurants[0].purchaseAverage
              : null,
          purchaseLast:
            skus.skus && skus.skus.restaurants.length
              ? skus.skus.restaurants[0].purchaseLast
              : null,
          conversion: skus.skus
            ? parseFloat(skus.conversion).toLocaleString('pt-br', {
              minimumFractionDigits: 3
            })
            : null,
          priceUc: skus ? skus.priceUc : null
        });
      });

      selectedProduct.skus = arraySkus;
    }

    setEditProducts(selectedProduct);
  }, [selectedProduct, setEditProducts]);

  useEffect(() => {
    (async () => {
      const ip = await getClientIp();
      const hasEanFilter = filtersChanged?.find(
        (item) => item.param === 'ean'
      )?.value;
      const hasSkuFilter = filtersChanged?.find(
        (item) => item.param === 'descriptionSku'
      )?.value;
      const restaurantIdFilterValue = filtersChanged?.find(
        (filter) => filter.param === 'restaurantId'
      )?.value;

      if (
        !filtersChanged?.map((item) => !!item?.value).some((el) => el) &&
        !hasEanFilter &&
        !hasSkuFilter
      ) {
        setCallSettings({
          ...callSettings,
          mainContent: [],
          loading: false
        });
        return;
      }

      if (filtersChanged == null || filtersChanged.length == 0) {
        filtersChanged.push({
          param: 'status',
          value: 'ANALYZE',
          urlParam: `status=ANALYZE`
        });
      }

      setSelectedStores(
        restaurantIdFilterValue
          ? Number.isInteger(restaurantIdFilterValue)
            ? [
              storeOptions.find(
                (item) => item._id === restaurantIdFilterValue
              )
            ]
            : restaurantIdFilterValue.length
              ? restaurantIdFilterValue?.map((item) =>
                storeOptions.find((el) => el._id === item)
              )
              : []
          : []
      );

      setPublicIp(ip);
      setCallSettings({ ...callSettings, loading: true });
      const params = filtersChanged.map((filter) => filter.urlParam).join('&');
      const response = await axiosInstanceCatalog.get(
        `${environments.catalog}/skus/platform/crossbloom?page=${page}&size=${size}&originId=${getId}&${params}`
      );
      const skus = await response.data;

      if (
        skus?.content?.length &&
        skus?.content?.filter((item) => item.status === 'APPROVED').length
      ) {
        skus?.content?.filter((item) => item.status === 'APPROVED').length ===
          1 && enableOpenDialog
          ? setSkuPreviewDialog({
            ...skuPreviewDialog,
            open: true,
            currentSku: skus?.content?.filter(
              (item) => item.status === 'APPROVED'
            )[0]
          })
          : setSkuPreviewDialog({
            ...skuPreviewDialog,
            open: false,
            currentSku: {}
          });

        setFindEan(!!hasEanFilter);
      } else {
        document
          .querySelector(`#skuDescriptionBox .searchByTermMode input`)
          .focus();
        setFindEan(false);
      }

      setUpdateMainList(false);
      setCallSettings({
        ...callSettings,
        totalPages: skus.totalPages,
        totalElements: skus.totalElements,
        mainContent: skus.content || [],
        loading: false,
        numberOfElements: skus.numberOfElements
      });
    })();
  }, [getId, page, filtersChanged, refreshData]);

  // Produtos
  useEffect(() => {
    if (
      !modalStates.modalComparation &&
      !modalStates.modalSku &&
      products.length
    )
      return;

    axiosInstanceCatalog
      .get(
        `${environments.catalog}/products/internal/skus?page=1&size=10&originId=${getId}`
      )
      .then((response) => {
        const products = response.data.content;
        setProducts(products);
      })
      .catch((error) => { });
  }, [getId, modalStates.modalComparation, modalStates.modalSku]);

  // Marcas
  useEffect(() => {
    if (!modalStates.modalComparation) return;

    (async () => {
      const response = await fetch(
        `${environments.catalog}/brands?page=1&size=1000&originId=${getId}`
      );
      const brands = await response.json();
      setBrandsOptions(brands.content);
    })();
  }, [getId, modalStates.modalComparation]);

  useEffect(() => {
    if (!paramsFilterQueue.length) return;

    handleMultipleFilters(paramsFilterQueue, filtersChanged, () => {
      setTimeout(() => {
        setRefreshData(Math.random());
        setParamsFilterQueue([]);
      }, 200);
    });
  }, [loading]);

  useEffect(() => {
    if (!storeOptions.length) {
      getOptions(
        `${environments.restaurants}/companies`,
        'restaurants',
        storeOptions,
        setStoreOptions,
        getId
      );
    }

    if (!modalStates.modalSku) return;

    !brands?.length &&
      getOptions(
        `${environments.catalog}/brands`,
        'brands',
        brands,
        setBrands,
        getId,
        null,
        null,
        10
      );

    !packageTypes.length &&
      getOptions(
        `${environments.catalog}/typePackages`,
        'packageTypes',
        packageTypes,
        setPackageTypes,
        getId
      );
    !unitsMeasurements.length &&
      getOptions(
        `${environments.catalog}/unitsMeasurements`,
        'unitsMeasurements',
        unitsMeasurements,
        setUnitsMeasurements,
        getId
      );
    !providers.length &&
      getOptions(
        `${environments.providers}/providers`,
        'providers',
        providers,
        setProviders,
        getId,
        null,
        null,
        10
      );
    !consumptionUnits.length &&
      getOptions(
        `${environments.catalog}/consumptionUnits`,
        'consumptionUnits',
        consumptionUnits,
        setConsumptionUnits,
        getId
      );
    !storageLocations.length &&
      getOptions(
        `${environments.catalog}/storageLocations`,
        'storageLocations',
        storageLocations,
        setStorageLocations,
        getId
      );
    !periodicities.length &&
      getOptions(
        `${environments.catalog}/periodicities`,
        'periodicities',
        periodicities,
        setPeriodicities,
        getId
      );
    !categories.length &&
      getOptions(
        `${environments.catalog}/categories`,
        'categories',
        categories,
        setCategories,
        getId,
        null,
        null,
        10
      );
  }, [modalStates.modalSku]);

  return (
    <Layout>
      <TitleHead title={t('cataloging')} />

      <GlobalStyle />

      <Container>
        <PageTitleAndFilter
          title={t('cataloging')}
          activeFilterFilds={filterFields}
          handleFilter={handleFilterComponent}
          getFilterhandle={getFilterhandle}
          additionalbutton={createSku}
          dynamicJustifyOff
        />

        <table
          className="desktop"
          width="100%"
          cellPadding="0"
          cellSpacing="0"
          border="0"
        >
          <tbody>
            <tr className="headerTitle">
              <th
                colSpan="10"
                style={{ padding: '5px 0', top: '-6px', fontSize: 12 }}
              >
                {/* <Grid
                  container
                  item
                  xs={7}
                  spacing={1}
                  style={{ marginLeft: '10px' }}
                >
                  <Grid item xs={3}>
                    <ButtonStatus
                      style={{ height: '25px', width: '100px' }}
                      color="success"
                      background="success"
                      onClick={() => handleFilter('status', 'APPROVED')}
                    >
                      Aprovados
                    </ButtonStatus>
                  </Grid>

                  <Grid item xs={3}>
                    <ButtonStatus
                      style={{ height: '25px', width: '100px' }}
                      color="dark"
                      background="dark"
                      onClick={() =>
                        handleFilter(
                          'updated_at',
                          new Date().toISOString().slice(0, 10)
                        )
                      }
                    >
                      Novos
                    </ButtonStatus>
                  </Grid>

                  <Grid item xs={3}>
                    <ButtonStatus
                      style={{ height: '25px', width: '100px' }}
                      color="warning"
                      background="warning"
                      onClick={() => handleFilter('status', 'ANALYZE')}
                    >
                      Análise
                    </ButtonStatus>
                  </Grid>

                  <Grid item xs={3}>
                    <ButtonStatus
                      style={{ height: '25px', width: '100px' }}
                      color="danger"
                      background="danger"
                      onClick={() => handleFilter('status', 'REFUSED')}
                    >
                      Rejeitados
                    </ButtonStatus>
                  </Grid>
                </Grid> */}
              </th>

              <th
                colSpan="4"
                className="subtitle left p12"
                style={{
                  borderLeft: '1px solid #91084C',
                  background: '#91084C',
                  top: '-6px',
                  fontSize: 12
                }}
              >
                Compras/Integração
              </th>

              <th
                className="subtitle left p12"
              >
                &nbsp;
              </th>
            </tr>

            <tr className="headerSubtitle">
              <td
                width="6%"
                className="subtitle"
                style={{ top: '24px', fontSize: 12, borderRight: 'solid 1px #9296AC' }}
              >
                DATA{' '}
                <OrderBy
                  filterName="created_at"
                  handleFilter={handleOrdernationFilter}
                />{' '}
              </td>

              <td
                width="2%"
                className="subtitle"
                style={{ top: '24px', fontSize: 12 }}
              >
                &nbsp;
              </td>

              <td
                width="27%"
                className="subtitle"
                style={{ top: '24px', fontSize: 12 }}
              >
                DESCRIÇÃO{' '}
                <OrderBy
                  filterName="description"
                  handleFilter={handleOrdernationFilter}
                />{' '}
              </td>

              <td
                width="6%"
                className="subtitle right"
                style={{ top: '24px', fontSize: 12 }}
              >
                QTDE{' '}
                <OrderBy
                  filterName="quantity"
                  handleFilter={handleOrdernationFilter}
                />{' '}
              </td>

              <td
                width="4%"
                className="subtitle center"
                style={{ top: '24px', fontSize: 12 }}
              >
                UN.{' '}
                <OrderBy
                  filterName="un"
                  handleFilter={handleOrdernationFilter}
                />{' '}
              </td>

              <td
                width="6%"
                className="subtitle"
                style={{ top: '24px', fontSize: 12 }}
              >
                EAN{' '}
                <OrderBy
                  filterName="ean"
                  handleFilter={handleOrdernationFilter}
                />{' '}
              </td>

              <td
                width="2%"
                className="subtitle left"
                style={{ top: '24px', fontSize: 12 }}
              />

              <td
                width="2%"
                className="subtitle left"
                style={{ top: '24px', fontSize: 12 }}
              />

              <td
                width="14%"
                className="subtitle pl12"
                style={{ top: '24px', fontSize: 12, borderLeft: 'solid 1px #9296AC' }}
              >
                FORNECEDOR
              </td>

              <td
                width="6%"
                className="subtitle"
                style={{ top: '24px', fontSize: 12 }}
              >
                CÓDIGO
              </td>

              <td
                width="7%"
                className="subtitle right pr12"
                style={{ top: '24px', fontSize: 12, borderLeft: 'solid 2px #91084C' }}
              >
                UNITÁRIO R$
              </td>

              <td
                width="3%"
                className="subtitle right pr12"
                style={{ top: '24px', fontSize: 12 }}
              >
                QTDE
              </td>

              <td
                width="6%"
                className="subtitle right pr12"
                style={{ top: '24px', fontSize: 12 }}
              >
                MÉDIO R$
              </td>

              <td
                width="7%"
                className="subtitle right pr12"
                style={{ top: '24px', fontSize: 12, borderRight: 'solid 1px #1F2445' }}
              >
                ÚLTIMO R$
              </td>

              <td
                width="2%"
                className="subtitle right"
                style={{ top: '24px', fontSize: 12, borderLeft: 'solid 1px #1F2445' }}
              >
                &nbsp;
              </td>
            </tr>

            <tr>
              <td
                colSpan="15"
                className="titleLineBorder"
                style={{ position: 'sticky', top: '39px' }}
              >
                <hr style={{ marginTop: '-1px' }} />
              </td>
            </tr>

            {loading && (
              <tr>
                <th colSpan="15" className="textAlignCenter m10">
                  <LinearProgress
                    style={{ height: '7px' }}
                    id="loading"
                    variant="query"
                  />
                </th>
              </tr>
            )}

            {mainContent.length ? (
              mainContent.map((sku, index) => {
                const providerNotCatalogued = sku?.id === 1 && sku?.providers?.length ? sku?.providers[0] : null
                const isCataloguedFilterActive = filtersChanged?.find(el => el?.param === 'showPackages')?.value === 'false'

                const buttonActive = () => {
                  const currentSku = sku?.id === 1 && sku?.providers?.length ? sku?.providers[0] : sku;
                  const contentUpdatedCanEdit = moment(currentSku?.updatedCanEdit);
                  const currentData = moment();
                  const difference = currentData.diff(contentUpdatedCanEdit, 'minutes');

                  return difference >= 15
                    ? true
                    : currentSku?.canEdit
                      ? true
                      : !!(publicIp === currentSku?.ipAddress || !currentSku?.ipAddress);
                };

                const isButtonActive = buttonActive();

                return (
                  <>
                    <tr
                      style={{
                        background:
                          sku.statusBloom === 'BLOOM'
                            ? 'rgba(80, 98, 240, .2)'
                            : 'transparent'
                      }}
                    >
                      <td
                        onClick={() =>
                          sku.statusBloom === 'BLOOM' || isCataloguedFilterActive
                            ? handleSelectedSku(sku, '', isCataloguedFilterActive && isAdmin, isAdminMaster)
                            : isButtonActive
                              ? handleOpenComparisonModal(sku)
                              : null
                        }
                        className="description pr12 pl12"
                        style={{ borderRight: 'solid 1px #9296AC' }}
                      >
                        {FormatDate(sku.created_at)}
                      </td>

                      <td
                        className={`dFlex alignCenter p0 ${sku?.disableSelection || sku?.statusBloom === 'BLOOM' && 'disabledCheckbox'}`}
                      >
                        <Checkbox
                          checked={unifyItemsPopover?.items?.some((el) => {
                            const currentProvider = el?.providers?.length ? el?.providers[0] : ''
                            const skuProvider = sku?.providers?.length ? sku?.providers[0] : ''

                            return el?.description === sku?.description
                              && currentProvider?.providerId === skuProvider?.providerId
                              && currentProvider?.codeProvider === skuProvider?.codeProvider
                          })}
                          disabled={
                            sku?.disableSelection || sku?.statusBloom === 'BLOOM'
                          }
                          size="small"
                          onChange={(_, checked) => {
                            if (checked) {
                              setSelectedItemToUnifyInfos([
                                ...selectedItemsToUnifyInfos,
                                sku
                              ]);
                            } else {
                              setSelectedItemToUnifyInfos(
                                selectedItemsToUnifyInfos.filter((el) => {
                                  const currentProvider = el?.providers?.length ? el?.providers[0] : ''
                                  const skuProvider = sku?.providers?.length ? sku?.providers[0] : ''

                                  return el?.description !== sku?.description
                                    && currentProvider?.providerId !== skuProvider?.providerId
                                    && currentProvider?.codeProvider !== skuProvider?.codeProvider
                                })
                              );
                            }

                            handleSelectItemsToUnify(sku);
                          }}
                        />
                      </td>

                      <td
                        onClick={() =>
                          sku.statusBloom === 'BLOOM' || isCataloguedFilterActive
                            ? handleSelectedSku(sku, '', isCataloguedFilterActive && isAdmin, isAdminMaster)
                            : isButtonActive
                              ? handleOpenComparisonModal(sku)
                              : null
                        }
                        className="description pr12"
                      >
                        {providerNotCatalogued && providerNotCatalogued.wasReceived
                          ? (
                            <Tooltip
                              title="SKU com registro de recebimento"
                              aria-label="info"
                            >
                              <p
                                className="mb0"
                                style={{
                                  fontFamily: 'Lato',
                                  fontSize: '12px',
                                  color: `${providerNotCatalogued?.wasReceived ? '#FF0000' : 'none'}`
                                }}
                              >
                                {
                                  ValidationLength(
                                    sku?.description,
                                    smallScreen ? 45 : 50
                                  )
                                }
                              </p>
                            </Tooltip>
                          )
                          : (
                            ValidationLength(
                              sku?.description,
                              smallScreen ? 45 : 50
                            )
                          )
                        }

                      </td>

                      <td
                        onClick={() =>
                          sku.statusBloom === 'BLOOM' || isCataloguedFilterActive
                            ? handleSelectedSku(sku, '', isCataloguedFilterActive && isAdmin, isAdminMaster)
                            : isButtonActive
                              ? handleOpenComparisonModal(sku)
                              : null
                        }
                        className="description right"
                      >
                        {(sku?.quantity || 0).toLocaleString(
                          'pt-br',
                          { minimumFractionDigits: 3 }
                        )}
                      </td>

                      <td
                        onClick={() =>
                          sku.statusBloom === 'BLOOM' || isCataloguedFilterActive
                            ? handleSelectedSku(sku, '', isCataloguedFilterActive && isAdmin, isAdminMaster)
                            : isButtonActive
                              ? handleOpenComparisonModal(sku)
                              : null
                        }
                        className="description center"
                        style={{ padding: '0 13px' }}
                      >
                        <div
                          style={{gap: '10px'}}
                          className={'description'}
                          onMouseEnter={() => getInfoProvider(sku.providers[0].providerId, sku.providers[0].codeProvider)}
                          onMouseLeave={() => setNoteMeasurement('')}
                        >
                          <Tooltip title={noteMeasurement} aria-label="info">
                            <div>
                              {sku.unitsMeasurements
                                ? sku.unitsMeasurements.abbreviation
                                : ''}
                            </div>
                          </Tooltip>
                        </div>
                      </td>

                      <td
                        onClick={() =>
                          sku.statusBloom === 'BLOOM' || isCataloguedFilterActive
                            ? handleSelectedSku(sku, '', isCataloguedFilterActive && isAdmin, isAdminMaster)
                            : isButtonActive
                              ? handleOpenComparisonModal(sku)
                              : null
                        }
                        className="description"
                      >
                        {ValidationLength(sku?.ean || '-', 15)}
                      </td>

                      <td className="description left pl8 pr8">
                        {sku.status == 'APPROVED' && (
                          <ButtonStatus
                            style={{
                              height: '25px',
                              width: '25px',
                              background: 'transparent'
                            }}
                            onClick={async () => {
                              setSkuBloomId(sku.skuBloomId);
                              setCodeEan(sku.ean);
                              setModalStates({ ...modalStates, editEan: true });
                            }}
                          >
                            {sku.ean != null ? (
                              <Tooltip title="Atualizar EAN" aria-label="add">
                                <img width="12px" height="12px" src={editEan} alt="EditEan" />
                              </Tooltip>
                            ) : (
                              <Tooltip title="Adicionar EAN" aria-label="add">
                                <img width="12px" height="12px" src={addEan} alt="AddEan" />
                              </Tooltip>
                            )}
                          </ButtonStatus>
                        )}
                      </td>

                      {CountRestaurants(sku)}

                      <td
                        onClick={() =>
                          sku.statusBloom === 'BLOOM' || isCataloguedFilterActive
                            ? handleSelectedSku(sku, '', isCataloguedFilterActive && isAdmin, isAdminMaster)
                            : isButtonActive
                              ? handleOpenComparisonModal(sku)
                              : null
                        }
                        className="description"
                        style={{ padding: '0 12px', borderLeft: 'solid 1px #9296AC' }}
                      >
                        {sku?.providers?.length > 0
                          ? ValidationLength(
                            sku?.providers[0]?.name,
                            smallScreen ? 15 : 20
                          )
                          : '-'}
                      </td>

                      <td
                        onClick={() =>
                          sku.statusBloom === 'BLOOM' || isCataloguedFilterActive
                            ? handleSelectedSku(sku, '', isCataloguedFilterActive && isAdmin, isAdminMaster)
                            : isButtonActive
                              ? handleOpenComparisonModal(sku)
                              : null
                        }
                        className="description pr12"
                      >
                        {sku?.providers?.length > 0
                          ? ValidationLength(
                            sku?.providers[0]?.codeProvider,
                            smallScreen ? 5 : 7
                          )
                          : '-'}
                      </td>

                      <td
                        onClick={() =>
                          sku.statusBloom === 'BLOOM' || isCataloguedFilterActive
                            ? handleSelectedSku(sku, '', isCataloguedFilterActive && isAdmin, isAdminMaster)
                            : isButtonActive
                              ? handleOpenComparisonModal(sku)
                              : null
                        }
                        className="description right"
                        style={{ padding: '0 12px', borderLeft: 'solid 2px #91084C' }}
                      >
                        {sku.restaurants.length > 0
                          ? (sku.restaurants[0].purchaseLast
                            ? sku.restaurants[0].purchaseLast
                            : 0
                          ).toLocaleString('pt-br', {
                            minimumFractionDigits: 3
                          })
                          : '-'}
                      </td>

                      <td
                        onClick={() =>
                          sku.statusBloom === 'BLOOM' || isCataloguedFilterActive
                            ? handleSelectedSku(sku, '', isCataloguedFilterActive && isAdmin, isAdminMaster)
                            : isButtonActive
                              ? handleOpenComparisonModal(sku)
                              : null
                        }
                        className="description right pr12"
                      >
                        {sku.restaurants.length > 0
                          ? sku.restaurants[0].purchaseCount != null
                            ? sku.restaurants[0].purchaseCount
                            : 0
                          : '-'}
                      </td>

                      <td
                        onClick={() =>
                          sku.statusBloom === 'BLOOM' || isCataloguedFilterActive
                            ? handleSelectedSku(sku, '', isCataloguedFilterActive && isAdmin, isAdminMaster)
                            : isButtonActive
                              ? handleOpenComparisonModal(sku)
                              : null
                        }
                        className="description right pr12"
                      >
                        {sku.restaurants.length > 0
                          ? (sku.restaurants[0].purchaseAverage != null
                            ? sku.restaurants[0].purchaseAverage
                            : 0
                          ).toLocaleString('pt-br', {
                            minimumFractionDigits: 3
                          })
                          : '-'}
                      </td>

                      <td
                        onClick={() =>
                          sku.statusBloom === 'BLOOM' || isCataloguedFilterActive
                            ? handleSelectedSku(sku, '', isCataloguedFilterActive && isAdmin, isAdminMaster)
                            : isButtonActive
                              ? handleOpenComparisonModal(sku)
                              : null
                        }
                        className="description right pr12"
                      >
                        <Tooltip
                          title={
                            <span>
                              TOTAL R$:{sku.restaurants.length > 0
                                ? calcTotal({
                                  purchaseLast: sku.restaurants[0].purchaseLast,
                                  quantity: sku.restaurants[0].purchaseCount
                                }).toLocaleString('pt-br', {
                                  minimumFractionDigits: 3
                                })
                                : '-'}
                            </span>
                          }
                        >
                          <span>
                            {sku.restaurants.length > 0
                              ? (sku.restaurants[0].purchaseLast != null
                                ? sku.restaurants[0].purchaseLast
                                : 0
                              ).toLocaleString('pt-br', {
                                minimumFractionDigits: 3
                              })
                              : '-'}
                          </span>
                        </Tooltip>
                      </td>

                      <td
                        onClick={() =>
                          sku.statusBloom === 'BLOOM' || isCataloguedFilterActive
                            ? handleSelectedSku(sku, '', isCataloguedFilterActive && isAdmin, isAdminMaster)
                            : isButtonActive
                              ? handleOpenComparisonModal(sku)
                              : null
                        }
                        className="description left"
                        id={
                          removeDuplicateProviders(sku?.providers).length > 1 &&
                            (publicIp === sku.ipAddress || !sku.ipAddress)
                            ? `moreThanOnePackage-${index}`
                            : 'onePackage'
                        }
                        style={{ borderLeft: 'solid 1px #1F2445' }}
                      >
                        {ButtonShipStatus(
                          sku,
                          index,
                          sku.statusBloom === 'BLOOM' ? true : isButtonActive
                        )}
                      </td>
                    </tr>

                    <tr>
                      <td colSpan="15">
                        <hr style={{ marginTop: '-1px' }} />
                      </td>
                    </tr>
                  </>
                );
              })
            ) : (
              <tr>
                <td
                  className="bold"
                  style={{ margin: '10px', textAlign: 'center' }}
                  colSpan="15"
                >
                  Nenhum registro encontrado
                </td>
              </tr>
            )}

            <tr>
              <td colSpan="15">
                <hr />
              </td>
            </tr>
          </tbody>
        </table>

        <div className="pagination">
          <Pagination
            disabled={loading}
            count={totalPages}
            onChange={(event, page) => {
              setCallSettings({ ...callSettings, page });
            }}
            variant="outlined"
            shape="rounded"
            size="small"
            color="primary"
          />

          <ItemsPerPage
            numberOfElements={numberOfElements}
            size={totalElements}
          />
        </div>
      </Container>

      <Tooltip
        className="floatButtonFirst"
        title={
          unifyItemsPopover?.items.length < 2
            ? 'Selecione ao menos 2 embalagens'
            : 'Unificar Embalagens'
        }
      >
        <Fab
          color="primary"
          className={`${
            unifyItemsPopover?.items.length < 2
              ? 'cursorAuto'
              : 'cursorPointer'
          }`}
          aria-label="add"
          size="medium"
          style={{ outline: 0 }}
          onClick={() => {
            if (unifyItemsPopover?.items.length < 2) return;

            handleOpenComparisonModal(unifyItemsPopover?.items, true)
          }}
        >
          <Add style={{ color: 'white' }} id="unifyProductIcon" />
        </Fab>
      </Tooltip>

      {modalStates.modalComparation && comparationCurrentData && (
        <ComparationDialog
          enableOpenSkuModal={enableOpenSkuModal}
          setEnableOpenSkuModal={setEnableOpenSkuModal}
          setRefreshData={setRefreshData}
          findEan={findEan}
          openModal={modalStates}
          isEditable={isEditable}
          handleClose={handleCloseModal}
          comparationCurrentData={comparationCurrentData}
          setToastProps={setToastProps}
          setToastOpen={setToastOpen}
          brandsOptions={brandsOptions}
          productsOptions={products}
          setUpdateMainList={setUpdateMainList}
          callSettings={callSettings}
          setCallSettings={setCallSettings}
          mainContent={mainContent}
          setComparationCurrentData={setComparationCurrentData}
          freeCatalogParamAssociate={freeCatalogParamAssociate}
          setSimilarSkusOpen={setSimilarSkusOpen}
          setSimilarSkuFilterValue={setSimilarSkuFilterValue}
          setSimilarSkuProvider={setSimilarSkuProvider}
          comparationCardFilterValue={comparationCardFilterValue}
          setComparationCardFilterValue={setComparationCardFilterValue}
          setSimilarSkuCurrentData={setSimilarSkuCurrentData}
          similarSkuCurrentData={similarSkuCurrentData}
          storeOptions={storeOptions}
          selectedStores={selectedStores}
        />
      )}

      {modalStates.modalSku &&
        products.length &&
        unitsMeasurements.length &&
        typeof brands === 'object' &&
        packageTypes.length ? (
        <SkuModal
          canApproveSku={canApproveSku}
          canAssociateCurrentSku={canAssociateCurrentSku}
          eanFilterValue={eanFilterValue}
          selectedStores={selectedStores}
          storeOptions={storeOptions}
          updateOptions={updateOptions}
          setUpdateOptions={setUpdateOptions}
          eanInfo={eanInfo}
          selectedEan={selectedEan}
          fullScreen
          maxWidth={null}
          fullWidth
          setUpdateMainList={setUpdateMainList}
          open={modalStates.modalSku}
          data={
            selectedSku && selectedSku.providers ? selectedSku.providers : []
          }
          editMode={isEditSku}
          getId={getId}
          sku={selectedSku}
          userInfo={userInfo}
          items={items}
          providers={providers}
          products={products}
          categories={categories}
          brands={brands}
          packageTypes={packageTypes}
          storageLocations={storageLocations}
          periodicities={periodicities}
          unitsMeasurements={unitsMeasurements}
          consumptionUnits={consumptionUnits}
          handleOnSave={handleSaveSku}
          onlyViewMode={isOnlyViewMode}
          onClose={() => {
            setIsOnlyViewMode(false)
            setSelectedEan('');
            setModalStates({ ...modalStates, modalSku: false });
            setSkuPreviewDialog({
              ...skuPreviewDialog,
              open: false,
              currentSku: {}
            });
            setSelectedStores([]);

            setTimeout(() => {
              document.querySelector(`#eanBox .searchByTermMode #ean`).select();
            }, 200);
          }}
          freeCatalogParamAssociate={freeCatalogParamAssociate}
          adminCatalogModal
          originPage='catalog'
        />
      ) : null}

      {(skuPreviewDialog.open &&
        skuPreviewDialog?.currentSku &&
        selectedStores?.length &&
        !associateSkuPopover.open) ? (
        <SkuPreview
          productOptions={products}
          openModal={skuPreviewDialog.open}
          handleClose={() => {
            setTimeout(() => {
              document.querySelector(`#eanBox .searchByTermMode #ean`).select();
            }, 200);
            setSkuPreviewDialog({ ...skuPreviewDialog, open: false });
          }}
          loading={associateSkuPopover.loading}
          handleSelectedSku={handleSelectedSku}
          handleAssociateSkuToRestaurants={handleAssociateSkuToRestaurants}
          currentSkuData={skuPreviewDialog?.currentSku}
          selectedStores={selectedStores}
          userInfo={userInfo}
          setUpdateMainList={setUpdateMainList}
        />
      ) : null}

      {modalStates.modalRestaurants && (
        <RestaurantsDialogs
          openModal={modalStates}
          handleClose={() => {
            setModalStates({ ...modalStates, modalRestaurants: false });
          }}
          restaurants={
            modalSettings.find(
              (modal) => modal.name === 'restaurantsAssociation'
            ).data
          }
          descriptionSku={
            modalSettings.find(
              (modal) => modal.name === 'restaurantsAssociation'
            ).descriptionSku
          }
        />
      )}

      {modalStates.editEan && (
        <EditEan
          openModal={modalStates}
          handleClose={() => {
            setModalStates({ ...modalStates, editEan: false });
          }}
          skuBloomId={skuBloomId}
          user={userInfo.user}
          edit={
            codeEan ||
            filtersChanged.find((item) => item.param === 'ean')?.value ||
            ''
          }
          setRefreshData={setRefreshData}
          setFindEan={setFindEan}
        />
      )}

      <ProvidersAssociation
        skusList={skusList}
        providers={providers}
        skuAssociation={skuAssociation}
        modalSettings={modalSettings}
        setModalSettings={setModalSettings}
        setSkuAssociation={setSkuAssociation}
      />

      {associateSkuPopover.open && (
        <Dialog
          open={associateSkuPopover.open}
          className="defaultModal associateSkuPopover"
          PaperComponent={PaperComponent}
          onClose={() => {
            setAssociateSkuPopover({
              ...associateSkuPopover,
              open: false,
              currentSku: ''
            });

            setSelectedStores([]);
          }}
        >
          <DialogTitle
            className="modalHeader navyBlue bold cursorMove textAlignCenter"
            id="draggable-dialog-title"
          >
            <Typography>Associar SKU ao(s) Restaurante(s)</Typography>
          </DialogTitle>

          <DialogContent className="modalContent bgWhite dFlex">
            <div className="dFlex flexColumn alignCenter w100">
              <div
                className="dFlex alignCenter w100"
                style={{ margin: '18px 0 18px 0' }}
              >
                <span
                  className="bold mr16 textAlignRight"
                  style={{ width: '23%' }}
                >
                  SKU:
                </span>

                <span style={{ width: '77%' }}>
                  {associateSkuPopover?.currentSku?.description}
                </span>
              </div>

              <div className="dFlex alignCenter w100">
                <span
                  className="bold mr16 textAlignRight"
                  style={{ width: '23%' }}
                >
                  Restaurantes:
                </span>

                <Autocomplete
                  style={{ width: '77%' }}
                  className="autocompleteSmall multipleNoExpand"
                  multiple
                  limitTags={1}
                  onFocus={() => {
                    !storeOptions.length &&
                      getOptions(
                        `${environments.restaurants}/companies`,
                        'restaurants',
                        storeOptions,
                        setStoreOptions,
                        getId
                      );
                  }}
                  options={storeOptions}
                  value={selectedStores?.filter(
                    (item) =>
                      !(
                        associateSkuPopover || skuPreviewDialog
                      )?.currentSku?.restaurantsIds
                        ?.split(',')
                        ?.map((el) => parseInt(el, 10))
                        .includes(item._id)
                  )}
                  size="small"
                  getOptionLabel={(option) => option?.companyName}
                  getOptionDisabled={(option) =>
                    selectedStores?.some((store) => store._id === option._id) ||
                    associateSkuPopover?.currentSku?.restaurantsIds
                      ?.split(',')
                      ?.map((item) => parseInt(item, 10))
                      ?.includes(option._id)
                  }
                  disableCloseOnSelect
                  onChange={(e, value) => {
                    if (value.length) {
                      setSelectedStores(value);
                    } else {
                      setSelectedStores([]);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      id={params.id}
                      type="text"
                      placeholder="Digite aqui..."
                      variant="outlined"
                      required
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  )}
                  renderOption={(params) => (
                    <Typography
                      style={{ fontSize: '12px', fontFamily: 'Lato' }}
                    >
                      {params?.companyName}
                    </Typography>
                  )}
                />
              </div>

              <div className="dFlex justifyCenter" style={{ marginTop: 30 }}>
                <Button
                  design="contained"
                  className="defaultButton backButton mr8 fontSizeDefault"
                  onClick={(event) => {
                    event.stopPropagation();
                    setAssociateSkuPopover({
                      ...associateSkuPopover,
                      open: false,
                      currentSku: ''
                    });

                    setSelectedStores([]);
                  }}
                  label="Cancelar"
                />

                <Button
                  design="contained"
                  disabled={!selectedStores?.length}
                  className="defaultButton secondActionButton fontSizeDefault mr8"
                  onClick={() => {
                    handleSelectedSku(associateSkuPopover?.currentSku);
                  }}
                  label="Editar"
                />

                <Button
                  design="contained"
                  disabled={!selectedStores?.length}
                  className="defaultButton submitButton fontSizeDefault"
                  onClick={() => {
                    handleAssociateSkuToRestaurants();
                  }}
                  label={
                    associateSkuPopover.loading ? 'Processando' : 'Associar'
                  }
                />
              </div>
            </div>
          </DialogContent>
        </Dialog>
      )}

      {modalSettings.find((modal) => modal.name === 'productsAssociation')
        .open &&
        productInfo?.data && (
          <RegisterProductModal
            dialogOpen={
              modalSettings.find(
                (modal) => modal.name === 'productsAssociation'
              ).open
            }
            isModal
            onlyView={onlyViewProduct}
            consumptionUnitOptions={consumptionUnits}
            periodicityOptions={periodicities}
            storageLocationOptions={storageLocations}
            onClose={() => {
              setEditProducts('');
              setModalSettings(
                modalSettings.map((modal) =>
                  modal.name === 'productsAssociation'
                    ? { ...modal, open: false }
                    : modal
                )
              );
            }}
          />
      )}

      {similarSkusOpen && (
        <Suspense fallback={<span />}>
          <SimilarSkusDialog
            open={similarSkusOpen}
            setSimilarSkusOpen={setSimilarSkusOpen}
            skuDescription={similarSkuFilterValue}
            similarSkuProvider={similarSkuProvider}
            skusList={skusList}
            handleSelectedSku={handleSelectedSku}
            setComparationCardFilterValue={setComparationCardFilterValue}
            setSimilarSkuCurrentData={setSimilarSkuCurrentData}
            setModalSettings={setModalSettings}
            selectedProduct={selectedProduct}
            setSelectedProduct={setSelectedProduct}
            setOnlyViewProduct={setOnlyViewProduct}
            setSkusList={setSkusList}
            setSkuAssociation={setSkuAssociation}
            setDialogRestaurantData={setDialogRestaurantData}
            setDialogRestaurantSettings={setDialogRestaurantSettings}
            setEnableOpenSkuModal={setEnableOpenSkuModal}
          />
        </Suspense>
      )}

      <RestaurantsDialog
        openModal={dialogRestaurantSettings}
        handleClose={() => setDialogRestaurantSettings(false)}
        restaurantData={dialogRestaurantData}
      />

      <CommonAlert
        open={toastOpen}
        severity={toastProps?.severity}
        indexMessage={toastProps?.message}
        messagePersonal={toastProps?.message}
        onClose={handleCloseToast}
        id="catalogToastMessage"
      />
    </Layout>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.user,
  productInfo: state.products
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ ...EanActions, ...ProductsActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Catalog);
