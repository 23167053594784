import styled, { css } from 'styled-components';

import { mediaQueries } from '../../styles/mediaQueries';

export const PageTitleAndFilterMainContainer = styled.div`
  /* margin-top: ${({ screen }) => (screen ? '-3.77%' : '0')}; */
  justify-content: start !important;
  align-items: center;
  padding: 5px 0;

  &.noMarginFilter {
    .input-search-sku-site {
      margin-bottom: 0;
    }
  }
`;

export const PageTitleAndFilterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
`;

export const Titlecard = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  align-items: center;
  @media (max-width: ${mediaQueries.desktop}) {
    width: 70%;
  }
`;

export const TitlecardButtons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-bottom: 0;
`;

export const PageTitle = styled.h2`
  font-family: 'Lato';
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  margin: 0;
  ${({ isAlignCenter }) =>
    isAlignCenter &&
    css`
      display: flex;
      justify-content: center;
      align-items: center;
    `};
`;

export const IconFilter = styled.img`
  position: relative;
  cursor: pointer;
  width: 25px;
  height: 25px;
  display: block;
  font-size: 1.5em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  margin-left: 10px;
  margin-bottom: '0';
`;

export const FilterWrap = styled.div`
  /* overflow: hidden; */
  width: 100%;
  margin-top: 0px;
  box-shadow: 0px 0px 8px rgba(4, 6, 26, 0.25);
  background-color: white;
  border-radius: 4px;
`;

export const FilterCase = styled.div`
  padding: 16px;
`;

export const FilterContainer = styled.div`
  display: flex;
  flex-flow: wrap;
  align-items: center;
  justify-content: ${({ justifyContent }) =>
    justifyContent ? 'space-between' : 'flex-start'};
  width: 100%;
`;

export const TreeSelectorWrapper = styled.div`
  margin-right: 35px;
  height: 45px;
  label {
    /* margin-bottom: 7px; */
  }
  div {
    top: -11px;
    dt {
      color: #666770;
      font-family: 'Lato';
      font-size: 11px;
    }
    label {
      input {
        margin-left: 0;
        padding: 5.4px 0;
        &::placeholder {
          font-style: italic;
          color: #d1d2d8;
        }
      }
    }
  }
`;

export const FilterLabel = styled.label`
  font-family: 'Lato';
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  color: #00020f;
`;

export const PageAnTitleFilterWrapper = styled.div`
  @media screen and (min-device-width: 320px) and (max-device-width: 1024px) {
    display: none;
  }
`;

export const BackToDefaultIcon = styled.img`
  width: 18px;
  cursor: pointer;
`;
