import makePut from 'services/useFetch/makePut';

const createSKUBody = (body, multipleSkuData) => {
  const {
    user = '',
    groups = null,
    product = null,
    restaurants = {},
    restaurantIds = [],
    unitsMeasurements = {},
    unitsMeasurementsId = null,
    quantity = null,
    brandsId = null,
    typePackageId = null,
    categoryId = null,
    specification = null,
    description = '',
    descriptionLabel = '',
    ean = null,
    id = null,
    images = {},
    tags = [],
    providers = []
  } = body;

  if (multipleSkuData) {
    return {
      skus: [...multipleSkuData],
      product: {
        ...product
      },
      restaurantId: restaurantIds,
      user,
      groups
    };
  }

  return {
    skus: [
      {
        id,
        ean,
        description,
        descriptionLabel,
        specification,
        providers: [...providers],
        categoryId,
        brandsId,
        typePackageId,
        quantity,
        unitsMeasurementsId,
        restaurants,
        unitsMeasurements: {
          ...unitsMeasurements
        },
        images,
        tags
      }
    ],
    product: {
      ...product
    },
    restaurantId: restaurantIds,
    user,
    groups
  };
};

const assembleBody = (body, selectOption) => {
  const options = {
    allPackagesSelected: {
      newSku: false,
      allSku: true
    },
    lassPackagesThanTotal: {
      newSku: true,
      allSku: false
    }
  };

  return {
    ...body,
    product: Object.keys(body.product).length > 0 ? body.product : null,
    ...options[selectOption]
  };
};

const makeRequest = (
  body,
  handleResponseAproved,
  selectOption,
  comparationSkuId,
  isDraft
) => {
  body = assembleBody(body, selectOption);
  makePut(
    'skuCards',
    body,
    handleResponseAproved,
    `${comparationSkuId}/status/APPROVED${isDraft}`
  );
};

export const handleAprovedSku = ({
  body,
  totalSelectedSKU,
  handleResponseAproved,
  multipleSkuData,
  isDraft
}) => {
  const { id: comparationSkuId } = body;

  if (body?.product?.restaurantId != 0) {
    delete body?.product?.id;
  }

  const processedBody = createSKUBody(body, multipleSkuData);

  const { skus = [] } = processedBody;

  if (skus.length) {
    const [firstSku] = skus;
    const { providers = [] } = firstSku;

    if (multipleSkuData && multipleSkuData.length > 0) {
      if (skus.length < totalSelectedSKU) {
        makeRequest(
          processedBody,
          handleResponseAproved,
          'lassPackagesThanTotal',
          comparationSkuId,
          isDraft
        );
      } else {
        makeRequest(
          processedBody,
          handleResponseAproved,
          'allPackagesSelected',
          comparationSkuId,
          isDraft
        );
      }
    } else if (providers.length < totalSelectedSKU) {
      makeRequest(
        processedBody,
        handleResponseAproved,
        'lassPackagesThanTotal',
        comparationSkuId,
        isDraft
      );
    } else {
      makeRequest(
        processedBody,
        handleResponseAproved,
        'allPackagesSelected',
        comparationSkuId,
        isDraft
      );
    }
  }
};
