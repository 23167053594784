/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable array-callback-return */
/* eslint-disable no-nested-ternary */
import React from 'react';

import { CircularProgress, Fade, Tooltip } from '@material-ui/core';

import VirtualProduction from 'images/icons/signals/alerts/virtualProductionIcon.svg';

function TotalByTypeRow({ 
  extractItems, 
  productModal, 
  stockDifferenceStyle, 
  handleClickSales, 
  handleClickDiscard, 
  differenceInExtractTooltip,
  handleGetDiffs, 
  setDifferenceInExtractTooltip, 
  item 
}) {
  const itemConsumed = productModal?.product?.historical?.some((el) => el?.consumed)
  ? parseFloat(productModal?.product?.historical[0]?.consumed)
  : '';

  const totalProduced = extractItems?.map(el => el?.extracts
    ?.filter((extract) => extract?.type === 'PRODUCTION_PRODUCED')
    ?.reduce(
      (total, value) =>
        value ? parseFloat(value?.quantity) + parseFloat(total) : total,
      0
    )).reduce(
      (total, value) =>
        value ? parseFloat(value) + parseFloat(total) : total,
      0
    );

  const totalConsumedProduction = extractItems?.map(el => el?.extracts
    ?.filter((extract) => extract?.type === 'CONSUMED_PRODUCTION')
    ?.reduce(
      (total, value) =>
        value ? parseFloat(value?.quantity) + parseFloat(total) : total,
      0
    )).reduce(
      (total, value) =>
        value ? parseFloat(value) + parseFloat(total) : total,
      0
    );

  const totalDiscard = extractItems?.map(el => el?.extracts
    ?.filter((extract) => extract?.type === 'DISCARD')
    ?.reduce(
      (total, value) =>
        value ? parseFloat(value?.quantity) + parseFloat(total) : total,
      0
    )).reduce(
      (total, value) =>
        value ? parseFloat(value) + parseFloat(total) : total,
      0
    );

  const totalSales = extractItems?.map(el => el?.extracts
    ?.filter((extract) => extract?.type === 'SALES')
    ?.reduce(
      (total, value) =>
        value ? parseFloat(value?.quantity) + parseFloat(total) : total,
      0
    )).reduce(
      (total, value) =>
        value ? parseFloat(value) + parseFloat(total) : total,
      0
    );

  const totalPurchases = extractItems?.map(el => el?.extracts
    ?.filter((extract) => extract?.type === 'SHOPPING')
    ?.reduce(
      (total, value) =>
        value ? parseFloat(value?.quantity) + parseFloat(total) : total,
      0
    )).reduce(
      (total, value) =>
        value ? parseFloat(value) + parseFloat(total) : total,
      0
    );

  const totalTransfersEntry = extractItems?.map(el => el?.extracts
    ?.filter((extract) => extract?.type?.toUpperCase() === 'TRANSFER_ENTRY')
    ?.reduce(
      (total, value) =>
        value ? parseFloat(value?.quantity) + parseFloat(total) : total,
      0
    )).reduce(
      (total, value) =>
        value ? parseFloat(value) + parseFloat(total) : total,
      0
    );

  const totalTransfersOut = extractItems?.map(el => el?.extracts
    ?.filter((extract) => extract?.type?.toUpperCase() === 'TRANSFER_OUT')
    ?.reduce(
      (total, value) =>
        value ? parseFloat(value?.quantity) + parseFloat(total) : total,
      0
    )).reduce(
      (total, value) =>
        value ? parseFloat(value) + parseFloat(total) : total,
      0
    );

  const totalStockDifference = extractItems?.map(el => el?.extracts
    ?.filter((extract) => extract?.type === 'NO_DIFF' || extract?.type === 'SURPLUS' || extract?.type === 'LACK')
    ?.reduce(
      (total, value) =>
        value ? parseFloat(value?.quantity) + parseFloat(total) : total,
      0
    )).reduce(
      (total, value) =>
        value ? parseFloat(value) + parseFloat(total) : total,
      0
    );
  
  const formattedStockDifferenceObj = {
    product: {
      lossPercentage: productModal?.lossPercentage,
    },
    quantity: totalStockDifference,
    consumed: itemConsumed
  }

  const renderDivider = () => (
    <tr>
      <td colSpan="7" className="p0 m0">
        <hr className="m0" style={{ borderTop: '1px solid #ECEDF1' }} />
      </td>
    </tr>
  );
  
  return (
    <>
      {totalProduced ? (
        <>
          <tr>
            <td className="description pr12 pl16">&nbsp;</td>

            <td className="description pr10 pl0">
              <div
                className={`${
                  extractItems?.map(el => el?.extracts?.some(extract => extract?.type === "SURPLUS" || extract?.type === "NO_DIFF" || extract?.type === "LACK")) && 'pr8 pl5'
                }
                dFlex justifyBetween alignCenter`}
              >
                <span className="bold">Produzido:</span>

                <span>
                  {totalProduced?.toLocaleString('pt-br', {
                    minimumFractionDigits: 3
                  })}

                  {productModal?.consumptionUnitsPrimary?.abbreviation ||
                    productModal.consumptionUnitsPrimary}
                </span>
              </div>
            </td>

            <td colSpan={4} className="description pr12 pl16">
              &nbsp;
            </td>
          </tr>

          {renderDivider()}
        </>
      ) : null}

      {totalConsumedProduction ? (
        <>
          <tr>
            <td className="description pr12 pl16">&nbsp;</td>

            <td className="description pr10 pl0">
              <div
                className={`${
                  extractItems?.map(el => el?.extracts?.some(extract => extract?.type === "SURPLUS" || extract?.type === "NO_DIFF" || extract?.type === "LACK")) && 'pr8 pl5'
                }
                dFlex justifyBetween alignCenter`}
              >
                <span className="bold">Consumido em produção:</span>

                <span>
                  {totalConsumedProduction?.toLocaleString('pt-br', {
                    minimumFractionDigits: 3
                  })}

                  {productModal?.consumptionUnitsPrimary?.abbreviation ||
                    productModal.consumptionUnitsPrimary}
                </span>
              </div>
            </td>

            <td colSpan={4} className="description pr12 pl16">
              &nbsp;
            </td>
          </tr>

          {renderDivider()}
        </>
      ) : null}

      {totalDiscard ? (
        <>
          <tr
            style={{ cursor: 'pointer', color: '#3f51b5' }}
            onClick={() => {
              handleClickDiscard(extractItems?.map(el => el?.extracts)?.flat()?.filter((extract) => extract?.description === 'Descarte' || extract?.type === 'DISCARD')[0], productModal, extractItems?.map(el => el?.extracts)?.flat())
            }}
          >
            <td className="description pr12 pl16">&nbsp;</td>

            <td className="description pr10 pl0">
              <div
                className={`${
                  extractItems?.map(el => el?.extracts?.some(extract => extract?.type === "SURPLUS" || extract?.type === "NO_DIFF" || extract?.type === "LACK")) && 'pr8 pl5'
                }
                dFlex justifyBetween alignCenter`}
              >
                <span style={{ color: '#3f51b5' }} className="bold">Descarte
                  <Tooltip
                    TransitionComponent={Fade}
                    TransitionProps={{ timeout: 600 }}
                    onOpen={() => handleGetDiffs(extractItems?.map(el => el?.extracts)?.flat()?.filter((extract) => extract?.description === 'Descarte' || extract?.type === 'DISCARD')[0])}
                    onClose={() => setDifferenceInExtractTooltip(null)}
                    title={
                      <div
                        className="dFlex flexColumn"
                        style={{ width: '220px', paddingTop: 5 }}
                      >
                        {differenceInExtractTooltip !== null ? (
                          differenceInExtractTooltip.map((el) => (
                            <p className="mb5">
                              {`${el?.quantity?.toLocaleString('pt-br', { minimumFractionDigits: 3 })}
                              ${el?.consumptionUnits}: ${el?.description}`}
                            </p>
                          ))
                        ) : (
                          <div className="countsCircularProgressBar dFlex justifyCenter">
                            <CircularProgress />
                          </div>
                        )}
                      </div>
                    }
                  >
                    <img
                      alt="Discard Type"
                      src={VirtualProduction}
                      className="ml5"
                    />
                  </Tooltip>
                </span>

                <span style={{ color: 'rgb(245, 61, 76)' }}>
                  {totalDiscard?.toLocaleString('pt-br', { minimumFractionDigits: 3 })}

                  {productModal?.consumptionUnitsPrimary?.abbreviation || productModal.consumptionUnitsPrimary}
                </span>
              </div>
            </td>

            <td colSpan={4} className="description pr12 pl16">
              &nbsp;
            </td>
          </tr>

          {renderDivider()}
        </>
      ) : null}

      {totalSales ? (
        <>
          <tr
            style={{ cursor: 'pointer' }}
            onClick={() => { handleClickSales(item, false) }}
          >
            <td className="description pr12 pl16">&nbsp;</td>

            <td className="description pr10 pl0">
              <div
                className={`${
                  extractItems?.map(el => el?.extracts?.some(extract => extract?.type === "SURPLUS" || extract?.type === "NO_DIFF" || extract?.type === "LACK")) && 'pr8 pl5'
                }
                dFlex justifyBetween alignCenter`}
              >
                <span style={{ color: '#3f51b5' }} className="bold">
                  Venda
                </span>

                <span style={{ color: '#3f51b5' }}>
                  {totalSales?.toLocaleString('pt-br', { minimumFractionDigits: 3 })}
                  {productModal?.consumptionUnitsPrimary?.abbreviation || productModal.consumptionUnitsPrimary}
                </span>
              </div>
            </td>

            <td colSpan={4} className="description pr12 pl16">
              &nbsp;
            </td>
          </tr>

          {renderDivider()}
        </>
      ) : null}

      {totalPurchases ? (
        <>
          <tr>
            <td className="description pr12 pl16">&nbsp;</td>

            <td className="description pr10 pl0">
              <div
                className={`${
                  extractItems?.map(el => el?.extracts?.some(extract => extract?.type === "SURPLUS" || extract?.type === "NO_DIFF" || extract?.type === "LACK")) && 'pr8 pl5'
                }
                dFlex justifyBetween alignCenter`}
              >
                <span className="bold">
                  Compra:
                </span>

                <span>
                  {totalPurchases?.toLocaleString('pt-br', { minimumFractionDigits: 3 })}
                  {productModal?.consumptionUnitsPrimary?.abbreviation || productModal.consumptionUnitsPrimary}
                </span>
              </div>
            </td>

            <td colSpan={4} className="description pr12 pl16">
              &nbsp;
            </td>
          </tr>

          {renderDivider()}
        </>
      ) : null}

      {totalTransfersEntry ? (
        <>
          <tr>
            <td className="description pr12 pl16">&nbsp;</td>

            <td className="description pr10 pl0">
              <div
                className={
                  `${extractItems?.some((el) => el?.type === 'Diferença de estoque') && 'pr8 pl5'}
                  dFlex justifyBetween alignCenter`
                }
              >
                <span className="bold">
                  Transferido de:
                </span>

                <span>
                  {totalConsumedProduction?.toLocaleString('pt-br', { minimumFractionDigits: 3 })}
                  {productModal?.consumptionUnitsPrimary?.abbreviation || productModal.consumptionUnitsPrimary}
                </span>
              </div>
            </td>

            <td colSpan={4} className="description pr12 pl16">
              &nbsp;
            </td>
          </tr>

          {renderDivider()}
        </>
      ) : null}

      {totalTransfersOut ? (
        <>
          <tr>
            <td className="description pr12 pl16">&nbsp;</td>

            <td className="description pr10 pl0">
              <div
                className={`${
                  extractItems?.map(el => el?.extracts?.some(extract => extract?.type === "SURPLUS" || extract?.type === "NO_DIFF" || extract?.type === "LACK")) && 'pr8 pl5'
                }
                dFlex justifyBetween alignCenter`}
              >
                <span className="bold">
                  Transferido para:
                </span>

                <span>
                  {totalTransfersOut?.toLocaleString('pt-br', { minimumFractionDigits: 3 })}
                  {productModal?.consumptionUnitsPrimary?.abbreviation || productModal.consumptionUnitsPrimary}
                </span>
              </div>
            </td>

            <td colSpan={4} className="description pr12 pl16">
              &nbsp;
            </td>
          </tr>

          {renderDivider()}
        </>
      ) : null}

      {totalStockDifference ? (
        <>
          <tr>
            <td className="description pr12 pl16">&nbsp;</td>

            <td className="description pr10 pl0">
              <div
                className={`${
                  extractItems?.map(el => el?.extracts?.some(extract => extract?.type === "SURPLUS" || extract?.type === "NO_DIFF" || extract?.type === "LACK")) && 'pr8 pl5'
                }
                dFlex justifyBetween alignCenter`}
                style={{
                  backgroundColor: stockDifferenceStyle(
                    formattedStockDifferenceObj
                  ).background,
                  borderRadius: '17px'
                }}
              >
                <span className="bold">
                  {
                    stockDifferenceStyle(
                      formattedStockDifferenceObj
                    ).textFormatted
                  }
                </span>

                <span
                  style={{
                    color: stockDifferenceStyle(
                      formattedStockDifferenceObj
                    ).color
                  }}
                >
                  {totalStockDifference !== 0
                    ? totalStockDifference < 0
                      ? ''
                      : '+'
                    : null
                  }

                  {totalStockDifference?.toLocaleString('pt-br', {
                    minimumFractionDigits: 3
                  })}

                  {productModal?.consumptionUnitsPrimary?.abbreviation || productModal.consumptionUnitsPrimary}
                </span>
              </div>
            </td>

            <td colSpan={4} className="description pr12 pl16">
              &nbsp;
            </td>
          </tr>

          {renderDivider()}
        </>
      ) : null}
    </>
  );
}

export default TotalByTypeRow;
