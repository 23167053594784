/* eslint-disable no-underscore-dangle */
import React, { useState, useCallback, useMemo, useEffect, Suspense } from 'react';

import { IconButton, Tooltip } from '@material-ui/core';
import axios from 'axios'
import format from 'date-fns/format';
import ptLocale from 'date-fns/locale/pt-BR';
import moment from 'moment';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { bindActionCreators } from 'redux';

import CommonAlert from 'components/CommonAlert/CommonAlert';
import CopilotDialog from 'components/Dialogs/Copilot';
import PageTitleAndFilter from 'components/PageTitleAndFilter';
import TitleHead from 'components/TitleHead';
import Layout from 'containers/layouts/layout';
import CopilotFinishedIcon from 'images/icons/configsAndSetups/configGreenBgBlue.svg';
import CopilotNotReviewedIcon from 'images/icons/configsAndSetups/configWhiteBgBlue.svg';
import CopilotPendentIcon from 'images/icons/configsAndSetups/configYellowBgBlue.svg';
import * as UserActions from 'store/actions/user';
import { GlobalStyle } from 'styles/general';
import Environment from 'utils/environments';

import CountTab from './Count';
import CountGroupCarousel from './CountGroupCarousel';
import ProductionTab from './Production';
import SalesProjectionTab from './SalesProjection';
import * as S from './styles'


const TheoryStockDialog = React.lazy(() =>
    import('components/Dialogs/ExtractsDialog')
);

function DailySchedule({ userInfo }) {
    const { id: getId } = useParams();
    const [salesInfos, setSalesInfos] = useState([])
    const [tabsValue, setTabsValue] = useState([])
    const [productionValue, setProductionValue] = useState([]);
    const [cardsLack, setCardsLack] = useState([]);
    const [cardsLeftover, setCardsLeftover] = useState([]);
    const [monetaryLack, setMonetaryLack] = useState();
    const [monetaryLeftover, setMonetaryLeftover] = useState();
    const [totally, setTotally] = useState();
    const [modalStates, setModalStates] = useState({
        modalProduct: false,
    });
    const [modalSettings, setModalSettings] = useState([
        {
          id: 1,
          name: 'copilotConfig',
          fullScreen: false,
          maxWidth: 'lg',
        }
    ]);
    const [loading, setLoading] = useState(false)
    const [productModal, setProductModal] = useState({})
    const [copilotStatus, setCopilotStatus] = useState()
    const [loadingCloseProgramming, setLoadingCloseProgramming] = useState(false);
    const [productDescription, setProductDescription] = useState('-');
    const [parameters, setParameters] = useState('');
    const [checkConsume, setCheckConsume] = useState(false);
    const [salesProjectionValue, setSalesProjectionValue] = useState();
    const [countValue, setCountValue] = useState()
    const [totalCount, setTotalCount] = useState();
    const [tabsDaily, setTabsDaily] = useState(
        [
            {
                id: 1,
                name: 'PRODUÇÃO',
                width: '830px',
                active: true,
            },
            {
                id: 2,
                name: 'CONTAGEM',
                width: '931px',
                active: false,
            },
            {
                id: 3,
                name: 'PROJEÇÃO DE VENDAS',
                width: '620px',
                active: false,
            }
        ]
    )
    const [message, setMessage] = useState({
        description: '',
        status: ''
    });
    const [periodicities, setPeriodicities] = useState([])
    const [periodicity, setPeriodicity] = useState('')
    const [periodicitySave, setPeriodicitySave] = useState()
    const [dateSave, setDateSave] = useState()

    const [diversion, setDiversion] = useState('0,00')
    const [productsNegative, setProductsNegative] = useState([])
    const [productsPositive, setProductsPositive] = useState([])
    const [productsLength, setProductsLength] = useState([])
    const [totalProducts, setTotalProducts] = useState([])
    const [date, setDate] = useState(moment(new Date()).subtract(1, 'days').format('YYYY-MM-DD'))
    const [court, setCourt] = useState()
    const [idRest, setIdRest] = useState()
    const [dateCount, setDateCount] = useState()
    const [dateProduction, setDateProduction] = useState()
    const [lengthNegative, setLengthNegative] = useState()
    const [lengthPositive, setLengthPositive] = useState()
    const [productsTotalNegative, setProductsTotalNegative] = useState()
    const [productsTotalPositive, setProductsTotalPositive] = useState()

    const environments = Environment(process.env.REACT_APP_ENV);
    const widthCard = tabsDaily?.find((item) => item.active)
    const { id, active } = widthCard

    const today = moment(new Date()).format('YYYY-MM-DD')

    const handleOpenExtract = (product) => {
        setProductDescription(product.description);
        setProductModal(product);
        setModalStates((prevState) => ({ ...prevState, modalProduct: true }));
    };

    const handleCloseModal = () => {
        setModalStates({
            ...modalStates,
            modalProduct: false,
        });
    };

    const getSalesProjection = () => {
        axios
          .get(`${environments.engine}/forecast/daily?restaurantId=${getId}`)
          .then((response) => {
            const data = response.data;
            setSalesProjectionValue(data[0].projection);
          })
          .catch((error) => {
            console.log(error);
          });
      };

    const getTabsInfos = useCallback(() => {
        const formattedDate = moment().format('YYYY-MM-DD');

        axios
            .get(
                `${environments.catalog}/inventories/counts/dailySchedule?originId=${getId}&date=${formattedDate}`
            )
            .then((response) => {
                setTabsValue(response?.data || {});
                setCountValue(response.data.count)
            })
            .catch((error) => {
                setTabsValue([]);
                console.log(error);
            })
            .finally(() => { });
    }, [getId]);

    const getSalesInfos = useCallback((date, periodicity) => {
        const formattedDate = moment(date).format('YYYY-MM-DD');
        // const formattedDateLessDays = moment(date)
        //     .subtract(periodSubtractDays, 'day')
        //     .format('YYYY-MM-DD');

        axios
            .get(
                `${environments.dashBoard}/cmvView/totals?restaurantIds=${getId}&startDate=${formattedDate}&endDate=${formattedDate}`
            )
            .then((response) => {
                setSalesInfos(response?.data || {});
            })
            .catch((error) => {
                setSalesInfos([]);
                console.log(error);
            })
            .finally(() => { });
    }, [getId]);

    const closureTotal = useMemo(() => {
        if (salesInfos.length) {
            const total = salesInfos.find(el => el._id === parseFloat(getId))?.total || 0

            return (
                total?.toLocaleString('pt-br', {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2
                }) || '0,00'
            );
        }

        return '0,00';
    }, [salesInfos]);

    const getCopilotStatus = useCallback(() => {
        axios
          .get(
            `${environments.manufactures}/copilot/status?originId=${getId
            }&date=${moment().format('YYYY-MM-DD')}`
          )
          .then((response) => {
            setCopilotStatus(response?.data || {});
          })
          .catch((error) => {
            setCopilotStatus();
            setMessage({
                description: error?.response?.data?.message,
                status: error?.response?.status
            })
          });
      }, [getId]);

    const closeProgramming = () => {
        setLoadingCloseProgramming(true)
        const uri = `${environments.manufactures}/copilot/refused?originId=${getId}&date=${moment().format('YYYY-MM-DD')}&user=${userInfo.user}`

        const link = document.createElement('a');
        link.href = uri;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        setTimeout(() => {
        setLoadingCloseProgramming(false)
        }, 1000);
    };

    const copilotStatusInfo = () => {
        let copilotStatusObj = {
            image: CopilotNotReviewedIcon,
            tooltip: 'Meta não revisada'
        }

        if (copilotStatus?.message === 'Pendente') {
            copilotStatusObj = {
                image: CopilotPendentIcon,
                tooltip: 'Programação pendente'
            }
        }

        if (copilotStatus?.message === 'Finalizado') {
            copilotStatusObj = {
                image: CopilotFinishedIcon,
                tooltip: 'Programação finalizada'
            }
        }

        return copilotStatusObj
    }

    const copilotConfigButton = () => (
        <Tooltip 
            title={
                <span>
                    Copiloto<br/>
                    {copilotStatusInfo().tooltip}
                </span> 
            }
            aria-label="filters"
        >
            <IconButton
                onClick={() => {
                    setModalSettings(
                        modalSettings.map((modal) =>
                        modal.name === 'copilotConfig' ? { ...modal, open: true } : modal
                        )
                    );
                }}
                className="p0 ml10"
                edge="start"
                color="inherit"
                aria-label="add"
            >
                <img style={{ width: '25px'}} src={copilotStatusInfo().image} alt="Copilot Button" />
            </IconButton>
        </Tooltip>
    );

    useEffect(() => {
        getSalesInfos()
        getCopilotStatus()
        getSalesProjection()
        getTabsInfos()
    }, [getId])

    useEffect(() => {
        (async () => {
            const response = await fetch(
                `${environments.restaurants}/companies/${getId}/parameters`
            );
            const parametersResponse = await response.json();
            setParameters(parametersResponse || '');
        })();
    }, [getId]);

    useEffect(() => {
        const formattedDate = moment(date).format('YYYY-MM-DD')
        const courtExist = court ? `&courtId=${court}` : ''
        axios.get(`${environments.catalog}/inventories/setups/count/total?originId=${getId}&startDate=${formattedDate}&endDate=${formattedDate}${courtExist}`)
        .then((res) => {
            setTotalCount(res.data)
        })
    }, [date, getId, court])

    useEffect(() => {
        if (idRest !== getId) {
            setPeriodicities([])
            setPeriodicity('')
            setCourt()
            setIdRest(getId)
        } else {
            setIdRest(getId)
            setPeriodicity('')
            setCourt(null)
        }
    }, [getId])

    return (
        <Layout>
            <TitleHead title="Programação Diária" />

            <GlobalStyle />
            <PageTitleAndFilter
                title={`Programação diária ${format(new Date(), 'dd/MM/yyyy', {
                    locale: ptLocale
                })}`}
                afterTitleButton={copilotConfigButton}
            />
            <S.Content>
                <S.GroupHeader>
                    <CountGroupCarousel
                        tabsDaily={tabsDaily}
                        tabsValue={tabsValue}
                        countValue={totalCount}
                        setTabsDaily={setTabsDaily}
                        closureTotal={closureTotal}
                        salesProjectionValue={salesProjectionValue}
                        productionValue={totally}
                    />
                </S.GroupHeader>
                <S.CardLayout widthCard={widthCard.width} className={`cardDefault ${id === 3 ? 'salesProj' : ''}`}>
                    
                    {id === 3 && active &&
                        <SalesProjectionTab
                            getSalesInfos={getSalesInfos}
                            closureTotal={closureTotal}
                            handleOpenExtract={handleOpenExtract}
                            setSalesProjectionValue={setSalesProjectionValue}
                            modalSettings={modalSettings}
                            setModalSettings={setModalSettings}
                        />
                    }
                    {id === 2 && active &&
                        <CountTab
                            getSalesInfos={getSalesInfos}
                            getTabsInfos={getTabsInfos}
                            handleOpenExtract={handleOpenExtract}
                            setCountValue={setCountValue}
                            periodicities={periodicities}
                            setPeriodicities={setPeriodicities}
                            periodicity={periodicity}
                            setPeriodicity={setPeriodicity}
                            diversion={diversion}
                            setDiversion={setDiversion}
                            productsNegative={productsNegative}
                            setProductsNegative={setProductsNegative}
                            productsPositive={productsPositive}
                            setProductsPositive={setProductsPositive}
                            productsLength={productsLength}
                            setProductsLength={setProductsLength}
                            totalProducts={totalProducts}
                            setTotalProducts={setTotalProducts}
                            date={date}
                            setDate={setDate}
                            idRest={idRest}
                            setIdRest={setIdRest}
                            periodicitySave={periodicitySave}
                            setPeriodicitySave={setPeriodicitySave}
                            dateSave={dateSave}
                            setDateSave={setDateSave}
                            dateCount={dateCount}
                            setDateCount={setDateCount}
                            setLengthNegative={setLengthNegative}
                            lengthNegative={lengthNegative}
                            setLengthPositive={setLengthPositive}
                            lengthPositive={lengthPositive}
                            setProductsTotalNegative={setProductsTotalNegative}
                            productsTotalNegative={productsTotalNegative}
                            setProductsTotalPositive={setProductsTotalPositive}
                            productsTotalPositive={productsTotalPositive}
                        />
                    }
                    {id === 1 && active &&
                        <ProductionTab
                            setLoading={setLoading}
                            loading={loading}
                            getSalesInfos={getSalesInfos}
                            closureTotal={closureTotal}
                            handleOpenExtract={handleOpenExtract}
                            date={date}
                            setDate={setDate}
                            cardsLack={cardsLack}
                            setCardsLack={setCardsLack}
                            cardsLeftover={cardsLeftover}
                            setCardsLeftover={setCardsLeftover}
                            monetaryLack={monetaryLack}
                            setMonetaryLack={setMonetaryLack}
                            monetaryLeftover={monetaryLeftover}
                            setMonetaryLeftover={setMonetaryLeftover}
                            totally={totally}
                            setTotally={setTotally}
                            dateSave={dateSave}
                            setDateSave={setDateSave}
                            idRest={idRest}
                            setIdRest={setIdRest}
                            dateProduction={dateProduction}
                            setDateProduction={setDateProduction}
                            court={court}
                            setCourt={setCourt}
                            />
                    }
                </S.CardLayout>
            </S.Content>

            {modalStates.modalProduct && (
                <Suspense fallback={<span />}>
                    <TheoryStockDialog
                        getId={getId}
                        openModal={modalStates}
                        checkConsume={checkConsume}
                        setCheckConsume={setCheckConsume}
                        handleClose={handleCloseModal}
                        productExtractDescription={productDescription}
                        productAge={null}
                        productModal={productModal}
                        setProductModal={setProductModal}
                        parameters={parameters?.product?.type}
                        countDate={date}
                        weekDays
                        originPage="productPage"
                    />
                </Suspense>
            )}

            {modalSettings.find(modal => modal.name === 'copilotConfig').open && (
                <CopilotDialog
                    open={modalSettings.find((modal) => modal.name === 'copilotConfig').open}
                    modalSettings={modalSettings}
                    setModalSettings={setModalSettings}
                    closeProgramming={closeProgramming}
                    loadingCloseProgramming={loadingCloseProgramming}
                    handleClose={() => {
                        setModalSettings(
                            modalSettings.map((modal) =>
                                modal.name === 'copilotConfig' ? { ...modal, open: false } : modal
                            )
                        );
                    }}
                />
            )}

            <CommonAlert
                open={message?.description}
                onClose={() => {
                    setMessage({
                        description: '',
                        status: '',
                        severity: ''
                    })
                }}
                indexMessage={message?.description}
                statusCode={message?.status}
                severity={message?.severity}
                autoHideDuration={2000}
            />
        </Layout>
    )
}

const mapStateToProps = (state) => ({
    userInfo: state.user
});
  
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(UserActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DailySchedule);
