/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';

import { LinearProgress, Tooltip } from '@material-ui/core';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import * as UserActions from 'store/actions/user';

import GroupedVerticalBarGraphic from '../graphics/GroupedVerticalBarGraphic';

function ReceivementInfosArea({ 
  receivementInfos,
  actualDateLessOne,
  actualDateLessFourteen,
  paramsID
}) {
  return (
    <Tooltip
      TransitionProps={{ timeout: 600 }}
      title={
        <span
          className="dBlock"
          style={{ padding: '2px 5px' }}
        >
          Visualizar todos os pedidos
        </span>
      }
    >
      <Link
        target="_blank"
        to={{
          pathname: `/recebimento/${paramsID}?startAt=${actualDateLessFourteen}&endAt=${actualDateLessOne}`
        }}
      >
        <section className="cockpitReceivementInfosContainer cockpitDefaultContainer">
          <div className="graphicContainer">
            <div className="graphicBox">
              <div className="graphicItem">
                <div className="dFlex justifyBetween">
                  <span className="graphicTitle">
                    recebimento
                  </span>
                </div>

                {receivementInfos?.loading ? (
                  <div className="dFlex">
                    <LinearProgress className="w100 mb10" id="loading" variant="query" />
                  </div>
                ) : 
                  <GroupedVerticalBarGraphic 
                    mainContent={receivementInfos}
                  />
                }
              </div>
            </div>
          </div>
        </section>
      </Link>
    </Tooltip>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.user
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(UserActions, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReceivementInfosArea);
