/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';

import { LinearProgress, Tooltip } from '@material-ui/core';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import * as UserActions from 'store/actions/user';

function CountInfosArea({ 
  countInfos,
  actualDateLessOne,
  actualDateLessFourteen,
  paramsID
}) {
  const mainContent = countInfos?.content

  const progressBarItem = (description, legend, percent, color, url) => (
    <Tooltip
      TransitionProps={{ timeout: 600 }}
      className={`barItem dBlock ${!url && 'disabledLink'}`}
      disableHoverListener={!url}
      title={
        <span
          className="dBlock"
          style={{ padding: '2px 5px' }}
        >
          Visualizar todas as contagens
        </span>
      }
    >
      <Link
        target="_blank"
        to={{
          pathname: url
        }}
      >
        <div className="barInfos">
          <span>
            {description}
          </span>

          <div className="legendInfos">
            <span>
              {legend}
            </span>

            <span className="percent">
              {percent}
            </span>
          </div>
        </div>

        <div className="progressBarBorder">
          <div className="progressBar" style={{ background: color, width: percent }}/>
        </div>
      </Link>
    </Tooltip>
  )

  return (
    <section className="cockpitCountInfosContainer cockpitDefaultContainer">
      <div className="graphicContainer">
        <div className="graphicBox">
          <div className="graphicItem">
            <div className="dFlex justifyBetween" style={{ marginBottom: '1vh' }}>
              <span className="graphicTitle mb0">
                contagem
              </span>

              <div className="pRelative justifyCenter dFlex alignCenter">
                <span 
                  className="graphicFilterTitle mb0 bold"
                >
                  descarte:
                </span>

                <span className="fontSizeDefault bold" style={{ color: '#AA8208' }}>
                  R$ {(mainContent?.discardValue || 0)?.toLocaleString('pt-br', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  })}
                </span>
              </div>
            </div>

            {countInfos?.loading ? (
              <div className="dFlex">
                <LinearProgress className="w100 mb10" id="loading" variant="query" />
              </div>
            ) : 
              <section className="barInfosContainer">
                {progressBarItem(
                  "conclusão",
                  "",
                  `${(mainContent?.completeCountsPercent || 0)?.toLocaleString('pt-br', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}%`,
                  "#5062F0",
                  `/inventario/contagens/${paramsID}?startAt=${actualDateLessFourteen}&endAt=${actualDateLessOne}`
                )}

                {progressBarItem(
                  "sobras",
                  `${(mainContent?.totalProductsWithSurplus || 0)?.toLocaleString('pt-br', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}
                  /${(mainContent?.totalProductsInCounts || 0)?.toLocaleString('pt-br', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}`,
                  `${(mainContent?.productsWithSurplusPercent || 0)?.toLocaleString('pt-br', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}%`,
                  "#F2B90D",
                  `/inventario/contagens/${paramsID}?startAt=${actualDateLessFourteen}&endAt=${actualDateLessOne}&difference=leftover`
                )}

                {progressBarItem(
                  "faltas",
                  `${(mainContent?.totalProductsWithShortage || 0)?.toLocaleString('pt-br', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}
                  /${(mainContent?.totalProductsInCounts || 0)?.toLocaleString('pt-br', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}`,
                  `${(mainContent?.productsWithShortagePercentage || 0)?.toLocaleString('pt-br', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}%`,
                  "#F53D4C",
                  `/inventario/contagens/${paramsID}?startAt=${actualDateLessFourteen}&endAt=${actualDateLessOne}&difference=lack`
                )}
              </section>
            }
          </div>
        </div>
      </div>
    </section>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.user
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(UserActions, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CountInfosArea);
