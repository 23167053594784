import React, { useState, useEffect } from 'react';

import { Container } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Axios from 'axios';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { connect, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as yup from 'yup';

import Button from 'components/Button/button';
import CommonAlert from 'components/CommonAlert/CommonAlert';
import TitleHead from 'components/TitleHead';
import Layout from 'containers/layouts/layoutNoBars';
import BloomIcon from 'images/logo/Logomarca_Bloom_Branco.svg';
import * as UserActions from 'store/actions/user';
import { handleCountColumns, handleMenusColumns } from 'store/reducers/userConfigs';
import Environment from 'utils/environments';
import history from 'utils/history';

function Login({ location, companiesSet }) {
  const [alertErr, setAlertErr] = useState(false);
  const [messageError, setMessageError] = useState('')
  const onDismiss = () => setAlertErr(false);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const environments = Environment(process.env.REACT_APP_ENV);
  const query = new URLSearchParams(location.search);
  const hashId = query.get('hashId');

  useEffect(() => {
    if (hashId) {
      setLoading(true);

      Axios
        .post(`${environments.auth}/users/providers/auth?hashId=${hashId}`)
        .then((response) => {
          const { data } = response;
          if (data) {
            companiesSet(data.user, data.companies, data.profile);
            localStorage.setItem('token', JSON.stringify(response));
            history.push(
              `/fornecedores/catalogo/${data.companies.groups[0].subGroups[0].stores[0].id}`,
              location.state + [response.data]
            );
          }
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          setMessageError(error.response.data.message)
          setAlertErr(true);
        });
    }
  }, [hashId]);

  const handleSubmit = (values) => {
    setLoading(true);
    localStorage.clear();

    Axios
      .post(`${environments.auth}/users/auth`, values)
      .then((response) => {
        const { data } = response;

        if (data) {
          companiesSet(
            data.user,
            data.companies,
            data.profile,
            data.keycloakId,
            data.systemType,
            data.configs,
            data.operationalUsers,
            data.userMaster
          );
         
          dispatch(handleCountColumns({ configs: data?.configs }));
          dispatch(handleMenusColumns({ configs: data?.configs }))
          localStorage.setItem('sessionExpireCountdown', 36000);
          localStorage.setItem('token', JSON.stringify(response));
          if (data.profile[0][0].name === 'admin') {
            history.push(
              `/admin/catalogacao/${data.companies.groups[0].subGroups[0].stores[0].id}`,
              location.state + [response.data]
            );
          } else if (data.profile[0][0].name === 'providers') {
            history.push(
              `/fornecedores/catalogo/${data.companies.groups[0].subGroups[0].stores[0].id}`,
              location.state + [response.data]
            );
          } else if (data.profile[0][0].name === 'quoter') {
            history.push(
              `/cotacao/gestor/compras/${data.companies.groups[0].subGroups[0].stores[0].id}`,
              location.state + [response.data]
            );
          } else if (data.profile[0][0].name === 'cataloguer') {
            history.push(
              `/admin/catalogacao/${data.companies.groups[0].subGroups[0].stores[0].id}`,
              location.state + [response.data]
            );
          } else {
            history.push(
              `/${data.companies.groups[0].subGroups[0].stores[0].id}`,
              location.state + [response.data]
            );
          }
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setMessageError(error?.response?.data?.message)
        setAlertErr(true);
      });
  };

  const validations = yup.object().shape({
    credential: yup.string().required('É obrigatorio informar usuário'),
    password: yup.string().min(3).required('É obrigatorio informar senha')
  });

  return (
    <Layout>
      <TitleHead title="Login" />

      <div
        className="dFlex alignCenter justifyCenter backgroundLogin w100"
      >
        <Container
          className="dFlex alignCenter justifyCenter"
          style={{ height: '100vh' }}
          maxWidth="xs"
        >
          <Formik
            initialValues={{
              credential: '',
              password: ''
            }}
            onSubmit={handleSubmit}
            validationSchema={validations}
          >
            <Form className="Form">
              <div className='radius'>
                <div className='dFlex justifyCenter backgroundTitle'>
                  <div className="logoTitle">
                    <img src={BloomIcon} alt="BloomIcon" />
                  </div>
                </div>

                <div className='dFlex flexColumn alignCenter p20 w100 gapItens'>
                  <Grid item xs={11} className='w100'>
                    <div className='bold fontSize14 fontColor'>
                      Usuário
                    </div>

                    <Field
                      component={TextField}
                      name="credential"
                      placeholder="Digite aqui ..."
                      disabled={false}
                      fullWidth
                      className="inputStyle"
                    />
                  </Grid>

                  <Grid item xs={11} className='w100'>
                    <div className='bold fontSize14 fontColor'>
                      Senha
                    </div>

                    <Field
                      component={TextField}
                      type="password"
                      name="password"
                      placeholder="Digite aqui ..."
                      disabled={false}
                      fullWidth
                      className="inputStyle"
                    />
                  </Grid>

                  <Grid item xs={12} className="buttonEnter">
                    <Button
                      type="submit"
                      label="Entrar"
                      design="contained"
                      fullWidth
                    />
                  </Grid>
                </div>

                <CommonAlert
                  open={alertErr}
                  onClose={onDismiss}
                  indexMessage={messageError}
                />
              </div>
            </Form>
          </Formik>
        </Container>
      </div>
    </Layout>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.user
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(UserActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Login);
