/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
import React, { Suspense, useEffect, useState } from 'react';

import DateFnsUtils from '@date-io/date-fns';
import {
  Grid,
  List,
  ListItem,
  Tooltip,
  Collapse,
  LinearProgress
} from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import axios from 'axios';
import ptLocale from 'date-fns/locale/pt-BR';
import moment from 'moment';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';

import TheoryStockDialog from 'components/Dialogs/ExtractsDialog';
import TreeSelectorFieldSquares from 'components/PageTitleAndFilter/TreeSelectorFieldSquares';
import { handleFields } from 'pages/production/utils';
import Environment from 'utils/environments';
import ValidationLength from 'utils/utils';
import { CalendarIcon } from './calendar';

import * as S from '../styles';

const environments = Environment(process.env.REACT_APP_ENV);

function handleRecipe(manufacture, open) {
  return (
    <tr>
      <td colSpan="13" className="tableColorHover">
        <Collapse
          in={open}
          timeout="0"
          unmountOnExit
          className={open && 'expanded'}
        >
          <table
            width="60%"
            cellPadding="0"
            cellSpacing="0"
            border="0"
            style={{ border: 'none' }}
            className="dropDownTable"
          >
            <thead>
              <tr height="25">
                <th
                  width="120"
                  style={{
                    paddingLeft: '24px',
                    fontSize: '9px',
                    color: '#666770'
                  }}
                  className="subtitle bold"
                >
                  ITENS
                </th>
                <th
                  width="42"
                  className="subtitle right pr8 bold"
                  style={{
                    fontSize: '9px',
                    color: '#666770'
                  }}
                >
                  QTDE
                </th>
                <th
                  width="40"
                  className="subtitle p8 bold"
                  style={{
                    fontSize: '9px',
                    color: '#666770'
                  }}
                >
                  UN
                </th>
              </tr>
            </thead>

            <tbody>
              {manufacture.items.map((item, index) => (
                  <tr>
                    <td
                      className="description itemProductionTableDescription"
                      style={{ paddingLeft: '24px' }}
                    >
                      {ValidationLength(
                        item.item,
                        16,
                        null,
                        null,
                        null,
                        null,
                        item?.itemProcess?.description
                          ? `- ${item?.itemProcess?.description}`
                          : ''
                      )}
                    </td>

                    <td className="description pr8 right">
                      {item.quantity
                        ? parseFloat(item.quantity).toLocaleString('pt-br', {
                          minimumFractionDigits: 3
                        })
                        : '0,000'}
                    </td>

                    <td className="description p8">{item?.un?.abbreviation}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </Collapse>
      </td>
    </tr>
  );
}

function handleTransformation(manufacture, open) {
  const cardYield =
    manufacture.type === 'recipe'
      ? manufacture.yield * (manufacture.quantity || 1)
      : (manufacture.quantity || 1) -
      (manufacture.quantity || 1) * (manufacture.loss / 100);
  const { loss } = manufacture;
  const total = manufacture.items.reduce(
    (total, value) =>
      value
        ? parseFloat(total) + parseFloat(value.quantity)
        : parseFloat(total),
    0
  );
  const cardQuantity = manufacture.quantity || 1;
  const cardLostTotal = parseFloat(cardQuantity) - parseFloat(total);

  return (
    <tr valign="center">
      <td colSpan="13" className="tableColorHover">
        <Collapse
          in={open}
          timeout="0"
          unmountOnExit
          className={open && 'expanded'}
        >
          <table
            width="100%"
            cellPadding="0"
            cellSpacing="0"
            border="0"
            style={{ border: 'none' }}
            className="dropDownTable"
          >
            <thead>
              <tr height="25">
                <th
                  width="140"
                  style={{
                    paddingLeft: '24px',
                    fontSize: '9px',
                    color: '#666770'
                  }}
                  className="subtitle bold"
                >
                  PRODUTOS
                </th>
                <th
                  width="65"
                  style={{
                    fontSize: '9px',
                    color: '#666770'
                  }}
                  className="subtitle right pr8 bold"
                >
                  ESTOQUE
                </th>
                <th
                  width="40"
                  style={{
                    fontSize: '9px',
                    color: '#666770'
                  }}
                  className="subtitle right p8 bold"
                >
                  DURAÇÃO
                </th>
                <th
                  width="70"
                  style={{
                    fontSize: '9px',
                    color: '#666770'
                  }}
                  className="subtitle right pr8 bold"
                >
                  QTDE
                </th>
                <th
                  width="10"
                  style={{
                    fontSize: '9px',
                    color: '#666770'
                  }}
                  className="subtitle p8 bold"
                >
                  UN
                </th>
                <th
                  width="50"
                  style={{
                    fontSize: '9px',
                    color: '#666770'
                  }}
                  className="subtitle right bold"
                >
                  NOVA DUR.
                </th>
              </tr>
            </thead>

            <tbody>
              {manufacture.items.map((item, index) => (
                <>
                  <tr>
                    <td
                      className="description"
                      style={{ paddingLeft: '24px' }}
                    >
                      {ValidationLength(item.item, 23)}
                      {item.suggestionYield
                        ? `(${item.suggestionYield.toLocaleString('pt-br', {
                          minimumFractionDigits: 3
                        }) + (item?.un?.abbreviation || '')
                        })`
                        : ''}
                    </td>

                    <td className="description right pr8">
                      {`${(item.stock ? item.stock : 0).toLocaleString(
                        'pt-br',
                        { minimumFractionDigits: 3 }
                      )} ${item?.un?.abbreviation || ''}`}
                    </td>

                    <td className="description right p8">
                      {`${item.duration ? item.duration : 0} ${item.duration > 1 ? 'dias' : 'dia'
                        }`}
                    </td>

                    <td className="description p8 right">
                      {item.quantity
                        ? parseFloat(item.quantity).toLocaleString('pt-br', {
                          minimumFractionDigits: 3
                        })
                        : '0,000'}
                    </td>

                    <td className="description p8">
                      {item?.un?.abbreviation}
                    </td>

                    <td className="description right">
                      {item.newDuration && item.newDuration > 1
                        ? `${item.newDuration} dias`
                        : item.newDuration
                          ? `${item.newDuration} dia`
                          : null}
                    </td>
                  </tr>

                  <tr>
                    <td colSpan="8">
                      <hr />
                    </td>
                  </tr>
                </>
              ))}

              <tr height="5">
                <td
                  className="description"
                  style={{ paddingLeft: '24px' }}
                  colSpan="3"
                >
                  Perda (
                  {loss
                    ? parseFloat(loss).toLocaleString('pt-br', {
                      minimumFractionDigits: 3
                    })
                    : '0,000'}
                  %)
                </td>

                <td
                  className="description right pr8"
                  style={{
                    color:
                      cardQuantity && total
                        ? parseFloat(cardQuantity) - parseFloat(cardYield) <
                          cardLostTotal
                          ? 'red'
                          : 'black'
                        : 'black'
                  }}
                >
                  {cardQuantity && total
                    ? cardLostTotal.toLocaleString('pt-br', {
                      minimumFractionDigits: 3
                    })
                    : (
                      parseFloat(cardQuantity) - parseFloat(cardYield)
                    ).toLocaleString('pt-br', {
                      minimumFractionDigits: 3
                    })}
                </td>

                <td className="description pl8">
                  {manufacture?.items?.[0]?.un
                    ? manufacture?.items?.[0]?.un?.abbreviation
                    : ''}
                </td>

                <td
                  className="description"
                  colSpan="3"
                  style={{
                    color:
                      parseFloat(100 * cardLostTotal) /
                        parseFloat(cardQuantity) >
                        parseFloat(loss)
                        ? 'red'
                        : 'black'
                  }}
                >
                  {total !== 0 &&
                    `${(
                      parseFloat(100 * cardLostTotal) / parseFloat(cardQuantity)
                    ).toLocaleString('pt-br', {
                      minimumFractionDigits: 3
                    })}%`}
                </td>
              </tr>
            </tbody>
          </table>
        </Collapse>
      </td>
    </tr>
  );
}

function ProductionTab({
  date,
  setDate,
  setCardsLack,
  cardsLack,
  setCardsLeftover,
  cardsLeftover,
  setMonetaryLack,
  monetaryLack,
  setMonetaryLeftover,
  monetaryLeftover,
  setTotally,
  totally,
  dateSave,
  setDateSave,
  setIdRest,
  idRest,
  dateProduction,
  setDateProduction,
  setCourt,
  court
}) {
  const { id: getId } = useParams();
  const formatDate = moment(date).format('YYYY-MM-DD');
  const [loading, setLoading] = useState(false);
  const [checkConsume, setCheckConsume] = useState(false);
  const [productExtractDescription, setProductExtractDescription] = useState('-');
  const [productModal, setProductModal] = useState({});
  const [parameters, setParameters] = useState('');
  const [modalStates, setModalStates] = useState({
    modalProduct: false,
  });

  const getReportInfos = () => {
    setLoading(true)
    const courtExist = court ? `&courtId=${court}` : ''
    axios
      .get(
        `${environments.manufactures}/manufactures/dailySchedule/${formatDate}?originId=${getId}${courtExist}`
      )
      .then((response) => {
        const sortedCardsLack = response?.data?.lack?.manufactures?.sort((a, b) => b.diffMonetary - a.diffMonetary);
        const filterCardlack = sortedCardsLack.filter(m => {
          const diffQuantity = m.manufactured - m.yield
          const diffPercent = (diffQuantity / m.yield) * 100
          const shouldReturn = diffPercent > m.variationPercentage || diffPercent < m.variationPercentage * -1
 
            return !!shouldReturn
        })
        
        setCardsLack(
          filterCardlack?.map((item) => ({
            ...item,
            open: false
          }))
        );
        const sortedCardsLeft = response?.data?.leftover?.manufactures?.sort((a, b) => b.diffMonetary - a.diffMonetary);
        const filterCardleft = sortedCardsLeft.filter(m => {
          const diffQuantity = m.manufactured - m.yield
          const diffPercent = (diffQuantity / m.yield) * 100
          const shouldReturn = diffPercent > m.variationPercentage || diffPercent < m.variationPercentage * -1
 
            return !!shouldReturn
        })
        setCardsLeftover(
          filterCardleft.map((item) => ({
            ...item,
            open: false
          }))
        );
        setMonetaryLack(response?.data?.lack?.totalMonetary);
        setMonetaryLeftover(response?.data?.leftover?.totalMonetary);
        setTotally(response?.data);
        setLoading(false);
        setDateSave(formatDate);
        setDateProduction(formatDate)
        setIdRest(getId)
      })
      .catch((error) => {
        setLoading(false)
        console.log(error);
      });
  };

  function handleOpenExtract(product) {
    setProductExtractDescription(product.description);
    setProductModal({
      ...product,
      id: product?.productId,
      consumptionUnitsPrimary: product?.un
    });
    setModalStates({ ...modalStates, modalProduct: true });
  }

  const handleClose = () => {
    setModalStates({
      modalProduct: false,
    });
  };

  const handleOpenItems = (id, setCurrentList, currentList) => {
    setCurrentList(
      currentList?.map((manufacture) => {
        if (manufacture._id === id) {
          return {
            ...manufacture,
            open: !manufacture.open
          };
        }

        return { ...manufacture };
      })
    );
  };

  const totalDiffCalc = (value) =>
    Math?.abs(
      value?.reduce(
        (total, value) =>
          value ? parseFloat(value?.diffMonetary) + parseFloat(total) : total,
        0
      )
    );

  useEffect(() => {
    if (dateProduction !== dateSave) {
      getReportInfos()
      setDateProduction(formatDate)
    }
  }, [formatDate])

  useEffect(() => {
    if (formatDate !== dateSave || idRest !== getId) {
      getReportInfos();
    }
  }, [date, getId]);

  return (
    <>
      <S.Filters>
        <div className="dFlex" style={{ gap: '16px' }}>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptLocale}>
            <KeyboardDatePicker
              margin="normal"
              id="dataInicio"
              variant="inline"
              autoOk
              label='Data'
              maxDate={new Date()}
              className="dateSmall"
              style={{ width: '70px' }}
              format="dd/MM"
              value={moment(date).add(1, 'day').format('YYYY-MM-DD')}
              placeholder="Selecione"
              onChange={(value) => {
                setDate(value);
              }}
              InputLabelProps={{
                shrink: true,
                fontSize: '12px'
              }}
              InputProps={{
                style: { fontFamily: 'Lato', fontSize: '12px', margin: 0 }
              }}
              KeyboardButtonProps={{
                size: 'small',
                'aria-label': 'change time',
                style: { outline: 0 }
              }}
            />
          </MuiPickersUtilsProvider>
          <div style={{marginTop: '3px'}}>
            <TreeSelectorFieldSquares
              filterName="courts"
              style={{ width: '30%'}}
              label='Praça:'
              handleFilter={(_, value) => {
                setCourt(value)
              }}
              key={(node) => node?.id}
              customSize="200px"
              getFilterhandle={() => {}}
              options={[]}
            />
          </div>
        </div>

        <S.Totaldifferences
          backgroundColor={
            totally?.totalDiffMonetary > 0 ? '#FFF2CC' : '#F7D4D7'
          }
          backgroundDiv={
            totally?.totalDiffMonetary > 0
              ? 'rgba(242, 185, 13, 0.6)'
              : 'rgba(245, 61, 76, 0.6)'
          }
        >
          <Tooltip
            TransitionProps={{ timeout: 600 }}
            placement="right"
            title={
              <span
                className="dBlock"
                style={{ padding: '4px 7px' }}
              >
                Visualizar todas as produções
              </span>
            }
          >
            <Link
              target="_blank"
              to={{
                pathname: `/producao/${getId}?&startAt=${moment(date).format(
                  'YYYY-MM-DD'
                )}&endAt=${moment(date).format(
                  'YYYY-MM-DD'
                )}&difference=all`
              }}
            >
              <div style={{ color: '#5062F0'}}>{totally?.total}</div>
            </Link>
          </Tooltip>

          <p>
            {totally?.totalDiffMonetary > 0 ? 'R$' : '- R$'}{' '}
            {totally?.totalDiffMonetary.toLocaleString('pt-br', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            })}
          </p>
        </S.Totaldifferences>
      </S.Filters>

      {loading && <LinearProgress variant="query" />}

      {cardsLack.length ? (
        <div>
          <div className="headerSubtitle">
            <div className="subheader">
              <th width="40.9%" className="subtitle">
                FICHA{' '}
              </th>

              <th width="13.5%" className="subtitle textAlignRight lineLeft">
                RENDIMENTO{' '}
              </th>

              <th width="13.4%" className="subtitle textAlignRight lineLeft">
                PRODUZIDO{' '}
              </th>

              <th width="13.1%" className="subtitle textAlignRight lineLeft">
                EM QTDE{' '}
              </th>
              <th width="19.4%" className="subtitle textAlignRight lineLeft">
                EM R${' '}
              </th>
            </div>
          </div>

          <table
            className="tableNegative"
            width="100%"
            cellPadding="0"
            cellSpacing="0"
            border="0"
          >
            <thead className='theadNegative'>
              <tr className="headerTitle">
                <th className="subtitle" colSpan={4}>
                  FALTAS{' '}
                </th>

                <th width="15%" className="subtitle lineLeft alignCollumn">
                  <div className="gapItens dFlex justifyBetween alignCenter">
                    <Tooltip
                      TransitionProps={{ timeout: 600 }}
                      placement="right"
                      title={
                        <span
                          className="dBlock"
                          style={{ padding: '4px 7px' }}
                        >
                          Visualizar todas as diferenças negativas
                        </span>
                      }
                    >
                      <Link
                        target="_blank"
                        to={{
                          pathname: `/producao/${getId}?&startAt=${moment(date).format(
                            'YYYY-MM-DD'
                          )}&endAt=${moment(date).format(
                            'YYYY-MM-DD'
                          )}&difference=lack`
                        }}
                      >
                        <div className="totalNumber" style={{ color: '#5062F0'}}>{totally?.lack?.total || 0}</div>
                      </Link>
                    </Tooltip>

                    <p className="totalValue">
                      - R${' '}
                      {monetaryLack?.toLocaleString('pt-br', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })}
                    </p>
                  </div>
                </th>
              </tr>
            </thead>

            <tbody>
              {cardsLack?.slice(0, 5)?.map((item, index) => {
                const type = item?.type?.toLowerCase();
                return (
                  <>
                    <tr className="list">
                      <td
                        width="41%"
                        className={`titleLink ${index === 0 && 'pt20'} pb0`}
                        style={{ paddingLeft: '4px' }}
                      >
                        <List component="nav" className="list">
                          <ListItem className="listItem p0">
                            <Grid
                              container
                              spacing={0}
                              style={{
                                padding: 0,
                                flexWrap: 'unset',
                                alignItems: 'center'
                              }}
                            >
                              <Grid
                                className="dFlex alignCenter"
                                container
                                item
                                xs={12}
                              >
                                <Grid
                                  item
                                  xs={1}
                                  style={{
                                    padding: '0 5px',
                                    cursor: 'pointer'
                                  }}
                                  onClick={() =>
                                    handleOpenItems(
                                      item._id,
                                      setCardsLack,
                                      cardsLack
                                    )
                                  }
                                >
                                  {cardsLack.find((el) => el._id === item._id)
                                    ?.open ? (
                                    <ExpandLess />
                                  ) : (
                                    <ExpandMore />
                                  )}
                                </Grid>

                                <Grid
                                  item
                                  xs={10}
                                  style={{ padding: '0 0 0 5px' }}
                                >
                                  <Tooltip
                                    TransitionProps={{ timeout: 600 }}
                                    placement="right"
                                    title={
                                      <span
                                        className="dBlock"
                                        style={{ padding: '4px 7px' }}
                                      >
                                        {item?.cardId} -{' '}
                                        {handleFields(item).description}
                                      </span>
                                    }
                                  >
                                    <Link
                                      target="_blank"
                                      to={{
                                        pathname: `/producao/${getId}?cardId=${item?.cardId
                                          }&cardDescription=${item?.description
                                          }&status=${item?.status
                                          }&startAt=${moment(date).format(
                                            'YYYY-MM-DD'
                                          )}&endAt=${moment(date).format(
                                            'YYYY-MM-DD'
                                          )}`
                                      }}
                                    >
                                      <span>
                                        {handleFields(item).description
                                          ?.length > 14
                                          ? `${handleFields(
                                            item
                                          ).description?.substring(0, 33)}`
                                          : handleFields(item).description}
                                        {handleFields(item).description
                                          ?.length > 35 && '...'}
                                      </span>
                                    </Link>
                                  </Tooltip>
                                </Grid>
                              </Grid>
                              
                              <Tooltip
                                TransitionProps={{ timeout: 600 }}
                                placement="right"
                                title={
                                  <span
                                    className="dBlock"
                                    style={{ padding: '4px 7px' }}
                                  >
                                    {item?.todaySchedule ? 'Com agenda definida para esta data' : 'Sem agenda definida para esta data'} 
                                  </span>
                                }
                              >
                                <span>
                                  <CalendarIcon
                                    fill={
                                      item?.todaySchedule ? '#5062F0' : '#9296AC'
                                    }
                                  />
                                </span>
                              </Tooltip>
                            </Grid>
                          </ListItem>
                        </List>
                      </td>
                      <td
                        width="13.2%"
                        className="lineLeft tdParagraph textAlignRight"
                      >
                        {(item?.yield || 0).toLocaleString('pt-br', {
                          minimumFractionDigits: 3,
                          maximumFractionDigits: 3
                        })}{' '}
                        {item?.un?.abbreviation}
                      </td>
                      <td
                        width="13%"
                        className="titleLink lineLeft textAlignRight cursorPointer"
                      >
                        <p
                          className='m0'
                          onClick={() => handleOpenExtract(item)}
                        >
                          {(item?.manufactured || 0)?.toLocaleString('pt-br', {
                            minimumFractionDigits: 3,
                            maximumFractionDigits: 3
                          })}{' '}
                          {item?.un?.abbreviation}
                        </p>
                      </td>
                      <td
                        width="13%"
                        className="lineLeft tdNegative tdParagraph textAlignRight"
                      >
                        {(item?.diffQuantity || 0)?.toLocaleString('pt-br', {
                          minimumFractionDigits: 3,
                          maximumFractionDigits: 3
                        })}{' '}
                        {item?.un?.abbreviation}
                      </td>
                      <td
                        width="20%"
                        className="lineLeft tdNegative textAlignRight"
                      >
                        - R${' '}
                        {Math.abs(item?.diffMonetary)?.toLocaleString('pt-br', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        })}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="7">
                        <hr className="m0" />
                      </td>
                    </tr>

                    {type === 'recipe'
                      ? handleRecipe(
                        item,
                        cardsLack.find((el) => el._id === item._id)?.open,
                        index
                      )
                      : type === 'transformation'
                        ? handleTransformation(
                          item,
                          cardsLack.find((el) => el._id === item._id)?.open,
                          index
                        )
                        : null}
                  </>
                );
              })}
            </tbody>

            <tfoot className='tfootNegative'>
              <tr>
                <th className="subtitle" colSpan={3}>
                  ATÉ 5 PRINCIPAIS NEGATIVAS{' '}
                </th>

                <th className="subtitle textAlignRight">
                  {(
                    ((totalDiffCalc(cardsLack.slice(0, 5)) * 100 / monetaryLack) || 0).toLocaleString('pt-br', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    })
                  )}{' '}
                  %
                </th>

                <th width="15%" className="subtitle lineLeft alignCollumn">
                  <div className="gapItens dFlex justifyBetween alignCenter">
                    <div className="totalNumber" style={{ color: "#000" }}>
                      {cardsLack.slice(0, 5).length || 0}
                    </div>

                    <p className="totalValue">
                      - R${' '}
                      {totalDiffCalc(cardsLack.slice(0, 5))?.toLocaleString(
                        'pt-br',
                        {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        }
                      )}
                    </p>
                  </div>
                </th>
              </tr>
            </tfoot>
          </table>
        </div>
      ) : null}

      {cardsLeftover.length ? (
        <div className="pRelative">
          <div className="headerSubtitlePositive">
            <div className="subheader">
              <th width="40.9%" className="subtitle" style={{ color: '#000' }}>
                FICHA{' '}
              </th>

              <th width="13.5%" className="subtitle textAlignRight lineLeft" style={{ color: '#000' }}>
                RENDIMENTO{' '}
              </th>

              <th width="13.4%" className="subtitle textAlignRight lineLeft" style={{ color: '#000' }}>
                PRODUZIDO{' '}
              </th>

              <th width="13.1%" className="subtitle textAlignRight lineLeft" style={{ color: '#000' }}>
                EM QTDE{' '}
              </th>
              <th width="19.1%" className="subtitle textAlignRight lineLeft" style={{ color: '#000' }}>
                EM R${' '}
              </th>
            </div>
          </div>

          <table
            className="tablePositive"
            width="100%"
            cellPadding="0"
            cellSpacing="0"
            border="0"
          >
            <thead className='theadPositive'>
              <tr className="headerTitle">
                <th className="subtitle" colSpan={4}>
                  SOBRAS{' '}
                </th>

                <th width="15%" className="subtitle lineLeft alignCollumn">
                  <div className="gapItens dFlex justifyBetween alignCenter">
                    <Tooltip
                      TransitionProps={{ timeout: 600 }}
                      placement="right"
                      title={
                        <span
                          className="dBlock"
                          style={{ padding: '4px 7px' }}
                        >
                          Visualizar todas as diferenças positivas
                        </span>
                      }
                    >
                      <Link
                        target="_blank"
                        to={{
                          pathname: `/producao/${getId}?&startAt=${moment(date).format(
                            'YYYY-MM-DD'
                          )}&endAt=${moment(date).format(
                            'YYYY-MM-DD'
                          )}&difference=leftover`
                        }}
                      >
                        <div className="totalNumberPositive" style={{ color: '#5062F0'}}>
                          {totally?.leftover?.total || 0}
                        </div>
                      </Link>
                    </Tooltip>

                    <p className="totalValue">
                      R${' '}
                      {monetaryLeftover?.toLocaleString('pt-br', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })}
                    </p>
                  </div>
                </th>
              </tr>
            </thead>
            
            <tbody>
              {cardsLeftover.slice(0, 5).map((item, index) => {
                const type = item?.type?.toLowerCase();
                return (
                  <>
                    <tr className="list">
                      <td
                        width="41%"
                        className={`titleLink ${index === 0 && 'pt20'} pb0`}
                        style={{ paddingLeft: '4px' }}
                      >
                        <List component="nav" className="list">
                          <ListItem className="listItem p0">
                            <Grid
                              container
                              spacing={0}
                              style={{
                                padding: 0,
                                flexWrap: 'unset',
                                alignItems: 'center'
                              }}
                            >
                              <Grid
                                className="dFlex alignCenter"
                                container
                                item
                                xs={12}
                              >
                                <Grid
                                  item
                                  xs={1}
                                  style={{
                                    padding: '0 5px',
                                    cursor: 'pointer'
                                  }}
                                  onClick={() =>
                                    handleOpenItems(
                                      item._id,
                                      setCardsLeftover,
                                      cardsLeftover
                                    )
                                  }
                                >
                                  {cardsLeftover.find(
                                    (el) => el._id === item._id
                                  )?.open ? (
                                    <ExpandLess />
                                  ) : (
                                    <ExpandMore />
                                  )}
                                </Grid>

                                <Grid
                                  item
                                  xs={10}
                                  style={{ padding: '0 0 0 5px' }}
                                >
                                  <Tooltip
                                    TransitionProps={{ timeout: 600 }}
                                    placement="right"
                                    title={
                                      <span
                                        className="dBlock"
                                        style={{ padding: '4px 7px' }}
                                      >
                                        {item?.cardId} -{' '}
                                        {handleFields(item).description}
                                      </span>
                                    }
                                  >
                                    <Link
                                      target="_blank"
                                      to={{
                                        pathname: `/producao/${getId}?cardId=${item?.cardId
                                          }&cardDescription=${item?.description
                                          }&status=${item?.status
                                          }&startAt=${moment(date).format(
                                            'YYYY-MM-DD'
                                          )}&endAt=${moment(date).format(
                                            'YYYY-MM-DD'
                                          )}`
                                      }}
                                    >
                                      <span>
                                        {handleFields(item).description
                                          ?.length > 14
                                          ? `${handleFields(
                                            item
                                          ).description?.substring(0, 33)}`
                                          : handleFields(item).description}
                                        {handleFields(item).description
                                          ?.length > 35 && '...'}
                                      </span>
                                    </Link>
                                  </Tooltip>
                                </Grid>
                              </Grid>
                              
                              <Tooltip
                                TransitionProps={{ timeout: 600 }}
                                placement="right"
                                title={
                                  <span
                                    className="dBlock"
                                    style={{ padding: '4px 7px' }}
                                  >
                                    {item?.todaySchedule ? 'Com agenda definida para esta data' : 'Sem agenda definida para esta data'} 
                                  </span>
                                }
                              >
                                <span>
                                  <CalendarIcon
                                    fill={
                                      item?.todaySchedule ? '#5062F0' : '#9296AC'
                                    }
                                  />
                                </span>
                              </Tooltip>
                            </Grid>
                          </ListItem>
                        </List>
                      </td>

                      <td
                        width="13.2%"
                        className="lineLeft tdParagraph textAlignRight"
                      >
                        {(item?.yield || 0).toLocaleString('pt-br', {
                          minimumFractionDigits: 3,
                          maximumFractionDigits: 3
                        })}{' '}
                        {item?.un?.abbreviation}
                      </td>

                      <td
                        width="13%"
                        className="titleLink lineLeft textAlignRight cursorPointer"
                      >
                        <p
                          className='m0'
                          onClick={() => handleOpenExtract(item)}
                        >
                          {(item?.manufactured || 0)?.toLocaleString('pt-br', {
                            minimumFractionDigits: 3,
                            maximumFractionDigits: 3
                          })}{' '}
                          {item?.un?.abbreviation}
                        </p>
                      </td>

                      <td
                        width="13%"
                        className="lineLeft tdParagraph textAlignRight tdPositive"
                        style={{ color: '#AA8208' }}
                      >
                        {(item?.diffQuantity || 0)?.toLocaleString('pt-br', {
                          minimumFractionDigits: 3,
                          maximumFractionDigits: 3
                        })}{' '}
                        {item?.un?.abbreviation}
                      </td>

                      <td
                        width="20%"
                        className="lineLeft tdNegative textAlignRight tdPositive"
                        style={{ color: '#AA8208' }}
                      >
                        R${' '}
                        {Math.abs(item?.diffMonetary)?.toLocaleString('pt-br', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        })}
                      </td>
                    </tr>

                    <tr>
                      <td colSpan="7">
                        <hr className="m0" />
                      </td>
                    </tr>

                    {type === 'recipe'
                      ? handleRecipe(
                        item,
                        cardsLeftover.find((el) => el._id === item._id)?.open,
                        index
                      )
                      : type === 'transformation'
                        ? handleTransformation(
                          item,
                          cardsLeftover.find((el) => el._id === item._id)?.open,
                          index
                        )
                        : null}
                  </>
                );
              })}
            </tbody>

            <tfoot className='tfootPositive'>
              <tr className="">
                <th className="subtitle" colSpan={3}>
                  ATÉ 5 PRINCIPAIS POSITIVAS{' '}
                </th>

                <th className="subtitle textAlignRight">
                  {(
                    ((totalDiffCalc(cardsLeftover.slice(0, 5)) * 100 / monetaryLeftover) || 0).toLocaleString('pt-br', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    })
                  )}{' '}
                  %
                </th>

                <th width="15%" className="subtitle lineLeft alignCollumn">
                  <div className="gapItens dFlex justifyBetween alignCenter">
                    <div className="totalNumberPositive" style={{ color: "#000" }}>
                      {cardsLeftover.slice(0, 5).length || 0}
                    </div>

                    <p className="totalValue">
                      R${' '}
                      {totalDiffCalc(cardsLeftover.slice(0, 5))?.toLocaleString(
                        'pt-br',
                        {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        }
                      )}
                    </p>
                  </div>
                </th>
              </tr>
            </tfoot>
          </table>

          {modalStates?.modalProduct && (
            <Suspense fallback={<span />}>
              <TheoryStockDialog
                getId={getId}
                checkConsume={checkConsume}
                setCheckConsume={setCheckConsume}
                openModal={modalStates}
                handleClose={handleClose}
                productExtractDescription={productExtractDescription}
                productModal={productModal}
                setProductModal={setProductModal}
                parameters={parameters}
                countDate={moment(new Date()).format('YYYY-MM-DD')}
                defaultStartDate={moment(date).subtract(7, 'days').format('YYYY-MM-DD')}
                originPage="productPage"
              />
            </Suspense>
          )}
        </div>
      ) : null}
    </>
  );
}

export default ProductionTab;
