import React, { useEffect, useState } from 'react';

import { format } from 'date-fns';
import moment from 'moment/moment';

import CountRow from './CountRow';
import NextCountRow from './NextCountRow';
import StockRow from './StockRow';
import TheoryOrRealStockRow from './TheoryOrRealStockRow';

function TheoryStockBody({
  extractItems,
  setExtractItems,
  checkConsume,
  fetchCards,
  productModal,
  extractDateAndDescription,
  extractType,
  modalStates,
  parameters,
  analyticFilter,
  handleGetDiffs,
  differenceInExtractTooltip,
  setDifferenceInExtractTooltip,
  getId,
  extractStartDate,
  extractEndDate,
  modalSettings,
  setModalSettings,
  setDrillDownInfo,
  drillDownInfo,
  setSelectedItem,
  showColumns
}) {
  const [selectedSales, setSelectedSales] = useState({
    extractItem: {},
    isUnique: true
  })

  const handleClickSales = (extractItem, isUnique = true, ) => {
    setSelectedSales({
      extractItem,
      isUnique
    });
    const checkConsumeValue = checkConsume ? 'sales_total' : 'sales_consumed';
    const date = format(new Date(`${extractItem.date.replaceAll('/', '-')}T03:00:00.000Z`), 'yyyy-MM-dd');
    const startDate = moment(extractStartDate);
    const newStartDate = moment(startDate).format('YYYY-MM-DD');
    const finalDate = moment(extractEndDate).format('YYYY-MM-DD');

    fetchCards(productModal, date, newStartDate, finalDate, isUnique, checkConsumeValue);
  };

  useEffect(()=>{
    if(modalStates.modalCards){
      handleClickSales(selectedSales.extractItem, selectedSales.isUnique);
    }
  },[checkConsume])

  return (
    <tbody>
      {extractItems.map((item, index) => (
        <TheoryOrRealStockRow
          extractItems={extractItems}
          setExtractItems={setExtractItems}
          index={index}
          isLastIndex={index === extractItems.length - 1}
          item={item}
          checkConsume={checkConsume}
          productModal={productModal}
          extractType={extractType}
          fetchCards={fetchCards}
          handleClickSales={handleClickSales}
          extractDateAndDescription={extractDateAndDescription}
          analyticFilter={analyticFilter}
          handleGetDiffs={handleGetDiffs}
          differenceInExtractTooltip={differenceInExtractTooltip}
          setDifferenceInExtractTooltip={setDifferenceInExtractTooltip}
          getId={getId}
          extractStartDate={extractStartDate}
          extractEndDate={extractEndDate}
          modalSettings={modalSettings}
          setModalSettings={setModalSettings}
          setDrillDownInfo={setDrillDownInfo}
          drillDownInfo={drillDownInfo}
          setSelectedItem={setSelectedItem}
          showColumns={showColumns}
        />
      ))}

      {extractItems.map((item) => (
        <StockRow
          item={item}
          extractItems={extractItems}
          parameters={parameters}
          productModal={productModal}
        />
      ))}

      <CountRow
        extractItems={extractItems}
        productModal={productModal}
        parameters={parameters}
      />

      <NextCountRow productModal={productModal} />
    </tbody>
  );
}

export default TheoryStockBody;
