/* eslint-disable no-nested-ternary */
import format from 'date-fns/format';
import ptLocale from 'date-fns/locale/pt-BR';

import ProductionIcon from 'images/icons/objects/bells/productionIcon.svg';
import CheckedCircleIcon from 'images/icons/signals/checks/checkedCircle.svg';
import CheckedCircleIconFull from 'images/icons/signals/checks/checkWhite.svg';
import CancelProduction from 'images/icons/signals/closesAndCancels/cancelProduction.svg';
import CancelProductionFull from 'images/icons/signals/closesAndCancels/closeModal.svg';
import ProductionIconFull from 'images/icons/signals/moreInfos/producingIcon.svg';
import PauseProductionIconFull from 'images/icons/signals/pausesAndStops/pauseIcon.svg';
import PauseProductionIcon from 'images/icons/signals/pausesAndStops/pauseProductionIcon.svg';
import playButton from 'images/icons/signals/playsAndStarts/playButton.svg';
import PlayButtonFull from 'images/icons/signals/playsAndStarts/playIcon.svg';
import PlayIconWhite from 'images/icons/signals/playsAndStarts/playIconWhite.svg';

const isBetween = (currentValue, min, max) => currentValue >= min && currentValue <= max;

export const handleFields = (data) => {
  const type = data?.type?.toLowerCase();
  const variationPercentage = data?.variationPercentage || 0;

  const typeOptions = {
    recipe: {
      pluralText: 'Ingredientes',
      singularText: 'Ingrediente'
    },
    transformation: {
      pluralText: 'Produtos',
      singularText: 'Produto'
    },
    default: {
      pluralText: 'Itens',
      singularText: 'Item'
    }
  };

  const description = data?.description
    ? `${data?.description} (${data?.items?.length} ${
        data?.items?.length > 1
          ? typeOptions[type]?.pluralText || typeOptions.default?.pluralText
          : typeOptions[type]?.singularText || typeOptions.default?.pluralText
      })`
    : null;

  const onlyDescription = data?.description
    ? `${data?.description}`
    : null;

  const stock = data?.stock
    ? `${data?.stock.toLocaleString('pt-br', { minimumFractionDigits: 3 })}`
    : `0,000`;

  const theoreticalStock = data?.theoreticalStock
    ? `${data?.theoreticalStock.toLocaleString('pt-br', { minimumFractionDigits: 3 })}`
    : `0,000`;

  const duration =
    data?.duration !== null
      ? `${data?.duration || 0} ${Number(data?.duration) == 1 ? 'dia' : 'dias'}`
      : '';

  const manufactureLimit = data?.limit
    ? `${format(new Date(data?.limit), 'kk:mm', { locale: ptLocale })}`
    : null;

  const manufactureYield = data?.yield
    ? `${data?.yield.toLocaleString('pt-br', { minimumFractionDigits: 3 })} ${
        data?.un?.abbreviation || ''
      }`
    : null;

  const newDuration =
    data?.newDuration >= 0 && data?.newDuration !== null
      ? `${data?.newDuration} ${Number(data?.newDuration) == 1 ? 'dia' : 'dias'}`
      : '';

  const lossManufacturedCalc =
    parseFloat(parseFloat(data?.manufactured)?.toFixed(3)) -
    parseFloat(parseFloat(data?.yield)?.toFixed(3));

  const lossManufactured = data?.manufactured
    ? `${lossManufacturedCalc.toLocaleString('pt-br', {
        minimumFractionDigits: lossManufacturedCalc === 0 ? 0 : 3
      })} ${data?.un?.abbreviation || ''}`
    : null;

  const diffPercentage = (100 * lossManufacturedCalc) / data?.yield;
  const formattedDiffPercentage = diffPercentage.toLocaleString('pt-BR', { minimumFractionDigits: 1, maximumFractionDigits: 1 });

  const lossManufacturedMonetary =
    data?.diffMonetary !== null
      ? `R$ ${
          lossManufacturedCalc < 0 && data?.diffMonetary !== 0 ? '-' : ''
        } ${data?.diffMonetary?.toLocaleString('pt-br', {
          minimumFractionDigits: 3
        })}`
      : null;

  const lossManufacturedStyle =
    data?.status !== 'FINISH'
      ? { textColor: '#454A67', bgColor: '' }
      : lossManufacturedCalc < 0
      ? { textColor: '#F53D4C', bgColor: '#F7D4D7' }
      : lossManufacturedCalc === 0
      ? { textColor: '#0E1337', bgColor: '#E6E7F0' }
      : { textColor: '#664C00', bgColor: '#FFF2CC' };

  const lossManufacturedMonetaryStyle =
    data?.status !== 'FINISH'
      ? { textColor: '#454A67', bgColor: '' }
      : 
      data?.diffMonetary === 0
      ? { textColor: '#0E1337', bgColor: '#E6E7F0' }
      : !isBetween(
          diffPercentage,
          variationPercentage * -1,
          variationPercentage
        )
      ? { textColor: '#F53D4C', bgColor: '#F7D4D7' }
      : { textColor: '#664C00', bgColor: '#FFF2CC' };

  let style = { textColor: '#454A67', bgColor: '#E6E7F0', background: '' };
  let monetaryStyle = { textColor: '#454A67', bgColor: '#E6E7F0', background: '#9296AC' };

  if (data?.status === 'FINISH') {
    const notDiff = lossManufacturedCalc === 0 || lossManufacturedCalc === NaN;
    if (notDiff) {
      style = { textColor: '#0E1337', bgColor: '#E6E7F0' };
    } else {
      const max = variationPercentage || 0;
      const min = variationPercentage ? variationPercentage * -1 : 0;
      const isBtw = isBetween(diffPercentage, min, max);

      if (isBtw) {
        style = { textColor: '#004D33', bgColor: '#D2F9D6', background: '#08AA15' };
        monetaryStyle = { textColor: '#0E1337', bgColor: '#E6E7F0', background: '#9296AC' };
      } else if (lossManufacturedCalc > 0) {
          style = { textColor: '#AA8208', bgColor: '#FFF2CC', background: '#AA8208' };
          monetaryStyle = { textColor: '#664C00', bgColor: '#FFF2CC', background: '#AA8208' };
        } else {
          style = { textColor: '#F53D4C', bgColor: '#F7D4D7', background: '#F53D4C' };
          monetaryStyle = { textColor: '#F53D4C', bgColor: '#F7D4D7', background: '#F53D4C' };
        }
    }

    if (data?.diffMonetary === 0) {
      monetaryStyle = { textColor: '#0E1337', bgColor: '#E6E7F0', background: '#9296AC' };
    }
  }

  const manufactured = data?.manufactured
    ? `${data?.manufactured.toLocaleString('pt-br', {
        minimumFractionDigits: 3
      })} ${data?.un?.abbreviation || ''}`
    : '-';

  return {
    description,
    onlyDescription,
    stock,
    theoreticalStock,
    type,
    duration,
    manufactureLimit,
    manufactureYield,
    newDuration,
    lossManufactured,
    lossManufacturedMonetary,
    manufactured,
    lossManufacturedStyle: style,
    lossManufacturedMonetaryStyle: monetaryStyle,
    lossManufacturedCalc,
    diffPercentage,
    formattedDiffPercentage
  };
};

export const productionCardStatus = {
  CANCELED: {
    statusText: 'Cancelado',
    statusTextRight: 'Cancelado',
    statusColor: '#AA0816',
    labelStatusColor: '#FFFFFF',
    labelStatusDialogColor: '#FFFFFF',
    labelBackGroundColor: '#AA0816',
    borderBackgroundColor: '#AA0816',
    icon: CancelProduction,
    iconFull: CancelProductionFull
  },
  WAITING: {
    statusText: 'Iniciar',
    statusTextRight: 'Clique para iniciar',
    statusColor: '#454A67',
    labelStatusColor: '#FFFFFF',
    labelStatusDialogColor: '#FFFFFF',
    labelBackGroundColor: '#454A67',
    borderBackgroundColor: '#454A67',
    icon: playButton,
    iconFull: playButton
  },
  START: {
    statusText: 'Produzindo',
    statusTextRight: 'Clique para finalizar',
    statusColor: '#5062F0',
    labelStatusColor: '#FFFFFF',
    labelStatusDialogColor: '#FFFFFF',
    labelBackGroundColor: '#5062F0',
    borderBackgroundColor: '#5062F0',
    icon: ProductionIcon,
    iconFull: PlayIconWhite
    // iconFull: ProductionIconFull playButton
  },
  PAUSE: {
    statusText: 'Pausado',
    statusTextRight: 'Clique para reiniciar',
    statusColor: '#AA8208',
    labelStatusColor: '#FFFFFF',
    labelStatusDialogColor: '#FFFFFF',
    labelBackGroundColor: '#AA8208',
    borderBackgroundColor: '#AA8208',
    icon: PauseProductionIcon,
    iconFull: PauseProductionIconFull
  },
  FINISH: {
    statusText: 'Finalizado',
    statusTextRight: 'Finalizado',
    statusColor: '#08AA15',
    labelStatusColor: '#FFFFFF',
    labelStatusDialogColor: '#FFFFFF',
    labelBackGroundColor: '#08AA15',
    borderBackgroundColor: '#08AA15',
    icon: CheckedCircleIcon,
    iconFull: CheckedCircleIconFull
  }
};
