/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';

import { LinearProgress, Tooltip, Typography } from '@material-ui/core';
import moment from 'moment/moment';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import * as UserActions from 'store/actions/user';
import ValidationLength from 'utils/utils';

function ShoppingInfosArea({ 
  shoppingInfos,
  shoppingOrderType,
  setShoppingOrderType,
  shoppingFirstDate,
  paramsID,
  actualDateLessOne,
  actualDateLessFourteen,
  shoppingTotalInfos
}) {
  const mainContent = shoppingInfos?.content
  const mainContentTotal = shoppingTotalInfos?.content?.length ? shoppingTotalInfos?.content[0] : ''

  const evolutionTagBg = (el) => {
    let bg = '#5062F0'

    if (!el) {
      bg = '#D0D3E2'
    }

    if (el > 0) {
      bg = '#F53D4C'
    }

    return bg
  }

  const firstDateCalcValue = () => {
    const totalValue = mainContentTotal?.totalCurrentWeek

    const totalDiff = mainContentTotal?.difference

    const totalEvolution = mainContentTotal?.percentageDifference

    const totalValueFormatted = totalValue
      ? `R$ ${totalValue?.toLocaleString('pt-br', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })}`
      : '-'

    const totalDiffFormatted = 
      totalDiff
      ? `${totalDiff > 0 ? '+' : totalDiff < 0 ? '-' : ''} 
        R$ 
        ${Math.abs(totalDiff)?.toLocaleString('pt-br', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })}`
      : '-'
    const totalEvolutionFormatted = isNaN(totalEvolution) 
      ? '-' 
      : `${totalEvolution?.toLocaleString('pt-br', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })}%`

    return {
      totalValueFormatted,
      totalDiff,
      totalDiffFormatted,
      totalEvolutionFormatted,
      totalEvolution
    }
  }

  return (
    <section className="cockpitShoppingInfosContainer cockpitDefaultContainer">
      <div className="graphicContainer">
        <div className="graphicBox">
          <div className="graphicItem">
            <div className="dFlex justifyBetween mb10">
              <div className="dFlex alignCenter">
                <span className="graphicTitle mb0 mr20">
                  compras
                </span>

                <span 
                  onClick={() => {
                    setShoppingOrderType('positiveEvolution')
                  }} 
                  className={`shoppingDefaultActionButton cursorPointer ${shoppingOrderType === 'positiveEvolution' ? 'active' : ''}`}
                >
                  inflação
                </span>

                <span 
                  onClick={() => {
                    setShoppingOrderType('negativeEvolution')
                  }} 
                  className={`shoppingDefaultActionButton deflationButton cursorPointer ${shoppingOrderType === 'negativeEvolution' ? 'active' : ''}`}
                >
                  deflação
                </span>
              </div>
              
              <Tooltip
                TransitionProps={{ timeout: 600 }}
                title={
                  <span
                    className="dBlock"
                    style={{ padding: '2px 5px' }}
                  >
                    Visualizar todos os skus
                  </span>
                }
              >
                <Link
                  target="_blank"
                  to={{
                    pathname: `/compras/evolucao-mensal/${paramsID}`
                  }}
                >
                  <div className="totalInfos">
                    <span>
                      {firstDateCalcValue()?.totalValueFormatted}
                    </span>

                    <span style={{ color: firstDateCalcValue()?.totalDiff > 0 ? "#F53D4C" : "#0B1A8E" }}>
                      {firstDateCalcValue()?.totalDiffFormatted}
                    </span>

                    <span style={{ background: evolutionTagBg(firstDateCalcValue()?.totalEvolution) }}>
                      {firstDateCalcValue()?.totalEvolutionFormatted}
                    </span>
                  </div>
                </Link>
              </Tooltip>
            </div>

            {shoppingInfos?.loading ? (
              <div className="dFlex">
                <LinearProgress className="w100 mb10" id="loading" variant="query" />
              </div>
            ) : 
              <Tooltip
                TransitionProps={{ timeout: 600 }}
                title={
                  <span
                    className="dBlock"
                    style={{ padding: '2px 5px' }}
                  >
                    Visualizar todos os skus
                  </span>
                }
              >
                <Link
                  target="_blank"
                  to={{
                    pathname: `/compras/evolucao-mensal/${paramsID}`
                  }}
                >
                  <table className="w100">
                    <thead>
                      <tr>
                        <th width="48%">
                          produto
                        </th>

                        <th width="5%" className="textAlignCenter pr8" style={{ borderRight: 'solid 1px #FFF' }}>
                          skus
                        </th>

                        <th width="16%" className="textAlignRight pr8" style={{ borderRight: 'solid 1px #FFF' }}>
                          volume
                        </th>

                        <th width="16%" className="textAlignRight pr8" style={{ borderRight: 'solid 1px #FFF' }}>
                          diferença total
                        </th>

                        <th width="15%" className="textAlignRight pr8">
                          evolução %
                        </th>
                      </tr>
                    </thead>

                    <tr>
                      <td colSpan="5" className="p0">
                        <hr
                          className="titleLineBorder m0"
                          style={{ borderColor: '#FFF' }}
                        />
                      </td>
                    </tr>

                    <tbody>
                      {mainContent?.length ? (
                        mainContent.map((item, itemIndex) => {
                          const isLastElement = itemIndex === mainContent?.length - 1;
                          const firstDateFormatted = moment(shoppingFirstDate).format('YYYY-MM-DD')
                          const firstDateEl = item?.weeks?.find(el => el?.date === firstDateFormatted)
                          
                          return (
                            <>
                              <tr>
                                <td className="description">
                                  {ValidationLength(item?.sku, 30)}
                                </td>

                                <td className="description textAlignCenter pr8" style={{ borderRight: 'solid 1px #FFF' }}>
                                  {item?.skuCount}
                                </td>

                                <td className="description textAlignRight pr8" style={{ borderRight: 'solid 1px #FFF' }}>
                                  {firstDateEl?.totalValue
                                    ? `R$ ${firstDateEl?.totalValue?.toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
                                    : '-'
                                  }
                                </td>

                                <td className="description textAlignRight pr8" style={{ color: firstDateEl?.difference > 0 ? "#F53D4C" : "#0B1A8E", borderRight: 'solid 1px #FFF' }}>
                                  {firstDateEl?.difference
                                    ? `${firstDateEl?.difference > 0 ? '+' : firstDateEl?.difference < 0 ? '-' : ''} 
                                      R$ 
                                      ${Math.abs(firstDateEl?.difference)?.toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
                                    : '-'
                                  }
                                </td>

                                <td className="description textAlignRight pr8">
                                  <span className="evolutionTag" style={{ background: evolutionTagBg(firstDateEl?.evolution) }}>
                                    {firstDateEl?.evolution 
                                      ? `${firstDateEl?.evolution?.toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}%` 
                                      : '-'
                                    }
                                  </span>
                                </td>
                              </tr>

                              {!isLastElement && (
                                <tr>
                                  <td colSpan="5" className="p0">
                                    <hr
                                      className="titleLineBorder m0"
                                      style={{ borderColor: '#FFF' }}
                                    />
                                  </td>
                                </tr>
                              )}
                            </>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan="5" className="description textAlignCenter">
                            <Typography className="fontSizeDefault" style={{ margin: '5px' }}>
                              Não há histórico de compras.
                            </Typography>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </Link>
              </Tooltip>
            }
          </div>
        </div>
      </div>
    </section>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.user
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(UserActions, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShoppingInfosArea);
