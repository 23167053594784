import React, { useState } from "react"
import { Button, Container, Dialog, DialogContent, Grid } from "@material-ui/core"
import { Formik, Form, Field } from "formik"
import { TextField } from "formik-material-ui"
import CommonAlert from "components/CommonAlert/CommonAlert"

function Index({
    open,
    close,
    userInfo,
    setProfileName,
    handleMenuItemClick
}) {

    const [alertErr, setAlertErr] = useState(false)
    const [messageError, setMessageError] = useState("")
    const onDismiss = () => setAlertErr(false)

    const handleSubmit = (values) => {
        const { password = undefined } = values

        if (!password) {
            setMessageError("ChangeUserTypePassword")
            setAlertErr(true)

            return false
        }

        handleMenuItemClick(userInfo?.userMaster, setProfileName([userInfo?.userMaster.profile], false), password)
    }

    return (
        <Dialog
            className="defaultModal selectUserPasswordDialog"
            open={open}
        >
            <DialogContent>
                <Container
                    className="alignCenter justifyCenter"
                    style={{
                        width: "100%",
                        height: "100%",
                        paddingLeft: "12px",
                        paddingRight: "12px"
                    }}
                >
                    <Formik
                        initialValues={{
                            password: ""
                        }}
                        style={{
                            width: "165px",
                            height: "40px"
                        }}
                        onSubmit={handleSubmit}
                    >
                        <Form
                            className="Form"
                        >
                            <Grid
                                item
                                xs={12}
                                className="w100"
                            >
                                <div
                                    className="bold fontSize14 fontColor"
                                >
                                    Senha
                                </div>
                                <Field
                                    component={TextField}
                                    type="password"
                                    name="password"
                                    placeholder="Insira sua senha"
                                    disabled={false}
                                    fullWidth
                                    className="inputStyle"
                                />
                            </Grid>
                            <div
                                className="dFlex justifyCenter"
                                style={{
                                    marginTop: "9px",
                                    marginLeft: "7px"
                                }}
                            >
                                <Button
                                    variant="contained"
                                    className="backButton"
                                    style={{
                                        display: "flex",
                                        borderRadius: "8px",
                                        fontSize: "12px",
                                        fontFamily: "Lato",
                                        marginRight: "8px",
                                        width: "78px",
                                        height: "40px",
                                        padding: "12px 16px",
                                        lineHeight: "normal",
                                        textTransform: "initial",
                                        letterSpacing: "normal"
                                    }}
                                    onClick={close}
                                >
                                    Cancelar
                                </Button>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    className="submitButton"
                                    style={{
                                        display: "flex",
                                        borderRadius: "8px",
                                        fontSize: "12px",
                                        fontWeight: "700",
                                        fontFamily: "Lato",
                                        marginRight: "8px",
                                        width: "66px",
                                        height: "40px",
                                        padding: "12px 16px",
                                        lineHeight: "normal",
                                        textTransform: "initial",
                                        letterSpacing: "normal"
                                    }}
                                >
                                    Trocar
                                </Button>
                            </div>
                        </Form>
                    </Formik>
                </Container>
                <CommonAlert
                    open={alertErr}
                    onClose={onDismiss}
                    indexMessage={messageError}
                />
            </DialogContent>
        </Dialog>
    )
}

export default Index