/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';

import { Tooltip, useMediaQuery } from '@material-ui/core';
import Carousel from 'react-multi-carousel';

import Report from 'images/icons/files/report.svg';
import ReportWhite from 'images/icons/files/whiteReport.svg';
import ValidationLength from 'utils/utils';

import ButtonGroup from '../ButtonGroup';

function CountGroupCarousel({
  setCountId,
  setCountIdArray,
  setCountGroupInfos,
  countGroupInfos,
  inBase,
  getReportInfos,
  loading,
  loadingReportInfos
}) {
  const counts = countGroupInfos?.countGroup?.counts?.filter((el) => el?.id);
  const numberItems = counts?.filter((item) => item?.id)
  const isMobileScreen = useMediaQuery('(max-width: 870px)');

  console.log(counts)

  const reportCounts = countGroupInfos?.countGroup?.counts?.filter((el) => el?.type === 'report');

  const positionIcon = () => {
    if (counts?.length < 5) {
      return 'noArrows'
    } 
    
    if (counts?.length === 5) {
      return 'limitCounts'
    } 
    
    return 'withArrows'
  }

  return (
    <div
      className={`${
        counts?.length ? 'dFlex' : 'dNone'
      } countTabsContainer sticky`}
    >
      <div
        className="tabsCarouselContainer"
        style={{
          maxWidth: isMobileScreen ? '560px' : '1027px'
        }}
      >
        {counts?.length && countGroupInfos?.isCountGroup && (
          <Carousel
            customButtonGroup={<ButtonGroup isMobileScreen={isMobileScreen} numberItems={numberItems?.length} counts={counts} />}
            arrows={false}
            centerMode={false}
            infinite={false}
            shouldResetAutoplay={false}
            containerClass="container"
            dotListClass=""
            focusOnSelect={false}
            itemClass=""
            keyBoardControl
            renderButtonGroupOutside
            renderDotsOutside={false}
            responsive={{
              desktop: {
                breakpoint: {
                  max: 3000,
                  min: 1024
                },
                items: 5,
                partialVisibilityGutter: 40
              },
              mobile: {
                breakpoint: {
                  max: 464,
                  min: 0
                },
                items: 1,
                partialVisibilityGutter: 30
              },
              tablet: {
                breakpoint: {
                  max: 1024,
                  min: 464
                },
                items: 3,
                partialVisibilityGutter: 40
              }
            }}
            showDots={false}
            slidesToSlide={isMobileScreen ? 3 : 5}
            swipeable={false}
          >
            {counts?.length &&
              counts?.map((count) => (
                <div
                  className={`countTabItem ${count?.active && 'active'} ${loading || loadingReportInfos ? 'onLoading' : ''}`}
                  onClick={() => {
                    if(loading || loadingReportInfos) return
                    setCountGroupInfos({
                      ...countGroupInfos,
                      countGroup: {
                        ...countGroupInfos?.countGroup,
                        counts: countGroupInfos?.countGroup?.counts.map((item) => {
                          if (item?.id === count.id) {
                            return {
                              ...item,
                              active: true
                            };
                          }

                          return {
                            ...item,
                            active: false
                          };
                        })
                      }
                    });

                    if (count?.type !== 'report') {
                      setCountId(count.id)
                      setCountIdArray([]);
                    } else {
                      getReportInfos();
                      setCountId();
                    }
                  }}
                >
                  <p className="mb0">
                    {ValidationLength(
                      count?.setup?.storageLocation?.description,
                      isMobileScreen ? 18 : 23
                    )}
                  </p>
                </div>
              ))}
          </Carousel>
        )}
          <div className={`MarginButton ${positionIcon()}`}>
            <div
              onClick={() => {
                if (loading || loadingReportInfos) return
                setCountGroupInfos({
                  ...countGroupInfos,
                  countGroup: {
                    ...countGroupInfos?.countGroup,
                    counts: countGroupInfos?.countGroup?.counts.map((item) => {
                      if (item?.id === reportCounts[0]?.id) {
                        return {
                          ...item,
                          active: true
                        };
                      }

                      return {
                        ...item,
                        active: false
                      };
                    })
                  }
                });

                if (reportCounts[0]?.type !== 'report') {
                  setCountId(reportCounts[0]?.id)
                  setCountIdArray([]);
                } else {
                  getReportInfos();
                  setCountId();
                  setCountIdArray(counts.map((item) => item?.setup?.storageLocationId));
                }
              }}
              className={`ReportButton ${reportCounts[0]?.active && 'active'}`}
            >
              <Tooltip
                placement="top"
                title={
                <p
                  className="mb0"
                  style={{ padding: '4px 2px' }}
                >
                  Visualizar relatório
                </p>
                }
              >
                <img src={reportCounts[0]?.active ? ReportWhite : Report} alt="Report" />
              </Tooltip>
            </div>
          </div>
      </div>
    </div>
  );
}

export default CountGroupCarousel;
